<div class="container-send-confirmation-code">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="form">
            <div class="row">
              <div class="row">
                <div class="col-12 container-send-confirmation-code--title">
                  <h2>Forgot your password?</h2>
                  <p>Enter your Email below and we will send a message to reset your password.</p>
                </div>
              </div>
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Email</mat-label>
                <input formControlName="email"
                       id="account_email"
                       matInput
                       type="email"
                >
              </mat-form-field>
              <div *ngIf="emailField!.touched && emailField!.invalid">
                <mat-error *ngIf="emailField!.hasError('pattern')">
                  Please enter a valid email address
                </mat-error>
              </div>
            </div>
            <div class="container-send-confirmation-code--submit">
              <button (click)="submit()" [ngStyle]="{'background':'#005eac'}"
                      mat-raised-button>
                Send email
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>


</div>

