// src/app/features/login/components/smart/login-main/login-main.component.ts
import {Component, OnInit, ViewChild} from '@angular/core';
import {AmplifyService} from "@features/login/services";
import {AuthService} from "@core/services";
import {Router} from "@angular/router";
import {GlobalConstants} from "@shared/utility/global-constants";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {LoadingService} from "@shared/services/loading.service";
import {mergeMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {REQUIRED_FIELDS, TITLE_INFO} from "@shared/utility/popup-error";
import {IS_ON_TIMEOUT, LocalStorageUtilityService} from "@core/services/utilities/localStorageUtility.service";
import {ValidationData} from "@core/model/validation-data";
import {IpUtilityService} from "@core/services/utilities/ip.service";
import {showErrorPopup, showSomethingWrongPopup1, TITLE_ERROR} from "@shared/utility/popup-message";
import {SignInOutput} from 'aws-amplify/auth';
import {QRCodeComponent} from '../qrcode/qrcode.component';
import {MatDialog} from "@angular/material/dialog";
import {TOTPConfirmationComponent} from "@features/login/components/smart/totp-confirmation/totp-confirmation.component";

@Component({
  selector: 'app-login-main',
  templateUrl: './login-main.component.html',
  styleUrls: ['./login-main.component.scss']
})
export class LoginMainComponent implements OnInit {
  @ViewChild(QRCodeComponent) qrCodeComponent!: QRCodeComponent;

  loginForm: UntypedFormGroup;
  accountForm: UntypedFormGroup;
  validationData: ValidationData;
  isAuthenticated: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private amplifyService: AmplifyService,
    private router: Router,
    private ipUtilityService: IpUtilityService,
    private loader: LoadingService,
    public authService: AuthService,
    private localStorageUtility: LocalStorageUtilityService,
    public dialog: MatDialog
  ) {
    this.loginForm = this.formBuilder.group({
      account_information: [''],
    });
    this.accountForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [
        Validators.required,
        Validators.minLength(6)]],
    })

    this.authService.isAuthenticated().then(value => {
      this.isAuthenticated = value
    });
    this.validationData = new ValidationData();
  }

  get emailField() {
    return this.loginForm.get('account_information')?.value.email;
  }

  get passwordField() {
    return this.loginForm.get('account_information')?.value.password;
  }

  ngOnInit(): void {
    if (this.isAuthenticated) {
      this.router.navigate(['home']).then(() => window.location.reload());
    }
  }

  submit() {
    if (this.loginForm.valid) {
      this.login();
    } else {
      this.accountForm.markAllAsTouched();
      showSomethingWrongPopup1(TITLE_INFO, REQUIRED_FIELDS)
    }
  }

  login() {
    this.loader.show();
    this.ipUtilityService.ipData
    .pipe(mergeMap((resultIp: any) => {
        this.validationData.ip = resultIp.body;
        return this.amplifyService.loginWithCognito(this.emailField, this.passwordField, this.validationData);
      }),
      catchError((err) => {
        console.log(err);
        this.validationData.ip = GlobalConstants.DEFAULT_IP_ADDRESS;
        return this.amplifyService.loginWithCognito(this.emailField, this.passwordField, this.validationData);
      })
    ).subscribe({
      next: ((resultSignIn: SignInOutput) => {
        this.loader.hide();
        if (resultSignIn.isSignedIn) {
          this.redirect();
          this.localStorageUtility.createItem(IS_ON_TIMEOUT, false)
        } else {
          switch (resultSignIn.nextStep.signInStep) {

            case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
              const totpSetupDetails = resultSignIn.nextStep.totpSetupDetails;
              const appName = 'DistritechApp';
              const setupUri = totpSetupDetails.getSetupUri(appName);
              const dialogRef = this.dialog.open(QRCodeComponent, {
                width: '800px',
                height: '600px',
              })
              let instance = dialogRef.componentInstance;
              instance.qrCode = setupUri.href;
              dialogRef.afterClosed().subscribe({
                next: () => {
                  console.log('Closed');
                }
              })
              break;
            case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
              const confirmationDiagRef = this.dialog.open(TOTPConfirmationComponent, {
                width: '700px',
                height: '400px',
              })
              let confirmation = confirmationDiagRef.componentInstance;
              // instance.qrCode = setupUri.href;
              confirmationDiagRef.afterClosed().subscribe({
                next: () => {
                  console.log('Closed');
                }
              })
          }
        }
      }),
      error: (err: any) => {
        this.loader.hide();
        showErrorPopup(TITLE_ERROR, err.message).then(() => {
          this.router.navigate(['login']);
        });
      }
    })
  }

  redirect() {
    //TODO: This must works with new views
    this.router.navigate(['admin'])
    .then(() => window.location.reload());
  }
}
