import {Component, OnInit} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {
  DealsNotificationsDto
} from '@features/product-offer/model/notifications/deals-notifications.dto';
import {ActivatedRoute, Router} from "@angular/router";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {PurchaseOrderService} from "@features/product-offer/services/purchase-order.service";
import {ShareDataService} from "@shared/services/share-data.service";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {
  SapPurchaseOrderDto
} from "@features/product-offer/model/offer-purchase-order/sap-purchase-order.dto";
import {
  SapPurchaseOrderDetailDto
} from "@features/product-offer/model/offer-purchase-order/sap-purchase-order-detail.dto";
import {ProductService} from "@features/product/services/product.service";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {
  SavePurchaseOrderDto
} from "@features/product-offer/model/offer-purchase-order/save-purchase-order.dto";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";


@Component({
  selector: 'app-offer-purchase-order-main',
  templateUrl: './offer-purchase-order-main.component.html',
  styleUrls: ['./offer-purchase-order-main.component.scss']
})
export class OfferPurchaseOrderMainComponent implements OnInit {


  poCreationForm!: UntypedFormGroup;
  poSelectionForm!: UntypedFormGroup;
  searchVendorForm!: UntypedFormGroup;
  selectWarehouseForm!: UntypedFormGroup;
  addItemToOrderForm!: UntypedFormGroup;
  purchaseOrderForm!: UntypedFormGroup;
  selection_customer!: UntypedFormGroup;
  selection_sales!: UntypedFormGroup;


  itemId!: number;
  sapCode!: string;
  listOffers: DealsNotificationsDto [] = [];
  eventId!: number;


  constructor(private formBuilder: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute,
              private productService: ProductService,
              private purchaseService: PurchaseOrderService,
              private authService: AuthService,
              private router: Router,
              private shareDataService: ShareDataService) {

    this.poSelectionForm = this.buildPoSelectionForm();
    this.poCreationForm = this.buildPoCreationForm();
    this.searchVendorForm = this.buildSearchVendorForm();
    this.selectWarehouseForm = this.buildSelectWarehouseForm();
    this.addItemToOrderForm = this.buildAddItemToOrderForm();
    this.purchaseOrderForm = this.buildPurchaseOrderForm();

  }

  get selection_products() {
    return this.poSelectionForm.get('selection_products');
  }

  get createInitialPoField() {
    return this.poCreationForm.get('selection_of_option');
  }

  get vendorField() {
    return this.searchVendorForm.get('vendor');
  }

  get selectWarehouseField() {
    return this.selectWarehouseForm.get('store_selected');
  }

  get purchaseOrderField() {
    return this.purchaseOrderForm.get('purchase_order') as FormArray;
  }

  get landedCostField() {
    return this.purchaseOrderForm.get('landed_cost');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(argsToParams => {
      this.itemId = argsToParams['id'];
      this.sapCode = decodeURIComponent(argsToParams['skuSap']);

      if (argsToParams['id'] != 'undefined' || argsToParams['skuSap'] != 'undefined') {
        console.log(argsToParams)
        if (this.itemId && (this.sapCode === 'undefined')) {
          this.productService.getItemById(this.itemId).subscribe({
            next: (value: ItemDto) => {
              let skuSap = ProductUtility.findSapCode(value.itemCustomized);
              if (skuSap) {
                value.sku = skuSap.itemCustomizedValue;
              }
              this.purchaseOrderField.push(this.buildOrderForm(value));
              console.log(this.purchaseOrderField)
            }, error: () => {
              showErrorPopup(TITLE_INFO, CONTACT_SUPPORT);
            }
          })
        } else {
          this.getSapItem();
        }
      }


    })
  }

  createPo() {
    let anyParamMissing =
      this.purchaseOrderField.controls.filter(item => !item.get('item_quantity')?.value
        || !item.get('item_unit_cost')?.value);

    if (anyParamMissing && anyParamMissing.length > 0) {
      showSomethingWrongPopup1(TITLE_INFO, "Any of the items is missing unit cost or quantity, please check")
      return;
    }

    let sapPurchaseOrderDto = new SapPurchaseOrderDto();
    let sapPurchaseOrderDetailList: SapPurchaseOrderDetailDto[] = [];
    let savePurchaseOrderDto = new SavePurchaseOrderDto();


    this.authService._getFromPayload("id_user").then(userId => {
      sapPurchaseOrderDto.landedCost = this.landedCostField?.value ? 'Yes' : null;
      sapPurchaseOrderDto.source = "Purchasing";
      this.purchaseOrderField.controls.forEach(item => {
        let sapPurchaseOrderDetail = new SapPurchaseOrderDetailDto();
        sapPurchaseOrderDetail.sku = item.get('item_sku')?.value;
        sapPurchaseOrderDetail.quantity = item.get('item_quantity')?.value;
        sapPurchaseOrderDetail.price = item.get('item_unit_cost')?.value;
        sapPurchaseOrderDetail.warehouseCode = this.selectWarehouseField?.value?.warehouseCode;
        sapPurchaseOrderDetail.creationUser = userId;
        sapPurchaseOrderDetailList.push(sapPurchaseOrderDetail);
        let listOffer = new DealsNotificationsDto();
        listOffer.sku = sapPurchaseOrderDetail.sku;
        listOffer.cost = sapPurchaseOrderDetail.price;
        listOffer.itemDescription = item.get('item_description')?.value;
        listOffer.url = item.get('url')?.value;
        this.listOffers.push(listOffer)
      })
      savePurchaseOrderDto.purchaseOrder = sapPurchaseOrderDto;
      savePurchaseOrderDto.sapPurchaseOrderDetail = sapPurchaseOrderDetailList;
      sapPurchaseOrderDto.cardCode = this.vendorField?.value.carCode;
      sapPurchaseOrderDto.creationUser = userId;
      this.purchaseService.save(savePurchaseOrderDto).subscribe({
        next: value => {
          showSuccessPopup(TITLE_INFO, value.message).then(() => {
            if (value?.status) {
              this.listOffers.forEach(offer => offer.idEvent = value.idEvent);
              console.log(value, "Aqui")
              console.log(this.listOffers, "tres")
              this.shareDataService.changeMessage(this.listOffers);
              this.router.navigate(['/product-offer/notification'])
            } else {
              this.listOffers = [];
            }
          })
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    })


  }

  private getSapItem() {
    let getItemsParams = new GetItemParamsDto();
    getItemsParams.type = "sku";
    getItemsParams.value = this.sapCode;
    console.log(getItemsParams)
    this.productService.geSapProduct(getItemsParams)
      .subscribe({
        next: (finalResult: ItemDto[]) => {
          console.log(finalResult)
          if (finalResult.length > 0) {
            this.purchaseOrderField.push(this.buildOrderForm(finalResult[0]));
          }
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
  }

  private buildPoSelectionForm() {
    return this.formBuilder.group({
      selection_products: ['', [Validators.required]],
    })
  }

  private buildPoCreationForm() {
    return this.formBuilder.group({
      selection_of_option: ['', [Validators.required]],
    })
  }

  private buildSearchVendorForm() {
    return this.formBuilder.group({
      search_type: ['', [Validators.required]],
      search_value: ['', [Validators.required]],
      vendor: ['', [Validators.required]],
    })
  }

  private buildAddItemToOrderForm() {
    return this.formBuilder.group({
      type_search_product: ['', [Validators.required]],
      product_search_value: ['', [Validators.required]],
      product_select_value: this.formBuilder.array([]),
    });
  }

  private buildSelectWarehouseForm() {
    return this.formBuilder.group({
      store_selected: ['', Validators.required]
    })
  }

  private buildPurchaseOrderForm() {
    return this.formBuilder.group({
      purchase_order: this.formBuilder.array([]),
      landed_cost: [false]
    })
  }

  private buildOrderForm(item: ItemDto) {
    return this.formBuilder.group({
      item_sku: [item.sku],
      item_description: [item.itemDescription],
      item_quantity: [null, Validators.required],
      item_unit_cost: [null, Validators.required],
      item_total: [null, Validators.required],
    })
  }
}
