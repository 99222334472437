import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {MatStepper} from "@angular/material/stepper";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {
  CONTACT_SUPPORT,
  INVALID_OR_MISSING_FIELDS,
  TITLE_ERROR,
  TITLE_INFO
} from "@shared";
import {RequestService} from "@features/request/services/request.service";
import {Router} from "@angular/router";
import {RequestStatus} from "@features/request/model/request-status";
import {Category} from "@core/model/category";
import {ItemUtility} from "@features/product/model/utility/itemUtility";
import {ProductService} from "@features/product/services/product.service";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-request-main',
  templateUrl: './request-main.component.html',
  styleUrls: ['./request-main.component.scss']
})
export class RequestMainComponent implements OnInit {

  form = new FormGroup({
    type_search_product: new FormControl(""),
    product_search_value: new FormControl('')
  })
  requestInformationForm!: FormGroup;
  formRequested!: UntypedFormGroup;
  searchVendorForm!: FormGroup;
  product!: ItemDto | null;
  @Input()
  request: WRequestDto = new WRequestDto();

  categories: Category[] = [];


  @ViewChild('stepper') myStepper!: MatStepper;

  constructor(private formBuilder: FormBuilder,
              private requestService: RequestService,
              private router: Router,
              private authService: AuthService,
              private productService: ProductService) {
    this.searchVendorForm = this.buildSearchVendorForm();
    this.requestInformationForm = this.buildRequestInformationForm();
  }

  get subCategoriesField() {
    return this.requestInformationForm.get('sub_categories') as FormArray;
  }

  get categoryField() {
    return this.requestInformationForm.get('category');
  }

  get lastCategory() {
    if (this.subCategoriesField.controls.length > 0) {
      return this.subCategoriesField?.controls.slice(-1)[0].get('sub_category')?.value;
    }
    return '';
  }

  ngOnInit(): void {
    this.getCategories();
  }

  productSelected(product: ItemDto | null) {
    if (product) {
      this.product = product;
      this.requestInformationForm.patchValue({
        description: this.product?.itemDescription,
        partNumber: this.product?.partNumber,
        upc: this.product?.itemCodes[0]?.itemCode,
        sku: this.product?.sku,
      })
    } else {
      this.requestInformationForm.reset();
      this.product = null;
    }
    this.moveStep(1);

  }

  moveStep(index: number) {
    this.myStepper.selectedIndex = index;
  }

  saveRequest() {
    try {
      console.log(this.request);
      this.requestService.savePurchaseOrder(this.request).subscribe({
        next: (value: any) => {
          if (value.status) {
            showSuccessPopup(TITLE_INFO, "Request created successfully").then(() => window.location.reload())
          } else {
            showSomethingWrongPopup1(TITLE_ERROR, CONTACT_SUPPORT);
          }
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
        }
      })
    } catch (error) {
      showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
    }
  }

  buildData() {
    if (this.product) {

      this.categoryField?.setValue(
        (this.product.itemCategories && this.product.itemCategories.parentCode)
          ? this.product.itemCategories.parentCode : 'NA');
      this.buildSubCategory(this.product?.itemCategories?.code);
    }
    if (this.requestInformationForm.valid && this.getVendorField()?.valid) {
      this.authService._getFromPayload("id_user").then(userId => {
        this.request = this.requestInformationForm.value;
        this.request.idRequested = Number(userId);
        this.request.cardCode = this.getVendorField()?.value.carCode;
        this.request.status = RequestStatus.PENDING;
        this.request.category = this.categoryField?.value;
        this.request.subCategory = this.lastCategory;
        this.saveRequest();
      });

    } else {
      this.requestInformationForm.markAllAsTouched();
      showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS);
    }
  }

  buildRequestInformationForm() {
    return this.formBuilder.group({
      description: ["", [Validators.required, Validators.maxLength(200)]],
      partNumber: ["", [Validators.maxLength(20)]],
      upc: ["", [Validators.minLength(12), Validators.maxLength(13)]],
      techSpec: [""],
      priceMin: [null],
      priceMax: [null],
      quantityMin: [null, [Validators.required,]],
      quantityMax: [null, [Validators.required]],
      eta: [""],
      paymentTerms: [""],
      idRequested: [""],
      sku: [""],
      cardCode: [""],
      category: ['', Validators.required],
      sub_categories: this.formBuilder.array([]),
    }, {
      validators: [this.minPriceAndMaxPriceValidator, this.quantityMaxAndMinValidator]
    })
  }

  getVendorField() {
    return this.searchVendorForm.get('vendor');
  }

  validateForm() {
    if (this.getVendorField()?.valid) {
      this.moveStep(2)
    } else {
      showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS).then(() => this.moveStep(1))
    }
  }

  buildSubCategory(parentKey?: string) {
    this.subCategoriesField.push(this.createSubCategoryField(parentKey));
  }

  getCategories() {
    this.productService.getCategories().subscribe({
      next: value => {
        ItemUtility.setCategory(value.body, this.categories);
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    })
  }

  private createSubCategoryField(parentKey?: string) {
    return this.formBuilder.group({
      sub_category: [parentKey ? parentKey : ''],
    });
  }

  private buildSearchVendorForm() {
    return this.formBuilder.group({
      search_type: ['', [Validators.required]],
      search_value: ['', [Validators.required]],
      vendor: ['', [Validators.required]],
    })
  }

  private minPriceAndMaxPriceValidator: any = (form: FormGroup) => {
    // @ts-ignore
    const start = form.get('priceMin').value;
    // @ts-ignore
    const end = form.get('priceMax').value;
    if (start !== null && end !== null && start <= end) {
      return null
    } else if (start === null && end === null) {
      return null
    } else {
      return {max_price_validator: true}
    }
  }

  private quantityMaxAndMinValidator: any = (form: FormGroup) => {
    // @ts-ignore
    const min = form.get('quantityMin').value;
    // @ts-ignore
    const max = form.get('quantityMax').value;
    return min !== null && max !== null && min <= max
      ? null
      : {max_quantity_validator: true};
  }


}
