<div class="marketplace-dashboard-container">
  <div class="marketplace-dashboard-container--title" (click)="toggle(filters)">
    <span>FILTER BY
      <mat-icon>{{ filterOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </span>
    <h2>MARKETPLACE DASHBOARD</h2>
  </div>

  <mat-card class="marketplace-dashboard-container--card">
    <mat-card-content #filters id="filters">
      <form class="row marketplace-dashboard-container--card__form" [formGroup]="form">
        <div class="col-6 marketplace-dashboard-container--card__form--field">
          <mat-label>Buyer</mat-label>
          <mat-form-field appearance="outline" class="small">
            <mat-select formControlName="buyer"
                        (valueChange)="changeSet($event)"
                        placeholder="Select">
              <mat-option *ngFor="let buyer of buyers" [value]="buyer">
                {{ buyerName(buyer) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 marketplace-dashboard-container--card__form--field">
          <mat-label>Vendor</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="vendor" multiple placeholder="Select">
              <mat-option *ngFor="let customer of customers" [value]="customer.cardCode">
                <span> {{ customer.cardCode + ' - ' + customer.businessName }}</span>
                <br>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 marketplace-dashboard-container--card__form--field">
          <mat-label>Start Date</mat-label>
          <mat-form-field appearance="outline">
            <input [matDatepicker]="picker1"
                   formControlName="startDate"
                   matInput
                   [min]="minDate"
                   [max]="maxDate"
                   placeholder="MM/DD/YYYY">
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4 marketplace-dashboard-container--card__form--field">
          <mat-label>End Date</mat-label>
          <mat-form-field appearance="outline">
            <input [matDatepicker]="picker2"
                   formControlName="endDate"
                   matInput
                   [min]="minDate"
                   [max]="maxDate"
                   placeholder="MM/DD/YYYY"
            >
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4 marketplace-dashboard-container--card__form--field">
          <mat-label>Granularity</mat-label>
          <mat-form-field appearance="outline" [ngStyle]="{'border-color': '#29aae3'}">
            <mat-select formControlName="granularity" placeholder="Select">
              <mat-option value="P1D">Day</mat-option>
              <mat-option value="P1W">Week</mat-option>
              <mat-option value="P1M">Month</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="marketplace-dashboard-container--card__form--actions">
          <button (click)="reset()"
                  mat-raised-button
                  [ngStyle]="{'background':'#efefef'}">
            Reset filter
            <mat-icon>restart_alt</mat-icon>
          </button>
          <button mat-raised-button
                  (click)="filterDashboard()"
                  [disabled]="form.invalid"
                  [ngStyle]="{'background':'#005eac'}">
            Apply filters
          </button>
        </div>
      </form>


    </mat-card-content>
  </mat-card>
  <div #filters id="dashboard-market">
    <div class="superset-dashboard" style="background: white">
      <div id="dashboard" style="background: white"></div>
    </div>
  </div>

</div>
