import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ProductService} from "@features/product/services/product.service";
import {Router} from "@angular/router";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ItemCode} from "@features/product/model/enum/item-code";
import {Category} from "@core/model/category";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {mergeMap, of} from "rxjs";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {ImageType} from "@features/product/model/enum/image-type";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss']
})
export class NewOfferComponent {

  products: ItemDto[] = [];

  @Output()
  productFound = new EventEmitter();

  @Input()
  form!: FormGroup;

  @Input()
  manufacturers: ProductMasterManufacturer[] = [];


  @Output()
  callProductStateValidation = new EventEmitter();

  constructor(private productService: ProductService, private router: Router) {
  }

  get typeSearchProduct() {
    return this.form.get('type_search_product');
  }

  get productSearchValue() {
    return this.form.get('product_search_value');
  }

  get productSelectValue() {
    return this.form.get('product_select_value');
  }

  getProduct() {
    let params = {
      itemCodeType: null,
      itemCode: null,
      sku: null,
      partNumber: null
    }
    let value: any = this.productSearchValue?.value;

    switch (this.typeSearchProduct?.value) {
      case "upc":
      case "ean":
        params.itemCodeType = this.typeSearchProduct?.value
        params.itemCode = value
        break;
      case "sku":
        params.sku = value
        break;
      case "partNumber":
        params.partNumber = value
        break;
      default:
        showSomethingWrongPopup1(TITLE_INFO, "Choose an option, please check!")
        break;
    }
    this.callProducts(params)
    console.log(params)


  }

  getProductSelect(product: ItemDto) {
    if (product && product.id) {
      let skuSap = ProductUtility.findSapCode(product.itemCustomized);
      let encodeSku = encodeURIComponent(skuSap ? skuSap.itemCustomizedValue : product.sku);
      this.router.navigate([`product-offer/offer-validation/create/${product.id}/${encodeSku}`])
    } else if (product) {
      let productConverted = this.convertTo(product);
      this.productSelectValue?.setValue(productConverted);
      this.productFound.emit(productConverted);
    }

  }

  getColor(itemDto: ItemDto) {
    let findColor = itemDto.itemSpecifications.find(spec => spec.specifications === 'color');
    return findColor ? findColor.descriptionItemSpecific : '';
  }

  upcField(product: ItemDto) {
    return ProductUtility.getItemCode(product!.itemCodes, ItemCode.UPC);
  }

  eanField(product: ItemDto) {
    return ProductUtility.getItemCode(product!.itemCodes, ItemCode.EAN);
  }

  private callProducts(params: any) {
    this.productService.getAllProductsLike(params)
    .pipe(mergeMap((masterProductResult: ItemDto[]) => {
      if (masterProductResult.length > 0) {
        console.log(masterProductResult)
        return of(masterProductResult);
      } else {
        let getItemsParams = new GetItemParamsDto();
        getItemsParams.type = this.typeSearchProduct?.value;
        getItemsParams.value = this.productSearchValue?.value;
        return this.productService.geSapProduct(getItemsParams);
      }
    })).subscribe({
      next: (finalResult: ItemDto[]) => {
        console.log(finalResult)
        if (finalResult.length > 0) {
          this.products = finalResult;
        } else {
          this.products = [];
          showSomethingWrongPopup1(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_FOUND);
        }

      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })

  }

  private validateManufacturer(product: ProductsDto) {
    if (product && product.idManufacturers) {
      console.log(product)
      let isManufacturerFound = this.manufacturers.find(value => value.ditmmnf_code === product?.idManufacturers);
      if (isManufacturerFound) {
        let manufacturer = new ProductMasterManufacturer();
        manufacturer.ditmmnf_front_dsc = isManufacturerFound.ditmmnf_front_dsc;
        manufacturer.ditmmnf_code = isManufacturerFound.ditmmnf_code;
        manufacturer.id = isManufacturerFound.id;
        product.manufacturer = manufacturer.ditmmnf_front_dsc;
      }
    }

  }

  private convertTo(itemDto: ItemDto) {
    let product = new ProductsDto();
    let pictures: ItemPicturesDto[] = [];
    if (itemDto.image && itemDto.image.length > 0) {
      let image1 = new ItemPicturesDto()
      image1.imageType = ImageType.URL;
      image1.url = itemDto.image;
      pictures.push(image1);
    }
    product.skuSap = itemDto.sku;
    product.itemDescription = itemDto.itemDescription;
    product.description = itemDto.itemDescription;
    product.partNumber = itemDto.partNumber;
    product.condition = itemDto.condition;
    product.itemSpecifications = itemDto.itemSpecifications;
    let upc = this.upcField(itemDto);
    let ean = this.eanField(itemDto);
    if (upc) {
      product.upc = upc;
    }
    if (ean) {
      product.ean = ean;
    }

    if (itemDto.idManufacturers) {
      product.idManufacturers = itemDto.idManufacturers;
      this.validateManufacturer(product);
    }
    let category = new Category()
    category.key = itemDto.itemCategories.parentCode;
    product.selectCategories = category;

    product.selectSudCategories = itemDto.itemCategories.code ?? "";


    product.color = this.getColor(itemDto);
    if (product.images.length > 0) {
      product.images = pictures;
    }

    return product;
  }


}
