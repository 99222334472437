<div class="menu">
  <div class="menu__item"
       *ngxPermissionsOnly="[REQUESTS.REQUESTS, ADMIN_USER]"
       (click)="showHide([subMenu,subMenu2])">
    <mat-icon class="menu__item--icon1" svgIcon="requests-icon"></mat-icon>
    <span  class="menu__item--text">Requests</span>
  </div>
  <ul class="menu__submenu" #subMenu>
    <li class="menu__submenu__second-menu" >
      <ul #subMenu2>
        <li *ngxPermissionsOnly="[REQUESTS.CREATE_REQUEST, ADMIN_USER]"
            routerLink="/request/new">
          Request Creation
        </li>
        <li *ngxPermissionsOnly="[REQUESTS.MY_REQUESTS, ADMIN_USER]"
            [routerLink]="'/request/my-request'">
          My Requests
        </li>

        <li *ngxPermissionsOnly="[REQUESTS.ACTIVE_REQUESTS, ADMIN_USER]"
            routerLink="/request/active-request">
          Active Requests
        </li>
        <li *ngxPermissionsOnly="[REQUESTS.BUYERS_STATS, ADMIN_USER]"
            routerLink="/stats/buyers">
          Buyer stats
        </li>
        <li *ngxPermissionsOnly="[REQUESTS.SALES_STATS, ADMIN_USER]"
            routerLink="/stats/sales">
          Sales stats
        </li>
      </ul>
    </li>
  </ul>
</div>

