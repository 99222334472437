export const ITEM_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    CREATION: 'We have successfully created the new item!',
    UPDATE: ' We have successfully updated the item!',
    NOT_FOUND: 'We weren\'t able to find any products that match your search criteria, please search again or input the product info directly',
    NOT_MATCH_CRITERIA: 'We weren\'t able to find any products that match your search criteria, please check!',
  }
}
export const DEAL_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    ITEM_NOT_FOUND: 'Please check the search criteria, we couldn’t find an item with the entered information. ',
    NON_ACTIVE_ITEM: 'non-active item, please try again!'
  }
}
export const DISBURSEMENT_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    GENERATE_FILE: 'Success! A new withdrawal file has been generated for {0}. ',
  }
}

export const NEW_DEAL_OFFER = {
  DEFAULT_MESSAGE_RESPONSE: {
    CREATE_NEW_ITEM: "OK, Let's create the item then!",
  }
}
export const REQUEST = {
  DEFAULT_MESSAGE_RESPONSE: {
    CUSTOMER_NOT_FOUND: 'We weren\'t able to find any user that match your search criteria, please check!'
  }
}
