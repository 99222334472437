import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItemPicturesDto} from '@features/product/model/dto/item/itemPictures.dto';
import {environment} from "@env";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private productMasterUrl = environment.productMasterUrl;
  private uploadImageUrl = `${this.productMasterUrl}images/upload-temp`;

  constructor(private http: HttpClient) {
  }

  uploadImage(itemPicturesDto: ItemPicturesDto): Observable<ItemPicturesDto> {
    return this.http.post<ItemPicturesDto>(this.uploadImageUrl, itemPicturesDto);
  }
}
