import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProductsDto} from '@features/product/model/dto/product/Products.dto';
import {confirm_popup_html, CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ProductService} from "@features/product/services/product.service";
import {ProductProcessType} from "@features/product/model/enum/product-process-type";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {Router} from "@angular/router";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-product-offer-main',
  templateUrl: './product-offer-main.component.html',
  styleUrls: ['./product-offer-main.component.scss']
})
export class ProductOfferMainComponent implements OnInit {

  chooseProcessForm: FormGroup;
  newOfferForm: FormGroup;

  product!: ProductsDto | undefined;
  showProductCreation: boolean = false;

  manufacturers: ProductMasterManufacturer[] = [];
  manufacture!: ProductMasterManufacturer;
  isManufacture!: boolean;
  productProcessType = ProductProcessType.PRODUCT_NEW_OFFER;


  constructor(private formBuilder: FormBuilder,
              private productService: ProductService,
              private router: Router) {
    this.chooseProcessForm = this.formBuilder.group({
      process: ['', Validators.required]
    })
    this.newOfferForm = this.buildProductSelectForm();
  }

  get processType() {
    return this.chooseProcessForm.get('process');
  }

  get typeSearchProduct() {
    return this.chooseProcessForm.get('process');
  }

  ngOnInit() {
    this.getManufacturers();
  }

  setProduct($event: any) {
    this.product = $event;
    this.validateManufacturer(this.product!);
    this.showProductCreation = true;
  }

  public verifyItemSapState($event: any) {
    console.log($event)
    let sku = $event.sku;
    let itemId = $event.itemId;
    this.productService.findItemStateInSap(sku).subscribe({
      next: value => {
        if (value && value.state === 'tNO') {
          this.showUpdateStatePopUp(value).then(option => {
            if (option.isConfirmed) {
              this.updateItemState(sku, itemId);
            }
          })
        } else if (value && value.state === 'tYES') {
          this.router.navigate([`product-offer/offer-validation/create/${itemId}/${this.encodeSku(this.product?.skuSap)}`])
        } else {
          showSomethingWrongPopup1(TITLE_INFO, "Item not fund in sap, please check later or contact support")
          .then(() => this.router.navigate(['/product-offer/new/offer']));
        }

      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT).then(() => this.router.navigate(['/product-offer/new/offer']))
      }
    })
  }

  showUpdateStatePopUp(value: any) {
    return confirm_popup_html("This product is currently marked as \"Inactive\"",
      `
                    <p>You cannot create an offer unless the product is back to "Active" status</p>
                     <div style="text-align: start">
                     <h2 >Inactive period</h2>
                     <p>From ${value.validFrom}&nbsp;&nbsp;&nbsp;&nbsp; To  ${value.validTo}</p>
                     <p>Remarks</p>
                     </div>
                     <p style="text-align: center">Would you like us to update this product back to active?</p> `
    )
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      },
    })
  }

  showOptions($event: any) {
    if ($event) {
      this.showProductCreation = false;
      this.product = undefined;
    }
  }

  private buildProductSelectForm() {
    return this.formBuilder.group({
      type_search_product: ['', [Validators.required]],
      product_search_value: ['', [Validators.required]],
      product_select_value: [''],
    });
  }

  private validateManufacturer(product: ProductsDto) {
    let manufacturer: any;
    if (product.idManufacturers) {
      manufacturer = this.manufacturers.find(value => value.ditmmnf_code == product.idManufacturers);
    }
    if (manufacturer) {
      this.manufacture = manufacturer;
      this.isManufacture = true;
    } else {
      this.manufacture = new ProductMasterManufacturer();
      this.isManufacture = false;
    }
  }

  private updateItemState(sku: string, itemId: number) {
    this.productService.updateItemSapState(sku).subscribe({
      next: () => {
        this.router.navigate([`product-offer/offer-validation/create/${itemId}/${this.encodeSku(this.product?.skuSap)}`])
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

  private encodeSku(sku: string | undefined) {
    if (sku) {
      return encodeURIComponent(sku);
    }
    return '';
  }
}
