import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-po-for-product-selected',
  templateUrl: './po-for-product-selected.component.html',
  styleUrls: ['./po-for-product-selected.component.scss']
})
export class PoForProductSelectedComponent {

  @Input()
  poselectionForm!: UntypedFormGroup;


  constructor(private router: Router) {
  }

  options() {
    this.router.navigate(['/product/create']);
  }


}


