<div *ngIf="isDialog" class="col-12 close_button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div *ngIf="product && descriptionCategories" class="container" id="product-preview">
  <mat-card class="product-preview-card">
    <mat-card-content>
      <div class="row product-preview-card__row">
        <div class="product-preview-card__row--item-description">
          <h3>
            <strong>{{product.itemDescription || ""}}</strong>
          </h3>
        </div>
        <div class="col-12 col-lg-8">
          <ngb-carousel
            #carousel
            [interval]="100000"
            class="product-preview-card__row--carousel"
          >
            <ng-container *ngIf="product && product.itemPictures?.length != 0">
              <ng-template *ngFor="let image of cleanImages(product.itemPictures) | sortByOrderAsc; let first=first; index as i" ngbSlide>
                <div class="product-preview-card__row--carousel--wrapper">
                  <img [src]="image.url" alt="image {{i + 1}}"/>
                </div>
              </ng-template>
            </ng-container>
          </ngb-carousel>
        </div>

        <div class="col-12 col-lg-4 product-preview-card__row--description">
          <div class="product-preview-card__row--description--title">
            <h3>Product specifications</h3>
          </div>
          <div class="product-preview-card__row--description--detail">
            <ul *ngIf="product.skuSap">
              <li><strong>SKU: </strong> {{product.skuSap}}</li>
            </ul>
            <ul>
              <li><strong>UPC: </strong> {{product.upc}}</li>
            </ul>
            <ul>
              <li><strong>EAN: </strong>{{product.ean}}</li>
            </ul>
            <ul>
              <li><strong>Condition: </strong>{{product.condition}}</li>
            </ul>
            <ul>
              <li><strong>Category: </strong> {{descriptionCategories}}</li>
            </ul>
            <ul>
              <li><strong>Manufacturer: </strong> {{product.manufacturer}}</li>
            </ul>
            <ul>
              <li><strong>Model: </strong> {{product.model}}</li>
            </ul>
            <ul>
              <li><strong>PartNumber: </strong> {{product.partNumber}}</li>
            </ul>

            <ul>
              <li>
                <strong class="primary-color">Specifications:</strong>
                <div *ngFor="let spec of product.itemSpecifications">
                  <ul>
                    <div>
                      <li><strong>{{spec.specifications}}</strong>
                        : {{spec.descriptionItemSpecific}}</li>
                    </div>
                  </ul>
                </div>
              </li>
            </ul>
            <ul>
              <li><strong
                class="product-preview-card__row--description--detail__hot">Description:</strong> {{product.description || "" }}</li>
            </ul>
          </div>
        </div>
      </div>
    </mat-card-content>

  </mat-card>
</div>
