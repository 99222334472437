<div class="moq-setup-container">
  <mat-card class="col-12">
    <mat-card-title class="text-center"><h3><strong>Search for a Vendor</strong></h3>
    </mat-card-title>
    <mat-card-content>
      <div [formGroup]="searchVendorForm">
        <div class="row">
          <mat-radio-group aria-label="Select an option" formControlName="search_type">
            <mat-radio-button color="primary" value="cardCode">Card Code</mat-radio-button>
            <mat-radio-button class="radio-but-margin" color="primary" value="cardName">Vendor
              Name
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select</mat-label>
            <input autocomplete="on"
                   formControlName="search_value"
                   matInput
                   type="text"
            >
          </mat-form-field>
          <div class="col-12">
            <button
              (click)="getBusinessPartner()"
              color="primary"
              mat-raised-button
            > Obtain
            </button>
          </div>
        </div>
        <div *ngIf="businessPartnerList.length > 0" class="row">
          <ng-container *ngFor="let businessPartner of businessPartnerList">
            <mat-card class="product_custom">
              <mat-card-content>
                <div class="row">
                  <p><strong>CardCode:</strong> {{businessPartner.carCode}}</p>
                  <p><strong>CardName:</strong> {{businessPartner.cardName}}</p>
                </div>
              </mat-card-content>
            </mat-card>
            <div class="text-center">
              <button (click)="selectBusinessPartner(businessPartner)" color="primary"
                      mat-raised-button> Select
              </button>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <h2>Vendor:</h2>
          <ng-container *ngIf="vendorField?.valid">
            <div class="col-12">
              <p>{{'CardCode: ' + vendorField?.value?.carCode + '  -  ' + 'CardName: '
              + vendorField?.value?.cardName}}</p>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>


