import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {ItemCustomizedCode} from "@features/product/model/enum/item-customized-code";
import {ItemCodesDto} from "@features/product/model/dto/item/itemCodes.dto";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {Category} from "@core/model/category";
import {ItemCode} from "@features/product/model/enum/item-code";


export class ProductUtility {
  static findSapCode(itemCustomized: ItemCustomizedDto[]): ItemCustomizedDto | undefined {
    return itemCustomized.find(param => param.itemCustomizedCode === ItemCustomizedCode.SAP_CODE)
  }

  static getItemCode(itemCodes: ItemCodesDto[], codeType: string) {
    if (itemCodes && itemCodes.length > 0) {
      return itemCodes.find(code => code.itemCodeType === codeType)?.itemCode;
    }
    return '';
  }

  static convertTo(itemDto: ItemDto) {
    let product = new ProductsDto();
    product.sku = itemDto.sku;
    product.itemCustomized = itemDto.itemCustomized;
    product.itemDescription = itemDto.itemDescription || "";
    product.description = itemDto.itemDescription || "";
    product.partNumber = itemDto.partNumber;
    product.condition = itemDto.condition;
    product.itemSpecifications = itemDto.itemSpecifications;
    product.image = itemDto.image;
    product.model = itemDto.model;
    let upc = ProductUtility.getItemCode(itemDto.itemCodes, ItemCode.UPC);
    let ean = ProductUtility.getItemCode(itemDto.itemCodes, ItemCode.EAN);
    if (upc) {
      product.upc = upc;
    }
    if (ean) {
      product.ean = ean;
    }
    product.manufacturer = itemDto.manufacturers?.description || "";


    let category = new Category()
    category.key = itemDto.itemCategories?.parentCode;
    product.selectCategories = category;

    product.selectSudCategories = itemDto.itemCategories?.code ?? "";
    product.itemPictures = itemDto.itemPictures;


    product.skuSap = ProductUtility.getSkuSap(product);
    return product;
  }

  static getSkuSap(product: ProductsDto) {
    let findSkuSap = product.itemCustomized?.find(item => item.itemCustomizedCode == ItemCustomizedCode.SAP_CODE);
    if (findSkuSap) {
      return findSkuSap.itemCustomizedValue;
    }
    return '';
  }

}
