<div class="qr-code-container">
  <div  class="col-12 close_button">
    <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
      <mat-icon svgIcon="close-popup-icon">close</mat-icon>
    </button>
  </div>
  <h2>QR Code for Microsoft Authenticator Validation</h2>
  <p>Scan this QR code with your Microsoft Authenticator app to complete the validation process.</p>
  <qrcode [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  <div class="qr-code-container__totp-input-container">
    <label for="totp">Enter TOTP Code:</label>
    <mat-form-field appearance="outline" class="col-4">
      <input matInput
             type="text"
             id="totp"
             [formControl]="totpCode"
             [maxlength]="6"
             [minlength]="6"
      >
    </mat-form-field>
    <button class="btn" (click)="submitTOTP()">Submit</button>
  </div>
</div>
