import { Component } from '@angular/core';
import {ECOMMERCE, MY_ACCOUNT} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-my-account-menu',
  templateUrl: './da-my-account-menu.component.html',
  styleUrls: ['./da-my-account-menu.component.scss']
})
export class DaMyAccountMenuComponent {


  protected readonly MY_ACCOUNT = MY_ACCOUNT;
  protected readonly ECOMMERCE = ECOMMERCE;
}
