import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {RequestStatus} from "@features/request/model/request-status";

export class RequestUtil {

  static getCategory(requestDto: WRequestDto) {
    return requestDto?.item?.itemCategories ? requestDto.item.itemCategories.parentCode : this.getCategoryFromOffer(requestDto);
  }

  static getCategoryFromOffer(requestDto: WRequestDto) {
    return requestDto.offer?.item?.itemCategories ? requestDto.offer.item.itemCategories.parentCode : '';
  }

  static getSubCategory(requestDto: WRequestDto) {
    return requestDto?.item?.itemCategories ? requestDto.item.itemCategories.description : this.getSubCategoryFromOffer(requestDto);
  }

  static getSubCategoryFromOffer(requestDto: WRequestDto) {
    return requestDto?.offer?.item && requestDto.offer.item?.itemCategories ? requestDto.offer.item.itemCategories.description : '';
  }

  static getManufacturer(requestDto: WRequestDto) {
    return requestDto?.item?.manufacturers ? requestDto.item.manufacturers.description : this.getManufacturerFromOffer(requestDto);
  }

  static getManufacturerFromOffer(requestDto: WRequestDto) {
    return requestDto?.offer?.item && requestDto.offer?.item?.manufacturers ? requestDto.offer.item.manufacturers.description : '';
  }

  static getSku(requestDto: WRequestDto) {
    return requestDto.item ? requestDto.item.sku : this.getSkuFromOffer(requestDto);
  }

  static getSkuFromOffer(requestDto: WRequestDto) {
    return requestDto?.offer?.item ? requestDto.offer.item.sku : '';
  }

  static getItemDescription(requestDto: WRequestDto) {
    return requestDto.item ? requestDto.item.itemDescription : this.getItemDescriptionFromOffer(requestDto);
  }

  static getItemDescriptionFromOffer(requestDto: WRequestDto) {
    return  requestDto?.offer?.item ? requestDto.offer.item.itemDescription : '';
  }

  static getPartNumber(requestDto: WRequestDto) {
    return requestDto.item ? requestDto.item.partNumber : this.getPartNumberFromOffer(requestDto);
  }

  static getPartNumberFromOffer(requestDto: WRequestDto) {
    return requestDto?.offer?.item ? requestDto.offer.item.partNumber : '';
  }


  static getBuyerName(userCustomized: UserCustomizedDto[]) {
    return userCustomized && userCustomized.length > 0 ? UserCustomizedUtil.findBuyerSapCode(userCustomized)?.userCustomizedValue : '';
  }

  static getSalesName(userCustomized: UserCustomizedDto[]) {
    return userCustomized && userCustomized.length > 0 ? UserCustomizedUtil.findSalesSapCode(userCustomized)?.userCustomizedValue : '';
  }

  static getMargin(price = 0, cost = 0) {
    if ((price && price != 0) && (cost && cost != 0)) {
      return (((price / cost) - 1) * 100);
    }
    return 0;
  }

  static getDescriptionStatus(requestStatus: RequestStatus): string {
    switch (requestStatus) {
      case RequestStatus.PENDING                  : {
        return "Pending";
      }
      case RequestStatus.SOURCING                 : {
        return "Sourcing";
      }
      case RequestStatus.SOURCED                  : {
        return "Sourced";
      }
      case RequestStatus.UNABLE_TO_SOURCE         : {
        return "Unable to Source";
      }
      case RequestStatus.WON_OPPORTUNITY          : {
        return "Won Opportunity";
      }
      case RequestStatus.LOST_OPPORTUNITY         : {
        return "Lost Opportunity";
      }
      case RequestStatus.CANCELLED                : {
        return "Cancelled";
      }
      case RequestStatus.SOURCED_PREVIOUS_STOCK   : {
        return "Sourced - Previous Stock";
      }
      case RequestStatus.SOURCED_SIMILAR_ITEM     : {
        return "Sourced - Similar Item";
      }
      case RequestStatus.NOT_IN_PLAN              : {
        return "Not in Plan";
      }
      case RequestStatus.UNABLE_TO_SOURCE_MOQ     : {
        return "Unable to Source - MOQ"
      }
      case RequestStatus.UNABLE_TO_SOURCE_PRICE_MATCH     : {
        return "Unable to Source - Price Match"
      }
    }
  }
}
