import {Pagination} from "@features/product/model/dto/utility/pagination";
import {ComparatorsFieldDto} from "@features/product-offer/model/offer-validation/ComparatorsField.dto";
import {SearchCriteriaDto} from "@features/product/model/dto/request/search-criteria.dto";

export class FindAllOfferParamsForVendorsDto extends Pagination {

  searchCriteria!: SearchCriteriaDto[];
  initDate!: string | null;
  endDate!: string | null;
  creationDateInit!: string | null;
  creationDateEnd!: string | null;
  offerPeriodDateInit!: string | null;
  offerPeriodDateEnd!: string | null;
  itemCreationDateInit!: string | null;
  itemCreationDateEnd!: string | null;
  category!: string;
  condition!: string;
  subcategory!: string;
  manufacturer!: number;
  buyer!: string;
  sku!: string;
  upc!: string;
  partNumber!: string;
  itemDescription!: string;
  isHotDeal!: boolean | null;
  source!: string;
  specs!: string;
  regionRestrictions!:string;
  comparators: ComparatorsFieldDto[] = [];


}
