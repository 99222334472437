<div class="container">
  <mat-card class="col-12">
    <mat-card-title class="text-center"><h3><strong>Base Price List</strong></h3></mat-card-title>
    <mat-card-content>
      <div [formGroup]="basePriceListForm">
        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Tier 4</mat-label>
            <input
              autocomplete="off"
              formControlName="tierFour"
              id="tierFour"
              matInput
              placeholder="0.00$"
              type="text"
            >
            <span matPrefix>USD&nbsp;</span>
          </mat-form-field>
          <mat-error *ngIf="tierFourField?.hasError('validation_value_four')">
            Check the number tier 4
          </mat-error>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
