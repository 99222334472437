import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {AuthService} from "@core/services";
import {ACCESS_DENIED, TITLE_INFO} from "@shared";
import {Observable} from "rxjs";
import {showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";

@Injectable()
export class RoleGuard  {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
  }

  // tslint:disable-next-line:max-line-length
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = route.data['role'] as string;
    return new Promise<boolean>((resolve => {
      return this.authService.isAuthenticated().then(response => {
        if (!response) {
          this.router.navigate(['login']);
          return resolve(false);
        }
        this.authService.hasRole(role).then(value => {
          if (value) {
            return resolve(true);
          } else {
            showSomethingWrongPopup1(TITLE_INFO, ACCESS_DENIED);
            return resolve(false);
          }
        })
      })
    }))
  }

}
