import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./features/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'product-offer',
    loadChildren: () =>
      import('./features/product-offer/product-offer.module').then((m) => m.ProductOfferModule),
  },
  {
    path: 'request',
    loadChildren: () =>
      import('./features/request/request.module').then((m) => m.RequestModule),
  },
  {
    path: 'stats',
    loadChildren: () =>
      import('./features/stats/stats.module').then((m) => m.StatsModule),
  },
  {
    path: 'ecommerce',
    loadChildren: () =>
      import('./features/eCommerce/ecommerce.module').then((m) => m.EcommerceModule),
  },
  {
    path: 'marketplace',
    loadChildren: () =>
      import('./features/marketplace/marketplace.module').then((m) => m.MarketplaceModule),
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./features/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./features/customers/customers.module').then((m) => m.CustomersModule),
  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
