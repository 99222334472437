import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {finalize, Observable, of} from 'rxjs';
import {LoadingService} from "@shared/services/loading.service";
import {catchError} from "rxjs/operators";

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

  totalRequests = 0;
  completedRequests = 0;

  constructor(private loader: LoadingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loader.show();
    this.totalRequests++;

    return next.handle(request).pipe(
      catchError(() => {
        this.loader.hide();
        return of();
      }),
      finalize(() => {
        this.completedRequests++;
        if (this.completedRequests == this.totalRequests) {
          this.loader.hide();
          this.completedRequests = 0;
          this.totalRequests = 0;
        }

      })
    );
  }
}
