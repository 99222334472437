<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="pocreationForm">
        <div class="row">
          <div class="po-creation-title">
            <h1><strong>Would you like to create an initial PO for this deal?</strong></h1>
          </div>
          <mat-radio-group class="col-12 group-po-creation" formControlName="selection_of_option">
            <mat-radio-button [value]="true" class="group-po-creation__radio-button"
                              color="primary">Yes
            </mat-radio-button>
            <mat-radio-button (click)="selectOption()" [value]="false"
                              class="group-po-creation__radio-button" color="primary">No
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

