import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'app-sales-notifications',
  templateUrl: './sales-notifications.component.html',
  styleUrls: ['./sales-notifications.component.scss']
})
export class SalesNotificationsComponent implements OnInit {
  optionSelected!: number;
  @Output() optionSelectedChild = new EventEmitter<number>()

  @Input()
  salesForm!: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {

  }


}




