import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "@features/home/home.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RequestMainComponent} from "@features/request/smart/request-main/request-main.component";
import {
  RequestInformationComponent
} from "@features/request/presentation/request-information/request-information.component";

import {ngxPermissionsGuard} from "ngx-permissions";
import { ADMIN_USER, REQUESTS} from "@shared/config/constants/menu-roles.const";
import {ActiveRequestComponent} from "@features/request/presentation/active-request/active-request.component";
import {MyRequestComponent} from "@features/request/presentation/my-request/my-request.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'new',
    canActivate: [ngxPermissionsGuard],
    component: RequestMainComponent,
    data: {
      permissions: {
        only: [REQUESTS.CREATE_REQUEST, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'information',
    canActivate: [ngxPermissionsGuard],
    component: RequestInformationComponent,
    data: {
      permissions: {
        only: [REQUESTS.CREATE_REQUEST, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'my-request',
    component: MyRequestComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [REQUESTS.MY_REQUESTS, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'active-request',
    component: ActiveRequestComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [REQUESTS.ACTIVE_REQUESTS, ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RequestRoutingModule {
}
