<form   [formGroup]="form" >
  <mat-label>{{label}}</mat-label>
  <mat-form-field appearance="outline">
    <mat-select ngxLazyMatSelect
                [multiple]="multiple"
                placeholder="Select"
                formControlName="cardCode"
                (infiniteScroll)="getNextBatch()"
                [complete]="offset === total"
    >
      <mat-option *ngFor="let option of options$ | async"
                  [value]="option.cardCode"
                  class="card-code-option">
        <span> {{ option.cardCode + ' - ' + option.cardName}}</span>
        <br>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

