import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, UntypedFormGroup} from "@angular/forms";
import {Category} from "@core/model/category";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductProcessType} from "@features/product/model/enum/product-process-type";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ProductInfoFormService} from "@features/product/features/presentation/product-info/product-info-form.service";

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css']
})
export class ProductInfoComponent implements OnInit {
  @Input() productInfoForm!: UntypedFormGroup;
  @Input() conditions: any[] = [];
  @Input() categories: Category[] = [];
  @Input() manufacturers: ProductMasterManufacturer[] = [];
  @Input() variant_specifications: any[] = [];
  @Input() product!: ProductsDto;
  @Input() manufacture!: ProductMasterManufacturer;
  @Input() isProductInfoForm!: boolean;
  @Input() productProcessType!: ProductProcessType;

  @Output() emitSubCategories = new EventEmitter();

  sub_categories: Category[] = [];
  showSpecifications!: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private productInfoFormService: ProductInfoFormService,
  ) {
  }

  get itemDescriptionField() {
    return this.productInfoForm.get('item_description');
  }

  get upcField() {
    return this.productInfoForm.get('upc');
  }

  get eanField() {
    return this.productInfoForm.get('ean');
  }

  get subCategoriesField() {
    return this.productInfoForm.get('sub_categories') as FormArray;
  }

  get whereToGetImageField() {
    return this.productInfoForm.get('where_to_get_image');
  }

  get categoryField() {
    return this.productInfoForm.get('category');
  }

  get imageField() {
    return this.productInfoForm.get('image');
  }

  get productDescription() {
    return this.productInfoForm.get('product_description');
  }

  get modelField() {
    return this.productInfoForm.get('model');
  }

  get conditionField() {
    return this.productInfoForm.get('condition');
  }

  get manufacturerField() {
    return this.productInfoForm.get('manufacturer');
  }

  ngOnInit(): void {
  }

  showSpecificationsEvent($event: any) {
    console.log($event);
    this.showSpecifications = $event;
    this.cdr.detectChanges();
  }

  getSubCategories($event: any) {
    this.emitSubCategories.emit($event);
  }
}
