<div class="moq-setup-container">
  <mat-card class="col-12">
    <mat-card-title class="text-center"><h3><strong>MOQ Setup</strong></h3></mat-card-title>
    <mat-card-content>
      <div [formGroup]="moqSetupForm">
        <div class="row">
          <mat-label>
            <h3><strong>Does this offer has a Minimum Order Quantity expected?</strong></h3>
          </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="min_order_quantity">
            <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="radio-but-margin" color="primary">No
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="minOrderQuantityField?.value" class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Select minimum quantity</mat-label>
            <input autocomplete="off"
                   formControlName="select_min_quantity"
                   id="tierThree"
                   matInput
                   type="text"
            >
          </mat-form-field>
          <mat-error *ngIf="moqSetupForm?.hasError('required_min_order_quantity')">
            Select minimum quantity is required
          </mat-error>
          <mat-error *ngIf="moqSetupForm?.hasError('not_matched_decimal_pattern')">
            Field does not matched currency pattern, please check !
          </mat-error>
        </div>
        <div class="row mt-4">
          <mat-label>
            <h3><strong>Is this a Hot Offer?</strong></h3>
          </mat-label>
          <p>
            <mat-checkbox color="primary" formControlName="isHotOffer">Hot Offer</mat-checkbox>
          </p>
        </div>
        <div class="row mt-4">
          <mat-label>
            <h3><strong>Is this a Clearance Offer?</strong></h3>
          </mat-label>
          <p>
            <mat-checkbox color="primary" formControlName="isClearanceOffer">Clearance</mat-checkbox>
          </p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
