// src/app/features/product/services/product-select-form.service.ts
import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSelectFormService {
  constructor(private formBuilder: FormBuilder) {
  }

  buildProductSelectForm(): FormGroup {
    return this.formBuilder.group({
      type_search_product: ['', [Validators.required]],
      product_search_value: ['', [Validators.required], [this.selectProductValidator()]],
      product_select_value: ['', [Validators.required]],
    });
  }

  private selectProductValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<any> => {
      if (!control.value) {
        return of(null);
      }
      let value = control.root.get('type_search_product')?.value;
      if (value == 'upc') {
        return control.value.length != 12 ? of({validate_length: true}) : of(null);
      } else if (value == 'ean') {
        return control.value.length != 13 ? of({validate_length: true}) : of(null);
      }
      return of(null);
    };
  }
}
