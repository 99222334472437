<div class="container">
  <mat-card class="product-upload-template-card">
    <mat-card-content>
      <div class="row product-upload-template-card__row">
        <div class="col-12 product-upload-template-card__row--title">
          <h1>Massive Upload</h1>
          <p>Make sure to have the correct format in the file, if unsure, please download first an
            example and template below.</p>
        </div>
      </div>
      <div class="row product-upload-template-card__row">
        <div class="col-12 product-upload-template-card__row--download">
          <span>Download Template</span>
          <button (click)="downloadTemplate()" class="btn btn-primary">Download</button>
        </div>
      </div>
      <div class="row product-upload-template-card__row">
        <div class="col-12 product-upload-template-card__row--upload">
          <label class="form-label" for="product-upload-template">Upload the required file</label>
          <input #uploadInput (change)="validateFile($event)"
                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                 class="form-control form-control-lg"
                 id="product-upload-template"
                 required
                 type="file"
          >
        </div>
      </div>
      <div class="row product-upload-template-card__row">
        <div class="col-12 product-upload-template-card__row--actions">
          <button (click)="uploadFile()" [disabled]="!valid" class="btn">Upload</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</div>
