<div class="menu">
  <div class="menu__item"
       id="orders-menu-item"
       *ngxPermissionsOnly="[ORDERS.ORDERS,ADMIN_USER]"
       (click)="showHide(subMenu)" >
    <mat-icon class="menu__item--icon1" svgIcon="orders-icon-2"></mat-icon>
    <span class="menu__item--text">Orders</span>
  </div>

  <ul class="menu__submenu" #subMenu>
    <li class="menu__submenu__item" id="orders-view-item" routerLink="/orders/orders-view">
      <ng-container *ngxPermissionsOnly="[ORDERS.ACTIVE_ORDERS.ACTIVE_ORDERS,ADMIN_USER]">
        <mat-icon class="menu__submenu__item--icon" svgIcon="orders-menu-icon"></mat-icon>
        <span class="menu__submenu__item--text">Orders View</span>
      </ng-container>
    </li>
    <li class="menu__submenu__item" id="orders-warnings-item" routerLink="/orders/orders-warnings">
      <ng-container *ngxPermissionsOnly="[ORDERS.ORDERS_WARNINGS,ADMIN_USER]">
        <mat-icon class="menu__submenu__item--icon" svgIcon="warning-orders-icon"></mat-icon>
        <span class="menu__submenu__item--text">Warnings</span>
      </ng-container>
    </li>
    <!--li class="menu__submenu__item" id="warehouse-order-preparation-item" routerLink="/orders/warehouse-order-preparation">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="order-preparation-menu-icon"></mat-icon>
        <span class="menu__submenu__item--text">Orders Preparation</span>
      </ng-container>
    </li-->
  </ul>
</div>
