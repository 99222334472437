import {Injectable} from "@angular/core";

const IS_ON_TIMEOUT = 'isOnTimeout';

@Injectable({
  providedIn: "root"
})
export class LocalStorageUtilityService {

  getItem(key: string) {
    return localStorage.getItem(key);
  }

  createItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  deleteItem(Key: string) {
    localStorage.removeItem(Key)
  }
}

export {
  IS_ON_TIMEOUT
}
