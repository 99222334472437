<div *ngIf="isDialog" class="col-12 close_button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>


<div *ngIf="request && isDialog" class="container">
  <mat-card class="container__card">
    <mat-card-title class="text-center" style="border: solid 1px"><h3>
      <strong>Edit typification - {{request.description || ""}}</strong>
    </h3>
    </mat-card-title>
    <mat-card-content>
      <div [formGroup]="typificationEditForm">
        <div class="row">
          <div class="col-12 typification__row--options">
            <mat-radio-group (ngModelChange)="optionSelected($event)"
                             [(ngModel)]="request.commercialStatus"
                             formControlName="select_commercial_status">
              <mat-radio-button class="mb-3" color="primary" value="Won">Won</mat-radio-button>
              <mat-radio-button class="mx-3" color="primary" value="Lost">Lost</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-form-field *ngIf="request.commercialStatus=='Won'" appearance="outline"
                        class="col-6 col-lg-3">
          <mat-label>Won option</mat-label>
          <mat-select [(ngModel)]="request.commercialStatusTypification"
                      [formControl]="commercialStatusTypificationBy">
            <mat-option *ngFor="let option of winOption" [value]="option.description">
              {{option.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="request.commercialStatus=='Lost'" appearance="outline"
                        class="col-6 col-lg-3">
          <mat-label>Lost option</mat-label>
          <mat-select [(ngModel)]="request.commercialStatusTypification"
                      [formControl]="commercialStatusTypificationBy">
            <mat-option *ngFor="let option of LostOption" [value]="option.description">
              {{option.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Comments</mat-label>
            <textarea [(ngModel)]="request.comments"
                      [cdkTextareaAutosize]="true"
                      autocomplete="on"
                      formControlName="request_comments"
                      id="request_comments"
                      matInput
                      type="text"
            ></textarea>
          </mat-form-field>
          <mat-error *ngIf="!typificationEditForm.valid">
            Maximum length is 500
          </mat-error>
        </div>
        <div class="stepper_button_container">
          <button (click)="save()" class="button" color="primary" mat-raised-button>Save
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
