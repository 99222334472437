import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "@features/home/home.component";
import {
  OfferPurchaseOrderMainComponent
} from "@features/product-offer/smart/offer-purchase-order-main/offer-purchase-order-main.component";
import {
  ProductOfferMainComponent
} from "@features/product-offer/smart/product-offer-main/product-offer-main.component";
import {
  NotificationsComponent
} from "@features/product-offer/smart/notifications-main/notifications.component";
import {
  ListOfferComponent
} from "@features/product-offer/presentation/list-offer/list-offer.component";
import {
  OfferValidationMainComponent
} from "@features/product-offer/smart/offer-validation-main/offer-validation-main.component";
import {
  ListOfferForRequestComponent
} from "@features/product-offer/presentation/list-offer-for-request/list-offer-for-request.component";
import {
  DownloadInventoryComponent
} from "@features/product-offer/presentation/download-inventory/download-inventory.component";
import {ngxPermissionsGuard} from "ngx-permissions";
import {PRODUCT_AND_OFFERS, ADMIN_USER} from "@shared/config/constants/menu-roles.const";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'new/offer',
    component: ProductOfferMainComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.OFFER_CREATION.OFFER_WIZARD,  ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'create-po/:id/:skuSap',
    component: OfferPurchaseOrderMainComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.PURCHASE_ORDER.CREATE_PO, ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'notification',
    component: NotificationsComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.OFFER_CREATION.OFFER_WIZARD,  ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'list/offers',
    component: ListOfferComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS,  ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'list/deals/:sku',
    component: ListOfferForRequestComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS,  ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'list/deals',
    component: ListOfferForRequestComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS,  ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'offer-validation',
    canActivate: [ngxPermissionsGuard],
    children: [
      {
        path: 'create/:id/:skuSap',
        component: OfferValidationMainComponent,
      }
    ],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.OFFER_CREATION.OFFER_WIZARD,  ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'inventory/download',
    component: DownloadInventoryComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ACTIVE_OFFERS.DOWNLOAD_INVENTORY,  ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductOfferRoutingModule {
}
