import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {EMPTY, from, mergeMap, Observable} from 'rxjs';
import {AuthService} from '@core/services';
import {Router} from "@angular/router";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return from(this.authService.isAuthenticated())
    .pipe(mergeMap(isAuthenticated => {
      console.log(this.router.url)
      if (!isAuthenticated && !this.router.url.includes('login')) {
        showSomethingWrongPopup1("Opps!", "We detected inactivity, please login again!")
        .then(() => this.authService.logout());
        return EMPTY;
      }
      return this.authService.token;
    })).pipe(mergeMap(token => {
      if (token) {
        let newRequest = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        });
        return next.handle(newRequest);
      } else {
        return next.handle(req);
      }
    }))


  }
}
