import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {RequestStatus} from "@features/request/model/request-status";
import {RequestUtil} from "@features/request/utility/requestUtil";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {Category} from "@core/model/category";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ItemUtility} from "@features/product/model/utility/itemUtility";
import {RequestService} from "@features/request/services/request.service";
import {MatDialog} from "@angular/material/dialog";
import {ProductService} from "@features/product/services/product.service";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Columns} from "@shared/model/interfaces/Columns";
import {SearchSolicitudeRequestDto} from "@features/product/model/dto/request/search-solicitude-request.dto";
import {ScrollUtil} from "@shared/utility/ScrollUtil";
import {RequestDetailComponent} from "@features/request/presentation/request-detail/request-detail.component";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {
  ProductPreviewComponent
} from "@features/product/features/presentation/product-preview/product-preview.component";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {TypificationEditComponent} from "@features/request/presentation/typification-edit/typification-edit.component";
import {lastValueFrom} from "rxjs";
import {confirm_popup_html, CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {showErrorPopup, showSuccessPopup} from "@shared/utility/popup-message";
import {DaUserService} from "@shared/services/user/da-user.service";
import {SapUserType} from "@shared/model/enum/sap-user-type";
import {Router} from "@angular/router";
import {
  FilterCustomersCardCodeComponent
} from "@shared/components/filter-customers-card-code/filter-customers-card-code.component";
import {FilterCustomersNameComponent} from "@shared/components/filter-customers-name/filter-customers-name.component";
import * as moment from "moment/moment";

@Component({
  selector: 'app-my-request',
  templateUrl: './my-request.component.html',
  styleUrls: ['./my-request.component.scss']
})
export class MyRequestComponent implements OnInit{

  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  columnsConfig: Columns = {};

  filterOpen = true;
  form!: FormGroup;
  status = Object.values(RequestStatus).filter(state => state != RequestStatus.PENDING);
  buyers: DAUserDto[] = [];
  requestUtil = RequestUtil;
  categories: Category[] = [];
  subcategories: Category[] = [];
  manufacturers: ProductMasterManufacturer[] = [];

  user!: DAUserDto;

  protected readonly RequestUtil = RequestUtil;
  resultsLength = 0;


  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(FilterCustomersCardCodeComponent) filterCustomersCardCodeComponent!: FilterCustomersCardCodeComponent;
  @ViewChild(FilterCustomersNameComponent) filterCustomersNameComponent!: FilterCustomersNameComponent;


  constructor(private requestService: RequestService,
              public dialog: MatDialog,
              private formBuilder: FormBuilder,
              private productService: ProductService,
              private userService: DaUserService,
              public router: Router) {


  }

  ngOnInit(): void {
    this.buildForm();
    this.initCall();
    this.getCategories();
    this.getSubCategories();
    this.getManufacturers();
    this.getBuyers();
  }


  private initCall(){
    this.form.get('status')?.setValue(RequestStatus.SOURCING);
    let params = new SearchSolicitudeRequestDto();
    params.status = RequestStatus.SOURCING;
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_ASC`,
        key: 'id',
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: 50
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: 0
      }
    ]
    this.getData(params);
  }


  getData(searchSolicitudeRequestDto: SearchSolicitudeRequestDto){
    this.requestService.findAllMyRequest(searchSolicitudeRequestDto)
      .subscribe(response => {
      this.resultsLength = response.totalElements;
      this.dataSource.data = response.content;
      this.paginator.pageIndex = response.number;
      this.paginator.pageSize = response.size;
      this.columnsConfig = this.columnsConfig = response.columns.reduce((acc: Columns, column:any) => {
        acc[column.name] = {
          visible: column.isVisible,
          editable: column.isEditable,
          dtoAttribute: column.dtoAttribute
        };
        return acc;
      }, {});
      this.displayedColumns = Object.keys(this.columnsConfig).filter(key => this.columnsConfig[key].visible);
    })

  }



  private buildForm(){
    this.form = this.formBuilder.group({
      initDate: [null],
      endDate: [null],
      category: [null],
      subcategory: [null],
      manufacturer: [null],
      buyer: [null],
      status: [null],
      cardCode: [null],
    })
  }




  toggle(filters: any) {
    this.filterOpen = !this.filterOpen;
    if (!this.filterOpen) {
      filters.style.display = 'none'
    } else {
      filters.style.display = 'flex'

    }
  }



  reset() {
    this.form.reset();
    this.filterCustomersCardCodeComponent.resetForm();
    this.filterCustomersNameComponent.resetForm();
    this.initCall();
  }

  filter() {
    let searchParamsFilter = this.buildParams();
    searchParamsFilter.searchCriteria = this.buildSearchCriteria();
    this.getData(searchParamsFilter);
  }

  pagination($event: PageEvent) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${this.sort?.direction ? this.sort.direction.toUpperCase() : 'DESC'}`,
        key: this.sort?.active ? this.sort.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: $event.pageSize
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: $event.pageIndex
      }
    ];
    this.getData(params)
    ScrollUtil.scrollToTop();
  }

  sortChange($event: Sort) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${$event?.direction ? $event.direction.toUpperCase() : 'DESC'}`,
        key: $event?.active ? $event.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: this.paginator?.pageSize ? this.paginator.pageSize : 50
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: this.paginator?.pageIndex ? this.paginator.pageIndex : 0
      }
    ];
    this.getData(params)
  }


  executeProcess(row: any, value: string) {
    switch (value) {
      case "view":
        if (row.itemId) {
          this.previewProduct(row.itemId);
        }
        break;
      case "edit":
        this.cancelled(row);
        break;
      case "typification":
        this.editTypification(row);
        break;
    }
  }


  cancelled(request: WRequestDto) {
    confirm_popup_html("Cancel request", `<p>You want to cancel the request</p>`
    ).then(option => {
      if (option.isConfirmed) {
        let requestToUpdate = new WRequestDto();
        requestToUpdate.id = request.id;
        requestToUpdate.status = RequestStatus.CANCELLED;
        requestToUpdate.requestDate = new Date();
        requestToUpdate.relatedProducts = [];

        try {
          this.requestService.updateStatus(requestToUpdate).subscribe({
            next: (value: any) => {
              if (value.status) {
                showSuccessPopup(TITLE_INFO, "Status cancelled").then(() => this.reset());
              }
            }, error: () => {
              showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
            }
          })
        } catch (error) {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
        }
      }
    });
  }


  previewProduct(itemId: number) {
    this.productService.getItemById(itemId).subscribe({
      next: (response) => {
        const dialogRef = this.dialog.open(ProductPreviewComponent, {
          width: '50%',
          height: '90%',
        })
        const product: ProductsDto = ProductUtility.convertTo(response);
        const instance = dialogRef.componentInstance;
        instance.product = product;
        instance.isDialog = true;
        this.getCategoryTree(product.selectSudCategories)
          .then(result => {
            if (result.body) {
              instance.descriptionCategories = result.body[0].category_lineage;
            }
          });
      }
    })

  }

  editTypification(request: any) {
    const dialogRef = this.dialog.open(TypificationEditComponent, {
      width: '50%',
      height: '60%',
    })
    const instance = dialogRef.componentInstance;
    instance.request = request;

    instance.isDialog = true;

    dialogRef.afterClosed().subscribe(() => this.reset())

  }

  async getCategoryTree(categories: string): Promise<any> {
    let response = this.productService.getCategoryTree({'itemCategory': categories});
    return lastValueFrom(response);
  }


  requestDetailPreview(row:any) {
    let dialogRef = this.dialog.open(RequestDetailComponent, {
      width: '50%',
      height: '90%',
    })
    dialogRef.componentInstance.request = row;
  }




  private buildParams() {
    let params = new SearchSolicitudeRequestDto();

    if (this.statusField?.value) {
      params.status = this.statusField?.value;
    }
    if (this.initDateField?.value) {
      params.requestInitDate = moment(this.initDateField?.value)
        .format('YYYY-MM-DD').concat('T').concat('00:00:00');
    }
    if (this.endDateField?.value) {
      params.requestEndDate = moment(this.endDateField?.value)
        .format('YYYY-MM-DD').concat('T').concat('23:59:59');
    }
    if (this.buyerField?.value) {
      params.buyerId = this.buyerField?.value;
    }
    if (this.categoryField?.value) {
      params.category = this.categoryField?.value;
    }
    if (this.subcategoryField?.value) {
      params.subcategory = this.subcategoryField?.value;
    }
    if (this.manufacturerField?.value) {
      params.manufacturer = this.manufacturerField?.value;
    }
    if (this.cardCodeField?.value) {
      params.cardCode = this.cardCodeField?.value;
    }
    return params;
  }


  private buildSearchCriteria() {
    return [
      {
        operation: `PAGE_ORDER_${this.sort?.direction ? this.sort.direction.toUpperCase() : 'DESC'}`,
        key: this.sort?.active ? this.sort.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: this.paginator? this.paginator.pageSize : 50
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: this.paginator? this.paginator.pageIndex : 0
      }
    ];
  }



  getCategories() {
    this.productService.getCategories().subscribe({
      next: value => {
        ItemUtility.setCategory(value.body, this.categories);
      },
      error: () => {
        this.categories = [];
      },
    })
  }

  getSubCategories() {
    this.productService.getAllSubCategories().subscribe({
      next: value => {
        value.body.forEach((cat: any) => {
          let category = new Category();
          category.key = cat.itmctg_code;
          category.values = cat.categorychild;
          this.subcategories.push(category);
        });
      },
      error: () => {
        this.subcategories = [];
      },
    })
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: () => {
        this.manufacturers = [];
      },
    })
  }


  getBuyers() {
    this.userService.findAllBuyers()
      .subscribe({
        next: value => {
          this.buyers = value;
        },
        error: () => {
          this.buyers = [];
        },
      })
  }
  isEditable(column: string): boolean {
    return this.columnsConfig[column]?.editable ?? false;
  }


  get initDateField() {
    return this.form.get('initDate');
  }

  get endDateField() {
    return this.form.get('endDate');
  }

  get categoryField() {
    return this.form.get('category');
  }

  get subcategoryField() {
    return this.form.get('subcategory');
  }

  get manufacturerField() {
    return this.form.get('manufacturer');
  }

  get buyerField() {
    return this.form.get('buyer');
  }

  get statusField() {
    return this.form.get('status');
  }

  get cardCodeField() {
    return this.form.get('cardCode');
  }

  get hotOfferField() {
    return this.form.get('hotOffer');
  }





  protected readonly String = String;

  protected readonly RequestStatus = RequestStatus;
  protected readonly SapUserType = SapUserType;
}
