import { Injectable } from '@angular/core';
import {environment} from "@env";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ItemInventoryService {
  private apiUrl = environment.automatePurchasesUrl;
  private downloadInventoryUrl = `${this.apiUrl}item-inventory/download/inventory/by/user`;

  constructor(private http: HttpClient) { }

  downloadInventory(params: any): Observable<any> {
    return this.http.post(this.downloadInventoryUrl, params, {responseType: 'text'});
  }
}
