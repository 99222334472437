<div class="container-notifications-main">
  <mat-stepper #NotificationsStepper labelPosition="bottom" linear="true">
    <mat-step [editable]="true" [stepControl]="salesForm">
      <ng-template matStepLabel>Notification Sales</ng-template>
      <app-sales-notifications
        [salesForm]="salesForm"
      ></app-sales-notifications>
      <div class="stepper_button_container">
        <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [editable]="true" [stepControl]="customerForm">
      <ng-template matStepLabel>Notification Customers</ng-template>
      <app-customer-notifications
        [customerForm]="customerForm"
        [segments]="segments"
      ></app-customer-notifications>
      <div class="stepper_button_container">
        <button mat-raised-button matStepperPrevious>Previous</button>
        <button class="button" color="primary" mat-raised-button
                (click)="validateSegmentsCustomer()">
          Next
        </button>
      </div>
    </mat-step>

    <mat-step [editable]="true" [stepControl]="whatsappForm">
      <ng-template matStepLabel>Notification Whatsapp</ng-template>
      <app-notification-whatsapp
        [segments]="segments"
        [whatsappForm]="whatsappForm">

      </app-notification-whatsapp>
      <div class="stepper_button_container">
        <button mat-raised-button matStepperPrevious>Previous</button>
        <button (click)="validateSegmentsWhatsapp()" class="button"  color="primary" mat-raised-button>Finish</button>
      </div>
    </mat-step>
  </mat-stepper>
</div>

