import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, UntypedFormGroup} from "@angular/forms";
import {OmnisedTypesDto} from "@features/product-offer/model/notifications/OmnisedTypes.dto";
import {MatSelectChange} from "@angular/material/select";
import {MatOption} from "@angular/material/core";
import {
  MessageType
} from "@features/product-offer/model/notifications/deals-notifications-segments.dto";

@Component({
  selector: 'app-notification-whatsapp',
  templateUrl: './notification-whatsapp.component.html',
  styleUrls: ['./notification-whatsapp.component.scss']
})
export class NotificationWhatsappComponent implements OnInit {
  @Input()
  whatsappForm!: UntypedFormGroup;
  @Input()
  segments: OmnisedTypesDto [] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  get segmentsField() {
    return this.whatsappForm.get('segments') as FormArray;
  }

  get optionWhatsappField() {
    return this.whatsappForm.get('selection');
  }

  ngOnInit(): void {
  }

  buildFormArray(segment: string) {
    return this.formBuilder.group({
      segment: [segment],
      type: [MessageType.Whatsapp]
    })
  }

  selectionChange($event: MatSelectChange) {
    this.segmentsField.clear();
    this.segmentsField.reset();
    let options = $event.source.selected as MatOption[];
    options.forEach(item => {
      console.log(item)
      if (item.selected) {
        let object: OmnisedTypesDto = item.value;
        let find = this.segmentsField
        .controls
        .find(control => control.get('segment')?.value == object.value);

        if (!find) {
          this.segmentsField.push(this.buildFormArray(object.value));
        }
      }
    })
  }

  clearFields() {
    this.segmentsField.clear();
    this.segmentsField.reset();
  }
}
