import {Injectable} from "@angular/core";
import {environment} from "@env";
import {HttpClient} from "@angular/common/http";
import {RequestStatsDto} from "@features/stats/model/request-stats.dto";
import {map, Observable} from "rxjs";
import {RequestStateSalesDto} from "@features/stats/model/request-state-sales.dto";
import {SearchStatisticsDto} from "@features/stats/model/search-statistics.dto";

@Injectable({
  providedIn: 'root'
})

export class StatsService {

  private apiUrl = environment.automatePurchasesUrl;

  private getStatsForBuyersUrl = `${this.apiUrl}request/buyer/statistics`;

  private getStatsForSalesUrl = `${this.apiUrl}request/salesman/statistics`;

  constructor(private http: HttpClient) {
  }


  getStatsForBuyers(searchStatisticsDto: SearchStatisticsDto): Observable<RequestStatsDto[]> {
    return this.http.post<any>(this.getStatsForBuyersUrl, searchStatisticsDto)
    .pipe(map(status => {
      const names = new Set()

      status.productsCreatedQty.map((item: any) => {
        names.add(item.employee)
      })
      status.requestResolved.map((item: any) => {
        names.add(item.employee)
      })
      status.offersEnter.map((item: any) => {
        names.add(item.employee)
      })
      status.offersEnter.map((item: any) => {
        names.add(item.employee)
      })

      console.log(status)

      let generalStats: RequestStatsDto [] = [];

      if (names.size == 0) {
        let temp = new RequestStatsDto();
        temp.averageTimeToTakeRequest = status.averageTimeToTakeRequest;
        generalStats.push(temp)
      } else {
        names.forEach(name => {
          let temp = new RequestStatsDto();
          status.productsCreatedQty.map((item: any) => {
            item.employee == name ? temp.products_created = item.items_created : null
          })
          status.offersEnter.map((item: any) => {
            item.employee == name ? temp.offersEnter = item.offers_created : null
          })
          status.requestResolved.map((item: any) => {
            item.employee == name ? temp.requestResolved = item.request_attended : null
          })
          status.requestResolved.map((item: any) => {
            item.employee == name ? temp.requestResolved = item.request_attended : null
          })
          status.averageTimeToSolveRequest.map((item: any) => {
            item.employee == name ? temp.averageTimeToSolveRequest = item.request_resolved_hours : null
          })
          console.log(temp)
          status.requestByState.map((item: any) => {
            if (item.employee == name) {
              const keys = Object.keys(item)
              keys.shift()
              temp.status = keys.map((key) => ({state: key, quantity: item[key]}))
            }
          })

          temp.averageTimeToTakeRequest = status.averageTimeToTakeRequest;
          temp.userName = name as string;
          generalStats.push(temp)
        })
      }


      console.log(generalStats)
      return generalStats;


    }))
    // return this.http.post<any>(this.updateStatusUrl, request);
  }


  getStatsForSales(searchStatisticsDto: SearchStatisticsDto): Observable<RequestStateSalesDto[]> {
    return this.http.post<any>(this.getStatsForSalesUrl, searchStatisticsDto).pipe(map(status => {
      const names = new Set()

      status?.requestCreated.map((item: any) => {
        names.add(item.employee)
      })
      status?.canvasDownloaded.map((item: any) => {
        names.add(item.employee)
      })
      status?.linksCopied.map((item: any) => {
        names.add(item.employee)
      })
      status?.feedbackProvided.map((item: any) => {
        names.add(item.employee)
      })
      status?.dealsWonLost.map((item: any) => {
        names.add(item.employee)
      })

      let generalStats: RequestStateSalesDto [] = [];

      for (let name of names) {
        // @ts-ignore
        let temp: RequestStateSalesDto = {};
        status.requestCreated.map((item: any) => {
          item.employee == name ? temp.requestCreated = item.request_created : null
        })
        status.canvasDownloaded.map((item: any) => {
          item.employee == name ? temp.canvasDownloaded = item.canvas_downloaded : null
        })
        status.linksCopied.map((item: any) => {
          item.employee == name ? temp.linksCopied = item.links_copied : null
        })
        status.feedbackProvided.map((item: any) => {
          item.employee == name ? temp.feedbackProvided = item.feedbacks : null
        })
        status.dealsWonLost.map((item: any) => {
          if (item.employee == name) {
            const keys = Object.keys(item)
            keys.shift()
            // @ts-ignore
            temp.dealsWonLost = keys.map((key) => ({type: key, value: item[key]}))
          }
        })
        status.averageTimeWonLostRequest.map((item: any) => {
          if (item.employee == name) {
            const keys = Object.keys(item)
            keys.shift()
            // @ts-ignore
            temp.averageTimeWonLostRequest = keys.map((key) => ({type: key, value: item[key]}))
          }
        })

        temp.userName = name as string;
        generalStats.push(temp)
        console.log(temp)
      }
      return generalStats;
    }))
    // return this.http.post<any>(this.updateStatusUrl, request);
  }


}
