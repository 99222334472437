import { Component } from '@angular/core';
import {ECOMMERCE, MY_ACCOUNT, SERVICE_DESK} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-service-desk-menu',
  templateUrl: './da-service-desk-menu.component.html',
  styleUrls: ['./da-service-desk-menu.component.scss']
})
export class DaServiceDeskMenuComponent {

  protected readonly SERVICE_DESK = SERVICE_DESK;
}
