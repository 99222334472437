import {Component, ElementRef, OnInit} from '@angular/core';
import {SapUserType} from "@shared/model/enum/sap-user-type";
import {NameProcessLogEnum} from "@shared/model/enum/transactional-log/NameProcessLogEnum";
import {DateUtil} from "@shared/utility/dateUtil";
import {DashboardUtil} from "@shared/utility/dashboards/DashboardUtil";
import {ITEM_PURCHASES} from "@shared/config/constants/dashboards.const";
import {environment} from "@env";
import {showErrorPopup} from "@shared/utility/popup-message";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {SupersetService} from "@shared/services/superset/superset.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {TransactionalLogService} from "@shared/services/transactional-log/transactional-log.service";

@Component({
  selector: 'app-dashboard-item-purchases',
  templateUrl: './dashboard-item-purchases.component.html',
  styleUrls: ['./dashboard-item-purchases.component.scss']
})
export class DashboardItemPurchasesComponent implements OnInit{
  sku!: string;
  itemDescription!: string;
  form!: FormGroup;

  oneMonthBeforeDate = DateUtil.getCurrentDate().subtract(3, 'months').toDate();
  protected readonly SapUserType = SapUserType;
  protected readonly NameProcessLogEnum = NameProcessLogEnum;




  constructor(private embedService: SupersetService,
              private elementRef: ElementRef,
              private formBuilder: FormBuilder,
              private readonly transactionalLogService: TransactionalLogService) {

    this.form = this.formBuilder.group({
      ownerCode: [''],
      cardCode: [''],
      startDate: [this.oneMonthBeforeDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      autoCardCode: ['']
    })

  }

  ngOnInit(): void {
    this.transactionalLogService
        .saveLog(
            null,
            null,
            "INIT DASHBOARD ITEM PURCHASES",
            NameProcessLogEnum.ITEM_PURCHASES_DASHBOARD,
            true);
  }




  filterDashBoard($event: any) {

    let cardCode: string | null = null;
    let salePerson: string | null = null;


    let vendors = [];
    let salesPersons = [];
    let endDate = new Date($event?.endDate);
    endDate.setDate(endDate.getDate() + 1);
    let initialDate = DateUtil.formatDate($event?.startDate, 'YYYY-MM-DD');
    let finishDate = DateUtil.formatDate(endDate, 'YYYY-MM-DD');

    if ($event?.cardCode && $event.cardCode != '') {
      vendors.push($event?.cardCode);
    }
    if ($event?.ownerCode && $event.ownerCode != '') {
      // @ts-ignore
      salesPersons.push($event?.ownerCode.userCustomized.find(item => item.userCustomizedCode === 'sap_sales_code')?.userCustomizedValue);

    }
    if ($event?.autoCardCode && $event?.autoCardCode != '') {
      vendors.push($event?.autoCardCode);
    }
    if (vendors.length > 0) {
      cardCode = vendors.length > 1 ? vendors.join(`,`) : vendors.toString();
    }

    if (salesPersons.length > 0) {
      salePerson = salesPersons.length > 1 ? salesPersons.join(`,`) : salesPersons.toString();
    }

    let filter = DashboardUtil.openFilter
      .concat(DashboardUtil.dateFilter(ITEM_PURCHASES.FILTERS.DATE, initialDate, finishDate))
      .concat(',').concat(DashboardUtil.itemCodeFilter(ITEM_PURCHASES.FILTERS.ITEM_CODE, this.sku));


    if (cardCode) {
      filter = filter.concat(',').concat(DashboardUtil.cardCodeFilter(ITEM_PURCHASES.FILTERS
        .CARD_CODE, cardCode))
    }

    if (salePerson) {
      filter = filter.concat(',').concat(DashboardUtil.salePersonFilter(ITEM_PURCHASES.FILTERS
        .SALES_PERSON, salePerson,'SP'))
    }

    filter = filter.concat(DashboardUtil.closeFilter);

    console.log(filter);
    this.embedSupersetDashboard('dashboard-item-purchases', filter);

  }


  embedSupersetDashboard(dashboardId: string, filter: string): void {
    const dashboardElement = this.elementRef.nativeElement.querySelector('#' + dashboardId);
    if (dashboardElement) {
      this.embedService.embedDashboard(filter, environment.item_purchases_dashboard, dashboardId)
        .subscribe({
          next: () => {
            const iframe = dashboardElement.querySelector('iframe');
            if (iframe) {
              iframe.style.width = '100%'; // Set the width as needed
              iframe.style.height = '5500px'; // Set the height as needed
              iframe.style.embedded = 'true';
              iframe.style.backgroundColor = '#ffffff';
              iframe.style.objectFit = 'contain'; // Adjust the content to fit the container
            }
          }, error: err => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        })
    }
  }


  usersResult(users: DAUserDto[]) {
    if (users.length == 1) {
      this.ownerCodeField?.setValue(users.at(0))
    }
    this.filterDashBoard(this.form.value);
  }

  get ownerCodeField() {
    return this.form.get('ownerCode');
  }
}
