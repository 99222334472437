import {ItemSpecificationsDto} from "@features/product/model/dto/item/itemSpecifications.dto";
import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {Category} from "@core/model/category";


export class ProductsDto {
  id!: number;
  itemDescription!: string;
  condition!: string;
  model!: string;
  partNumber!: string;
  image!: string;
  sku!: string
  skuSap!: string
  description!: string;
  idManufacturers!: number;
  idCategories!: string;
  itemSpecifications: ItemSpecificationsDto[] = [];
  itemCodes!: any;
  itemPictures: ItemPicturesDto[] = [];

  itemCustomized: ItemCustomizedDto[] = [];


  title!: string;
  manufacturer!: string;
  manufacturers!: any;
  itemCategories!: any;
  color!: string;
  size!: string;
  images: ItemPicturesDto[] = [];
  upc!: string;
  ean!: string;
  selectCategories!: Category;
  selectSudCategories: any;
  creationUser!: string;
  selected!: boolean;

}
