import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

import {CONTACT_SUPPORT, INVALID_OR_MISSING_FIELDS, LOGIN_AGAIN, TITLE_ERROR, TITLE_INFO} from "@shared";
import {AmplifyService} from "@shared/services/amplify/amplify.service";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


export interface AccountInformationFormValues {
  email: string;
  oldPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChangePasswordComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ChangePasswordComponent),
      multi: true,
    }
  ]
})
export class ChangePasswordComponent implements OnInit {

  @Input()
  form!: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  hideOldPassword: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder,
              private amplifyService: AmplifyService,
              private authService: AuthService) {
  }

  get value(): AccountInformationFormValues {
    return this.form.value;
  }

  set value(value: AccountInformationFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get oldPasswordField() {
    return this.form.get('old_password');
  }

  get newPasswordField() {
    return this.form.get('password');
  }

  get confirmPasswordField() {
    return this.form.get('confirm_password');
  }

  get emailField() {
    return this.form.get('email');
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      console.log(value);
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {personal_information: {valid: false,},};
  }

  submit() {
    if (this.form.valid) {
      this.amplifyService.resetPassword(this.emailField?.value,
        this.oldPasswordField?.value)
      .then(() => {
        showSomethingWrongPopup1(TITLE_INFO, LOGIN_AGAIN).then(() => this.logout());
      }).catch((err) => showErrorPopup(TITLE_ERROR, err ? err : CONTACT_SUPPORT));
    } else {
      this.form?.markAllAsTouched();
      this.form?.markAllAsTouched();
      showSomethingWrongPopup1(TITLE_ERROR, INVALID_OR_MISSING_FIELDS);
    }
  }

  logout() {
    this.authService.logout();
  }

  private buildForm() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }
}
