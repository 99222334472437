<div class="request-main-container">
  <mat-stepper #stepper labelPosition="bottom" linear="true">
    <mat-step [editable]="true" [stepControl]="form">
      <ng-template matStepLabel>My Request</ng-template>
      <app-request (productFound)="productSelected($event)"></app-request>
    </mat-step>
    <mat-step [editable]="true" [stepControl]="form">
      <ng-template matStepLabel>Find customer</ng-template>
      <app-find-customer [searchVendorForm]="searchVendorForm"></app-find-customer>
      <div class="stepper_button_container">
        <button class="button" mat-raised-button matStepperPrevious>Previous</button>
        <button (click)="validateForm()" class="button" color="primary" mat-raised-button>Next
        </button>
      </div>
    </mat-step>
    <mat-step [editable]="true" [stepControl]="form">
      <ng-template matStepLabel>Create Request</ng-template>
      <app-request-information
        [categories]="categories"
        [formInformation]="requestInformationForm"
        [product]="product"
        [requestInformation]="request"
      >
      </app-request-information>
      <div class="stepper_button_container">
        <button class="button" mat-raised-button matStepperPrevious>Previous</button>
        <button (click)="buildData()" class="button" color="primary" mat-raised-button>Save</button>
      </div>
    </mat-step>

  </mat-stepper>
</div>
<app-loader></app-loader>
