import { Component } from '@angular/core';
import {ADMIN, CUSTOMERS, ECOMMERCE} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-ecommerce-menu',
  templateUrl: './da-ecommerce-menu.component.html',
  styleUrls: ['./da-ecommerce-menu.component.scss']
})
export class DaEcommerceMenuComponent {

  protected readonly ADMIN = ADMIN;
  protected readonly ECOMMERCE = ECOMMERCE;
}
