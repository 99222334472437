import {Component, ElementRef, OnInit} from '@angular/core';
import {SapUserType} from "@shared/model/enum/sap-user-type";
import {NameProcessLogEnum} from "@shared/model/enum/transactional-log/NameProcessLogEnum";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DateUtil} from "@shared/utility/dateUtil";
import {SupersetService} from "@shared/services/superset/superset.service";
import {DashboardUtil} from "@shared/utility/dashboards/DashboardUtil";
import {DASHBOARD_VENDORS_INVENTORY} from "@shared/config/constants/dashboards.const";
import {environment} from "@env";
import {showErrorPopup} from "@shared/utility/popup-message";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {TransactionalLogService} from "@shared/services/transactional-log/transactional-log.service";

@Component({
  selector: 'app-dashboard-vendor-inventory',
  templateUrl: './dashboard-vendor-inventory.component.html',
  styleUrls: ['./dashboard-vendor-inventory.component.scss']
})
export class DashboardVendorInventoryComponent implements OnInit{

  protected readonly SapUserType = SapUserType;
  protected readonly NameProcessLogEnum = NameProcessLogEnum;

  form!: FormGroup;
  oneMonthBeforeDate = DateUtil.getCurrentDate().subtract(3, 'months').toDate();
  constructor(private embedService: SupersetService,
              private elementRef: ElementRef,
              private formBuilder: FormBuilder,
              private readonly transactionalLogService: TransactionalLogService) {

    this.form = this.formBuilder.group({
      ownerCode: [''],
      startDate: [this.oneMonthBeforeDate, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      granularity: ['P1D', [Validators.required]]
    })
  }

  ngOnInit(): void {
    this.transactionalLogService
        .saveLog(
            null,
            null,
            "INIT DASHBOARD VENDORS INVENTORY",
            NameProcessLogEnum.DASHBOARD_VENDORS_INVENTORY,
            true);
  }

  filterDashBoard($event: any) {
    console.log($event)


    let salePerson: string | null = null;


    let salesPersons = [];
    let endDate = new Date($event?.endDate);
    endDate.setDate(endDate.getDate() + 1);
    let initialDate = DateUtil.formatDate($event?.startDate, 'YYYY-MM-DD');
    let finishDate = DateUtil.formatDate(endDate, 'YYYY-MM-DD');
    let granularity = $event?.granularity;


    if ($event?.ownerCode && $event.ownerCode != '') {
      salesPersons.push($event?.ownerCode.userCustomized.find((item: any) => item.userCustomizedCode === 'sap_buyer_code')?.userCustomizedValue);
    }

    if (salesPersons.length > 0) {
      salePerson = salesPersons.length > 1 ? salesPersons.join(`,`) : salesPersons.toString();
    }


    let filter = DashboardUtil.openFilter
      .concat(DashboardUtil.dateFilter(DASHBOARD_VENDORS_INVENTORY.FILTERS.DATE, initialDate, finishDate))
      .concat(',')
      .concat(DashboardUtil.granularityFilter(DASHBOARD_VENDORS_INVENTORY.FILTERS.GRANULARITY, granularity));


    if (salePerson) {
      filter = filter.concat(',').concat(DashboardUtil.salePersonFilter(DASHBOARD_VENDORS_INVENTORY.FILTERS
        .BUYER, salePerson,'SP'))
    }

    filter = filter.concat(DashboardUtil.closeFilter);
    console.log(filter);
    this.embedSupersetDashboard('inventory-vendors-dashboard', filter);
  }

  embedSupersetDashboard(dashboardId: string, filter: string): void {
    const dashboardElement = this.elementRef.nativeElement.querySelector('#' + dashboardId);
    if (dashboardElement) {
      this.embedService.embedDashboard(filter, environment.superset_dashboard_vendor_inventory, dashboardId)
        .subscribe({
          next: () => {
            const iframe = dashboardElement.querySelector('iframe');
            if (iframe) {
              iframe.style.width = '100%'; // Set the width as needed
              iframe.style.height = '5500px'; // Set the height as needed
              iframe.style.embedded = 'true';
              iframe.style.backgroundColor = '#ffffff';
              iframe.style.objectFit = 'contain'; // Adjust the content to fit the container
            }
          }, error: err => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        })
    }
  }


}
