import {Component, Input} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {DAUserRoleDto} from "@shared/model/dto/roles/DAUserRole.dto";
import {NgxPermissionsService} from "ngx-permissions";
import {
  ADMIN, ADMIN_USER, DASHBOARD_AND_ANALYTICS
} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-admin-menu',
  templateUrl: './da-admin-menu.component.html',
  styleUrls: ['./da-admin-menu.component.scss']
})

export class DaAdminMenuComponent{
  @Input()
  sidenav!: MatSidenav;

  @Input()
  userRole!: DAUserRoleDto;

  constructor(private ngxPermissionsService: NgxPermissionsService) {
  }


  getPermissions(){
    console.log(this.ngxPermissionsService.getPermissions())
  }


  protected readonly ADMIN = ADMIN;


  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;
  protected readonly ADMIN_USER = ADMIN_USER;
}
