<div class="row price-and-cost-container">

  <h4>
    <span>Price for: </span>
    <span *ngIf="sku">{{sku}}</span>
    <span *ngIf="businessPartnerPriceList">
      {{businessPartnerPriceList?.cardCode}} - {{businessPartnerPriceList?.cardName}}
    </span>
  </h4>
  <!--p>Aplicable Price List: TODO</p-->
  <div class="col-12 price-and-cost-container--table">
    <div class="row price-and-cost-container--table__header">
      <p class="col-4">
        Cost
      </p>
      <p class="col-4">
        Price
      </p>
      <p class="col-4">
        Margin
      </p>
    </div>
    <div class="row price-and-cost-container--table__row">
      <p class="col-4">
        {{cost | currency}}
      </p>
      <p class="col-4">
        {{price | currency}}
      </p>
      <p class="col-4">
        {{calculateMargin.toFixed(2)}} &percnt;
      </p>
    </div>
  </div>
  <div class="col-12 price-and-cost-container--actions">
    <button class="btn"
            (click)="downloadCanva()">
      {{title}}
    </button>
  </div>

</div>
