<form [formGroup]="productInfoForm">
  <div class="row">
    <mat-form-field appearance="fill" class="col-12">
      <mat-label>Category</mat-label>
      <mat-select (selectionChange)="parentGetSubcategory($event.value, false)"
                  formControlName="category" id="category">
        <mat-option *ngFor="let category of categories" [value]="category.key">
          {{category.values}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="row" formArrayName="sub_categories">
    <div *ngFor="let sub of subCategoriesField.controls; let i=index">
      <div [formGroupName]="i">
        <mat-form-field appearance="fill" class="col-12">
          <mat-label>Sub Category</mat-label>
          <mat-select (selectionChange)="getSubcategory($event.value)"
                      (valueChange)="removeCategory($event, i)"
                      formControlName="sub_category">
            <mat-option *ngFor="let category of getCategoriesAt(i)"
                        [disabled]="category.disabled"
                        [value]="category.key">
              {{category.values}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

