export class ScrollUtil{
  static scrollTo(id: string){
    setTimeout(() => {
      const element = document.getElementById(id);
      console.log(element);
      if (element) {
        element.scrollIntoView({behavior: 'smooth', block: 'start', inline:
            'nearest'});
        console.log('scrollIntoView');
      }

    }, 250 );
  }
  static scrollToTop(){
    window.scroll(
      {
        top: 0,
        left: 0,
        behavior: "smooth"
      }
    )
  }
}
