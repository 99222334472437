import {Component, Input, OnInit} from '@angular/core';
import {ChangeLogDto} from "@shared/model/dto/change-log/ChangeLog.dto";
import {ChangeLogService} from "@shared/services/change-log/change-log.service";
import {MatDialog} from "@angular/material/dialog";
import {
  ChangeLogDetailComponent
} from "@shared/components/change-log/change-log-detail/change-log-detail.component";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";
import {TITLE_INFO} from "@shared";
import {GetChangeLogDto} from "@shared/model/dto/change-log/GetChangeLog.dto";

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  getChangeLogParams!: GetChangeLogDto;
  @Input()
  title: string = '';

  changeLog: ChangeLogDto[] = [];
  displayedColumns: string[] = ['date', 'changeType', 'changeDescription', 'user', 'showDetail'];


  constructor(private changeLogService: ChangeLogService, private matDialog: MatDialog) {
  }

  openDetail(row: ChangeLogDto) {
    if (row.changelogDetails.length > 0) {
      const dialogRef = this.matDialog.open(ChangeLogDetailComponent, {
        width: '1000px',
        height: '90%',
      });
      dialogRef.componentInstance.changeLogDetail = row.changelogDetails;
    } else {
      showSomethingWrongPopup1(TITLE_INFO, 'Not data Found!');
    }

  }

  ngOnInit(): void {
    this.changeLogService.get(this.getChangeLogParams)
    .subscribe(result => {
      if (result.length > 0) {
        this.changeLog = result;
      } else {
        this.matDialog.closeAll();
        showSomethingWrongPopup1(TITLE_INFO, 'Not data Found!');
      }
    })
  }
}
