import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";


@Component({
  selector: 'app-special-deal-period-setup',
  templateUrl: './special-deal-period-setup.component.html',
  styleUrls: ['./special-deal-period-setup.component.scss']
})
export class SpecialDealPeriodSetupComponent {
  @Input()
  specialDealPeriodSetupForm!: UntypedFormGroup;
  @Input()
  product!: ProductsDto;


  get hasLimitValidPeriodField() {
    return this.specialDealPeriodSetupForm.get('has_limit_valid_period');
  }


}
