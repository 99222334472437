import {Component, Input} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {MatDialog} from "@angular/material/dialog";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductService} from "@features/product/services/product.service";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent {
  productSelectImageForm!: UntypedFormGroup;
  @Input()
  itemId!: number;
  @Input()
  product!: ProductsDto;

  constructor(private formBuilder: FormBuilder,
              private productService: ProductService,
              private matDialog: MatDialog) {
    // this.product.images = [];
    this.productSelectImageForm = this.buildProductSelectImageForm();
  }

  ngOnInit(): void {
    this.product.images = this.product.itemPictures;
    console.log(this.product)
  }

  uploadImages() {
    let imagesToUpload = this.product.images ? this.product.images.filter(image => image.checked) : [];
    imagesToUpload = imagesToUpload.map((item) => {
      item.order = this.product.images.length + 1
      return item
    })
    if (imagesToUpload && imagesToUpload.length > 0) {
      imagesToUpload.forEach(image => image.itemId = this.itemId);
      this.productService.saveImages(imagesToUpload).subscribe({
        next: value => {
          if (value) {
            showSuccessPopup(TITLE_INFO, 'Upload successful!').then(() => {
              this.matDialog.closeAll();
            })
          }
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    } else {
      showSomethingWrongPopup1(TITLE_INFO, 'You must upload at least one image, please check!')
    }
  }

  private buildProductSelectImageForm() {
    return this.formBuilder.group({
      selected_product_image: ['', [Validators.required]],
    });
  }

}
