import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from "@angular/forms";

import {Observable, of} from "rxjs";
import {GlobalConstants} from "@shared/utility/global-constants";
import {DateUtil} from "@shared/utility/dateUtil";

export class CustomValidator {

  static hasNumber(control: UntypedFormControl) {
    let hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return {has_not_number: true};
    }
    return null;
  }

  static hasUpperCase(control: UntypedFormControl) {
    let hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return {has_not_upper_case: true};
    }
    return null;
  }

  static hasLowerCase(control: UntypedFormControl) {
    let hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return {has_not_lower_case: true};
    }
    return null;
  }

  static hasSpecialCharacter(control: UntypedFormControl) {
    let hasSpecialCharacter = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value);
    if (!hasSpecialCharacter) {
      return {has_not_special_character: true};
    }
    return null;
  }

  static matchPasswords(control: AbstractControl) {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirm_password')?.value;
    if (password === confirmPassword) {
      return null;
    }
    return {match_password: true};
  }

  static findCodes(control: AbstractControl) {
    const upc = control.get('upc');
    const ean = control.get('ean');
    if (!CustomValidator.isCodeValid(upc) && !CustomValidator.isCodeValid(ean)) {
      console.log('entra a la validaciom')
      return {not_found_any_code: true};
    }
    return null;
  }

  static isCodeValid(control: AbstractControl | null) {
    return control?.value && control.value != '';
  }

  static validateSize(size: any, sizeToCompare: number) {
    return size < sizeToCompare;
  }


  static validateDecimal(control: UntypedFormControl) {
    if (control.value === '') {
      return null;
    }
    let hasDecimal = GlobalConstants.PATTER_ONLY_DECIMAL.test(control.value);
    if (!hasDecimal) {
      return {not_matched_decimal_pattern: true};
    }
    return null;
  }

  static validateDecimalFn(controlIn: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      console.log(control);
      let hasDecimal = GlobalConstants.PATTER_ONLY_DECIMAL.test(controlIn.value);
      console.log(hasDecimal);
      if (!hasDecimal) {
        return {not_matched_decimal_pattern: true};
      }
      return null
    }

  }

  static validateMinAndMaxPrice(control: AbstractControl) {
    const min = Number(control.get('min_price')?.value);
    const max = Number(control.get('max_price')?.value);
    if (min > max) {
      return {max_price_validator: true};
    }
    return null;
  }

  static validatePayingActualBetweenMinAndMaxPrice(control: AbstractControl) {
    const min = Number(control.get('min_price')?.value);
    const max = Number(control.get('max_price')?.value);
    const paying_actual = Number(control.get('paying_actual')?.value);
    if (paying_actual < min || paying_actual > max) {
      return {paying_actual_validator: true};
    }
    return null;
  }

  static validateOnlyNumber(control: UntypedFormControl) {
    let isOnlyNumber = GlobalConstants.PATTERN_ONLY_NUMBER.test(control.value);
    if (!isOnlyNumber) {
      return {is_only_number: true};
    }
    return null;
  }

  static validateAlphanumeric(control: UntypedFormControl) {
    let isAlphaNumeric = GlobalConstants.PATTERN_ALPHANUMERIC.test(control.value);
    if (!isAlphaNumeric) {
      return {is_alpha_numeric: true};
    }
    return null;
  }


  static dateComparisonBeforeToday(control: UntypedFormControl) {
    const isBeforeToday = DateUtil.isDateBefore(control.value);
    if (isBeforeToday) {
      return {date_before_today: true};
    }
    return null;
  }

  static dateComparisonAfterToday(control: UntypedFormControl) {
    const isBeforeToday = DateUtil.isDateAfter(control.value);
    if (isBeforeToday) {
      return {date_after_today: true};
    }
    return null;
  }

  static matchAccounts(control: AbstractControl) {
    const account_number = control.get('account_number')?.value;
    const verify_account_number = control.get('verify_account_number')?.value;
    if (account_number === verify_account_number) {
      return null;
    }
    return {match_accounts: true};
  }

  static validateBarCodeLength(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<any> => {
      if (!control.value) {
        return of(null);
      }
      let type = control.get('type_search_product');
      let controlValue = control.get('product_search_value');
      if (type?.value == 'upc') {
        return controlValue?.value.length != 12 ? of({validate_length: true}) : of(null);
      } else if (type?.value == 'ean') {
        return controlValue?.value.length != 13 ? of({validate_length: true}) : of(null);
      }
      return of(null);
    };

  }
}
