import { Component } from '@angular/core';
import {ADMIN, ADMIN_USER, DASHBOARD_AND_ANALYTICS} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-analytics-menu',
  templateUrl: './da-analytics-menu.component.html',
  styleUrls: ['./da-analytics-menu.component.scss']
})
export class DaAnalyticsMenuComponent {

  showSubMenu = false;


  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;
  protected readonly ADMIN = ADMIN;

  showHide(ItemSubMenu: HTMLUListElement[]) {
    this.showSubMenu = !this.showSubMenu;
    ItemSubMenu.forEach(item => {
      if (this.showSubMenu){
        item.style.display = 'flex'
      }else {
        item.style.display = 'none'
      }
    })


  }

  protected readonly ADMIN_USER = ADMIN_USER;
}
