<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <form [formGroup]="form">
        <div class="warehouse-title">
          <h1><strong>Please select the warehouse</strong></h1>
        </div>
        <mat-radio-group class="row group-warehouse"
                         color="primary" formControlName="store_selected">
          <mat-radio-button *ngFor="let warehouse of warehouses"
                            [value]="warehouse" class="col-4 group-warehouse--radio-button">
            {{warehouse.warehouseCode + ' - ' + warehouse.warehouseName}}
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </mat-card-content>
  </mat-card>
</div>
