<div class="row">
  <div class="col-12 close-button">
    <button [mat-dialog-close]="true" mat-icon-button>
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
  </div>
  <app-product-main (emitUpdateProduct)="saveProduct($event)"
                    *ngIf="product"
                    [isManufacture]="isManufacture"
                    [manufacture]="manufacture"
                    [productProcessType]="productProcessType"
                    [product]="product"
  >
  </app-product-main>
</div>
