import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ShareDataService} from "@shared/services/share-data.service";
import {
  DealsNotificationsDto
} from "@features/product-offer/model/notifications/deals-notifications.dto";
import {
  DealsNotificationsService
} from "@features/product-offer/services/deals-notifications.service";
import {OmnisedTypesService} from "@features/product-offer/services/OmnisedTypesService";
import {custom_popup, TITLE_INFO} from "@shared";
import {
  DealsNotificationSegmentDto
} from "@features/product-offer/model/notifications/deals-notifications-segments.dto";
import {OmnisedTypesDto} from "@features/product-offer/model/notifications/OmnisedTypes.dto";
import {MatStepper} from "@angular/material/stepper";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  salesForm!: UntypedFormGroup;
  customerForm!: UntypedFormGroup;
  whatsappForm!: UntypedFormGroup;

  listDeals: DealsNotificationsDto [] = [];

  segments: OmnisedTypesDto [] = [];

  @ViewChild('NotificationsStepper') myStepper!: MatStepper;

  constructor(private formBuilder: UntypedFormBuilder,
              private DealsNotifications: DealsNotificationsService,
              private shareDataService: ShareDataService,
              private omnisedTypes: OmnisedTypesService,
              private router: Router,
  ) {

    this.customerForm = this.buildCustomerForm();
    this.salesForm = this.buildSalesForm();
    this.whatsappForm = this.buildWhatsappNotificationsForm();
  }

  get optionSalesField() {
    return this.salesForm.get('selection');
  }

  get optionWhatsappField() {
    return this.whatsappForm.get('selection');
  }

  get optionCustomerField() {
    return this.customerForm.get('selection');
  }

  get segmentsFieldCustomer() {
    return this.customerForm.get('segments') as FormArray;
  }

  get segmentsFieldWhatsapp() {
    return this.whatsappForm.get('segments') as FormArray;
  }

  ngOnInit(): void {
    this.shareDataService.currentMessage.subscribe(data => {
      this.listDeals = data;
    })
    this.omnisedTypes.notificationsCustomerDeals()
    .subscribe(data => {
      this.segments = data;
    })
  }

  save() {
    if (this.optionCustomerField || this.optionSalesField || this.optionWhatsappField) {
      this.saveNotifications();
      custom_popup({
        title: 'You are set!\n',
        html: "<p>You have completed the deal wizard!</p><p>Thanks!</p>",
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: ' Yes!'
      })

    } else {
      custom_popup({
        title: 'You are set!\n',
        html: "<p>You have completed the deal wizard!</p><p>Thanks!</p>",
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: ' Yes!'
      }).then(() => this.router.navigate(['/product-offer/new/offer']))
    }
  }

  saveNotifications() {

    this.listDeals?.forEach(listOffer => {
      listOffer.statusCustomer = this.optionCustomerField?.value;
      listOffer.statusSales = this.optionSalesField?.value;
      listOffer.statusWhatsapp = this.optionWhatsappField?.value;

      let segments: DealsNotificationSegmentDto[] = [];
      let segmentsCustomer: DealsNotificationSegmentDto[] = [];
      let segmentsWhatsapp: DealsNotificationSegmentDto[] = [];
      if (this.optionCustomerField?.value == 1) {
        this.segmentsFieldCustomer.controls.forEach(customerSegments => {
          let dealsSegments = new DealsNotificationSegmentDto();
          dealsSegments.segment = customerSegments.get('segment')?.value;
          dealsSegments.messageType = customerSegments.get('type')?.value;
          segmentsCustomer.push(dealsSegments);
        })
      } else {
        segmentsCustomer = [];
      }
      if (this.optionWhatsappField?.value == 1) {
        this.segmentsFieldWhatsapp.controls.forEach(customerSegments => {
          let dealsSegments = new DealsNotificationSegmentDto();
          dealsSegments.segment = customerSegments.get('segment')?.value;
          dealsSegments.messageType = customerSegments.get('type')?.value;
          segmentsWhatsapp.push(dealsSegments);
        })
      } else {
        segmentsWhatsapp = [];
      }
      segments.push(...segmentsCustomer, ...segmentsWhatsapp);
      listOffer.segments = segments;
    })

    console.log(this.listDeals)
    this.DealsNotifications.saveDealsNotifications(this.listDeals).subscribe({
      next: (value: any) => {
        if (value.status) {
          this.router.navigate(['/product-offer/new/offer'])
        }
      }
    })
  }

  private buildWhatsappNotificationsForm() {
    return this.formBuilder.group({
      selection: [''],
      segments: this.formBuilder.array([])
    })
  }

  private buildCustomerForm() {
    return this.formBuilder.group({
      selection: ['', [Validators.required]],
      segments: this.formBuilder.array([])
    })
  }

  private buildSalesForm() {
    return this.formBuilder.group({
      selection: ['', Validators.required],
    });
  }

  validateSegmentsCustomer() {
    if (this.optionCustomerField?.value == 1){
      this.segmentsFieldCustomer.controls.length > 0
        ? this.myStepper.next()
        :  showSomethingWrongPopup1(TITLE_INFO,
          'You must select at least one segment to notify customers,please check!');
    }else {
      this.myStepper.next();
    }
  }
  validateSegmentsWhatsapp() {
    if (this.optionWhatsappField?.value == 1){
      this.segmentsFieldWhatsapp.controls.length > 0
        ? this.save()
        :  showSomethingWrongPopup1(TITLE_INFO,
          'You must select at least one segment to notify customers, please check!');
    }else {
      this.save();
    }

  }

}
