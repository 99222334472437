import { Injectable } from '@angular/core';
import {environment} from "@env";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {ChangeLogDto} from "@shared/model/dto/change-log/ChangeLog.dto";
import {GetChangeLogDto} from "@shared/model/dto/change-log/GetChangeLog.dto";

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private getUrl = `${this.automatePurchasesUrl}change-log/get/all`;

  constructor(private httpClient: HttpClient) { }

  get(params: GetChangeLogDto): Observable<any[]>{
    return this.httpClient.post<ChangeLogDto[]>(this.getUrl, params)
      .pipe(
        map((result: any) => {
          let items: ChangeLogDto[] = [];
          if (result && result.length > 0) {
            items = result;
          }
          return items;
        }), catchError(() => {
          return [];
        })
      )

  }

}
