export class ValidationData {
  ip: string = "0.0.0.0";
  recaptchaToken: string = "";

  toValidationData(): { [key: string]: string } {
    return {
      'ip': this.ip,
      'recaptchaToken': this.recaptchaToken
    };
  }
}
