<footer class="footer" >
  <div class="container">
    <h1 class=" text-center w-100 footer__main-title">DistriApp</h1>
    <p class="footer__sub-title text-center w-100">We are here to make your workday more productive and enjoyable! </p>
    <section class="text-center">
      <a
        class="social-network"
        href="https://www.instagram.com/distritechllc/?igshid=MzRlODBiNWFlZA%3D%3D" role="button"
        target="blank"
      >
        <img alt="Linkedin" [ngSrc]="'assets/icon/home/ig.png'" height="29" width="30">
      </a>
      <a
        class="social-network"
        href="https://www.linkedin.com/company/distritech/" role="button"
        target="blank"
      >
        <img alt="Linkedin" [ngSrc]="'assets/icon/home/link.png'" height="33" width="30">
      </a>
      <a
        class="social-network"
        href="https://www.facebook.com/distritechllc?mibextid=ZbWKwL" role="button"
        target="_blank"
      >
        <img alt="Linkedin" [ngSrc]="'assets/icon/home/fb.png'" height="30" width="30">
      </a>
      <p class="footer__copyright text-center w-100">© {{getYear()}} . Concept Created by Distritech</p>
    </section>
  </div>
</footer>

