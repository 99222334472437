<div class="col-12 close_button" id="close-button-container">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button id="close-button">
    <mat-icon svgIcon="close-popup-icon" id="close-popup-icon">close</mat-icon>
  </button>
</div>
<div class="change-log-container" id="change-log-container">
  <ng-container *ngIf="changeLog.length > 0; else notFound" id="change-log-container-ng">
    <h2 class="change-log-container--title" id="change-log-title">Change Log {{ title }}</h2>
    <div id="table-wrapper">
      <table [dataSource]="changeLog" mat-table id="change-log-table">
        <caption id="table-caption"></caption>
        <ng-container matColumnDef="date" id="date-column">
          <th *matHeaderCellDef mat-header-cell id="date-header">Date Time</th>
          <td *matCellDef="let row" mat-cell id="date-cell">
            {{ row?.operationDatetime |  date:'yyyy-MM-dd HH:mm:ss' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="changeType" id="change-type-column">
          <th *matHeaderCellDef mat-header-cell id="change-type-header">Change Type</th>
          <td *matCellDef="let row" mat-cell id="change-type-cell">{{ row?.operationType }}</td>
        </ng-container>
        <ng-container matColumnDef="changeDescription" id="change-description-column">
          <th *matHeaderCellDef mat-header-cell id="change-description-header">Change Description</th>
          <td *matCellDef="let row" mat-cell id="change-description-cell">
            <p style="text-align: start; margin: 0" id="change-description-text">{{ row?.description }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="user" id="user-column">
          <th *matHeaderCellDef mat-header-cell id="user-header">User</th>
          <td *matCellDef="let row" mat-cell id="user-cell">
            {{ row?.user.toString().replaceAll('@distritech.com', '') }}
          </td>
        </ng-container>
        <ng-container matColumnDef="showDetail" id="show-detail-column">
          <th *matHeaderCellDef mat-header-cell id="show-detail-header">Detail</th>
          <td *matCellDef="let row" mat-cell id="show-detail-cell">
            <ng-container *ngIf="row.changelogDetails.length > 0" id="show-detail-ng">
              <button
                [disabled]="(getChangeLogParams.warningType == 'MISSING_PRODUCT' || getChangeLogParams.warningType == 'DISCOUNT_APPROVAL')&& row.description=='Order Creation'"
                (click)="openDetail(row)" mat-icon-button id="show-detail-button">
                <mat-icon svgIcon="view-icon" id="view-icon"></mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row id="header-row"></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row id="data-row-{{row?.operationDatetime}}"></tr>

      </table>
    </div>
  </ng-container>

  <ng-template #notFound>
    <p id="not-found-text">Not data Found</p>
  </ng-template>
</div>
