import {Component, OnInit} from '@angular/core';
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {SimilarSkusDto} from "@features/product/model/dto/request/similarSkusDto";
import {RequestService} from "@features/request/services/request.service";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {ProductService} from "@features/product/services/product.service";
import {showErrorPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-show-skus',
  templateUrl: './show-skus.component.html',
  styleUrls: ['./show-skus.component.scss']
})
export class ShowSkusComponent implements OnInit {
  requestId!: number;
  product!: ItemDto;
  similars: SimilarSkusDto [] = [];
  request!: WRequestDto;
  products: ItemDto[] = [];

  constructor(
    private requestService: RequestService,
    private productService: ProductService,
  ) {
  }

  ngOnInit(): void {
    this.requestService.findSimilars(this.requestId).subscribe({
      next: (value: SimilarSkusDto[]) => {
        /* this.request.relatedProducts = this.products?.map((product)=>product.itemDescription);*/
        this.similars = value;
        this.similars?.map((similar) => {
          this.getProduct(similar.similarSkus)
          console.log(similar)
        })
      }
    });

  }


  getProduct(sku: string) {
    let getItemsParams = new GetItemParamsDto();
    getItemsParams.type = 'sku';
    getItemsParams.value = sku;
    return this.productService.geSapProduct(getItemsParams)
    .subscribe({
      next: (finalResult: ItemDto[]) => {
        console.log(finalResult)
        if (finalResult.length > 0) {
          this.products.push(finalResult[0]);
        }
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

}
