import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {ProductPreviewFormService} from "@features/product/features/presentation/product-preview/product-preview-form.service";

@Component({
  selector: 'app-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss']
})
export class ProductPreviewComponent implements OnInit {
  @Input() productPreviewForm!: FormGroup;
  @Input() product!: ProductsDto;
  @Input() descriptionCategories!: string;
  @Input() isDialog: boolean = false;

  constructor(private productPreviewFormService: ProductPreviewFormService) {
  }

  get specificationsField() {
    return this.productPreviewForm.get('specifications') as FormArray;
  }

  get modelField() {
    return this.productPreviewForm ? this.productPreviewForm.get('model')?.value : this.modelProduct;
  }

  get modelProduct() {
    return this.product && this.product.model ? this.product.model : '';
  }

  ngOnInit() {
    if (!this.productPreviewForm) {
      this.productPreviewForm = this.productPreviewFormService.buildProductPreviewForm();
    }

  }

  getSpecificationFieldAt(position: number) {
    return this.specificationsField.at(position);
  }

  cleanImages(images: ItemPicturesDto[]): ItemPicturesDto[] {
    return images.filter((item) => item.status !== false && item.status);
  }
}
