import Swal from 'sweetalert2';


const TITLE_ERROR = 'Error';
const TITLE_INFO = 'Important';
const TITLE_OK = 'Ok';
const TITLE_SUCCESS = 'SUCCESS';
const INVALID_OR_MISSING_FIELDS = 'there are invalid fields or missing fields to fill, please check!';
const CONTACT_SUPPORT = 'Something went wrong, please contact support!';
const ACCESS_DENIED = `You don't have permission to access this module, please check!`;
const LOGIN_AGAIN = 'You will be redirect to login again';
const REQUIRED_FIELDS = 'Please complete all required fields.';
const ERROR_MESSAGE = 'We have encountered an issue and our technical staff have been advised. Please try again later.';



const custom_popup = (options: any) => {
  return Swal.fire(options);
}
const confirm_popup_html = (title: string, messageHtml: string) => {
  return Swal.fire({
    title: title,
    showDenyButton: true,
    confirmButtonText: 'Confirm',
    denyButtonText: `Cancel`,
    confirmButtonColor: '#401def',
    denyButtonColor: '#eb3600',
    customClass: {
      popup: 'sweet-customized-popup',
      actions: 'sweet-customized-actions',
      confirmButton: 'sweet-customized-confirm-button',
    },
    html: messageHtml
  })
}

export {
  custom_popup,
  confirm_popup_html,
  TITLE_ERROR,
  TITLE_INFO,
  INVALID_OR_MISSING_FIELDS,
  CONTACT_SUPPORT,
  TITLE_OK,
  TITLE_SUCCESS,
  ACCESS_DENIED,
  LOGIN_AGAIN,
  REQUIRED_FIELDS,
  ERROR_MESSAGE
};
