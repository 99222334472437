import {Component, Input} from '@angular/core';


const ZOOM_STEP: number = 0.25;
const DEFAULT_ZOOM: number = 1;
@Component({
  selector: 'app-pdf-reader',
  templateUrl: './pdf-reader.component.html',
  styleUrls: ['./pdf-reader.component.scss'],
  host: {
    '(contextmenu)': 'disableClick($event)'
  }

})
export class PdfReaderComponent {


  @Input()
  pdfFilePath!: string;
  @Input()
  isModal: boolean = true;

  public pdfZoom: number = DEFAULT_ZOOM;



  zoomIn() {
    this.pdfZoom += ZOOM_STEP;
  }

  zoomOut() {
    if (this.pdfZoom > DEFAULT_ZOOM) {
      this.pdfZoom -= ZOOM_STEP;
    }
  }

  resetZoom() {
    this.pdfZoom = DEFAULT_ZOOM;
  }



  private disableClick(e:any){
    e.preventDefault();
  }
}
