<div class="po-associated-tracking-container">
  <mat-card>
    <mat-card-title class="text-center" style="margin-bottom: 40px">
      <h2><strong>PO ASSOCIATED WITH TRACKING</strong></h2>
    </mat-card-title>
    <mat-card-content>
      <div class="row">
        <mat-form-field appearance="outline" class=" col-4 filter_field">
          <mat-label>Tracking</mat-label>
          <input (keyup.enter)="search()" (ngModelChange)="noShowTablet()" [formControl]="filter"
                 matInput>
        </mat-form-field>
        <div class="col-4">
          <button (click)="search()" class="search_button" color="primary" mat-raised-button>
            <mat-icon><span class="material-symbols-outlined">search</span></mat-icon>
            Search
          </button>
        </div>

      </div>
      <div *ngIf="showTablet" class="row">
        <app-po-associated-tracking-tablet
          [data]="data"
        >
        </app-po-associated-tracking-tablet>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
