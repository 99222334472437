import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {UserSupplierDto} from "@shared/model/dto/user/UserSupplier.dto";
import {DateUtil} from "@shared/utility/dateUtil";
import {DaUserService} from "@shared/services/user/da-user.service";
import {SupersetService} from "@shared/services/superset/superset.service";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {environment} from "@env";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {DARoles} from "@shared/model/enum/roles/DARoles";
import {TransactionalLogService} from "@shared/services/transactional-log/transactional-log.service";
import {NameProcessLogEnum} from "@shared/model/enum/transactional-log/NameProcessLogEnum";
import {EmbeddedDashboard} from "@superset-ui/embedded-sdk";
import {showErrorPopup} from "@shared/utility/popup-message";
import {DashboardUtil} from "@shared/utility/dashboards/DashboardUtil";
import {DASHBOARD_MARKETPLACE} from "@shared/config/constants/dashboards.const";


@Component({
  selector: 'app-dashboard-analytics',
  templateUrl: './dashboard-analytics.component.html',
  styleUrls: ['./dashboard-analytics.component.scss']
})
export class DashboardAnalyticsComponent implements OnInit {
  form!: FormGroup;
  buyers: DAUserDto[] = [];
  customers: UserSupplierDto[] = [];
  maxDate = DateUtil.getCurrentDate().toDate();
  startOfMonth = DateUtil.getCurrentDate().startOf('month').toDate();
  minDate = new Date('2010/01/01');
  oneMonthBeforeDate = DateUtil.getCurrentDate().subtract(1, 'months').toDate();
  filterOpen = true;

  constructor(private formBuilder: FormBuilder,
              private daUserService: DaUserService,
              private embedService: SupersetService,
              private elementRef: ElementRef,
              private transactionalLogService: TransactionalLogService) {
    this.form = this.formBuilder.group({
      buyer: [''],
      vendor: [''],
      startDate: [this.startOfMonth, [Validators.required]],
      endDate: [new Date(), [Validators.required]],
      granularity: ['P1D', [Validators.required]],
    })
  }

  get buyerField() {
    return this.form.get('buyer');
  }

  get vendorField() {
    return this.form.get('vendor');
  }

  get startDateField() {
    return this.form.get('startDate');
  }

  get endDateField() {
    return this.form.get('endDate');
  }

  get granularityField() {
    return this.form.get('granularity');
  }


  ngOnInit(): void {
    this.transactionalLogService
    .saveLog(
      null,
      null,
      "INIT MKP DASHBOARD VIEW",
      NameProcessLogEnum.MKP_DASHBOARD,
      true);
    this.daUserService.findAllBuyers()
    .subscribe(result => {
      this.buyers = result
      if (this.buyers.length == 1) {
        this.buyerField?.setValue(this.buyers.at(0))
      }
    });

    this.daUserService.findUser()
    .subscribe(user => {
      if (user) {
        this.callBusinessPartnerByRol(user);
      }
    })
  }

  buyerName(buyer: DAUserDto) {
    return buyer.firstName + ' ' + buyer.lastName + ' - ' + this.getBuyerName(buyer.userCustomized);
  }

  getBuyerName(userCustomized: UserCustomizedDto[]) {
    return userCustomized && userCustomized.length > 0 ? UserCustomizedUtil.findBuyerSapCode(userCustomized)?.userCustomizedValue : '';
  }

  filterDashboard() {
    let vendors: string | null = null;
    let owner: string | null = null;

    if (!(this.vendorField?.value && this.vendorField.value == '')) {
      let vendorsArray = Array(this.vendorField?.value);
      vendors = vendorsArray.join(`','`)
    }
    if (this.buyerField?.value) {
      let find: DAUserDto = this.buyerField.value;
      let ownerCode = UserCustomizedUtil.findBuyerSapCode(find.userCustomized);
      if (ownerCode?.userCustomizedValue) {
        owner = ownerCode.userCustomizedValue;
      }
    }

    this.callDashboard(owner, vendors);

  }

  callDashboard(owner: any, vendors: any) {
    let endDate = new Date(this.endDateField?.value);
    endDate.setDate(endDate.getDate() + 1);
    let initialDate = DateUtil.formatDate(this.startDateField?.value, 'YYYY-MM-DD');
    let finishDate = DateUtil.formatDate(endDate, 'YYYY-MM-DD');
    let granularity = this.granularityField?.value;
    let filter = DashboardUtil.openFilter
    .concat(DashboardUtil.dateFilter(DASHBOARD_MARKETPLACE.FILTERS.DATE,initialDate, finishDate))
    .concat(',')
    .concat(DashboardUtil.granularityFilter(DASHBOARD_MARKETPLACE.FILTERS.GRANULARITY,granularity));
    if (vendors) {
      filter = filter.concat(',').concat(DashboardUtil.cardCodeFilter(DASHBOARD_MARKETPLACE.FILTERS.VENDORS,vendors))
    }
    if (owner) {
      filter = filter.concat(',').concat(DashboardUtil.ownerFilter(DASHBOARD_MARKETPLACE.FILTERS.OWNER,owner));
    }
    filter = filter.concat(DashboardUtil.closeFilter);
    console.log(filter)

    this.embedSupersetDashboard2('dashboard', filter);
    this.transactionalLogService
    .saveLog(this.form.value,
      filter,
      "FILTER MKP DASHBOARD",
      NameProcessLogEnum.MKP_DASHBOARD,
      true);
  }

  embedSupersetDashboard2(dashboardId: string, filter: string): void {
    const dashboardElement = this.elementRef.nativeElement.querySelector('#' + dashboardId);
    if (dashboardElement) {
      this.embedService.embedDashboard(filter, environment.superset_dashboard_mkp_listing_summary, dashboardId)
      .subscribe({
        next: async (dashboard: EmbeddedDashboard) => {
          const iframe = dashboardElement.querySelector('iframe');
          if (iframe) {
            iframe.style.width = '100%'; // Set the width as needed
            iframe.style.height = '7188px'; // Set the height as needed
            iframe.style.embedded = 'true';
            iframe.style.backgroundColor = '#ffffff';
          }
        }, error: err => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    }
  }

  changeSet(user: DAUserDto) {
    this.vendorField?.reset();
    this.callBusinessPartnerByOwner(user);
  }

  reset() {
    this.vendorField?.reset();
    if (this.buyers.length > 1) {
      this.buyerField?.reset();
    }
    this.startDateField?.reset()
    this.endDateField?.reset()
    this.granularityField?.reset();
  }

  toggle(filters: HTMLElement) {
    this.filterOpen = !this.filterOpen;
    if (!this.filterOpen) {
      filters.style.display = 'none'
    } else {
      filters.style.display = 'flex'

    }
  }


  private callBusinessPartnerByRol(user: DAUserDto) {
    if (user.roles.rol.rolName == DARoles.BUYER) {
      let ownerCode = UserCustomizedUtil.findBuyerSapCode(user.userCustomized);
      if (ownerCode?.userCustomizedValue) {
        this.daUserService.getAllUserSuppliers(ownerCode.userCustomizedValue)
        .subscribe(result => {
          this.customers = result;
          this.callDashboard(ownerCode?.userCustomizedValue, null);
        })
      }
    }
    if (user.roles.rol.rolName == DARoles.BUYERS_LEADER || user.roles.rol.rolName == DARoles.ADMIN) {
      this.daUserService.getAllUserSuppliers("NA")
      .subscribe(result => {
        this.customers = result;
        this.callDashboard(null, null);
      });
    }

  }

  private callBusinessPartnerByOwner(user: DAUserDto) {
    let ownerCode = UserCustomizedUtil.findBuyerSapCode(user.userCustomized);
    if (ownerCode?.userCustomizedValue) {
      this.daUserService.getAllUserSuppliers(ownerCode.userCustomizedValue)
      .subscribe(result => {
        this.customers = result;
      })
    }
  }
}
