import {Component, Input} from '@angular/core';
import {AmplifyService} from "@shared/services/amplify/amplify.service";
import {Router} from "@angular/router";
import {FormControl, Validators} from "@angular/forms";
import {GlobalConstants} from "@shared/utility/global-constants";
import {showErrorPopup, TITLE_ERROR} from "@shared/utility/popup-message";

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss']
})
export class QRCodeComponent {
  @Input() qrCode: string = '';
  totpCode = new FormControl('',
      [Validators.required,Validators.pattern(GlobalConstants.PATTERN_ONLY_NUMBER)]);

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
  ) {
  }

  submitTOTP() {
    if (this.totpCode.valid && this.totpCode.value){
      this.amplifyService.handleSignInConfirmation(this.totpCode.value)
          .then((response) => {
            if (response.isSignedIn) {
              console.log('TOTP Response:', response);
              this.amplifyService.handleUpdateMFAPreference().then(() => {
                this.redirect();
                // Add logic to handle TOTP response
              });
            }

      },(err) => {
            showErrorPopup(TITLE_ERROR, 'Code Invalid, please check!').then(() => {
              this.router.navigate(['login']).then(() => window.location.reload());
            });
      });
    }else {
      showErrorPopup(TITLE_ERROR, 'Code Invalid, please check!')
    }
  }

  redirect() {
    //TODO: This must works with new views
    this.router.navigate(['admin'])
    .then(() => window.location.reload());
  }
}
