<div class="pdf-reader-container">
  <div class="row">
    <div class="col-6">
      <div [style]="isModal ? 'position: fixed': 'position: initial'" >
        <mat-icon (click)="zoomIn()" style="cursor: pointer">zoom_in</mat-icon>
        <mat-icon (click)="zoomOut()" style="cursor: pointer">zoom_out</mat-icon>
        <mat-icon (click)="resetZoom()" style="cursor: pointer">restart_alt</mat-icon>
      </div>
    </div>
    <div class="col-6 close_button close-pdf-button" *ngIf="isModal">
        <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
          <mat-icon svgIcon="close-popup-icon">close</mat-icon>
        </button>
    </div>
  </div>

</div>
<div class="container_viewer" *ngIf="pdfFilePath">

  <pdf-viewer [src]="pdfFilePath"
              [original-size]="false"
              [zoom-scale]="'page-width'"
              [zoom]="pdfZoom"
              [fit-to-page]="true"
              [render-text]="false"
              [render-text-mode]="0"
              class="viewer"
  ></pdf-viewer>

</div>
