<div class="container">
  <mat-card class="process-type-card">
    <mat-card-content>
      <div class="row process-type-card__row">
        <div class="col-12 process-type-card__row--title">
          <h1>Product Creation</h1>
        </div>
        <div class="col-12 process-type-card__row--text">
          <h3>Would you like to create a single item or do a massive upload?</h3>
        </div>
        <div class="col-12 process-type-card__row--options">
          <mat-radio-group [formControl]="processType">
            <mat-radio-button class="mb-3" color="primary" value="0">Single Item</mat-radio-button>
            <mat-radio-button class="mx-3" color="primary" value="1">Massive Upload
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
