import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from "@env";

@Injectable()
export class IpUtilityService {

  private ipUrl = `${environment.resourcesUrl}ip`;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public get ipData(): any {
    return this.httpClient.get<any>(this.ipUrl, {headers: this.getHttpHeaders()});
  }

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.resourcesApiKey,
    });
  }

}
