<div class="inventory-dashboard-container">
  <app-filter-business-partner [title]="'DASHBOARD INVENTORY'"
                               [process]=NameProcessLogEnum.DASHBOARD_INVENTORY
                               [sapUsertype]="SapUserType.SAP_BUYER"
                               (emitFormValue)="filterDashBoard($event)"
                               [form]="form"
  >
  </app-filter-business-partner>
  <div class="inventory-dashboard-container--superset-dashboard" style="background: white">
    <div id="inventory-dashboard" style="background: white"></div>
  </div>
</div>


