import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

// Rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log(req.url);
    return next.handle(req).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => this.errorHandler(error)),
    );
  }

  errorHandler(error: HttpErrorResponse) {
    console.error('Ha ocurrido un error!', error);
    return throwError(error);
  }
}
