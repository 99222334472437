<div class="download-inventory-container">
  <div class="download-inventory-container--title">
    <h2>Customer Search</h2>
  </div>
  <mat-card class="download-inventory-container--card">
    <mat-card-content #filters id="filters">
      <form  class="row download-inventory-container--card__form" [formGroup]="form" >
        <div class="col-6 download-inventory-container--card__form--field" >
          <mat-label>Sales person</mat-label>
          <mat-form-field appearance="outline" class="small" >
            <mat-select   formControlName="ownerCode"
                          (valueChange)="changeSet($event)"
                          placeholder="Select">
              <mat-option *ngFor="let user of users" [value]="user" >
                {{userName(user)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 download-inventory-container--card__form--field">
          <mat-label>Customer</mat-label>
          <mat-form-field appearance="outline">
            <mat-select ngxLazyMatSelect
                        placeholder="Select"
                        formControlName="cardCode"
                        (infiniteScroll)="getNextBatch()"
                        [complete]="offset === total">
              <mat-option *ngFor="let option of options$ | async" [value]="option.cardCode">
                <span> {{ option.cardCode + ' - ' + option.cardName}}</span>
                <br>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 download-inventory-container--card__form--field" >
          <mat-label>Filter By</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="searchType" placeholder="Select">
              <mat-option value="cardCode">By CardCode</mat-option>
              <mat-option value="cardName">By CardName</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 download-inventory-container--card__form--field">
          <mat-label>Type value</mat-label>
          <mat-form-field appearance="outline" >
            <input autocomplete="on"
                   formControlName="searchValue"
                   matInput
                   type="text"
                   placeholder="Write Here"
            >
          </mat-form-field>
        </div>
        <div class="download-inventory-container--card__form--actions">
          <button class="btn"
                  (click)="filter()">
            Search
          </button>
          <button class="btn"
                  (click)="download()"
                  [disabled]="cardCodeField?.invalid">
            Download inventory
          </button>
        </div>
      </form>
      <div  *ngIf="businessPartnerList.length > 0" class="row download-inventory-container--card__user">
        <ng-container *ngFor="let businessPartner of businessPartnerList">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <p><strong>Card Code:</strong> {{businessPartner.cardCode}}</p>
                <p><strong>Card Name:</strong> {{businessPartner.cardName}}</p>
              </div>
            </mat-card-content>
            <div class="download-inventory-container--card__user--actions">
              <button (click)="selectBusinessPartner(businessPartner)" class="btn "> Select
              </button>
            </div>
          </mat-card>

        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
