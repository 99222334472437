<div class="menu">
  <div class="menu__item"
       *ngxPermissionsOnly="CUSTOMERS.CUSTOMERS"
       (click)="showHide(subMenu)">
    <img [ngSrc]="'assets/icon/menu-customers.png'" alt="admin" class="menu__item--icon1" height="26" width="25">
    <span  class="menu__item--text">Customers</span>
    <img [ngSrc]="'assets/icon/menu-arrow1.png'" alt="admin" class="menu__item--icon2" height="23" width="21">
  </div>
  <ul class="menu__submenu" #subMenu>
    <li>
      <div class="menu__submenu--item__toggle"
           *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.MY_CUSTOMERS"
           (click)="showHide(subMenuCustomersView)">
        <span>Customer View</span>
        <img [ngSrc]="'assets/icon/menu-arrow2.png'" alt="admin" class="menu__item--icon2" height="23" width="21">
      </div>
      <ul class="menu__submenu__second" #subMenuCustomersView>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.PROFILE">
          <span>Customer Profile</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.CONTACTS">
          <span> Customer Contacts</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.ADDRESS">
          <span> Address view</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.COMMERCIAL_OPPORTUNITIES">
          <span>  Commercial Opportunities</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.BILLING.BILLING">
          <span>  Billing / Accounts Receivable</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.REMINDERS_BACKLOG">
          <span> Reminders Backlog</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.FINANCING">
          <span>Financing</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.SERVICE_CASES">
          <span> Service Cases</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="CUSTOMERS.MY_CUSTOMERS.DATA_QUALITY">
          <span> Data Quality</span>
        </li>
      </ul>
    </li>

  </ul>
</div>


