import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {ProductService} from "@features/product/services/product.service";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {ShareDataService} from "@shared/services/share-data.service";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";

@Component({
  selector: 'app-select-multiple-skus',
  templateUrl: './select-multiple-skus.component.html',
  styleUrls: ['./select-multiple-skus.component.scss']
})
export class SelectMultipleSkusComponent implements OnInit {
  form!: FormGroup;
  purchaseOrderForm!: FormGroup;
  products: ItemDto[] = [];
  @Input()
  productSelected: ItemDto[] = [];

  constructor(private formBuilder: FormBuilder,
              private productService: ProductService,
              private shareDataService: ShareDataService
  ) {
    this.form = this.formBuilder.group({
      type_search_product: ['', Validators.required],
      product_search_value: ['', Validators.required],
    })
    this.purchaseOrderForm = this.buildPurchaseOrderForm()
  }

  get searchValueField() {
    return this.form.get('product_search_value');
  }

  get typeSearchProduct() {
    return this.form.get('type_search_product');
  }

  get productSearchValue() {
    return this.form.get('product_search_value');
  }

  get productSelectValue() {
    return this.form.get('product_select_value') as FormArray;
  }

  get purchaseOrderField() {
    return this.purchaseOrderForm.get('purchase_order') as FormArray;
  }

  ngOnInit(): void {
  }

  getProduct() {
    this.products = [];
    let getItemsParams = new GetItemParamsDto();
    getItemsParams.type = this.typeSearchProduct?.value;
    getItemsParams.value = this.searchValueField?.value;
    return this.productService.geSapProduct(getItemsParams)
    .subscribe({
      next: (finalResult: ItemDto[]) => {
        console.log(finalResult)
        if (finalResult.length > 0) {
          this.products = finalResult;
        } else {
          this.products = [];
          showSomethingWrongPopup1(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_MATCH_CRITERIA);
        }

      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

  getProductSelect(product: ItemDto) {
    let alreadyExists = this.purchaseOrderField.controls.filter(item => item.get('item_sku')?.value === product.sku);
    console.log(alreadyExists)
    if (alreadyExists && alreadyExists.length <= 0) {
      this.purchaseOrderField.push(this.buildOrderForm(product));
      this.productSelected.push(product)
      this.products = [];
      console.log(this.productSelected)
    } else {
      showSomethingWrongPopup1(TITLE_INFO, 'Item already added, please check!');
      this.products = [];
    }
  }

  upcField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.UPC);
  }

  eanField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.EAN);
  }

  reset() {
    this.form.reset();
    this.purchaseOrderField.clear()
    this.productSelected = [];
    this.products = [];
  }

  private buildPurchaseOrderForm() {
    return this.formBuilder.group({
      purchase_order: this.formBuilder.array([])
    })
  }

  private buildOrderForm(item: ItemDto) {
    return this.formBuilder.group({
      item_sku: [item.sku],
      item_description: [item.itemDescription],
      item_quantity: ['', Validators.required],
      item_unit_cost: ['', Validators.required],
      item_total: ['', Validators.required],
      item_vendor: ['', Validators.required],
      item_warehouse: ['', Validators.required]
    })
  }
}
