<mat-form-field appearance="outline">
  <input matInput [matDatepicker]="dp" [formControl]="date" placeholder="MM/YYYY">
  <mat-hint>MM/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</mat-form-field>
