import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';

// Componente principal
import {LoginComponent} from './login.component';
import {LoginMainComponent} from '@features/login/components/smart/login-main/login-main.component';

import {LoginAuthService} from '@features/login/services';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "@shared/shared.module";
import {ResetPasswordMainComponent} from './components/smart/reset-password-main/reset-password-main.component';
import {SendConfirmationCodeComponent} from './components/presentation/send-confirmation-code/send-confirmation-code.component';
import {VerifyConfirmationCodeComponent} from './components/presentation/verify-confirmation-code/verify-confirmation-code.component';
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {AccountInformationLoginComponent} from './components/presentation/account-information-login/account-information-login.component';
import {MatIconModule} from "@angular/material/icon";
import {ChangePasswordComponent} from './components/presentation/change-password/change-password.component';
import {QRCodeComponent} from './components/smart/qrcode/qrcode.component';
import {QRCodeModule} from "angularx-qrcode";
import {TOTPConfirmationComponent} from './components/smart/totp-confirmation/totp-confirmation.component';
import {MatDialogModule} from "@angular/material/dialog";


@NgModule({
  declarations: [
    LoginComponent,
    LoginMainComponent,
    ResetPasswordMainComponent,
    SendConfirmationCodeComponent,
    VerifyConfirmationCodeComponent,
    AccountInformationLoginComponent,
    ChangePasswordComponent,
    QRCodeComponent,
    TOTPConfirmationComponent
  ],
    imports: [
        CommonModule,
        LoginRoutingModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        LoginRoutingModule,
        MatIconModule,
        QRCodeModule,
        MatDialogModule
    ],
  providers: [
    LoginAuthService,
  ],
  exports: [LoginComponent, ChangePasswordComponent],
})
export class LoginModule {
}
