import {Component, Input, OnInit} from '@angular/core';
import {InfoTrackingPoDto} from "@features/product/model/dto/product/info-tracking-po.dto";

@Component({
  selector: 'app-po-associated-tracking-tablet',
  templateUrl: './po-associated-tracking-tablet.component.html',
  styleUrls: ['./po-associated-tracking-tablet.component.scss']
})
export class PoAssociatedTrackingTabletComponent implements OnInit {
  @Input()
  data: InfoTrackingPoDto[] = [];
  displayedColumns: string[] = [
    'docNum', 'sku', 'item_description', 'quantity', 'cardCode'
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
