import {SearchCriteriaDto} from "@features/product/model/dto/request/search-criteria.dto";

export class SearchSolicitudeRequestDto {
  searchCriteria: SearchCriteriaDto[] = [];
  idRequested!: number;
  category!: string;
  subcategory!: string;
  manufacturer!: number;
  requestInitDate!: string;
  requestEndDate!: string;
  partNumber!: string;
  sku!:string;
  itemCode!:string;
  buyerId!: string;
  status!: string;
  cardCode!: string;
  itemDescription!: string;
  isHotOffer!: boolean;
  id!: number;

}
