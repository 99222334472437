// src/app/features/product/services/product-preview-form.service.ts
import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProductPreviewFormService {
  constructor(private formBuilder: FormBuilder) {
  }

  buildProductPreviewForm(): FormGroup {
    return this.formBuilder.group({
      specifications: this.formBuilder.array([]),
      model: ['', [Validators.required]],
    });
  }

}
