import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

import {embedDashboard, EmbeddedDashboard} from '@superset-ui/embedded-sdk';
import {environment} from "@env";

@Injectable({
  providedIn: 'root'
})
export class SupersetService {
  /**
   * API URL of Superset to send request
   *
   */
  private apiUrl = environment.supersetUrl;

  /**
   * @param {HttpClient} http Http Client to send requests.
   */
  constructor(private http: HttpClient) {
  }

  /**
   *
   * @returns { guest Token }
   */
  getGuestToken(): Observable<any> {
    return this.fetchAccessToken().pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      }),
      switchMap((accessToken: any) => this.fetchGuestToken(accessToken))
    );
  }

  /**
   *
   * @returns { dashboard }
   */
  embedDashboard(filers: string, dashboard: string, elementId: string): Observable<EmbeddedDashboard> {
    return new Observable((observer) => {
      this.getGuestToken().subscribe({
        next: (token): any => {
          // @ts-ignore
          embedDashboard({
            id: dashboard + filers,  // Replace with your dashboard ID
            supersetDomain: this.apiUrl,
            mountPoint: document.getElementById(elementId)!,
            fetchGuestToken: () => token["token"],
            dashboardUiConfig: {
              hideTitle: true,
              hideChartControls: true,
              hideTab: false,
            },
          }).then((embeddedDashboard: EmbeddedDashboard) => {
            observer.next(embeddedDashboard);
            observer.complete();
          });
        },
        error: (error) => {
          observer.error(error);
        }
      });
    });
  }

  /**
   *
   * @returns { access token }
   */
  private fetchAccessToken(): Observable<any> {
    const body = {
      "username": environment.superset_userName,
      "password": environment.superset_pass,
      "provider": "db",
      "refresh": true
    };

    const headers = new HttpHeaders({"Content-Type": "application/json"});

    return this.http.post<any>(`${this.apiUrl}api/v1/security/login`, body, {headers});
  }

  /**
   *
   * @returns { guest token } using @param { accessToken }
   */
  private fetchGuestToken(accessToken: any): Observable<any> {
    const body = {
      "resources": [
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_mkp_listing_summary
        },
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_orders
        },
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_customers
        },
        {
          "type": "dashboard",
          "id": environment.test_dashboard
        },
        {
          "type": "dashboard",
          "id": environment.item_purchases_dashboard
        },
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_inventory
        },
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_vendor_inventory
        },
        {
          "type": "dashboard",
          "id": environment.superset_dashboard_key_metrics
        },
      ],
      /**
       * rls: Row Level Security, this differs for client to client ,like what to show each client
       */
      "rls": [],
      "user": {
        "username": "appdataviewer",
        "first_name": "Viewer",
        "last_name": "App",
      }
    };

    const acc = accessToken["access_token"]; //accessToken is an object in which there are two tokens access_token and refresh_token ,out of which we just need to send access_token to get guest_token
    const headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": `Bearer ${acc}`,
    });
//guest_token URL should end with forward_slash(/)
    return this.http.post<any>(`${this.apiUrl}api/v1/security/guest_token/`, body, {headers});
  }
}
