import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {SearchBPPriceListDto} from "@features/product-offer/model/offer-purchase-order/SearchBPPriceList.dto";
import {showErrorPopup, TITLE_ERROR, TITLE_SOMETHING_WRONG} from "@shared/utility/popup-message";
import {SearchBusinessPartnerDto} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {DABusinessPartnerService} from "@features/product/services/da-business-partner.service";
import {
  DABusinessPartnerPriceListDto
} from "@features/product/model/dto/da-business-partner/DABusinessPartnerPriceList.dto";
import {RequestUtil} from "@features/request/utility/requestUtil";
import {
  ProductOfferCanvaComponent
} from "@features/product-offer/presentation/product-offer-canva/product-offer-canva.component";
import {MatDialog} from "@angular/material/dialog";
import {DABusinessPartnerDto} from "@features/product/model/dto/da-business-partner/DABusinessPartner.dto";
import {SapUserType} from "@shared/model/enum/sap-user-type";
import {ItemCustomizedUtil} from "@features/product-offer/utility/validator/ItemCustomizedUtil";
import {
  MyCustomersPriceComponent
} from "@features/product-offer/presentation/canva-and-price/my-customers-price/my-customers-price.component";

@Component({
  selector: 'app-canva-and-price',
  templateUrl: './canva-and-price.component.html',
  styleUrls: ['./canva-and-price.component.scss']
})
export class CanvaAndPriceComponent{
  form!: FormGroup;
  item!: ItemDto;
  offerId!: number;
  businessPartnerList: DABusinessPartnerDto[] = [];
  businessPartnerPriceListSelected!: DABusinessPartnerPriceListDto | null;


  constructor(private formBuilder: FormBuilder,
              private businessPartnerService: DABusinessPartnerService,
              public dialog: MatDialog,) {
    this.form = this.formBuilder.group({
      searchType: ['cardName', Validators.required],
      searchValue: ['', Validators.required]
    })

  }

  filter(){
    this.businessPartnerPriceListSelected = null;
    let search = new SearchBusinessPartnerDto();
    search.searchType = this.form.get('searchType')?.value;
    search.value = this.form.get('searchValue')?.value;
    this.businessPartnerService.get(search)
      .subscribe(result => {
        if(result.length > 0){
          this.businessPartnerList = result
        }else {
          showErrorPopup(TITLE_ERROR,
            'We weren\'t able to find any user that match your search criteria, please check')
        }

      });
  }

  private getBusinessPartnerPrice(cardCode: string) {
    let sku = ItemCustomizedUtil.skuSap(this.item.itemCustomized);
    if (sku){
      let search = new SearchBPPriceListDto();
      search.sku = sku;
      search.cardCode = cardCode;


      this.businessPartnerService.getBusinessPartnerPrice(search).subscribe({
        next: result =>{
          if (result){
            this.businessPartnerPriceListSelected = result;
          }else {
            showErrorPopup(TITLE_ERROR, TITLE_SOMETHING_WRONG);
          }
        },error: err =>{
          showErrorPopup(TITLE_ERROR, err);
        }
      })

    }else {
      showErrorPopup(TITLE_ERROR, TITLE_SOMETHING_WRONG);
    }
  }





  downloadCanva(price: number | null) {
    if (this?.item) {
     let instance = this.dialog.open(ProductOfferCanvaComponent, {
       width: '700px',
       height: '80%',
     });
     instance.componentInstance.item = this.item;
     instance.componentInstance.price = price;
     instance.componentInstance.offerId = this.offerId;
   }else {
      showErrorPopup(TITLE_ERROR, TITLE_SOMETHING_WRONG);
    }
  }

  selectBusinessPartner(businessPartner: DABusinessPartnerDto) {
      this.getBusinessPartnerPrice(businessPartner.cardCode);
  }


  protected readonly SapUserType = SapUserType;
  control = new FormControl('');

  protected readonly ItemCustomizedUtil = ItemCustomizedUtil;
}
