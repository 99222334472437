<div class="container">
  <mat-card class="container__card">
    <mat-card-title class="text-center"><h3><strong>Manufacturer Creation</strong></h3>
    </mat-card-title>
    <mat-card-content>
      <div *ngIf="product" [formGroup]="manufactureCreateForm">
        <div class="text-center">
          <h3>
            the manufacturer <strong>{{product?.manufacturer!}}</strong> does not exist
          </h3>
        </div>
        <div class="row">
          <section class="example-section">
            <label class="example-margin">Manufacturer: </label>
            <mat-radio-group (change)="optionManufacture($event)"
                             formControlName="manufacture_create_option_manufacture">
              <mat-radio-button class="example-margin" value="create">Manufacturer creations
              </mat-radio-button>
              <mat-radio-button class="example-margin" value="select">Manufacturer selections
              </mat-radio-button>
            </mat-radio-group>
          </section>
        </div>
        <div *ngIf="this.manufactureCreateOption=='create'" class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>MANUFACTURER</mat-label>
            <input [(ngModel)]="_manufacturer"
                   autocomplete="on"
                   formControlName="manufacture_create_manufacture"
                   id="manufacture_create_manufacture"
                   matInput
                   type="text"
            >
          </mat-form-field>
        </div>
        <div *ngIf="this.manufactureCreateOption=='select'" class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Manufacturer</mat-label>
            <mat-select formControlName="manufacture_create_manufacture" id="manufacturer">
              <mat-option *ngFor="let manufacturer of manufacturers "
                          [value]="manufacturer.ditmmnf_front_dsc">
                {{manufacturer.ditmmnf_front_dsc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
