import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ROffersForRequestDto} from "@features/product/model/dto/request/ROffersForRequestDto";
import {RequestStatus} from "@features/request/model/request-status";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";


export class WRequestDto {
  id!: number;
  description!: string;
  partNumber!: string;
  upc!: string;
  techSpec!: string;
  priceMin!: number;
  priceMax!: number;
  quantityMin!: number;
  quantityMax!: number;
  eta!: string;
  paymentTerms!: string;
  requester!: DAUserDto;
  sku!: string;
  offerId!: number;
  buyer!: DAUserDto;
  stock!: number;
  moq!: number;
  offerCost!: number;
  offerPrice!: number;
  offerMargin!: number;
  offerEta!: string;
  item!: ItemDto;
  manufacturer!: string;
  requestDate!: Date;
  status!: RequestStatus;
  offer!: ROffersForRequestDto;
  requestDateUpdate!: string;
  commercialStatus!: string;
  commercialStatusTypification!: string;
  comments!: string;
  cardCode!: string;
  indicatorPurchase!: number;
  indicatorSales!: number;
  relatedProducts: string[] = [];
  idRequested!: number;
  category!: string;
  subCategory!: string;

}
