<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div class="row">
        <mat-label>
          <h3><strong>Product select image</strong></h3>
        </mat-label>
      </div>
      <div class="row">
        <mat-form-field>
          <div class="file__container">
            <input matInput readonly/>
            <label class="form-label" for="uploadFile">Upload the required file</label>
            <!-- Fetch selected filed on change -->
            <input
              #fileInput
              (change)="uploadFile($event)"
              accept="image/.jpg, image/png, image/gif, image/jpeg"
              class="form-control form-control-lg"
              id="uploadFile"
              name="uploadFile"
              type="file"
            />
          </div>
        </mat-form-field>
      </div>
      <div *ngIf="product && product.images && product.images.length>0"
           [formGroup]="productSelectImageForm">
        <mat-selection-list #imagesBySearch class="container__image__search"
                            formControlName="selected_product_image">
          <mat-list-option (click)="productSelectImage(image)" *ngFor="let image of product.images"
                           [selected]="image.checked"
                           [value]="image"
                           color="primary"
          >
            <img [src]="image.url" alt="item image" class="show_image">
          </mat-list-option>
        </mat-selection-list>
      </div>

    </mat-card-content>
  </mat-card>
</div>
