import {
  AfterViewInit,
  Component,
  EventEmitter, Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, filter, map, Observable, pairwise, scan, throttleTime} from "rxjs";
import {
  DABusinessPartnerPriceListDto
} from "@features/product/model/dto/da-business-partner/DABusinessPartnerPriceList.dto";
import {SearchBPPriceListDto} from "@features/product-offer/model/offer-purchase-order/SearchBPPriceList.dto";
import {DABusinessPartnerService} from "@features/product/services/da-business-partner.service";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ItemCustomizedUtil} from "@features/product-offer/utility/validator/ItemCustomizedUtil";
import {
  ProductOfferCanvaComponent
} from "@features/product-offer/presentation/product-offer-canva/product-offer-canva.component";
import {showErrorPopup, TITLE_ERROR, TITLE_SOMETHING_WRONG} from "@shared/utility/popup-message";
import {MatDialog} from "@angular/material/dialog";
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {RequestUtil} from "@features/request/utility/requestUtil";

@Component({
  selector: 'app-my-customers-price',
  templateUrl: './my-customers-price.component.html',
  styleUrls: ['./my-customers-price.component.scss',
  ]
})
export class MyCustomersPriceComponent implements OnInit, AfterViewInit{

  @Input()
  item!: ItemDto;
  @Input()
  offerId!: number;
  options = new BehaviorSubject<DABusinessPartnerPriceListDto[]>([]);
  options$: Observable<DABusinessPartnerPriceListDto[]> | undefined;

  offset = 0;
  limit = 20;
  total = 10000000;


  @Output() emitter = new EventEmitter();
  @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;

  constructor(private businessPartnerService: DABusinessPartnerService,
              private dialog: MatDialog,
              private ngZone: NgZone) {
    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerPriceListDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );
  }

  ngAfterViewInit(): void {

    this.scroller.elementScrolled().pipe(
      map(() => this.scroller.measureScrollOffset('bottom')),
      pairwise(),
      filter(([y1, y2]) => (y2 < y1 && y2 < 140)),
      throttleTime(200)
    ).subscribe(() => {
        this.ngZone.run(() => {
          this.offset += this.limit;
          let search =  new SearchBPPriceListDto();
          search.sku = ItemCustomizedUtil.skuSap(this.item.itemCustomized);
          search.totalItems = this.offset;
          this.callDataBP(search);
        });
      }
    );
  }

  ngOnInit(): void {
    if (this.item){
      let search =  new SearchBPPriceListDto();
      search.sku = ItemCustomizedUtil.skuSap(this.item.itemCustomized);
      this.callDataBP(search);
    }
  }


  private callDataBP(search: SearchBPPriceListDto) {
    this.businessPartnerService.getBusinessPartnerPriceByOwner(search).
    subscribe((result: any) => {
      if (result && result.length > 0) {
          this.options.next(result);
      }
    })
  }

  downloadCanva(price: number | null) {
    if (this?.item) {
      let instance = this.dialog.open(ProductOfferCanvaComponent, {
        width: '700px',
        height: '80%',
      });
      instance.componentInstance.item = this.item;
      instance.componentInstance.price = price;
      instance.componentInstance.offerId = this.offerId;
    }else {
      showErrorPopup(TITLE_ERROR, TITLE_SOMETHING_WRONG);
    }
  }



  getNextBatch() {
    this.offset += this.limit;
    let search =  new SearchBPPriceListDto();
    search.sku = ItemCustomizedUtil.skuSap(this.item.itemCustomized);
    search.totalItems = this.offset;
    this.callDataBP(search);
  }

  calculateMargin(option: DABusinessPartnerPriceListDto) {
    return option.price &&  this.item?.itemSapData?.cost ?
      RequestUtil.getMargin(option.price,this.item?.itemSapData?.cost)
      : 0.00;
  }

}
