import {Component} from '@angular/core';
import {AmplifyService} from "@shared/services/amplify/amplify.service";
import {Router} from "@angular/router";
import {showErrorPopup, TITLE_ERROR} from "@shared/utility/popup-message";
import {FormControl, Validators} from "@angular/forms";
import {GlobalConstants} from "@shared/utility/global-constants";

@Component({
  selector: 'app-totp-confirmation',
  templateUrl: './totp-confirmation.component.html',
  styleUrls: ['./totp-confirmation.component.scss']
})
export class TOTPConfirmationComponent {
  totpCode = new FormControl('',
      [Validators.required,Validators.pattern(GlobalConstants.PATTERN_ONLY_NUMBER)]);

  constructor(
    private amplifyService: AmplifyService,
    private router: Router
  ) {
  }

  submitTOTP() {
    if (this.totpCode.valid && this.totpCode.value){
      this.amplifyService.handleSignInConfirmation(this.totpCode.value)
          .then((response) => {
        this.redirect();
      }, (err) => {
        showErrorPopup(TITLE_ERROR, 'Code Invalid, please check!').then(() => {
          this.router.navigate(['login']).then(() => window.location.reload());
        });
      });
    }else {
      showErrorPopup(TITLE_ERROR, 'Code Invalid, please check!')
    }

  }

  redirect() {
    //TODO: This must works with new views
    this.router.navigate(['admin'])
    .then(() => window.location.reload());
  }
}
