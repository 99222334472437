<div *ngIf="sku && isDialog" class="container-eta">
  <mat-card class="container-eta__card">
    <div class="col-12 close_button">
      <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
        <mat-icon svgIcon="close-popup-icon">close</mat-icon>
      </button>
    </div>
    <mat-card-title>
      <h1>QTY - ETA</h1>
      <h2>
        <strong>{{sku}}</strong>
      </h2>
    </mat-card-title>
    <mat-card-content>
      <table>
        <tr>
          <th>ETA</th>
          <th>InStock</th>
        </tr>
        <tr *ngFor="let items of inventoryEta">
          <td>{{items?.eta}}D</td>
          <td>{{items?.inStock}}</td>
        </tr>
        <tr [ngClass]="{'total-row': true, 'positive': totalInStock >= 0, 'negative': totalInStock < 0}">
          <td><strong>Total</strong></td>
          <td><strong>{{ totalInStock }}</strong></td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
