import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {
  BusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/business-partner.dto";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {
  SearchBusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {BusinessPartnerService} from "@features/product-offer/services/business-partner.service";
import {BusinessPartnerType} from "@shared/model/enum/business-partner-type";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-search-for-vendor',
  templateUrl: './search-for-vendor.component.html',
  styleUrls: ['./search-for-vendor.component.scss']
})
export class SearchForVendorComponent {

  @Input()
  searchVendorForm!: UntypedFormGroup;

  businessPartnerList: BusinessPartnerDto[] = [];


  constructor(private businessPartner: BusinessPartnerService) {
  }

  get searchType() {
    return this.searchVendorForm.get('search_type');
  }

  get searchValue() {
    return this.searchVendorForm.get('search_value');
  }

  get vendorField() {
    return this.searchVendorForm.get('vendor');
  }

  getBusinessPartner() {
    this.businessPartnerList = [];
    let searchBusinessPartnerDto = new SearchBusinessPartnerDto();
    searchBusinessPartnerDto.searchType = this.searchType?.value;
    searchBusinessPartnerDto.value = this.searchValue?.value;
    searchBusinessPartnerDto.userType = BusinessPartnerType.SUPPLIER;
    this.businessPartner.getBusinessPartner(searchBusinessPartnerDto)
    .subscribe({
      next: result => {
        if (result && result.length > 0) {
          this.businessPartnerList = result;
        } else {
          showSomethingWrongPopup1(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_MATCH_CRITERIA);
        }
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

  selectBusinessPartner(businessPartner: BusinessPartnerDto) {
    this.businessPartnerList = [];
    this.vendorField?.setValue(businessPartner);
  }


}



