import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductOfferUtility} from "@shared/utility/productOfferUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RequestStatus} from "@features/request/model/request-status";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";

import {ROffersForRequestDto} from "@features/product/model/dto/request/ROffersForRequestDto";
import {MatDialog} from "@angular/material/dialog";
import {RequestUtil} from "@features/request/utility/requestUtil";
import {AuthService} from "@core/services";
import {
  showErrorPopup,
  showSomethingWrongPopup1,
  showSuccessPopup,
  TITLE_SOMETHING_WRONG
} from "@shared/utility/popup-message";
import {RequestService} from "@features/request/services/request.service";
import {RequestDto} from "@features/request/model/RequestDto";
import {ProductService} from "@features/product/services/product.service";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {DaUserService} from "@shared/services/user/da-user.service";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";

@Component({
  selector: 'app-request-update-status',
  templateUrl: './request-update-status.component.html',
  styleUrls: ['./request-update-status.component.scss']
})
export class RequestUpdateStatusComponent implements OnInit, AfterViewInit {

  product!: ItemDto;
  request!: RequestDto;
  form: FormGroup;

  statusExcluded = [RequestStatus.WON_OPPORTUNITY, RequestStatus.LOST_OPPORTUNITY, RequestStatus.CANCELLED, RequestStatus.PENDING];
  status: RequestStatus[] = Object.values(RequestStatus)
  .filter((item =>  !this.statusExcluded.includes(item)))


  buyers: DAUserDto[] = [];
  offers: ROffersForRequestDto[] = [];
  products: ItemDto[] = [];
  protected readonly RequestUtil = RequestUtil;

  constructor(private formBuilder: FormBuilder,
              private productOfferService: ProductOfferService,
              private userService: DaUserService,
              private requestService: RequestService,
              public dialog: MatDialog,
              private authService: AuthService,
              private productService: ProductService,
  ) {


    this.form = this.formBuilder.group({
      status: ['', Validators.required],
      offer: [null],
      request_comments: ['', Validators.required]
    })
  }

  get statusField() {
    return this.form.get('status');
  }



  ngOnInit(): void {
    this.getBuyers();
  }

  ngAfterViewInit() {
    if (this.request?.itemId) {
      this.productService.getItemById(this.request.itemId).subscribe({
        next: (response) => {
          this.product = response;
        }
      })
    }
  }

  eanField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.EAN)
  }

  upcField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.UPC)
  }

  getBuyerName(userCustomized: UserCustomizedDto[]) {
    return userCustomized && userCustomized.length > 0 ? UserCustomizedUtil.findBuyerSapCode(userCustomized)?.userCustomizedValue : '';
  }

  getBuyers() {
    this.userService.findAllBuyers()
    .subscribe({
      next: value => {
        this.buyers = value;
      },
      error: () => {
        this.buyers = [];
      },
    })
  }

  updateStatus() {
    if (this.form.invalid){
      showSomethingWrongPopup1(TITLE_SOMETHING_WRONG,
        'You must select a status from  the list and add a comment!')
      return
    }
    let request = new WRequestDto();
    request.id = this.request.id;
    request.relatedProducts = this.products?.map((product) => product.sku);
    request.status = this.form.get('status')?.value;
    request.comments = this.form.get('request_comments')?.value;
    if (!this.request.offerId && this.statusField?.value == RequestStatus.SOURCED) {
      let offer = new ROffersForRequestDto();
      offer.id = this.form.get('offer')?.value
      request.offer = offer;
    }
    this.requestService.updateStatus(request).subscribe({
      next: value => {
        if (value.status) {
          showSuccessPopup(TITLE_INFO, 'Status updated').then(() => this.dialog.closeAll());
        }
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })

  }

  findOffers($event: any) {
    if ($event == RequestStatus.SOURCED) {
      if (this.request?.itemId) {
        this.productOfferService.findAllByItemId(this.request.itemId).subscribe({
          next: (value: ROffersForRequestDto[]) => {
            this.offers = value;
          }, error: () => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        })
      } else {
        this.authService._getFromPayload("id_user").then(userId => {
          this.productOfferService.findLastByUser(userId).subscribe({
            next: (value: ROffersForRequestDto[]) => {
              this.offers = value;
            }, error: () => {
              showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
            }
          })
        })

      }
    }

  }

  protected readonly RequestStatus = RequestStatus;
}
