<div class="col-12 close-button-template">
  <button [mat-dialog-close]="true" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>

<div class="container ">

  <div *ngIf="product" class="row mx-auto w-100">
    <div>
      <mat-card class="product_custom">
        <mat-card-header>
          <mat-card-title>
            <p>{{product.itemDescription}}</p>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row product-container">
            <div class="col-5 mx-auto">
              <img [src]="product.image" alt="item image" height="200px" width="200px">
            </div>
            <div class="col-7">
              <p *ngIf="product.sku"><strong>SKU:</strong> {{product.sku}} </p>
              <p><strong>Manufacturer:</strong> {{product.manufacturers
                ? product.manufacturers.description : ''}}</p>
              <p><strong>Model:</strong>{{product.model ?? ''}}</p>
              <p><strong>Part Number:</strong>{{product.partNumber ?? ''}}</p>
              <p><strong>Condition:</strong>{{product.condition ?? ''}}</p>
              <p><strong>Category: </strong>{{product.itemCategories.description ?? ''}}</p>
              <p *ngIf="eanField(product)"><strong>EAN:</strong>{{eanField(product)}}</p>
              <p *ngIf="upcField(product)"><strong>UPC:</strong>{{upcField(product)}}</p>
              <ul>
                <li>
                  <strong>Tech specs:</strong>
                  <div *ngFor="let spec of product.itemSpecifications">
                    <ul>
                      <div>
                        <li>
                          <strong>{{spec.specifications}}</strong>:{{spec.descriptionItemSpecific}}
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="row  mx-auto mt-3">
      <div class=" row w-100">
        <mat-label>Product Name/Description</mat-label>
        <mat-form-field appearance="outline">
          <input formControlName="description" matInput type="text">
        </mat-form-field>
        <mat-label>Manufacturer PartNumber</mat-label>
        <mat-form-field appearance="outline">
          <input formControlName="partNumber" matInput type="text">
        </mat-form-field>
      </div>

      <div class="col-6 m-auto d-flex flex-column align-top">
        <mat-label>Target Unit Price Min</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="priceMin" matInput step="0.2" type='number'>
        </mat-form-field>
        <mat-label>Estimated Quantity Min</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="quantityMin" matInput type='number'>
        </mat-form-field>
        <mat-label>Expected ETA</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="eta" matInput type='number'>
        </mat-form-field>
      </div>

      <div class="col-6 m-auto d-flex flex-column align-top">
        <mat-label>Target Unit Price max</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="priceMax" matInput step="0.2" type='number'>
        </mat-form-field>
        <mat-label>Estimated Quantity Max</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input formControlName="quantityMax" matInput placeholder="Quantity Max"
                 type="number">
        </mat-form-field>

        <mat-label>Payment Terms</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="paymentTerms">
            <mat-option [value]="'Prepaid/Cash'">Prepaid/Cash</mat-option>
            <mat-option [value]="'Credit/Line'">Credit/Line</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-label>Additional Product/Tech specs</mat-label>
      <mat-form-field appearance="outline" class="w-77 mt-2 m-auto">
        <textarea cols="30" formControlName="techSpec" matInput rows="6"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
