<div class="">
  <div class="row">
    <div class="col-12 close-button">
      <button [mat-dialog-close]="true" mat-icon-button>
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </div>

  <mat-stepper #stepper labelPosition="bottom" linear="true">
    <mat-step [editable]="true" [stepControl]="basePriceListForm">
      <ng-template matStepLabel>Base Price List</ng-template>
      <app-base-price-list
        [basePriceListForm]="basePriceListForm"
        [itemId]="itemId"
        [sapCode]="sapCode"

      >
      </app-base-price-list>
      <button (click)="saveBasePriceOnly()" mat-raised-button>Submit form</button>
    </mat-step>
  </mat-stepper>
</div>
