import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductOfferUtility} from "@shared/utility/productOfferUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";

@Component({
  selector: 'app-request-deatil-preview',
  templateUrl: './request-detail-preview.component.html',
  styleUrls: ['./request-detail-preview.component.scss']
})
export class RequestDetailPreviewComponent implements OnInit {
  form!: FormGroup;
  product!: ItemDto;
  request!: WRequestDto;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    console.log(this.request)
    this.product = this.request.item;
    this.form = this.formBuilder.group({
      description: [{value: this.request ? this.request.description : '', disabled: true}],
      partNumber: [{value: this.request ? this.request.partNumber : '', disabled: true}],
      upc: [{value: this.request ? this.request.upc : '', disabled: true}],
      techSpec: [{value: this.request ? this.request.techSpec : '', disabled: true}],
      priceMin: [{value: this.request ? this.request.priceMin : '', disabled: true}],
      priceMax: [{value: this.request ? this.request.priceMax : '', disabled: true}],
      quantityMin: [{value: this.request ? this.request.quantityMin : '', disabled: true}],
      quantityMax: [{value: this.request ? this.request.quantityMax : '', disabled: true}],
      eta: [{value: this.request ? this.request.eta : '', disabled: true}],
      paymentTerms: [{value: this.request ? this.request.paymentTerms : '', disabled: true}],
    })
  }


  eanField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product?.itemCodes, ItemCode.EAN)
  }

  upcField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product?.itemCodes, ItemCode.UPC)
  }

}
