import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {MatRadioChange} from "@angular/material/radio";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ManufactureCreateFormService} from "@features/product/features/presentation/manufacture-create/manufacture-create-form.service";

@Component({
  selector: 'app-manufacture-create',
  templateUrl: './manufacture-create.component.html',
  styleUrls: ['./manufacture-create.component.scss']
})
export class ManufactureCreateComponent implements OnInit {
  @Input()
  manufactureCreateForm!: UntypedFormGroup;
  @Input()
  manufacturers: ProductMasterManufacturer[] = [];
  @Input()
  product!: ProductsDto;
  manufactureCreateOption!: string;
  _manufacturer!: string;

  constructor(private manufactureCreateFormService: ManufactureCreateFormService) {
  }

  get manufactureCreateManufactureField() {
    return this.manufactureCreateForm.get('manufacture_create_manufacture');
  }

  get manufactureCreateOptionManufactureField() {
    return this.manufactureCreateForm.get('manufacture_create_option_manufacture');
  }

  ngOnInit(): void {
    if (!this.manufactureCreateForm) {
      this.manufactureCreateForm = this.manufactureCreateFormService.buildManufactureCreateForm();
    }
    console.log(this.product);
  }

  optionManufacture($event: MatRadioChange) {
    this.manufactureCreateOption = $event.value;
    this._manufacturer = this.product.manufacturer;
  }
}
