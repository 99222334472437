<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div class="row">
        <mat-label>
          <h3><strong>Product select image</strong></h3>
        </mat-label>
      </div>
      <div class="row">
        <mat-form-field>
          <div class="file__container">
            <input matInput readonly/>
            <label class="form-label" for="uploadFile">Upload the required file</label>
            <input
              #fileInput
              (change)="uploadFile($event)"
              accept="image/.jpg, image/png, image/gif, image/jpeg"
              class="form-control form-control-lg"
              id="uploadFile"
              name="uploadFile"
              type="file"
            />
          </div>
        </mat-form-field>
      </div>
      <div *ngIf="product && product.images && product.images.length > 0" [formGroup]="addMultipleImagesForm">
        <div class="row container-image-search" cdkDropList [cdkDropListData]="product.images" (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let image of product.images">
            <div cdkDrag style="display: flex; margin: 1rem auto; width: 90%; border: solid 1px">
              <div class="col-6" style="display: flex; justify-content: center; align-items: center">
                <img [src]="image.url" alt="item image" class="container-image-search--image">
              </div>
              <div class="col-6" style="display: flex; justify-content: center; align-items: center">
                <mat-checkbox (change)="productSelectImage($event, image)" [checked]="image.checked" color="primary"></mat-checkbox>
              </div>
            </div>
          </ng-container>
        </div>
        <mat-error *ngIf="addMultipleImagesForm.get('images')?.hasError('atLeastOneCheckboxChecked')">
          At least one image must be selected.
        </mat-error>
        <input type="hidden" formControlName="images">
      </div>
    </mat-card-content>
  </mat-card>
</div>
