<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <form [formGroup]="form">
        <mat-label>
          <h3><strong>Please select the SAP SKUs for the items you want to add to the order</strong>
          </h3>
        </mat-label>
        <div class="col-12">
          <mat-radio-group aria-label="Select an option"
                           class="radio_group_type_search_product"
                           formControlName="type_search_product">
            <mat-radio-button class="radio_where_type_search_product" color="primary" value="sku">
              SKU SAP
            </mat-radio-button>
            <mat-radio-button class="radio_where_type_search_product" color="primary"
                              value="partNumber">
              Part Number
            </mat-radio-button>
            <mat-radio-button class="radio_where_type_search_product" color="primary" value="upc">
              UPC
            </mat-radio-button>
            <mat-radio-button class="radio_where_type_search_product" color="primary" value="ean">
              EAN
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Search value</mat-label>
            <input autocomplete="on"
                   formControlName="product_search_value"
                   matInput
                   type="text"
            >
          </mat-form-field>
        </div>
        <div class="col-12">
          <button
            (click)="getProduct()"
            [disabled]="!form.valid"
            color="primary"
            mat-raised-button
          > Obtain
          </button>
          <span>
            <button (click)="purchaseOrderField.clear()"
                    color="primary"
                    mat-mini-fab
                    style="margin-left: 1.5rem">
            <mat-icon>delete</mat-icon>
            </button>
          </span>
        </div>
        <div *ngIf="products.length > 0" class="row">
          <div *ngFor="let product of products">
            <mat-card class="product_custom">
              <mat-card-header>
                <mat-card-title>{{product.itemDescription}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="row">
                  <div class="col-4">
                    <img [src]="product.image" alt="item image" height="200px" width="200px">
                  </div>
                  <div class="col-8">
                    <p *ngIf="product.sku"><strong>SKU:</strong> {{product.sku}}</p>
                    <p><strong>Manufacturer:</strong> {{product.manufacturers?.description}}</p>
                    <p><strong>Model: </strong>{{product.model ? product.model : ''}}</p>
                    <p><strong>Part Number:</strong> {{product.partNumber ? product.partNumber
                      : ''}}</p>
                    <p><strong>Condition:</strong> {{product.condition ? product.condition : ''}}
                    </p>
                    <p><strong>Category:</strong> {{product.itemCategories
                      ? product.itemCategories.description : ''}}</p>
                    <p *ngIf="eanField(product)"><strong>EAN:</strong> {{eanField(product)}}</p>
                    <p *ngIf="upcField(product)"><strong>UPC:</strong> {{upcField(product)}}</p>
                    <ul>
                      <li>
                        <strong>Tech specs:</strong>
                        <div *ngFor="let spec of product.itemSpecifications">
                          <ul>
                            <div>
                              <li><strong>{{spec.specifications}}</strong>
                                : {{spec.descriptionItemSpecific}}</li>
                            </div>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <div class="text-center">
              <button (click)="getProductSelect(product)" color="primary" mat-raised-button>
                Select
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <h2>Added SKUs:</h2>
          <ng-container *ngIf="purchaseOrderField.length > 0">
            <div *ngFor="let order of purchaseOrderField.controls; let i=index" class="col-12">
              <p>
                <span>{{'SKU ' + order.get('item_sku')?.value + '  -  ' + 'Item description '
                + order.get('item_description')?.value}}</span>
              </p>
            </div>
          </ng-container>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

</div>
