import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  ProductOfferForRequestDto
} from "@features/product-offer/model/offer-validation/productOfferForRequest.dto";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {
  ProductPreviewComponent
} from "@features/product/features/presentation/product-preview/product-preview.component";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {MatDialog} from "@angular/material/dialog";
import {lastValueFrom} from "rxjs";
import {ProductService} from "@features/product/services/product.service";
import {ClipboardService} from "ngx-clipboard";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Category} from "@core/model/category";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {UserDto} from "@shared/model/dto/user.dto";
import {ItemUtility} from "@features/product/model/utility/itemUtility";
import {UserService} from "@shared/services/user.service";
import {
  FindAllOfferParamsForVendorsDto
} from "@features/product-offer/model/offer-validation/findAllOfferParamsForVendors.dto";

import * as moment from "moment";
import {MatSort, Sort} from "@angular/material/sort";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {RequestUtil} from "@features/request/utility/requestUtil";
import {
  FeedbackDealOffersComponent
} from "@features/product-offer/presentation/feedback-deal-offers/feedback-deal-offers.component";
import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {ShowEtaComponent} from "@features/product-offer/presentation/show-eta/show-eta.component";
import {NdoTransactionalLogDto} from "@features/request/model/ndo-transactional-log.dto";
import {RequestedService} from "@features/request/services/requestedService";
import {AuthService} from "@core/services";
import {showErrorPopup} from "@shared/utility/popup-message";
import {PageableResultDto} from "@shared/utility/PageableResult.dto";
import {ComparatorsFieldDto} from "@features/product-offer/model/offer-validation/ComparatorsField.dto";
import {ScrollUtil} from "@shared/utility/ScrollUtil";
import {CanvaAndPriceComponent} from "@features/product-offer/presentation/canva-and-price/canva-and-price.component";
import {ItemMiscTypesDto} from "@features/product/model/dto/item/ItemMiscTypes.dto";
import {
  DashboardItemPurchasesComponent
} from "@features/product-offer/presentation/dashboard-item-purchases/dashboard-item-purchases.component";
import {NgxPermissionsService} from "ngx-permissions";
import {ADMIN_USER, ROLES} from "@shared/config/constants/menu-roles.const";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-list-offer-for-request',
  templateUrl: './list-offer-for-request.component.html',
  styleUrls: ['./list-offer-for-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListOfferForRequestComponent implements OnInit {
  form!: FormGroup;
  data: ProductOfferForRequestDto[] = [];
  displayedColumns: string[] = [
    'isHotOffer', 'initial_date', 'end_date', 'sub_category', 'manufacturer',
    'sku', 'item_description', 'buyer', 'available_qty', 'source', 'aging', 'itemPurchases', 'moq', 'price_and_canva',
    'exp_margin', 'view', 'link', 'feedback'
  ];
  resultsLength = 0;
  categories: Category[] = [];
  subcategories: Category[] = [];
  manufacturers: ProductMasterManufacturer[] = [];
  regionRestrictions: ItemMiscTypesDto[] = [];
  conditions: string[] = ['New', 'Used', 'Open Box', 'Brown Box'
    , 'Factory Refurbished', '3rd Party Refurbished', 'Damaged Box'];

  buyers: UserDto[] = [];

  requestUtil = RequestUtil;

  filterBy = new FormControl(null);
  filterOpen = true;

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;


  constructor(private productOfferService: ProductOfferService,
              public dialog: MatDialog,
              private productService: ProductService,
              private _clipboardService: ClipboardService,
              private _snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private userService: UserService,
              private authService: AuthService,
              private requestService: RequestedService,
              private ngxPermissionService: NgxPermissionsService,
              private route: ActivatedRoute,) {

    this.form = this.formBuilder.group({
      hotOffer: [null],
      itemCreationDateStart: [null],
      itemCreationDateEnd: [null],
      publishedDateStart: [null],
      publishedDateEnd: [null],
      validaOfferPeriodStart: [null],
      validaOfferPeriodEnd: [null],
      category: [null],
      condition: [null],
      subcategory: [null],
      manufacturer: [null],
      buyer: [null],
      source: [null],
      sku: [null],
      upc: [null],
      partNumber: [null],
      itemDescription: [null],
      aging: this.formBuilder.array([]),
      basePrice: this.formBuilder.array([]),
      margin: this.formBuilder.array([]),
      moq: this.formBuilder.array([]),
      regionRestrictions: [null],
      specs: [null]

    })
  }

  private buildAgingForm() {
    this.agingArray.push(this.comparatorFields);
  }

  private buildBasePriceForm() {
    this.basePriceArray.push(this.comparatorFields);
  }

  private buildMarginForm() {
    this.marginArray.push(this.comparatorFields);
  }

  private buildMoqForm() {
    this.moqArray.push(this.comparatorFields);
  }

  get comparatorFields() {
    return this.formBuilder.group({
      comparator: [null],
      value: [null],
    })
  }

  get agingArray() {
    return this.form.get('aging') as FormArray;
  }

  get basePriceArray() {
    return this.form.get('basePrice') as FormArray;
  }

  get marginArray() {
    return this.form.get('margin') as FormArray;
  }

  get moqArray() {
    return this.form.get('moq') as FormArray;
  }

  ngOnInit(): void {
    this.initCall();
    this.getCategories();
    this.getSubCategories();
    this.getManufacturers();
    this.getBuyers();
    this.buildAgingForm();
    this.buildBasePriceForm();
    this.buildMarginForm();
    this.buildMoqForm();
    this.getMiscTypes();
    this.ngxPermissionService.hasPermission([ROLES.ACCOUNT_MANAGER, ADMIN_USER]).then(result => {
      if (!result) {
        this.displayedColumns = this.displayedColumns.filter(items => items != 'itemPurchases');
      }
    })

  }

  private  initCall(){
    this.route.params.subscribe(urlParams => {
      let sku = urlParams['sku'];
      if (sku) {
         this.form.get('sku')?.setValue(sku);
      }
      let params = this.buildParams();
      params.searchCriteria = this.getInitParams();
      this.callData(params);
    });

  }


  callData(params: any) {
    this.productOfferService.findAllOffersForRequest(params).subscribe({
      next: value => {
        if (value) {
          this.manageResponse(value);
        }
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

  getInitParams() {
    return [
        {
          operation: `PAGE_ORDER_DESC`,
          key: 'id',
          value: '',
        },
        {
          operation: "PAGE_SIZE",
          key: "",
          value: 50
        },
        {
          operation: "PAGE_INDEX",
          key: "",
          value: 0
        }
    ]

  }

  private manageResponse(value: PageableResultDto) {
    if (value) {
      this.resultsLength = value.totalElements;
      this.data = value.content;
      this.paginator.pageIndex = value.number;
      this.paginator.pageSize = value.size;
    }
  }

  openPreview(row: ProductOfferForRequestDto) {
    let product: ProductsDto = ProductUtility.convertTo(row.item);
    const dialogRef = this.dialog.open(ProductPreviewComponent, {
      width: '80%',
      height: '90%',
    });
    let instance = dialogRef.componentInstance;
    instance.product = product;

    instance.isDialog = true;
    this.getCategoryTree(product.selectSudCategories)
      .then(result => {
        if (result.body) {
          instance.descriptionCategories = result.body[0].category_lineage;
        }
      });
  }


  copyLink(row: ProductOfferForRequestDto) {
    this._clipboardService.copy('https://www.distritech.com/products/' + row.item.sku);
    this._snackBar.open('We have copied the URL to the clipboard, you can now paste it elsewhere!', '', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5 * 1000,
    });

    this.authService._getFromPayload("id_user").then(userId => {
      let transactionalLog = new NdoTransactionalLogDto();
      transactionalLog.transactionDetail = 'Offer Link Copied by Sales';
      transactionalLog.transactionType = 'Update';
      transactionalLog.userId = userId || 'UNKNOWN';
      transactionalLog.transactionEntity = 'ndo_offer';
      transactionalLog.idPk = row.id ? row.id : null;
      this.requestService.saveTransactionalLog(transactionalLog).subscribe();
    });

  }

  downloadTemplate(row: ProductOfferForRequestDto) {
    let instance = this.dialog.open(CanvaAndPriceComponent, {
      width: '100%',
      height: '95%',
    });
    instance.componentInstance.item = row.item;
    instance.componentInstance.offerId = row.id;
  }

  async getCategoryTree(category: string): Promise<any> {
    let response = this.productService.getCategoryTree({'itemCategory': category});
    return lastValueFrom(response);
  }

  pagination($event: PageEvent) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${this.sort?.direction ? this.sort.direction.toUpperCase() : 'DESC'}`,
        key: 'id',
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: $event.pageSize
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: $event.pageIndex
      }
    ]
    this.callData(params);
    ScrollUtil.scrollToTop();
  }

  filter() {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_DESC`,
        key: 'id',
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: 50
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: 0
      }
    ]
    this.callData(params);
    ScrollUtil.scrollToTop();

  }


  getCategories() {
    this.productService.getCategories().subscribe({
      next: value => {
        ItemUtility.setCategory(value.body, this.categories);
      },
      error: () => {
        this.categories = [];
      },
    })
  }

  getSubCategories() {
    this.productService.getAllSubCategories().subscribe({
      next: value => {
        value.body.forEach((cat: any) => {
          let category = new Category();
          category.key = cat.itmctg_code;
          category.values = cat.categorychild;
          this.subcategories.push(category);
        });
      },
      error: () => {
        this.subcategories = [];
      },
    })
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: () => {
        this.manufacturers = [];
      },
    })
  }

  getBuyers() {
    this.userService.findAllByRoles(['PURCHASING']).subscribe({
      next: value => {
        this.buyers = value;
      },
      error: () => {
        this.buyers = [];
      },
    })
  }

  reset() {
    this.form.reset();
    let params = this.buildParams();
    params.searchCriteria = this.getInitParams();
    this.callData(params);
  }

  sortChange($event: Sort) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${$event.direction.toUpperCase()}`,
        key: `${$event.active}`,
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: 50
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: 0
      }
    ]


    this.callData(params);
  }

  getBuyerName(userCustomized: UserCustomizedDto[]) {
    return userCustomized && userCustomized.length > 0 ? UserCustomizedUtil.findBuyerSapCode(userCustomized)?.userCustomizedValue : '';
  }

  feedbackDealOffers(request: ProductOfferForRequestDto) {
    const dialogRef = this.dialog.open(FeedbackDealOffersComponent, {
      width: '70%',
      height: '90%',
    })
    const instance = dialogRef.componentInstance;
    instance.request = request;
    instance.isDialog = true;
  }

  showEta($event: MouseEvent, itemCustomizedList: ItemCustomizedDto[]) {
    let itemCustomized: ItemCustomizedDto = itemCustomizedList.filter(value => value.itemCustomizedCode == 'sap_itemcode')[0];
    const dialogRef = this.dialog.open(ShowEtaComponent, {
      width: '60%',
      height: '90%',
    })
    const instance = dialogRef.componentInstance;
    instance.sku = itemCustomized.itemCustomizedValue;
    instance.isDialog = true;
  }

  skuSap(itemCustomized: ItemCustomizedDto[]): any {
    const filteredData = itemCustomized.filter((item) => item.itemCustomizedCode == 'sap_itemcode')
    if (filteredData.length > 0) {
      return this.subSku(filteredData[0].itemCustomizedValue);
    }
    return "";
  }

  private subSku(sku: string) {
    return sku.length > 22 ? sku.substring(0, 22).concat('...') : sku;
  }

  private buildPublishedDate(params: FindAllOfferParamsForVendorsDto) {
    if (this.form.get('publishedDateStart')?.value && this.form.get('publishedDateEnd')?.value) {
      params.creationDateInit = moment(this.form.get('publishedDateStart')?.value)
        .format('YYYY-MM-DD').concat('T').concat('00:00:00');
      params.creationDateEnd = moment(this.form.get('publishedDateEnd')?.value)
        .format('YYYY-MM-DD').concat('T').concat('23:59:59');
    }
  }

  private buildCreationDate(params: FindAllOfferParamsForVendorsDto) {
    if (this.form.get('itemCreationDateStart')?.value && this.form.get('itemCreationDateEnd')?.value) {
      params.itemCreationDateInit = moment(this.form.get('itemCreationDateStart')?.value)
        .format('YYYY-MM-DD').concat('T').concat('00:00:00');
      params.itemCreationDateEnd = moment(this.form.get('itemCreationDateEnd')?.value)
        .format('YYYY-MM-DD').concat('T').concat('23:59:59');
    }
  }

  private buildOfferPeriodDate(params: FindAllOfferParamsForVendorsDto) {
    if (this.form.get('validaOfferPeriodStart')?.value && this.form.get('validaOfferPeriodEnd')?.value) {
      params.offerPeriodDateInit = moment(this.form.get('validaOfferPeriodStart')?.value)
        .format('YYYY-MM-DD').concat('T').concat('00:00:00');
      params.offerPeriodDateEnd = moment(this.form.get('validaOfferPeriodEnd')?.value)
        .format('YYYY-MM-DD').concat('T').concat('23:59:59');
    }
  }

  private buildAgingParam(params: FindAllOfferParamsForVendorsDto) {
    this.agingArray.controls.forEach(control => {
      if (control.get('comparator')?.value && control.get('value')?.value) {
        let item = new ComparatorsFieldDto();
        item.type = 'aging';
        item.comparator = control.get('comparator')?.value;
        item.value = control.get('value')?.value;
        params.comparators.push(item);
      }
    })
  }

  private buildBasePriceParam(params: FindAllOfferParamsForVendorsDto) {
    this.basePriceArray.controls.forEach(control => {
      if (control.get('comparator')?.value && control.get('value')?.value) {
        let item = new ComparatorsFieldDto();
        item.type = 'basePrice';
        item.comparator = control.get('comparator')?.value;
        item.value = control.get('value')?.value;
        params.comparators.push(item);
      }
    })
  }

  private buildMoqParam(params: FindAllOfferParamsForVendorsDto) {
    this.moqArray.controls.forEach(control => {
      if (control.get('comparator')?.value && control.get('value')?.value) {
        let item = new ComparatorsFieldDto();
        item.type = 'moq';
        item.comparator = control.get('comparator')?.value;
        item.value = control.get('value')?.value;
        params.comparators.push(item);
      }
    })
  }

  private buildMarginParam(params: FindAllOfferParamsForVendorsDto) {
    this.marginArray.controls.forEach(control => {
      if (control.get('comparator')?.value && control.get('value')?.value) {
        let item = new ComparatorsFieldDto();
        item.type = 'margin';
        item.comparator = control.get('comparator')?.value;
        item.value = control.get('value')?.value;
        params.comparators.push(item);
      }
    })
  }


  private buildParams() {
    let params = new FindAllOfferParamsForVendorsDto();
    this.buildPublishedDate(params);
    this.buildCreationDate(params);
    this.buildOfferPeriodDate(params);

    if (this.form.get('category')?.value) {
      params.category = this.form.get('category')?.value;
    }
    if (this.form.get('condition')?.value) {
      params.condition = this.form.get('condition')?.value;
    }
    if (this.form.get('subcategory')?.value) {
      params.subcategory = this.form.get('subcategory')?.value;
    }
    if (this.form.get('manufacturer')?.value) {
      params.manufacturer = this.form.get('manufacturer')?.value;
    }
    if (this.form.get('buyer')?.value) {
      params.buyer = this.form.get('buyer')?.value;
    }
    if (this.form.get('sku')?.value) {
      params.sku = this.form.get('sku')?.value;
    }
    if (this.form.get('upc')?.value) {
      params.upc = this.form.get('upc')?.value;
    }
    if (this.form.get('partNumber')?.value) {
      params.partNumber = this.form.get('partNumber')?.value;
    }
    if (this.form.get('itemDescription')?.value) {
      params.itemDescription = this.form.get('itemDescription')?.value;
    }
    if (this.form.get('hotOffer')?.value) {
      params.isHotDeal = this.form.get('hotOffer')?.value;
    }
    if (this.form.get('source')?.value) {
      params.source = this.form.get('source')?.value;
    }
    if (this.form.get('specs')?.value) {
      params.specs = this.form.get('specs')?.value;
    }
    if (this.form.get('regionRestrictions')?.value) {
      params.regionRestrictions = this.form.get('regionRestrictions')?.value;
    }
    this.buildAgingParam(params);
    this.buildBasePriceParam(params);
    this.buildMoqParam(params);
    this.buildMarginParam(params);
    return params;
  }


  calculateMargin(row: ProductOfferForRequestDto) {
    return this.requestUtil.getMargin(row.item.itemSapData.price, row.item.itemSapData.cost);
  }


  toggle(filters: HTMLFormElement) {
    this.filterOpen = !this.filterOpen;
    if (!this.filterOpen) {
      filters.style.display = 'none'
    } else {
      filters.style.display = 'flex'

    }
  }


  getSource(row: ProductOfferForRequestDto) {
    if (row.item?.itemSapData?.source) {
      let split = row.item.itemSapData.source.split(',');
      return split.length > 1 ? split.join('<br>').toString() : split.join().toString();
    }
    return '';
  }

  getMiscTypes() {
    this.productService.getMiscTypes("regionrestrictions")
      .subscribe(result => (this.regionRestrictions = result));

  }

  itemPurchases(row: ProductOfferForRequestDto) {
    const dialogRef = this.dialog.open(DashboardItemPurchasesComponent, {
      width: '100%',
      height: '95%',
    })
    const instance = dialogRef.componentInstance;
    instance.itemDescription = row.item.itemDescription;
    instance.sku = this.skuSap(row.item.itemCustomized);
  }

  canShowItemPurchases(row: ProductOfferForRequestDto) {
    const regex = /[#\+]/;
    let sku = this.skuSap(row.item.itemCustomized);
    return sku ? regex.test(sku) : true;
  }


  protected readonly String = String;
}
