import { Component } from '@angular/core';
import {ADMIN_USER, DASHBOARD_AND_ANALYTICS, PRODUCT_AND_OFFERS} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-products-and-offers-menu',
  templateUrl: './da-products-and-offers-menu.component.html',
  styleUrls: ['./da-products-and-offers-menu.component.scss']
})
export class DaProductsAndOffersMenuComponent {

  showSubMenu = false;

  protected readonly PRODUCT_AND_OFFERS = PRODUCT_AND_OFFERS;
  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;

  showHide(ItemSubMenu: HTMLUListElement) {
    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      ItemSubMenu.style.display = 'block'
    }else {
      ItemSubMenu.style.display = 'none'
    }

  }

  protected readonly ADMIN_USER = ADMIN_USER;
}
