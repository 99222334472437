import {Component} from '@angular/core';
import {DateUtil} from "@shared/utility/dateUtil";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  getYear() {
    return DateUtil.getCurrentDate().year();
  }
}
