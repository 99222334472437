<div class="container-offer-purchase-main">

  <mat-stepper #stepper labelPosition="bottom" linear="true">
    <mat-step [editable]="true" [stepControl]="poCreationForm">
      <ng-template matStepLabel>PO creation</ng-template>
      <app-po-creation
        [pocreationForm]="poCreationForm"
      ></app-po-creation>
      <div class="stepper_button_container">
        <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <ng-container *ngIf="createInitialPoField?.value">
      <mat-step *ngIf="purchaseOrderField.length > 0" [editable]="true"
                [stepControl]="poSelectionForm">
        <ng-template matStepLabel>Select more products</ng-template>
        <app-po-for-product-selected
          [poselectionForm]="poSelectionForm"
        >
        </app-po-for-product-selected>
        <div class="stepper_button_container">
          <button mat-raised-button matStepperPrevious>Previous</button>
          <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
        </div>
      </mat-step>

      <mat-step *ngIf="!selection_products?.value || purchaseOrderField.length <= 0"
                [editable]="true">
        <ng-template matStepLabel>Add items to order</ng-template>
        <app-add-items-to-order
          [form]="addItemToOrderForm"
          [purchaseOrderForm]="purchaseOrderForm">
        </app-add-items-to-order>
        <div class="stepper_button_container">
          <button class="button" mat-raised-button matStepperPrevious>Previous</button>
          <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
        </div>
      </mat-step>
      <ng-container *ngIf="purchaseOrderField.length > 0">
        <mat-step [editable]="true" [stepControl]="searchVendorForm">
          <ng-template matStepLabel>Search for a Vendor</ng-template>
          <app-search-for-vendor
            [searchVendorForm]="searchVendorForm"
          ></app-search-for-vendor>
          <div class="stepper_button_container">
            <button class="button" mat-raised-button matStepperPrevious>Previous</button>
            <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
          </div>
        </mat-step>
        <mat-step [editable]="true" [stepControl]="selectWarehouseForm">
          <ng-template matStepLabel>Select the store</ng-template>
          <app-select-the-store [form]="selectWarehouseForm"></app-select-the-store>
          <div class="stepper_button_container">
            <button mat-raised-button matStepperPrevious>Previous</button>
            <button class="button" color="primary" mat-raised-button matStepperNext>Next</button>
          </div>
        </mat-step>

        <mat-step *ngIf="purchaseOrderField.length > 0" [editable]="true"
                  [stepControl]="purchaseOrderForm">
          <ng-template matStepLabel>Enter costs and quantity</ng-template>
          <app-enter-cost-and-quantity [purchaseOrderForm]="purchaseOrderForm">
          </app-enter-cost-and-quantity>
          <div class="stepper_button_container">
            <button mat-raised-button matStepperPrevious>Previous</button>
            <button (click)="createPo()" class="button" color="primary" mat-raised-button>Create PO
            </button>
          </div>
        </mat-step>
      </ng-container>


    </ng-container>

  </mat-stepper>
</div>
<app-loader></app-loader>

