import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {UserDto} from "@shared/model/dto/user.dto";

export class ROffersForRequestDto {
  id!: number;
  item!: ItemDto;
  initialHour!: number;
  endHour!: number;
  limitPeriod!: boolean;
  initialDate!: string;
  endDate!: string;
  hasUnsoldLimit!: boolean;
  orderMin !: number;
  tierOne!: number;
  tierTwo!: number;
  tierThree!: number;
  tierFour!: number;
  lastUpdate!: string;
  creationDate!: string;
  limitedTimeOfferState!: string;
  isHotOffer!: boolean;
  creationUser!: UserDto;

}
