import { Component } from '@angular/core';
import {
  CUSTOMERS,
  DASHBOARD_AND_ANALYTICS,
  ORDERS,
  PRODUCT_AND_OFFERS
} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-customers-menu',
  templateUrl: './da-customers-menu.component.html',
  styleUrls: ['./da-customers-menu.component.scss']
})
export class DaCustomersMenuComponent {

  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;
  protected readonly CUSTOMERS = CUSTOMERS;

  showSubMenu = false;

  showHide(ItemSubMenu: HTMLUListElement) {
    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      ItemSubMenu.style.display = 'block'
    }else {
      ItemSubMenu.style.display = 'none'
    }

  }

  protected readonly ORDERS = ORDERS;
  protected readonly PRODUCT_AND_OFFERS = PRODUCT_AND_OFFERS;
}
