import { NgModule } from '@angular/core';
import { DashboardAnalyticsComponent } from './features/presentation/dashboard-analytics/dashboard-analytics.component';
import {EcommerceRoutingModule} from "@features/eCommerce/ecommerce-routing.module";
import { TestFiltersComponent } from './features/presentation/test-filters/test-filters.component';
import {SharedModule} from "@shared/shared.module";



@NgModule({
  declarations: [
    DashboardAnalyticsComponent,
    TestFiltersComponent
  ],
    imports: [
        EcommerceRoutingModule,
        SharedModule
    ]
})
export class EcommerceModule { }
