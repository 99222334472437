<div class="product-offer-main-container">
  <mat-stepper #stepper *ngIf="!showProductCreation" labelPosition="bottom" linear="true">
    <mat-step [editable]="true" [stepControl]="chooseProcessForm">
      <ng-template matStepLabel>New Deal Offer</ng-template>
      <app-choose-process [form]="chooseProcessForm"></app-choose-process>
      <button mat-raised-button matStepperNext>Next Page</button>
    </mat-step>
    <ng-container [ngSwitch]="processType?.value">
      <ng-template ngSwitchCase="0">
        <mat-step [editable]="true" [stepControl]="chooseProcessForm">
          <ng-template matStepLabel>Search Item</ng-template>
          <app-new-offer (callProductStateValidation)="verifyItemSapState($event)"
                         (productFound)="setProduct($event)"
                         [form]="newOfferForm"
                         [manufacturers]="manufacturers"></app-new-offer>
          <button mat-raised-button matStepperPrevious>Previous</button>
        </mat-step>
      </ng-template>
    </ng-container>
  </mat-stepper>
  <ng-container *ngIf="product && showProductCreation">
    <app-product-main (callNewOfferOption)="showOptions($event)"
                      (callProductStateValidation)="verifyItemSapState($event)"
                      [isManufacture]="isManufacture"
                      [manufacture]="manufacture"
                      [productProcessType]="productProcessType"
                      [newOfferForm]="newOfferForm"
                      [product]="product"></app-product-main>
  </ng-container>
</div>
<app-loader></app-loader>
