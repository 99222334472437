import {Component, OnInit} from '@angular/core';
import {LoadingService} from "@shared/services/loading.service";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit{

  loading$ : Observable<boolean> = of(false);

  constructor(public loader: LoadingService) {

  }

  ngOnInit(): void {
    this.loading$ = this.loader.loading$;
  }

}
