<div class="menu">
  <div class="menu__item"
       *ngxPermissionsOnly="MARKETPLACE.MARKETPLACE"
       (click)="showHide(subMenu)">
    <img [ngSrc]="'assets/icon/menu-marketplace.png'" alt="admin" class="menu__item--icon1" height="26" width="25">
    <span  class="menu__item--text">Marketplace</span>
    <img [ngSrc]="'assets/icon/menu-arrow1.png'" alt="admin" class="menu__item--icon2" height="23" width="21">
  </div>
  <ul class="menu__submenu" #subMenu>
    <li class="menu__submenu--item__list"
        *ngxPermissionsOnly="MARKETPLACE.PRODUCT_MANAGER.PRODUCT_MANAGER">
      <span>Product Manager</span>
    </li>
    <li class="menu__submenu--item__list"
        *ngxPermissionsOnly="MARKETPLACE.PRODUCT_MANAGER.PRODUCT_MANAGER">
      <span>Vendor Stats</span>
    </li>
  </ul>
</div>
