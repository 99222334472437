import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  BusinessPartnerPriceListDto
} from "@features/product-offer/model/offer-purchase-order/BusinessPartnerPriceList.dto";
import {RequestUtil} from "@features/request/utility/requestUtil";

@Component({
  selector: 'app-prices-and-cost',
  templateUrl: './prices-and-cost.component.html',
  styleUrls: ['./prices-and-cost.component.scss']
})
export class PricesAndCostComponent {
  @Input()
  businessPartnerPriceList!: BusinessPartnerPriceListDto | null;
  @Input()
  cost!: number | undefined;
  @Input()
  price!: number | undefined;
  @Input()
  title: string = "";
  @Input()
  sku!: string;

  @Output() emitter = new EventEmitter();

  downloadCanva() {
    this.emitter.emit(this.price);
  }

  get calculateMargin() {
    return this.price && this.cost ?
      RequestUtil.getMargin(this.price,this.cost)
      : 0.00;
  }
}
