<h2 class="title-my-customers">MY CUSTOMERS</h2>
<mat-form-field appearance="outline" style="width: 100%" class="col-12">
  <mat-select ngxLazyMatSelect
              placeholder="Select"
              formControlName="cardCode"
              (infiniteScroll)="getNextBatch()"
              [complete]="offset === total">

    <mat-option *ngFor="let option of options$ | async" [value]="option.cardCode">
      <p> {{option?.cardCode}} - {{option?.cardName}} - {{option?.price | currency}} - {{calculateMargin(option).toFixed(2)}}&percnt;
        <button class="btn download-canva-button" (click)="downloadCanva(option.price)">
          Download Canva
        </button>
      </p>

    </mat-option>
  </mat-select>
</mat-form-field>

<app-loader></app-loader>
