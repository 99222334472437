<div class="container">
  <div class="col-12 close-button">
    <button [mat-dialog-close]="true" mat-icon-button>
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
  </div>
  <mat-card class="product-upload-template-card">
    <mat-card-content>
      <div class="row product-upload-template-card__row">
        <div *ngIf="product && product.images"
             class="col-12 product-upload-template-card__row--title">
          <h1>Upload Images</h1>
          <app-add-multiple-images
            [addMultipleImagesForm]="productSelectImageForm"
            [product]="product"
          >
          </app-add-multiple-images>

        </div>
        <div class="col-12 mt-2 save-images-container">
          <button (click)="uploadImages()" color="primary" mat-raised-button>
            Upload images
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
