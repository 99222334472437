import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {environment} from "@env";
import {catchError} from "rxjs/operators";
import {WarehouseDto} from "@features/product-offer/model/offer-purchase-order/warehouse.dto";
import {
  SavePurchaseOrderDto
} from "@features/product-offer/model/offer-purchase-order/save-purchase-order.dto";


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private getAllActiveWarehouseUrl = `${this.automatePurchasesUrl}purchase-order/get/all/active/warehouses`;
  private savePurchaseOrderUrl = `${this.automatePurchasesUrl}purchase-order/save`;

  constructor(private httpClient: HttpClient) {
  }


  getAllWarehouse(): Observable<WarehouseDto[]> {
    return this.httpClient.get<any>(this.getAllActiveWarehouseUrl)
    .pipe(
      map((result: any) => {
        let items: WarehouseDto[] = [];
        console.log(result)
        if (result && result.length > 0) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  save(params: SavePurchaseOrderDto): Observable<SavePurchaseOrderDto> {
    return this.httpClient.post<SavePurchaseOrderDto>(this.savePurchaseOrderUrl, params);
  }
}
