<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="poselectionForm">
        <div class="row">
          <div class="po-for-product-selected-title">
            <h1><strong>Is this PO only for the products selected? </strong></h1>
          </div>
          <mat-radio-group class="col-12 group-po-for-product-selected"
                           formControlName="selection_products">
            <mat-radio-button [value]="true" class="group-po-for-product-selected__radio-button"
                              color="primary">Yes
            </mat-radio-button>
            <mat-radio-button [value]="false" class="group-po-for-product-selected__radio-button"
                              color="primary">No, i want to add more items
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

