<div class="list-offer-container">
  <mat-card>
    <mat-card-title class="text-center">
      <h2><strong>List Product Deal Offers</strong></h2>
    </mat-card-title>
    <mat-card-content>
      <div class="row">
        <form [formGroup]="form">
          <div class="col-12">
            <mat-radio-group aria-label="Select an option"
                             class="radio_group_type_search_product"
                             formControlName="type_search_product">
              <mat-radio-button class="radio_where_type_search_product" color="primary" value="sku">
                SKU
              </mat-radio-button>
              <mat-radio-button class="radio_where_type_search_product" color="primary" value="upc">
                UPC
              </mat-radio-button>
              <mat-radio-button class="radio_where_type_search_product" color="primary" value="ean">
                EAN
              </mat-radio-button>
              <mat-radio-button class="radio_where_type_search_product" color="primary"
                                value="itemDescription">
                ITEM DESCRIPTION
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-8">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Search value</mat-label>
              <input autocomplete="on"
                     formControlName="product_search_value"
                     matInput
                     type="text"
              >
            </mat-form-field>
          </div>
          <div class="col-12">
            <button
              (click)="getProduct()"
              [disabled]="!form.valid"
              color="primary"
              mat-raised-button
            > Search
            </button>
          </div>
        </form>
      </div>
      <div class="row">
        <mat-table [dataSource]="data" class="col-12 list-offer-table">
          <caption>Product deal offer List</caption>

          <!-- Id item Column -->
          <ng-container matColumnDef="sku">
            <th *matHeaderCellDef mat-header-cell>SKU</th>
            <td *matCellDef="let row" mat-cell>{{row.item ? row.item.sku : ''}}</td>
          </ng-container>
          <!-- item period_limit -->
          <ng-container matColumnDef="item_description">
            <th *matHeaderCellDef mat-header-cell>Item description</th>
            <td *matCellDef="let row" mat-cell>
              <pre class="list-offer-itemDescription">{{row.item ? row.item.itemDescription
                : ''}}</pre>
            </td>
          </ng-container>
          <!-- item initial_date -->
          <ng-container matColumnDef="initial_date">
            <th *matHeaderCellDef mat-header-cell>Initial Date</th>
            <td *matCellDef="let row" mat-cell>{{formatDate(
              row.initialDate ? row.initialDate : '')}}</td>
          </ng-container>
          <!-- item end_date -->
          <ng-container matColumnDef="end_date">
            <th *matHeaderCellDef mat-header-cell>End Date</th>
            <td *matCellDef="let row" mat-cell>{{formatDate(row.endDate ? row.endDate : '')}}</td>
          </ng-container>
          <!-- item has_unsold_limit -->
          <ng-container matColumnDef="barcode">
            <th *matHeaderCellDef mat-header-cell>Barcode (UPC/ EAN)</th>
            <td *matCellDef="let row" mat-cell>{{this.getBarcode(
              row.item ? row.item.itemCodes : null)}}</td>
          </ng-container>
          <!-- item order_min -->
          <ng-container matColumnDef="order_min">
            <th *matHeaderCellDef mat-header-cell>Order Min</th>
            <td *matCellDef="let row" mat-cell>{{row.orderMin ? row.orderMin : ''}}</td>
          </ng-container>
          <!-- item tier_four -->
          <ng-container matColumnDef="tier_four">
            <th *matHeaderCellDef mat-header-cell>Tier Four</th>
            <td *matCellDef="let row" mat-cell>{{row.tierFour ? row.tierFour : ''}}</td>
          </ng-container>
          <!-- item status -->
          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>Offer validity(status)</th>
            <td *matCellDef="let row" mat-cell>{{row.limitedTimeOfferState ? getState(
              row.limitedTimeOfferState) : ''}}</td>
          </ng-container>

          <ng-container matColumnDef="edit_offer">
            <th *matHeaderCellDef mat-header-cell>Edit Offer</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="openUpdateOffer(row)"
                      mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="edit_base_prices">
            <th *matHeaderCellDef mat-header-cell>Edit Base Prices</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="openEditBasePrice(row)"
                      mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </mat-table>
      </div>
      <div class="row">
        <mat-paginator (page)="pagination($event)"
                       [hidePageSize]="this.paginator.pageIndex != 0"
                       [length]="resultsLength"
                       [pageSizeOptions]="[5, 10, 25, 50]"
                       [pageSize]="25"
                       aria-label="Select page of Deals Information search results">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
