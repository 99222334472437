import { Component } from '@angular/core';
import {
  ADMIN_USER,
  DASHBOARD_AND_ANALYTICS,
  PRODUCT_AND_OFFERS,
  REQUESTS
} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-requests-menu',
  templateUrl: './da-requests-menu.component.html',
  styleUrls: ['./da-requests-menu.component.scss']
})
export class DaRequestsMenuComponent {

  showSubMenu = false;

  protected readonly REQUESTS = REQUESTS;

  showHide(ItemSubMenu: HTMLUListElement[]) {
    console.log(this.showSubMenu);

    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      ItemSubMenu.forEach(item => item.style.display = 'block')

    }else {
      ItemSubMenu.forEach(item => item.style.display = 'none')

    }

  }

  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;
    protected readonly PRODUCT_AND_OFFERS = PRODUCT_AND_OFFERS;
  protected readonly ADMIN_USER = ADMIN_USER;

  showHide2(subMenu: HTMLLIElement) {
    console.log(this.showSubMenu);
    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      subMenu.style.display = 'block'
    }else {
      subMenu.style.display = 'none'
    }
  }
}
