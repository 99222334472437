<div class="container">
  <mat-card class="product-offer-choose-process">
    <mat-card-content>
      <form [formGroup]="form">
        <div class="row product-offer-choose-process__row">
          <div class="col-12 product-offer-choose-process__row--title">
            <h1>New Deal Offer</h1>
          </div>
          <div class="col-12 product-offer-choose-process__row--text">
            <h3>Is this product already created in SAP?</h3>
          </div>
          <div class="col-12 product-offer-choose-process__row--options">
            <mat-radio-group (ngModelChange)="optionSelected($event)" formControlName="process">
              <mat-radio-button class="mb-3" color="primary" value="0">Yes</mat-radio-button>
              <mat-radio-button class="mx-3" color="primary" value="1">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
