import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup,} from "@angular/forms";
import {Router} from "@angular/router";
import {CONTACT_SUPPORT, TITLE_INFO} from "@shared";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductService} from "@features/product/services/product.service";
import {mergeMap, of} from "rxjs";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {ITEM_CONST} from "@shared/utility/popup-message.const";
import {ProductOfferUtility} from "@shared/utility/productOfferUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  products: ItemDto[] = [];

  selectRadio!: boolean;

  @Output()
  callProductStateValidation = new EventEmitter();
  @Output()
  productFound = new EventEmitter();
  @Input()
  manufacturers: ProductMasterManufacturer[] = [];
  form = new FormGroup({
    type_search_product: new FormControl(""),
    product_search_value: new FormControl(''),
  })


  constructor(private productService: ProductService,
              private router: Router,
  ) {
  }

  get productSearchValue() {
    return this.form.get('product_search_value');
  }

  get typeSearchProduct() {
    return this.form.get('type_search_product');
  }

  ngOnInit(): void {
    this.selectRadio = false;
  }

  searchData() {
    const params = {
      "searchCode": this.typeSearchProduct?.value,
      'searchValue': this.productSearchValue?.value,
      itemCodeType: null,
      itemCode: null,
      itemDescription: null,
      sku: null,
      partNumber: null
    }
    const searchValue: any = this.form.value.product_search_value;

    switch (this.form.value.type_search_product) {
      case "ean":
      case "upc":
        // @ts-ignore
        params.itemCodeType = this.form.value.type_search_product
        params.itemCode = searchValue
        break;
      case "sku":
        params.sku = searchValue
        break;
      case "partNumber":
        params.sku = searchValue
        break;
      case "item_description":
        params.itemDescription = searchValue
        break;
      default:
        showSomethingWrongPopup1(TITLE_INFO, "Choose an option, please check!")
        break;
    }
    this.getProducts(params)
  }

  selectProduct(product: ItemDto) {
    console.log(product)
    if (product) {
      this.productFound.emit(product);
    }
  }

  notProduct() {
    this.products = [];
    this.productFound.emit(null);
  }

  getColor(itemDto: ItemDto) {
    let findColor = itemDto.itemSpecifications.find(spec => spec.specifications === 'color');
    return findColor ? findColor.descriptionItemSpecific : '';
  }

  upcField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.UPC)
  }

  eanField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.EAN)
  }


  productSelectValue() {
    return this.form.get(('product_selected_value'))
  }

  chanceRadio() {
    this.selectRadio = true;
    this.productSearchValue?.setValue("");
    this.products = [];
  }

  private getProducts(params: any) {
    this.productService.getAllProductsLike(params)
    .pipe(mergeMap((masterProductResult: ItemDto[]) => {
      if (masterProductResult.length > 0) {
        return of(masterProductResult);
      } else {
        const itemParams = new GetItemParamsDto();
        itemParams.type = this.form.value.type_search_product || '';
        itemParams.value = this.form.value.product_search_value || '';
        return this.productService.geSapProduct(itemParams);
      }
    })).subscribe({
      next: (result: ItemDto[]) => {
        if (result.length > 0) {
          this.products = result;
        } else {
          this.products = [];
          showSomethingWrongPopup1(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_FOUND);
        }
      }, error: () => {
        showErrorPopup(TITLE_INFO, CONTACT_SUPPORT);
      }
    })

  }

  private validateManufactures(product: ProductsDto) {
    if (product && product.idManufacturers) {
      let manufactureFound = this.manufacturers.find(value => value.ditmmnf_code === product?.idManufacturers);
      if (manufactureFound) {
        const manufacturer = new ProductMasterManufacturer();
        manufacturer.ditmmnf_front_dsc = manufactureFound.ditmmnf_front_dsc;
        manufacturer.id = manufactureFound.ditmmnf_code;
        product.manufacturer = manufacturer.ditmmnf_front_dsc;
      }
    }
  }


}















