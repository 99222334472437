export class LimitedTimeOfferDto {
  id!: number;
  itemId!: number;
  limitPeriod!: boolean;
  initialDate!: any;
  endDate!: any;
  initialHour!: number;
  endHour!: number;
  hasUnsoldLimit!: boolean;
  orderMin!: number;
  tierOne!: number;
  tierTwo!: number;
  tierThree!: number;
  tierFour!: number;
  creationDate!: string;
  creationUser!: string;
  isHotOffer!: string;
  isClearance!: boolean;

}
