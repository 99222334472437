import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {InfoTrackingPoDto} from "@features/product/model/dto/product/info-tracking-po.dto";
import {ProductService} from "@features/product/services/product.service";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";

@Component({
  selector: 'app-po-associated-tracking',
  templateUrl: './po-associated-tracking.component.html',
  styleUrls: ['./po-associated-tracking.component.scss']
})
export class PoAssociatedTrackingComponent implements OnInit {

  filter = new UntypedFormControl('');
  data: InfoTrackingPoDto[] = [];
  showTablet: boolean = false;

  constructor(
    private productService: ProductService
  ) {
  }

  get trackingField() {
    return this.filter.value;
  }

  ngOnInit(): void {
  }

  search() {
    let value = this.trackingField;
    this.callData(value);
  }

  callData(tracking: string) {
    this.productService.getTrackingInfo(tracking).subscribe((data: InfoTrackingPoDto[]) => {
      this.data = data;
      if (data.length > 0) {
        this.showTablet = true;
      } else {
        this.showTablet = false;
        showSomethingWrongPopup1('NOT FOUND', 'No results were found for the guide number entered. Please check the guide number and try again.');
      }

    });
  }

  noShowTablet() {
    this.showTablet = false;
  }
}
