<div class="col-12 close-button-template">
  <button [mat-dialog-close]="true" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div class="container">
  <div class="row mx-auto w-100">
    <mat-card *ngIf="product" class="product_custom">
      <mat-card-header>
        <mat-card-title>
          <p>{{product.itemDescription}}</p>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row product-container">
          <div class="col-5 mx-auto">
            <img [src]="product.image" alt="item image" height="200px" width="200px">
          </div>
          <div class="col-7">
            <p *ngIf="product.sku"><strong>SKU:</strong> {{product.sku}} </p>
            <p><strong>Manufacturer:</strong> {{product.manufacturers
              ? product.manufacturers.description : ''}}</p>
            <p><strong>Model:</strong>{{product.model ?? ''}}</p>
            <p><strong>Part Number:</strong>{{product.partNumber ?? ''}}</p>
            <p><strong>Condition:</strong>{{product.condition ?? ''}}</p>
            <p><strong>Category: </strong>{{product.itemCategories.description ?? ''}}</p>
            <p *ngIf="eanField(product)"><strong>EAN:</strong>{{eanField(product)}}</p>
            <p *ngIf="upcField(product)"><strong>UPC:</strong>{{upcField(product)}}</p>
            <ul>
              <li>
                <strong>Tech specs:</strong>
                <div *ngFor="let spec of product.itemSpecifications">
                  <ul>
                      <li>
                        <strong>{{spec.specifications}}</strong>:{{spec.descriptionItemSpecific}}
                      </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <form [formGroup]="form">
    <div class="col-12">
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Status</mat-label>
        <mat-select #dropdownAnchor (valueChange)="findOffers($event)"
                    formControlName="status" id="request-status">
          <mat-option *ngFor="let state of status" [value]="state">
            {{RequestUtil.getDescriptionStatus(state)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-12">
      <ng-container *ngIf="statusField?.value == 'SOURCED'">
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>Offer</mat-label>
          <mat-select formControlName="offer" id="request-offer-with-item">
            <mat-option *ngFor="let offer of offers" [value]="offer.id">
              {{offer.id}} <span> - </span> {{offer.item.sku}}
              <span> - </span>{{offer.creationDate | date: 'MM-dd-yyyy HH:mm:ss'}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
    </div>

    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Comments</mat-label>
      <textarea [cdkTextareaAutosize]="true"
                autocomplete="on"
                formControlName="request_comments"
                id="request_comments"
                matInput
                type="text"
                placeholder="Add a comment"
      ></textarea>
    </mat-form-field>

    <ng-container
      *ngIf="statusField?.value == RequestStatus.SOURCED_PREVIOUS_STOCK || statusField?.value == RequestStatus.SOURCED_SIMILAR_ITEM "
      class="col-12">
      <app-select-multiple-skus [productSelected]="products"></app-select-multiple-skus>
    </ng-container>

  </form>
  <div class="request-update-submit">
    <button (click)="updateStatus()" color="primary" mat-raised-button>Update Request</button>
  </div>
</div>
<app-loader></app-loader>
