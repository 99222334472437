import * as moment from "moment";
import {AbstractControl} from "@angular/forms";

export class DateUtil {
  static formatDate(date: any, format: string) {
    return moment(date).format(format);
  }

  static momentDate(date: any) {
    return moment(date);
  }

  static isDateBefore(date: any) {
    return moment(date).isBefore(moment(), "day");
  }

  static isDateAfter(date: any) {
    return moment(date).isAfter(moment(), "day");
  }

  static isDateSameOrBefore(date: any) {
    return moment(date).isSameOrBefore(moment(), "day");
  }

  static isDateSameOrAfter(date: any) {
    return moment(date).isSameOrAfter(moment(), "day");
  }

  static getCurrentDate() {
    return moment();
  }

  static dateComparison(control: AbstractControl) {
    const startDate = control.get('deal_date_start')?.value;
    const endDate = control.get('deal_date_end')?.value;
    if (startDate > endDate) {
      return {date_invalid: true};
    }
    return null;
  }

}
