import {Component, Input, OnInit} from '@angular/core';
import {RequestByStateDto} from "@features/stats/model/request-by-state.dto";

@Component({
  selector: 'app-status-for-request',
  templateUrl: './status-for-request.component.html',
  styleUrls: ['./status-for-request.component.scss']
})
export class StatusForRequestComponent implements OnInit {
  requestByStatus!: RequestByStateDto[];
  @Input()
  isDialog: boolean = false;


  constructor() {
  }

  ngOnInit(): void {
  }


}
