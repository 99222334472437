<div class="special-date-period-setup-container">
  <mat-card class="col-12">
    <mat-card-title class="text-center"><h3><strong>Special Deal period Setup</strong></h3>
    </mat-card-title>
    <mat-card-content>
      <div [formGroup]="specialDealPeriodSetupForm">
        <div class="row">
          <mat-label>
            <h3><strong>Does this offer has a limited valid period?</strong></h3>
          </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="has_limit_valid_period">
            <mat-radio-button [value]="true" color="primary">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="radio-but-margin" color="primary">No
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
