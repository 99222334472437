import { Injectable } from '@angular/core';
import {environment} from "@env";
import {TransactionalLog} from "@shared/model/dto/transactional-log/TransactionalLog";
import {HttpClient} from "@angular/common/http";
import {NameProcessLogEnum} from "@shared/model/enum/transactional-log/NameProcessLogEnum";

@Injectable({
  providedIn: 'root'
})
export class TransactionalLogService {

  private apiUrl = environment.automatePurchasesUrl;
  private saveLogUrl = `${this.apiUrl}custom-transactional-log/`;

  constructor(private http: HttpClient) { }

  save(transactionalLog: TransactionalLog){
    return this.http.post(this.saveLogUrl, transactionalLog);
  }

  saveLog(input: any, output: any, message: string, nameProcessLogEnum: NameProcessLogEnum,
          isFunctional: boolean){
    let params = new TransactionalLog();
    params.nameProcessLogEnum = nameProcessLogEnum;
    params.objectInput = input;
    params.objectOutput = output;
    params.message = message;
    params.isFunctional = isFunctional;
    this.save(params).subscribe();
  }
}
