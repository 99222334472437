<mat-card class="card">
  <mat-card-title class="title-text">
    <h2><strong>Sales Stats</strong></h2>
  </mat-card-title>
  <div class="row">
    <mat-form-field appearance="outline" class="col-12">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input [formControl]="startDate" matStartDate placeholder="Start date" readonly>
        <input [formControl]="endDate" matEndDate placeholder="End date" readonly>
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div class="col-12 button-search">
      <button (click)="getFilteredData()"
              color="primary"
              mat-raised-button>
        Search

      </button>
    </div>
  </div>
  <mat-table [dataSource]="stats" class="col-12 list-offer-table  " matSort
  >
    <ng-container matColumnDef="userName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="userName">User Name</th>
      <i class="bi bi-person"></i>
      <td *matCellDef="let row" class="text-center" mat-cell><i
        class="bi bi-person"></i> {{row.userName ?? 'NN'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="requestCreated">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="requestCreated">
        Request Created
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{row.requestCreated ?? 0}}</td>
    </ng-container>

    <ng-container matColumnDef="canvasDownloaded">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="canvasDownloaded">Canvas Downloaded
      </th>
      <td *matCellDef="let row" class="text-center"
          mat-cell>{{row.canvasDownloaded ?? 0}}</td>
    </ng-container>

    <ng-container matColumnDef="linksCopied">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="linksCopied">Links Copied
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{row.linksCopied ?? 0}}</td>
    </ng-container>

    <ng-container matColumnDef="feedbackProvided">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="feedbackProvided">feedback Provided
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{row.feedbackProvided ?? 0}}</td>
    </ng-container>

    <ng-container matColumnDef="dealsWon">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="dealsWon">
        Deals Won
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{row.dealsWonLost ? filterWonLost(
          row.dealsWonLost, 'won')
        : 0}}</td>
    </ng-container>

    <ng-container matColumnDef="dealsLost">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="dealsLost">
        Deals Lost
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{ row.dealsWonLost ? filterWonLost(
        row.dealsWonLost,
        'lost') : 0}}</td>
    </ng-container>

    <ng-container matColumnDef="averageTimeWonLostRequest">
      <th *matHeaderCellDef mat-header-cell mat-sort-header="averageTimeWonLostRequest">
        Average Time Won/Lost Request
      </th>
      <td *matCellDef="let row" class="text-center" mat-cell>{{ row.averageTimeWonLostRequest
        ? time_convert(filterWonLost(
          row.averageTimeWonLostRequest, 'hours') ?? 0) : 0}}</td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        mat-row>
  </mat-table>
</mat-card>
<app-loader></app-loader>

