<div class="list-active-request">
  <div class="list-active-request--title" (click)="toggle(filters)">
    <span>FILTER BY
      <mat-icon>{{ filterOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </span>
    <h2>ACTIVE REQUEST</h2>
  </div>
  <mat-card class="list-active-request--card">
    <mat-card-content>
      <form [formGroup]="form" class="row list-active-request--card__form" #filters id="filters">
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Status</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="status" id="status" placeholder="Select">
              <mat-option *ngFor="let state of status " [value]="state">
                {{requestUtil.getDescriptionStatus(state)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Enter a date range</mat-label>
          <mat-form-field appearance="outline">
            <mat-date-range-input [rangePicker]="picker">
              <input formControlName="requestInitDate" matStartDate placeholder="Start date">
              <input formControlName="requestEndDate" matEndDate placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Buyer</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="buyerId" id="buyerId" placeholder="Select">
              <mat-option *ngFor="let buyer of buyers " [value]="buyer.id">
                {{buyer.firstName + ' ' + buyer.lastName + ' - ' + requestUtil.getBuyerName(
                buyer.userCustomized)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Category</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="category" id="category" placeholder="Select">
              <mat-option *ngFor="let category of categories" [value]="category.key">
                {{category.values}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Sub Category</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="subcategory" id="sub_category" placeholder="Select">
              <mat-option *ngFor="let category of subcategories" [value]="category.key">
                {{category.values}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Manufacturer</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="manufacturer" id="manufacturer" placeholder="Select">
              <mat-option *ngFor="let manufacturer of manufacturers "
                          [value]="manufacturer.ditmmnf_code">
                {{manufacturer.ditmmnf_front_dsc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Part Number</mat-label>
          <mat-form-field appearance="outline">
            <input formControlName="partNumber" matInput id="partNumber" placeholder="Enter Part Number">
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>SKU</mat-label>
          <mat-form-field appearance="outline">
            <input formControlName="sku" matInput id="sku"  placeholder="Enter SKU">
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>Item Description</mat-label>
          <mat-form-field appearance="outline">
            <input formControlName="itemDescription" matInput id="itemDescription"  placeholder="Enter Item Description">
          </mat-form-field>
        </div>
        <div class="col-3 list-active-request--card__form--field">
          <mat-label>UPC/EAN</mat-label>
          <mat-form-field appearance="outline">
            <input
              formControlName="upc"
              matInput
              placeholder="Enter UPC/EAN"
            >
          </mat-form-field>
        </div>



        <div class="list-active-request--card__form--actions">
          <button (click)="reset()"
                  mat-raised-button
                  [ngStyle]="{'background':'#efefef'}">
            Reset filter
            <mat-icon>restart_alt</mat-icon>
          </button>
          <button mat-raised-button
                  (click)="filter()"
                  [ngStyle]="{'background':'#005eac', 'color':'white'}">
            Apply filters
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <div  class="list-active-request__stats row" *ngIf="statusStats">
    <div class="list-active-request__stats--per-requests col-9">
        <div>
          <h3>Cancelled</h3>
          <p>{{statusStats?.cancelled}}</p>
        </div>
        <div >
          <h3>Sourcing</h3>
          <p>{{statusStats?.sourcing}}</p>
        </div>
        <div >
          <h3>Sourced</h3>
          <p>{{statusStats?.sourced}}</p>
        </div>
        <div >
          <h3>Sourced previous <br> stock</h3>
          <p>{{statusStats?.sourcedPreviousStock}}</p>
        </div>
        <div >
          <h3>Sourced <br> similar item</h3>
          <p>{{statusStats?.sourcedSimilarItem}}</p>
        </div>
        <div >
          <h3>Unable to <br> source</h3>
          <p>{{statusStats?.unableToSource}}</p>
        </div>
        <div >
          <h3>Not <br> In Plan</h3>
          <p>{{statusStats?.notInPlan}}</p>
        </div>
        <div>
          <h3>Unable To <br> Source MOQ</h3>
          <p>{{statusStats?.unableToSourceMOQ}}</p>
        </div>
        <div>
          <h3>Unable to Source <br> Price Match</h3>
          <p>{{statusStats?.unableToSourcePriceMatch}}</p>
        </div>
    </div>
    <div class="list-active-request__stats--avg-time col-2">
        <div>
          <h3>Time to respond a request</h3>
          <p>{{time_convert(statusStats?.responseRequest ?? 0)}}</p>
        </div>

    </div>
  </div>
  <div class="mt-4" style="margin: 0 auto">
    <div class="paginator-container">
      <mat-paginator (page)="pagination($event)"
                     [hidePageSize]="this.paginator.pageIndex != 0"
                     [length]="resultsLength"
                     [pageSizeOptions]="[5, 10, 25, 50]"
                     [pageSize]="50"
                     aria-label="Select page of request Information search results">
      </mat-paginator>
    </div>
    <div class="table-container">

      <table mat-table (matSortChange)="sortChange($event)" [dataSource]="dataSource"
             class="col-12 list-offer-table"
             matSort>
        <caption>Active Request</caption>


        <ng-container matColumnDef="viewRequest">
          <th *matHeaderCellDef mat-header-cell>View</th>
          <td *matCellDef="let row" mat-cell>
            <button (click)="requestDetailPreview(row)"
                    mat-icon-button
                    [disabled]="!isEditable('viewRequest')">
              <mat-icon svgIcon="view-icon"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container  matColumnDef="editRequest">
          <th *matHeaderCellDef mat-header-cell>Edit</th>
          <td *matCellDef="let row" mat-cell>
            <button (click)="editItemDialog(row)" [disabled]="!isEditable('editRequest') || row.status=='Cancelled'"
                    mat-icon-button>
              <mat-icon svgIcon="edit-orders-icon"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="buyer">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="buyer.userCustomized.userCustomizedValue">Buy.</th>
          <td *matCellDef="let row" mat-cell>
            {{row.buyer ?? ''}}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="status">Status</th>
          <td *matCellDef="let row" mat-cell>{{requestUtil.getDescriptionStatus(row.status)}}</td>
        </ng-container>

        <ng-container matColumnDef="requestDate">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="requestDate">Rqs. Date</th>
          <td *matCellDef="let row"
              mat-cell>{{row.requestDate | date: 'MM-dd-yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="goalIndicator">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="indicatorPurchase">Goal</th>
          <td *matCellDef="let row" mat-cell>
              <span
                [ngStyle]="row.indicatorPurchase && (row.indicatorPurchase < 0) ?  {'color':'red'} : {'color':'green' }">
                {{row.indicatorPurchase}}
              </span>
          </td>
        </ng-container>


        <ng-container matColumnDef="subCategory">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.itemCategories.description">SubCategory</th>
          <td *matCellDef="let row" mat-cell>{{row.itemSubCategory ?? ''}}</td>
        </ng-container>

        <ng-container matColumnDef="manufacturer">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.manufacturers.description">Manufacturer</th>
          <td *matCellDef="let row" mat-cell>{{row.itemManufacturer ?? ''}}</td>
        </ng-container>

        <ng-container matColumnDef="partNumber">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.partNumber">Part Number</th>
          <td *matCellDef="let row" mat-cell>
            <p style="text-align: start; margin: 0 auto">{{row.itemPartNumber ?? ''}}</p>
          </td>
        </ng-container>


        <ng-container matColumnDef="itemDescription">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.itemDescription">Item desc.</th>
          <td *matCellDef="let row" mat-cell  [matTooltip]="row?.itemDescription!">

            <div (click)="showSimilarSkus(row?.id)"
                 *ngIf="(row.status==RequestStatus.SOURCED_PREVIOUS_STOCK || row.status==RequestStatus.SOURCED_SIMILAR_ITEM)"
                 class="similarSkus">

              <p style="padding: .2rem; text-align: start; margin:  0 auto" >
                {{row?.itemDescription?.length > 20 ? String(row?.itemDescription).substring(0,20)
                  .concat("...")
                :row?.itemDescription! }}</p>
            </div>
            <div
              *ngIf="!(row.status=='Sourced - Previous Stock' || row.status=='Sourced - Similar Item')">
              <p style="padding: .2rem; text-align: start; margin:  0 auto" >
                {{row?.itemDescription?.length > 20 ? String(row?.itemDescription).substring(0,20)
                  .concat("...")
                :row?.itemDescription! }}</p>
            </div>


          </td>
        </ng-container>

        <ng-container matColumnDef="salesMan">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="requester.userCustomized.userCustomizedValue">SP</th>
          <td *matCellDef="let row" mat-cell>
            {{row.requester}}
          </td>
        </ng-container>


        <ng-container matColumnDef="targetPrice">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offerPrice">Target price</th>
          <td *matCellDef="let row" mat-cell>{{row.targetPrice}}</td>
        </ng-container>

        <ng-container matColumnDef="expectedQty">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="quantityMax">Exp. Qty</th>
          <td *matCellDef="let row" mat-cell>{{row.expectedQty}}</td>
        </ng-container>

        <ng-container matColumnDef="eta">
          <th *matHeaderCellDef mat-header-cell mat-sort-header="offerEta">Exp. ETA</th>
          <td *matCellDef="let row" mat-cell>{{row.eta}}</td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row>
      </table>
    </div>
    <div class="paginator-container">
      <mat-paginator (page)="pagination($event)"
                     [hidePageSize]="this.paginator.pageIndex != 0"
                     [length]="resultsLength"
                     [pageSizeOptions]="[5, 10, 25, 50]"
                     [pageSize]="50"
                     aria-label="Select page of request Information search results">
      </mat-paginator>
    </div>
  </div>
</div>
<app-loader></app-loader>



