<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="whatsappForm">
        <div class="row">
          <div class="customer-title">
            <h1><strong>Would you like to notify Customer about this deal by whatsapp?</strong></h1>
          </div>
          <mat-radio-group
            (selectionchange)="clearFields()" class="col-12 group-customer"
            formControlName="selection">
            <mat-radio-button [value]="1" class="group-customer__radio-button" color="primary">Yes
            </mat-radio-button>
            <mat-radio-button [value]="0" class="group-customer__radio-button" color="primary">No
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="row">
          <mat-form-field *ngIf="optionWhatsappField?.value == 1" appearance="fill" class="col-12">
            <mat-label>Please select the segment</mat-label>
            <mat-select (selectionChange)="selectionChange($event)"
                        disableRipple
                        multiple>
              <mat-option *ngFor="let segment of segments"
                          [value]="segment">{{segment.code}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>

