import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {UntypedFormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";

import {lastValueFrom} from "rxjs";

import {
  ProductPreviewComponent
} from "@features/product/features/presentation/product-preview/product-preview.component";

import {ItemCode} from "@features/product/model/enum/item-code";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductService} from "@features/product/services/product.service";
import {ItemCustomizedCode} from "@features/product/model/enum/item-customized-code";
import {
  ProductUpdateComponent
} from "@features/product/features/presentation/product-update/product-update.component";
import {
  UploadImagesComponent
} from "@features/product/features/presentation/upload-images/upload-images.component";
import {NgxPermissionsService} from "ngx-permissions";
import {ROLES} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-list-product',
  templateUrl: './list-product.component.html',
  styleUrls: ['./list-product.component.scss']
})
export class ListProductComponent implements OnInit {

  displayedColumns: string[] = [
    'id_item', 'sku', 'item_description', 'upc', 'ean', 'item_details', 'item_edit', 'upload_image'
  ];
  data: ProductsDto[] = [];
  resultsLength = 0;
  totalPages = 0;
  currentPage = 0;

  categoryTree: any = '';

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  filter = new UntypedFormControl('');
  search_by = new UntypedFormControl('SKU');

  constructor(private productService: ProductService,
              public dialog: MatDialog,
              private ngxPermissionsService: NgxPermissionsService) {
  }

  get searchByField() {
    return this.search_by.value;
  }

  get filterField() {
    return this.filter.value;
  }

  ngOnInit(): void {
    let params = {page: this.currentPage, size: 25};
    this.callData(params);
    console.log(this.ngxPermissionsService.getPermissions())
  }

  callData(params: any) {
    this.productService.getAllItems(params).subscribe({
      next: value => {
        this.data = value.items;
        this.resultsLength = value.totalItems;
        this.totalPages = value.totalPages;
        this.currentPage = value.currentPage;
      }
    })
  }

  pagePagination($event: PageEvent) {
    let params = {page: $event.pageIndex, size: $event.pageSize};
    this.callData(params);
  }

  getUpc(productsDto: ProductsDto) {
    // @ts-ignore
    let findUpc = productsDto.itemCodes.find(code => code.itemCodeType === ItemCode.UPC);
    if (findUpc) {
      return findUpc.itemCode;
    }
    return '';
  }

  getEan(product: ProductsDto) {
    // @ts-ignore
    let findEan = product.itemCodes.find(code => code.itemCodeType === ItemCode.EAN);
    if (findEan) {
      return findEan.itemCode;
    }
    return '';
  }

  getSkuSap(product: ProductsDto) {
    let findSkuSap = product.itemCustomized.find(item => item.itemCustomizedCode == ItemCustomizedCode.SAP_CODE);
    if (findSkuSap) {
      return findSkuSap.itemCustomizedValue;
    }
    return '';
  }

  search() {
    let paramsToSearch: any;
    if (this.searchByField === ItemCode.UPC || this.searchByField === ItemCode.EAN) {
      paramsToSearch = {
        itemCodeType: this.searchByField,
        itemCode: this.filterField,
        sku: null,
        page: 0,
        size: 25
      };
    }
    if (this.searchByField === ItemCode.SKU) {
      paramsToSearch = {
        itemCodeType: null,
        itemCode: null,
        sku: this.filterField,
        page: 0,
        size: 25
      };
    }
    if (this.searchByField === ItemCode.PART_NUMBER) {
      paramsToSearch = {
        itemCodeType: null,
        itemCode: null,
        sku: null,
        partNumber: this.filterField,
        page: 0,
        size: 25
      };
    }
    this.callData(paramsToSearch);
  }

  openPreview(product: ProductsDto) {
    const dialogRef = this.dialog.open(ProductPreviewComponent, {
      width: '50%',
      height: '90%',
    });
    let instance = dialogRef.componentInstance;
    product.ean = this.getEan(product)
    product.upc = this.getUpc(product)
    product.skuSap = this.getSkuSap(product)
    // @ts-ignore
    product.manufacturer = product.manufacturers.description;
    instance.product = product;

    instance.isDialog = true;
    // @ts-ignore
    this.getCategoryTree(product.itemCategories.code)
    .then(result => {
      if (result.body) {
        instance.descriptionCategories = result.body[0].category_lineage;
      }
    });


  }

  getDescriptionCategories(product: ProductsDto): string {
    let category: string = product.selectCategories?.values;
    let subCategory: string = '';
    product.selectSudCategories?.forEach((value: any) => {
      subCategory = subCategory.concat(' -> '.concat(value.sub_category.values));
    });
    return category.concat(subCategory);
  }


  async getCategoryTree(category: string): Promise<any> {
    let response = this.productService.getCategoryTree({'itemCategory': category});
    return lastValueFrom(response);
  }


  async getManufacturers(): Promise<any> {
    let response = this.productService.getManufacturer();
    return lastValueFrom(response);
  }

  editItem(product: ProductsDto) {
    const dialogRef = this.dialog.open(ProductUpdateComponent, {
      width: '80%',
      height: '90%',
    });
    dialogRef.componentInstance.itemId = product.id;
    dialogRef.componentInstance.itemImages = product.itemPictures;

    dialogRef.afterClosed().subscribe(() => {
      let params = {page: this.currentPage, size: 25};
      this.callData(params);
    })

  }

  uploadImage(product: ProductsDto) {
    const dialogRef = this.dialog.open(UploadImagesComponent, {
      width: '80%',
      height: '90%',
    });
    dialogRef.componentInstance.itemId = product.id;
    dialogRef.componentInstance.product = product;

    dialogRef.afterClosed().subscribe(() => {
      let params = {page: this.currentPage, size: 25};
      this.callData(params);
    })
  }
  getPermissions(){
    console.log(this.ngxPermissionsService.getPermissions())
  }


  protected readonly ROLES = ROLES;
}
