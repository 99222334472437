// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: "https://api-sumcake-dev.sumcake.com/",
  // apiUrl: "http://localhost:8081/",
  // productMasterUrl: "http://localhost:8080/",
  productMasterUrl: "https://api-productmaster-dev.sumcake.com/",
  //automatePurchasesUrl: "http://localhost:8080/",
  automatePurchasesUrl: "https://api-automatepurchases-dev.sumcake.com/",
  // oauthUrl: "http://localhost:8080/",
  oauthUrl: "https://api-oauth-dev.sumcake.com/",
  resourcesUrl: "https://fqig5bxje0.execute-api.us-east-1.amazonaws.com/dev/",
  production: false,
  resourcesApiKey: "UCui0aU1XG4FfoMMlRYalawtYEW85F408fyRYyJH",
  recaptchaV3SiteKey: "6Lcgt94eAAAAACSBoYNzE1j3qWilmAf_zUqt5NT0",
  userPoolWebClientId: "75o71qvjhkiks8k7pnpv9k97lb",
  userPoolId: "us-east-1_ijVaalYQT",
  aws_cognito_region: "us-east-1",
  supersetUrl: "https://api-superset-apache-prod.sumcake.com/",
  superset_userName: "appdataviewer",
  superset_pass: "S4p3rS3td@taView3r",
  superset_dashboard_mkp_listing_summary: 'a5a6e081-12fd-4740-9a10-684236384323',
  superset_dashboard_orders: '81dc6fda-ecb2-4059-8b46-0672dfb87a8e',
  superset_dashboard_customers: '7fc9c872-da06-43c4-8119-188698c503ff',
  test_dashboard: 'aa627b5d-23c6-452c-b65f-cf1eeb19bec3',
  item_purchases_dashboard: 'c5fb94fa-bd11-4e1d-917c-2fd692b25919',
  superset_dashboard_inventory: '36fbdb85-080b-43c4-85c1-2f0ab3544acd',
  superset_dashboard_vendor_inventory: 'e12f47b4-b7c5-49a6-81f6-4827d4971692',
  superset_dashboard_key_metrics: '80b77993-b3f7-44aa-ad6a-13f8d66f3ce1',


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
