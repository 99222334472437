
<div  class="container-canva-and-price">
  <mat-card class="container-canva-and-price__card">
    <div  class="col-12 close_button">
      <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
        <mat-icon svgIcon="close-popup-icon">close</mat-icon>
      </button>
    </div>
    <div class="icon-container">
      <img src="assets/icon/offers/PriceListIcon.svg" alt="Price List Icon">
    </div>
    <mat-card-title ><h2>PRICE LIST</h2></mat-card-title>
    <app-prices-and-cost [cost]="item?.itemSapData?.cost"
                         [price]="item?.itemSapData?.price"
                         title="Download Canva w/o Price"
                         [sku]="ItemCustomizedUtil.skuSap(item.itemCustomized)"
                         (emitter)="downloadCanva(null)"
    >
    </app-prices-and-cost>

    <mat-card-content class="container-canva-and-price__card--content">
      <div class="row container-canva-and-price__card--content--my-customer">
        <app-my-customers-price [item]="item" [offerId]="offerId"></app-my-customers-price>
      </div>

      <form [formGroup]="form" class="row">
        <h4>SEARCH FOR A CUSTOMER</h4>
        <div class="col-4">
          <mat-label>Filter By</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="searchType" placeholder="Select">
              <mat-option value="cardCode">By CardCode</mat-option>
              <mat-option value="cardName">By CardName</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-8">
          <mat-label>Type value</mat-label>
          <mat-form-field appearance="outline" >
            <input autocomplete="on"
                   formControlName="searchValue"
                   matInput
                   type="text"
                   placeholder="Write Here"
            >
          </mat-form-field>
        </div>
        <div class="container-canva-and-price__card--content__actions">
          <button class="btn"
                  (click)="filter()"
                   [disabled]="form.invalid">
            Search
          </button>
        </div>
      </form>
      <div *ngIf="businessPartnerList.length > 0 && !businessPartnerPriceListSelected" class="row container-canva-and-price__card--content__user">
        <ng-container *ngFor="let businessPartner of businessPartnerList">
          <mat-card>
            <mat-card-content>
              <div class="row">
                <p><strong>Card Code:</strong> {{businessPartner.cardCode}}</p>
                <p><strong>Card Name:</strong> {{businessPartner.cardName}}</p>
              </div>

              <div class="container-canva-and-price__card--content__user--actions">
                <button (click)="selectBusinessPartner(businessPartner)" class="btn "> Select
                </button>
              </div>
            </mat-card-content>
          </mat-card>

        </ng-container>
      </div>
      <app-prices-and-cost *ngIf="businessPartnerPriceListSelected"
                           [cost]="item?.itemSapData?.cost"
                           [price]="businessPartnerPriceListSelected?.price"
                           [businessPartnerPriceList]="businessPartnerPriceListSelected"
                           title="Download Canva"
                           (emitter)="downloadCanva($event)"

      >

      </app-prices-and-cost>

    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
