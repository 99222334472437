import {Component, Input} from '@angular/core';
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RequestService} from "@features/request/services/request.service";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {Router} from "@angular/router";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-typification-edit',
  templateUrl: './typification-edit.component.html',
  styleUrls: ['./typification-edit.component.scss']
})
export class TypificationEditComponent {
  @Input()
  request!: WRequestDto;
  @Input()
  isDialog: boolean = false;
  @Input()
  typificationEditForm!: FormGroup;
  commercialStatusTypificationBy = new FormControl(null);

  winOption: any[] = [
    {value: 1, description: 'Client accepts terms and conditions'}
  ];
  LostOption: any[] = [
    {value: 1, description: 'Price too high'},
    {value: 2, description: 'MOQ'},
    {value: 3, description: 'Late response'},
    {value: 4, description: 'Client lost deal'},
    {value: 5, description: 'Not enough quantities'},
    {value: 6, description: 'Product does not match request'}
  ];

  constructor(private formBuilder: FormBuilder,
              private requestService: RequestService,
              private dialog: MatDialog
  ) {
    this.typificationEditForm = this.formBuilder.group({
      request_comments: ['', [Validators.maxLength(500)]],
      select_commercial_status: [""],

    }, {})
  }

  get requestComments() {
    return this.typificationEditForm.get('request_comments');
  }

  get selectCommercialStatus() {
    return this.typificationEditForm.get('select_commercial_status');
  }


  optionSelected($event: any) {
    this.request.commercialStatus = $event;
  }

  save() {
    if (this.typificationEditForm.valid) {
      try {

        let request = new WRequestDto();
        request.id = this.request.id;
        request.comments = this.requestComments?.value;
        request.commercialStatus = this.selectCommercialStatus?.value;
        request.commercialStatusTypification = this.commercialStatusTypificationBy.value!;

        this.requestService.saveTypificationCommercial(request).subscribe({
          next: (value: any) => {
            if (value.status) {
              showSuccessPopup(TITLE_INFO, "Ok").then(() => this.dialog.closeAll())
            } else {
              showSomethingWrongPopup1(TITLE_ERROR, CONTACT_SUPPORT)
            }
          }, error: () => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
          }
        })
      } catch (error) {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
      }
    }
  }
}
