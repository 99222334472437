<div class="totp-confirmation-container">
  <div  class="col-12 close_button">
    <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
      <mat-icon svgIcon="close-popup-icon">close</mat-icon>
    </button>
  </div>
  <h2>Enter TOTP Code</h2>
  <p>Please enter the TOTP code generated by your authenticator app.</p>
  <div class="totp-confirmation-container__totp-input-container">
    <label for="totp">TOTP Code:</label>
    <mat-form-field appearance="outline" class="col-6">
      <input [formControl]="totpCode"
             matInput
             type="text"
             id="totp"
             name="totpCode"
             [maxlength]="6"
             [minlength]="6"
      >
    </mat-form-field>
    <button class="btn" type="submit" (click)="submitTOTP()" >Submit</button>
  </div>
</div>
