import {AbstractControl} from "@angular/forms";
import * as moment from "moment";

export class ProductOfferValidator {


  static offerValidationDateComparison(control: AbstractControl) {
    let startDate = control.get('deal_period_date_start')?.value;
    let endDate = control.get('deal_period_date_end')?.value;
    let startHour = control.get('deal_period_hour_start')?.value;
    let endHour = control.get('deal_period_hour_end')?.value;

    if (startDate && endDate && (startDate?.getTime() > endDate?.getTime() || (startDate?.getTime() == endDate?.getTime() && startHour >= endHour))) {
      return {offer_validation_date_invalid: true};
    }
    return null;
  }

  static timeValidator(control: AbstractControl) {
    let startDate = control.get('deal_period_date_start')?.value;
    let startHour = control.get('deal_period_hour_start')?.value;
    if (startDate && startHour) {
      let firstDateTimeToEvaluate = moment(startDate).add(startHour, 'hours');
      let currentTimeToEvaluate = moment().add(1, 'hour');
      console.log(firstDateTimeToEvaluate)
      console.log(currentTimeToEvaluate)
      console.log(moment().toDate().getDate())
      console.log(moment(startDate).toDate().getDate())

      return firstDateTimeToEvaluate.isBefore(currentTimeToEvaluate)
        ? {offer_validation_first_date_time_invalid: true} : null

    }
    return null;
  }


}
