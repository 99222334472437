import {
  SapPurchaseOrderDto
} from "@features/product-offer/model/offer-purchase-order/sap-purchase-order.dto";
import {
  SapPurchaseOrderDetailDto
} from "@features/product-offer/model/offer-purchase-order/sap-purchase-order-detail.dto";


export class SavePurchaseOrderDto {
  purchaseOrder!: SapPurchaseOrderDto;
  sapPurchaseOrderDetail!: SapPurchaseOrderDetailDto[];
  status!: boolean;
  message!: string;
  idEvent!: number;
}
