export enum RequestStatus {
  PENDING = "PENDING",
  SOURCING = "SOURCING",
  SOURCED = "SOURCED",
  UNABLE_TO_SOURCE = "UNABLE_TO_SOURCE",
  WON_OPPORTUNITY = "WON_OPPORTUNITY",
  LOST_OPPORTUNITY = "LOST_OPPORTUNITY",
  CANCELLED = "CANCELLED",
  SOURCED_PREVIOUS_STOCK = "SOURCED_PREVIOUS_STOCK",
  SOURCED_SIMILAR_ITEM = "SOURCED_SIMILAR_ITEM",
  NOT_IN_PLAN = "NOT_IN_PLAN",
  UNABLE_TO_SOURCE_MOQ = "UNABLE_TO_SOURCE_MOQ",
  UNABLE_TO_SOURCE_PRICE_MATCH = "UNABLE_TO_SOURCE_PRICE_MATCH",
}
