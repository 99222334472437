import {AfterViewInit, Component, Input, ChangeDetectorRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomValidator} from "@shared/utility/validators/validators";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO, TITLE_SUCCESS} from "@shared";
import {MatDialog} from "@angular/material/dialog";
import {
  OfferStartingPriceDto
} from "@features/product-offer/model/offer-validation/offerStartingPrice.dto";
import {OfferValidationService} from "@features/product-offer/services/offer-validation.service";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-update-base-prices-main',
  templateUrl: './update-base-prices-main.component.html',
  styleUrls: ['./update-base-prices-main.component.scss']
})
export class UpdateBasePricesMainComponent implements AfterViewInit {

  basePriceListForm: UntypedFormGroup;
  @Input()
  itemId!: number;
  @Input()
  sapCode!: string;
  @Input()
  basePrices!: OfferStartingPriceDto;

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly authService: AuthService,
              private readonly offerValidationService: OfferValidationService,
              public dialog: MatDialog,
              private readonly cdr: ChangeDetectorRef) {
    this.basePriceListForm = this.buildBasePriceListForm();
  }


  get tierFourField() {
    return this.basePriceListForm.get('tierFour');
  }

  ngAfterViewInit() {
    if (this.basePrices && this.basePrices.itemId) {
      this.itemId = this.basePrices.itemId;
      this.basePriceListForm.patchValue({
        tierFour: this.basePrices.tierFour ? this.basePrices.tierFour : null,
      })
      this.basePriceListForm.markAllAsTouched();
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  saveBasePriceOnly() {
    if (this.basePriceListForm.valid) {
      this.authService._getFromPayload("id_user").then(userId => {
        this.offerValidationService.updateOfferValidation(this.createOfferValidation(userId)).subscribe({
          next: (createOfferValidationResponse: any) => {
            if (createOfferValidationResponse && !createOfferValidationResponse.status) {
              showSomethingWrongPopup1(TITLE_INFO, createOfferValidationResponse.message);
            } else {
              showSuccessPopup(TITLE_SUCCESS, createOfferValidationResponse.message).then(() => this.dialog.closeAll());
            }

          },
          error: (err: any) => {
            console.log(err);
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        })
      })

    }
  }

  createOfferValidation(userId: string) {
    let offerStartingPrice = new OfferStartingPriceDto();
    if (this.basePrices && this.basePrices.id) {
      offerStartingPrice.id = this.basePrices.id;
    }
    offerStartingPrice.itemId = this.itemId;
    offerStartingPrice.creationUser = userId;

    offerStartingPrice.tierOne = Number(this.tierFourField?.value);
    offerStartingPrice.tierTwo = Number(this.tierFourField?.value);
    offerStartingPrice.tierThree = Number(this.tierFourField?.value);
    offerStartingPrice.tierFour = Number(this.tierFourField?.value);

    return {
      offerStartingPrice: offerStartingPrice,
      limitedTimeOffer: null,
    }
  }

  private buildBasePriceListForm() {
    return this.formBuilder.group({
      tierFour: ['', [Validators.required, CustomValidator.validateDecimal, Validators.min(1)]],
    });
  }
}
