import {Component, OnInit} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'admin_apps_distritech';

  iconNames: Icons[] = [
    {name: 'home-icon', path: '../assets/icon/header/Home-Icon.svg'},
    {name: 'dashboard-and-analytics-icon', path: '../assets/icon/header/Dashboard-Icon.svg'},
    {name: 'dashboard-and-analytics-icon-blue', path: '../assets/icon/home/Dashboard-IconBlue.svg'},
    {name: 'product-and-offers-icon', path: '../assets/icon/header/Products-Offers-Icon.svg'},
    {name: 'product-and-offers-icon-blue', path: '../assets/icon/home/Products-Offers-IconBlue.svg'},
    {name: 'requests-icon', path: '../assets/icon/header/Request-Icon.svg'},
    {name: 'requests-icon-blue', path: '../assets/icon/home/Request-IconBlue.svg'},
    {name: 'marketplace-icon', path: '../assets/icon/header/Marketplace-Icon.svg'},
    {name: 'marketplace-icon-blue', path: '../assets/icon/home/Marketplace-IconBlue.svg'},
    {name: 'item-master-icon', path: '../assets/icon/header/Item-Master-Icon.svg'},
    {name: 'active-offers-icon', path: '../assets/icon/header/Active-Offers-Icon.svg'},
    {name: 'offer-creation-icon', path: '../assets/icon/header/Offer-Creation-Icon.svg'},
    {name: 'purchase-order-icon', path: '../assets/icon/header/Purchase-Orders-Icon.svg'},
    {name: 'calendar-icon', path: '../assets/icon/header/Calendar-Icon.svg'},
    {name: 'chat-globe-icon', path: '../assets/icon/header/Chat-Globe-Icon.svg'},
    {name: 'favorite-icon', path: '../assets/icon/header/Favorite-Icon.svg'},
    {name: 'check-icon', path: '../assets/icon/header/Icon-Check.svg'},
    {name: 'mail-icon', path: '../assets/icon/header/Mail-Icon.svg'},
    {name: 'notification-icon', path: '../assets/icon/header/Notification-Icon.svg'},
    {name: 'search-icon', path: '../assets/icon/header/Search-Icon.svg'},
    {name: 'user-icon', path: '../assets/icon/header/Icon-User.svg'},
    {name: 'down-arrow-icon', path: '../assets/icon/home/Down-Arrow-Icon.svg'},
    {name: 'up-arrow-icon', path: '../assets/icon/home/Up-Arrow-Icon.svg'},
    {name: 'feedback-icon', path: '../assets/icon/offers/Feedback.svg'},
    {name: 'hot-offer-icon', path: '../assets/icon/offers/HotOfferIcon.svg'},
    {name: 'item-purchases-icon', path: '../assets/icon/offers/ItemPurchases.svg'},
    {name: 'link-icon', path: '../assets/icon/offers/Link.svg'},
    {name: 'orders-icon', path: '../assets/icon/orders/OrdersIcon.svg'},
    {name: 'price-canva-icon', path: '../assets/icon/offers/PriceCanva.svg'},
    {name: 'view-icon', path: '../assets/icon/offers/View.svg'},
    {name: 'price-list-icon', path: '../assets/icon/offers/PriceListIcon.svg'},
    {name: 'close-popup-icon', path: '../assets/popup/CloseIconPopup.svg'},
    {name: 'feedback-popup-icon', path: '../assets/icon/offers/FeedbackPopupIcon.svg'},
    {name: 'feedback-icon-green', path: '../assets/icon/offers/FeedbackIconGreen.svg'},
    {name: 'change-log', path: '../assets/icon/changeLog/ChangelogIcon.svg'},
    {name: 'orders-icon-2', path: '../assets/icon/orders/Orders2Icon.svg'},
    {name: 'warning-orders-icon', path: '../assets/icon/orders/WarningsOrdersIcon.svg'},
    {name: 'customers-dashboard-icon', path: '../assets/icon/customers/DashboardCustomerIcon.svg'},
    {name: 'comments-orders-icon', path: '../assets/icon/orders/Comments.svg'},
    {name: 'edit-orders-icon', path: '../assets/icon/orders/Edit.svg'},
    {name: 'notification-orders-icon', path: '../assets/icon/orders/Notification.svg'},
    {name: 'prepare-orders-icon', path: '../assets/icon/orders/Prepare.svg'},
    {name: 'shipping-orders-icon', path: '../assets/icon/orders/Shipping.svg'},
    {name: 'orders-menu-icon', path: '../assets/icon/orders/OrdersViewIcon.svg'},
    {name: 'attachment-orders-icon', path: '../assets/icon/orders/Attachment.svg'},
    {name: 'alert-orders-icon', path: '../assets/icon/orders/IconAlert.svg'},
    {name: 'delivery-info-photo-icon', path: '../assets/icon/orders/deliveryInfo/IconPhoto.svg'},
    {name: 'delivery-info-certificate-icon', path: '../assets/icon/orders/deliveryInfo/IconCertificate.svg'},
    {name: 'delivery-info-edit-icon', path: '../assets/icon/orders/deliveryInfo/IconEdit.svg'},
    {name: 'orders-view-icon', path: '../assets/icon/orders/View.svg'},
    {name: 'inventory-dashboard-icon', path: '../assets/icon/inventory/Inventory.svg'},
    {name: 'icon-general-information', path: '../assets/icon/IconGeneralInformation.png'},
    {name: 'icon-dashboard-vendors', path: '../assets/icon/orders/IconDashboardVendors.svg'},
    {name: 'dashboard-key', path: '../assets/icon/orders/DashboardKey.svg'},
    {name: 'customer-info-icon', path: '../assets/icon/warehouse-order-preparation/CustomerInfoIcon.svg'},
    {name: 'delivery-acceptance-icon', path: '../assets/icon/warehouse-order-preparation/DeliveryAcceptanceIcon.svg'},
    {name: 'delivery-ticket-icon', path: '../assets/icon/warehouse-order-preparation/DeliveryTicketIcon.svg'},
    {name: 'dimensioner-info-icon', path: '../assets/icon/warehouse-order-preparation/DimensionerIcon.svg'},
    {name: 'packing-label-icon', path: '../assets/icon/warehouse-order-preparation/PackingLabelsIcon.svg'},
    {name: 'packing-list-icon', path: '../assets/icon/warehouse-order-preparation/PackingListIcon.svg'},
    {name: 'packing-preview-icon', path: '../assets/icon/warehouse-order-preparation/PackingPreviewIcon.svg'},
    {name: 'serial-icon', path: '../assets/icon/warehouse-order-preparation/SerialIcon.svg'},
    {name: 'order-preparation-menu-icon', path: '../assets/icon/warehouse-order-preparation/OrderPreparationMenuIcon.svg'},
  ]

  constructor(private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer,) {


  }

  ngOnInit(): void {
    this.iconNames.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    })
  }
}

export interface Icons {
  name: string;
  path: string
}
