import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {ItemCustomizedCode} from "@features/product/model/enum/item-customized-code";

export class ItemCustomizedUtil{
  static  skuSap(itemCustomized: ItemCustomizedDto[]): any {
    const filteredData = itemCustomized
      .filter((item) => item.itemCustomizedCode == ItemCustomizedCode.SAP_CODE)
    return filteredData.length > 0  ? filteredData[0].itemCustomizedValue : null;
  }

  static clearanceOffer(itemCustomized: ItemCustomizedDto[]): any {
    const filteredData = itemCustomized
      .filter((item) => item.itemCustomizedCode == ItemCustomizedCode.IS_CLEARANCE_OFFER)
    return filteredData.length > 0  ? filteredData[0].itemCustomizedValue : null;
  }
}
