import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env";
import {
  DealsNotificationsDto
} from "@features/product-offer/model/notifications/deals-notifications.dto";


@Injectable({
  providedIn: 'root'
})
export class DealsNotificationsService {

  controls: any;
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private saveDealsNotificationsUrl = `${this.automatePurchasesUrl}notifications-omnised/save/deals_notifications`;

  constructor(private httpClient: HttpClient) {
  }

  saveDealsNotifications(deals: DealsNotificationsDto[]): Observable<string> {
    return this.httpClient.post<string>(this.saveDealsNotificationsUrl, deals);
  }

}
