<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="productSelectForm">
        <div class="row">
          <mat-label>
            <h3><strong>Product Select</strong></h3>
          </mat-label>
        </div>
        <mat-radio-group (change)="chanceRadio()" aria-label="Select an option"
                         class="radio_group_type_search_product"
                         formControlName="type_search_product">
          <mat-radio-button class="radio_where_type_search_product" color="primary" value="upc">UPC
          </mat-radio-button>
          <mat-radio-button class="radio_where_type_search_product" color="primary" value="ean">EAN
          </mat-radio-button>
          <mat-radio-button class="radio_where_type_search_product" color="primary" value="mpn">Part
            Number
          </mat-radio-button>
          <mat-radio-button class="radio_where_type_search_product" color="primary" value="title">
            Product
            Name
          </mat-radio-button>
          <mat-radio-button *ngIf="false" class="radio_where_type_search_product" color="primary"
                            value="sku">sku
          </mat-radio-button>
        </mat-radio-group>
        <div class="row">
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Search value</mat-label>
            <input autocomplete="off"
                   formControlName="product_search_value"
                   matInput
                   type="text"
            >
          </mat-form-field>
          <mat-error *ngIf="productSearchValue?.hasError('validate_length')">
            Check the number of characters for UPC 12 for EAN 13
          </mat-error>
          <mat-error *ngIf="productSearchValue?.hasError('codeAlreadyExists')">
            code already <strong>exists</strong>
          </mat-error>
        </div>
        <div>
          <button (click)="getProductValue()"
                  [disabled]="!(typeSearchProduct?.valid && productSearchValue?.valid)"
                  color="primary"
                  mat-raised-button
          > Search
          </button>
        </div>

        <div>
          <div *ngFor="let product of products" class="row">
            <mat-card class="product_custom">
              <mat-card-header>
                <mat-card-title>{{product.title}}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="row">
                  <div class="col-4">
                    <img
                      [src]="product.images && product.images.length > 0 ?  product.images[0].url: 'Not Image'"
                      alt="item image" height="200px" width="200px">
                  </div>
                  <div class="col-8">
                    <p *ngIf="product.ean"><strong>EAN:</strong> {{product.ean}}</p>
                    <p *ngIf="product.upc"><strong>UPC:</strong> {{product.upc}}</p>
                    <p><strong>Manufacturer:</strong> {{product.manufacturer}}</p>
                    <p><strong>Model: </strong>{{product.model}}</p>
                    <p><strong>Part Number:</strong> {{product.partNumber}}</p>
                    <p><strong>Color:</strong> {{product.color}}</p>
                    <p><strong>Size:</strong> {{product.size}}</p>
                  </div>
                </div>
              </mat-card-content>
              <div class="text-center">
                <button (click)="getProductSelect(product)" color="primary"
                        mat-raised-button
                > Select
                </button>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
