<div class="container-verify-confirmation-code">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="form">
            <div class="row">
              <div class="col-12 container-verify-confirmation-code--title">
                <h2>Reset Password:</h2>
                <p>We have sent a password reset code by email to you. Enter it below to reset your
                  password.</p>
              </div>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Code</mat-label>
                <input (keypress)="numberOnly($event)"
                       [maxLength]="12"
                       [minLength]="1"
                       autocomplete="off"
                       formControlName="code"
                       id="code"
                       matInput
                       type="text"
                >
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>New Password</mat-label>
                <input [type]="hidePassword ? 'password' : 'text'"
                       autocomplete="on"
                       formControlName="password"
                       id="verify__newPassword"
                       matInput
                       type="password"
                >
                <button (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePassword"
                        mat-icon-button
                        matSuffix type="button">
                  <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Confirm Password</mat-label>
                <input [type]="hideConfirmPassword ? 'password' : 'text'"
                       autocomplete="on"
                       formControlName="confirm_password"
                       id="account_confirm_password"
                       matInput
                       type="password"
                >
                <button (click)="hideConfirmPassword = !hideConfirmPassword"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideConfirmPassword"
                        mat-icon-button
                        matSuffix
                        type="button">
                  <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div *ngIf="confirmPasswordField!.touched && confirmPasswordField!.valid">
                <mat-error *ngIf="form.hasError('match_password')">
                  Password does not match, please check!
                </mat-error>
              </div>
              <div *ngIf="passwordField!.touched && passwordField!.invalid">
                <mat-error *ngIf="passwordField!.hasError('minlength')">
                  Must be at least 6 characters!
                </mat-error>
                <mat-error *ngIf="passwordField!.hasError('has_not_number')">
                  Must contain at least 1 number!
                </mat-error>
                <mat-error *ngIf="passwordField!.hasError('has_not_upper_case')">
                  Must contain at least 1 in Capital Case!
                </mat-error>
                <mat-error *ngIf="passwordField!.hasError('has_not_lower_case')">
                  Must contain at least 1 Letter in Small Case!
                </mat-error>
                <mat-error *ngIf="passwordField!.hasError('has_not_special_character')">
                  Must contain at least 1 Special Character!
                </mat-error>
              </div>
            </div>
            <div class="container-verify-confirmation-code--submit">
              <button (click)="submit()" mat-raised-button
                      [ngStyle]="{'background':'#005eac'}"
                      type="submit">CHANGE PASSWORD</button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>

</div>

