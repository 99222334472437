import {Component, Input} from '@angular/core';
import {UntypedFormGroup,} from "@angular/forms";
import {
  SearchBusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {REQUEST} from "@features/product/model/utility/config/constants/popup-message.const";
import {BusinessPartnerService} from "@features/product-offer/services/business-partner.service";
import {
  BusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/business-partner.dto";
import {BusinessPartnerType} from "@shared/model/enum/business-partner-type";
import {UserService} from "@shared/services/user.service";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-find-customer',
  templateUrl: './find-customer.component.html',
  styleUrls: ['./find-customer.component.scss']
})
export class FindCustomerComponent {
  @Input()
  searchVendorForm!: UntypedFormGroup;
  businessPartnerList: BusinessPartnerDto[] = [];

  constructor(private businessPartner: BusinessPartnerService,
              private userService: UserService,
              private authService: AuthService) {
  }

  get searchType() {
    return this.searchVendorForm.get('search_type');
  }

  get searchValue() {
    return this.searchVendorForm.get('search_value');
  }

  get vendorField() {
    return this.searchVendorForm.get('vendor');
  }

  getBusinessPartner() {
    this.businessPartnerList = [];
    this.authService._getFromPayload("id_user").then(userId => {
      this.userService.getById(userId).subscribe({
        next: value => {
          let ownerCode = UserCustomizedUtil.findBuyerOwnerCode(value.userCustomized);
          this.callBusinessPartner(ownerCode?.userCustomizedValue);
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    })


  }

  callBusinessPartner(ownerCode: string | undefined) {
    console.log(ownerCode)
    if (ownerCode) {
      let searchBusinessPartnerDto = new SearchBusinessPartnerDto();
      searchBusinessPartnerDto.searchType = this.searchType?.value;
      searchBusinessPartnerDto.value = this.searchType?.value == 'cardCode' ? String(this.searchValue?.value).toUpperCase() : this.searchValue?.value;
      searchBusinessPartnerDto.userType = BusinessPartnerType.CUSTOMER;
      searchBusinessPartnerDto.ownerCode = Number.parseInt(ownerCode);

      this.businessPartner.getBusinessPartner(searchBusinessPartnerDto)
      .subscribe({
        next: result => {
          if (result && result.length > 0) {
            this.businessPartnerList = result;
          } else {
            showSomethingWrongPopup1(TITLE_INFO, REQUEST.DEFAULT_MESSAGE_RESPONSE.CUSTOMER_NOT_FOUND);
          }
        }, error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    } else {
      showSomethingWrongPopup1(TITLE_INFO, REQUEST.DEFAULT_MESSAGE_RESPONSE.CUSTOMER_NOT_FOUND)
    }

  }

  selectBusinessPartner(businessPartner: BusinessPartnerDto) {
    this.businessPartnerList = [];
    this.vendorField?.setValue(businessPartner);
  }
}
