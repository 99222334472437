import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env";
import {OmnisedTypesDto} from "@features/product-offer/model/notifications/OmnisedTypes.dto";


@Injectable({
  providedIn: 'root'
})
export class OmnisedTypesService {

  controls: any;
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private notificationsCustomerDealsUrl = `${this.automatePurchasesUrl}notifications-omnised/list/segments`;

  constructor(private httpClient: HttpClient) {
  }

  notificationsCustomerDeals(): Observable<OmnisedTypesDto[]> {
    return this.httpClient.get<OmnisedTypesDto[]>(this.notificationsCustomerDealsUrl)
  }

}
