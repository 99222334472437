import Swal from 'sweetalert2';

const TITLE_ERROR = 'ERROR';
const TITLE_SUCCESS = 'SUCCESS';
const TITLE_SOMETHING_WRONG = 'SOMETHING IS WRONG';


const showErrorPopup = (title: string, message: string) => {
  return showPopUp(title, message, 'assets/popup/icon-error.svg');
}

const showSuccessPopup = (title: string, message: string) => {
  return showPopUp(title, message, 'assets/popup/icon-success.svg');
}
const showSomethingWrongPopup1 = (title: string, message: string) => {
  return showPopUp(title, message, 'assets/popup/icon-general-information.svg');
}
const showSomethingWrongPopup2 = (title: string, message: string) => {
  return showPopUp(title, message, 'assets/popup/icon-wrong.svg');
}

const showPopUp = (title: string, message: string, iconUrl: string) => {
  let template =
    `<div class="popup-container row">
      <div class="col-3">
        <img  class="popup-container--image"
              src="${iconUrl}"
              alt="Card image"
              height="118"
             width="114">
      </div>
      <div class="col-8">
        <h4 class="popup-container--title">${title}</h4>
        <p class="popup-container--text">${message}</p>
        <div class="popup-container--actions">

        </div>
      </div>
    </div>`;
  return Swal.fire({
    html: template,
    width: '750px',
    background: '#fff',
    showCloseButton: false,
    showConfirmButton: true,
    confirmButtonText: 'CONFIRM',
    customClass: {
      confirmButton: "btn popup-container--actions__button",
    },
    buttonsStyling: false
  })
}

const confirmPopup = (title: string, text: string, confirmTextButton?: string, denyTextButton?: string) => {
  return Swal.fire({
    title: title,
    text: text,
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: confirmTextButton ?? 'Confirm',
    denyButtonText: denyTextButton ?? `Cancel`,
    confirmButtonColor: '#005eac',
    denyButtonColor: '#333333',
    customClass: {
      popup: 'sweet-customized-popup',
      actions: 'sweet-customized-actions',
      confirmButton: 'sweet-customized-confirm-button',
    },
  })
}

const confirmPopupHML = (title: string, messageHtml: string, confirmTextButton?: string, denyTextButton?: string) => {
  const iconUrl = 'assets/icon/IconGeneralInformation.png';
  const template = `
    <div class="approval-popup-container" id="approval-popup-container">
      <div class="approval-popup-icon" id="approval-popup-icon">
        <img src="${iconUrl}" alt="Icon" id="approval-popup-icon-img" />
      </div>
      <div class="approval-popup-content" id="approval-popup-content">
        <h4 class="approval-popup-title" id="approval-popup-title">${title}</h4>
        <div id="approval-popup-message">${messageHtml}</div>
        <p class="approval-popup-question" id="approval-popup-question">Are you sure you want to continue?</p>
      </div>
    </div>
  `;

  return Swal.fire({
    html: template,
    width: '700px',
    showDenyButton: true,
    showConfirmButton: true,
    confirmButtonText: confirmTextButton ?? 'Confirm',
    denyButtonText: denyTextButton ?? 'Cancel',
    confirmButtonColor: '#005eac',
    denyButtonColor: '#005eac',
    customClass: {
      popup: 'sweet-customized-popup',
      actions: 'sweet-customized-actions',
      confirmButton: 'sweet-customized-confirm-button',
      denyButton: 'sweet-customized-deny-button',
    },
  });
}

const textAreaPopup = (text: string, confirmText?: string, cancelText?: string) => Swal.fire({
  input: 'textarea',
  text: text,
  inputPlaceholder: '',
  width: '600px',
  showCancelButton: true,
  cancelButtonText: cancelText ?? 'Cancel',
  cancelButtonColor: '#333333',
  confirmButtonText: confirmText ?? 'Confirm',
  confirmButtonColor: '#005eac',
  inputAttributes: {
    'aria-label': 'Type your message here',
    maxLength: '199'
  },

})
export {
  TITLE_ERROR,
  TITLE_SUCCESS,
  TITLE_SOMETHING_WRONG,
  showErrorPopup,
  showSuccessPopup,
  showPopUp,
  showSomethingWrongPopup1,
  showSomethingWrongPopup2,
  confirmPopup,
  confirmPopupHML,
  textAreaPopup
}
