import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class LoginAuthService {

  constructor(private http: HttpClient) {
  }

  validateToken(token: string): Observable<any> {
    return new Observable(subscriber => {
      subscriber.next(1);
    });
  }
}
