import {Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-send-confirmation-code',
  templateUrl: './send-confirmation-code.component.html',
  styleUrls: ['./send-confirmation-code.component.scss']
})
export class SendConfirmationCodeComponent {
  form: UntypedFormGroup;
  @Output() sendCode = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.buildForm();
  }

  get emailField() {
    return this.form.get('email');
  }

  buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
      ],
    });
  }

  submit() {
    if (this.form.valid) {
      this.sendCode.emit(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
