import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO, TITLE_SUCCESS} from "@shared";
import {DateUtil} from "@shared/utility/dateUtil";
import {OfferValidationService} from "@features/product-offer/services/offer-validation.service";
import {GlobalConstants} from "@shared/utility/global-constants";
import {ProductOfferDto} from "@features/product-offer/model/offer-validation/ProductOffer.dto";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {
  LimitedTimeOfferDto
} from "@features/product-offer/model/offer-validation/limitedTimeOffer.dto";
import {CustomValidator} from "@shared/utility/validators/validators";
import {
  ProductOfferValidator
} from "@features/product-offer/utility/validator/productOfferValidator";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";
import {ItemCustomizedUtil} from "@features/product-offer/utility/validator/ItemCustomizedUtil";

@Component({
  selector: 'app-update-deal-offer-main',
  templateUrl: './update-deal-offer-main.component.html',
  styleUrls: ['./update-deal-offer-main.component.scss']
})
export class UpdateDealOfferMainComponent implements AfterViewInit {

  specialDealPeriodSetupForm: UntypedFormGroup;
  dealPeriodSetupForm: UntypedFormGroup;
  moqSetupForm: UntypedFormGroup;
  product!: ProductsDto;

  productOfferDto!: ProductOfferDto;

  itemId!: number;

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly authService: AuthService,
              private readonly offerValidationService: OfferValidationService,
              public dialog: MatDialog,
              private readonly cdr: ChangeDetectorRef) {
    this.specialDealPeriodSetupForm = this.buildSpecialDealPeriodSetupForm();
    this.dealPeriodSetupForm = this.buildDealPeriodSetupForm();
    this.moqSetupForm = this.buildMoqSetupFormForm();
  }

  get hasLimitValidPeriodField() {
    return this.specialDealPeriodSetupForm.get('has_limit_valid_period');
  }

  get subjectToUnsoldField() {
    return this.dealPeriodSetupForm.get('subject_to_unsold');
  }

  get dealPeriodDateStartField() {
    return this.dealPeriodSetupForm.get('deal_period_date_start');
  }

  get dealPeriodDateEndField() {
    return this.dealPeriodSetupForm.get('deal_period_date_end');
  }

  get dealPeriodHourStartField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_start');
  }

  get dealPeriodHourEndField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_end');
  }


  get dealPeriodTierFourField() {
    return this.dealPeriodSetupForm.get('tierFour');
  }

  get minOrderQuantityField() {
    return this.moqSetupForm.get('min_order_quantity');
  }

  get isHotOfferField() {
    return this.moqSetupForm.get('isHotOffer');
  }

  get isClearanceOfferField() {
    return this.moqSetupForm.get('isClearanceOffer');
  }

  get selectMinQuantityField() {
    return this.moqSetupForm.get('select_min_quantity');
  }

  ngAfterViewInit() {
    if (this.productOfferDto) {
      console.log(this.productOfferDto)
      this.itemId = this.productOfferDto.item.id;
      this.specialDealPeriodSetupForm.patchValue({
        has_limit_valid_period: this.productOfferDto.limitPeriod
      })
      let startDate = DateUtil.momentDate(this.productOfferDto.initialDate)
      let endDate = DateUtil.momentDate(this.productOfferDto.endDate)
      this.dealPeriodSetupForm.patchValue({
        deal_period_date_start: this.productOfferDto.initialDate ? startDate.toDate() : null,
        deal_period_date_end: this.productOfferDto.endDate ? endDate.toDate() : null,
        subject_to_unsold: this.productOfferDto.hasUnsoldLimit,
        deal_period_hour_start: this.productOfferDto.initialDate ? DateUtil.formatDate(startDate, 'hh:mm') : null,
        deal_period_hour_end: this.productOfferDto.endDate ? DateUtil.formatDate(endDate, 'hh:mm') : null,
        tierFour: this.productOfferDto.tierFour
      })
      let orderMin = this.productOfferDto.orderMin;
      let clearanceOffer = ItemCustomizedUtil.clearanceOffer(this.productOfferDto.item.itemCustomized);
      this.moqSetupForm.patchValue({
        min_order_quantity: orderMin != null,
        select_min_quantity: orderMin ? orderMin : '',
        isHotOffer: this.productOfferDto.isHotOffer,
        isClearanceOffer: clearanceOffer && clearanceOffer == 'Yes',
      })

      this.specialDealPeriodSetupForm.markAllAsTouched();
      this.dealPeriodSetupForm.markAllAsTouched();
      this.moqSetupForm.markAllAsTouched();

      // Manually trigger change detection
      this.cdr.detectChanges();
    }
  }

  saveOfferValidation() {
    this.authService._getFromPayload("id_user").then(userId => {
      this.offerValidationService.updateOfferValidation(this.createOfferValidation(userId))
      .subscribe({
        next: (createOfferValidationResponse: any) => {
          if (createOfferValidationResponse && !createOfferValidationResponse.status) {
            showSomethingWrongPopup1(TITLE_INFO, createOfferValidationResponse.message);
          } else {
            showSuccessPopup(TITLE_SUCCESS, createOfferValidationResponse.message)
            .then(() => this.dialog.closeAll())
          }
        },
        error: (err: any) => {
          console.log(err);
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    });
  }

  createOfferValidation(userId: any) {
    let limitedTimeOfferDto = new LimitedTimeOfferDto();
    if (this.productOfferDto && this.productOfferDto.id) {
      limitedTimeOfferDto.id = this.productOfferDto.id;
    }
    limitedTimeOfferDto.itemId = this.itemId;
    limitedTimeOfferDto.limitPeriod = this.hasLimitValidPeriodField?.value
    limitedTimeOfferDto.creationUser = userId;
    limitedTimeOfferDto.isHotOffer = this.isHotOfferField?.value;
    limitedTimeOfferDto.isClearance =  this.isClearanceOfferField?.value;
    if (this.hasLimitValidPeriodField?.value) {
      limitedTimeOfferDto.tierOne = Number(this.dealPeriodTierFourField?.value);
      limitedTimeOfferDto.tierTwo = Number(this.dealPeriodTierFourField?.value);
      limitedTimeOfferDto.tierThree = Number(this.dealPeriodTierFourField?.value);
      limitedTimeOfferDto.tierFour = Number(this.dealPeriodTierFourField?.value);
      limitedTimeOfferDto.hasUnsoldLimit = this.subjectToUnsoldField?.value;
      let hourInitial = this.dealPeriodHourStartField?.value;
      let hourEnd = this.dealPeriodHourEndField?.value;
      limitedTimeOfferDto.initialDate = this.dealPeriodDateStartField?.value;
      limitedTimeOfferDto.endDate = this.dealPeriodDateEndField?.value;
      limitedTimeOfferDto.initialHour = Number(hourInitial.substring(0, 2));
      limitedTimeOfferDto.endHour = Number(hourEnd.substring(0, 2));
    }
    if (this.minOrderQuantityField?.value) {
      limitedTimeOfferDto.orderMin = this.selectMinQuantityField?.value;
    }

    return {
      offerStartingPrice: null,
      limitedTimeOffer: limitedTimeOfferDto,
    }
  }

  requiredMinQuantity(control: AbstractControl) {
    let selectMinQuantity = control.get('select_min_quantity')?.value;
    let minOrderQuantity = control.get('min_order_quantity')?.value;
    if (minOrderQuantity == "true" && !selectMinQuantity) {
      return {required_min_order_quantity: true};
    }
    if (minOrderQuantity == "true" && selectMinQuantity) {
      let hasDecimal = GlobalConstants.PATTER_ONLY_DECIMAL.test(selectMinQuantity);
      if (!hasDecimal) {
        return {not_matched_decimal_pattern: true};
      }
    }

    return null;
  }

  private buildSpecialDealPeriodSetupForm() {
    return this.formBuilder.group({
      has_limit_valid_period: ['', [Validators.required]]
    });
  }

  private buildDealPeriodSetupForm() {
    return this.formBuilder.group({
      deal_period_date_start: ['', [Validators.required]],
      deal_period_date_end: ['', [Validators.required]],
      subject_to_unsold: [],
      deal_period_hour_start: ['', [Validators.required]],
      deal_period_hour_end: ['', [Validators.required]],
      tierFour: ['', [Validators.required, CustomValidator.validateDecimal, Validators.min(1)]],
    }, {
      validators: [
        ProductOfferValidator.offerValidationDateComparison
        , ProductOfferValidator.timeValidator]
    });
  }

  private buildMoqSetupFormForm() {
    return this.formBuilder.group({
      min_order_quantity: ['', [Validators.required]],
      select_min_quantity: [''],
      isHotOffer: [false],
      isClearanceOffer: [false],
    }, {
      validators: this.requiredMinQuantity
    });
  }
}
