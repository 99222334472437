import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {Category} from "@core/model/category";
import {ShareDataService} from "@shared/services/share-data.service";
import {ProductProcessType} from "@features/product/model/enum/product-process-type";
import {mergeMap, Observable, of, Subject} from "rxjs";
import {ItemSpecificationsDto} from "@features/product/model/dto/item/itemSpecifications.dto";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductService} from "@features/product/services/product.service";
import {Specification} from "@features/product/model/specification";
import {ProductMiscTypes} from "@shared/model/product-misc-types";
import {ProductMiscTypeService} from "@shared/services/product-misc-type.service";
import {ProductInfoFormService} from "@features/product/features/presentation/product-info/product-info-form.service";


@Component({
  selector: 'app-product-specifications',
  templateUrl: './product-specifications.component.html',
  styleUrls: ['./product-specifications.component.css']
})
export class ProductSpecificationsComponent implements AfterViewInit, OnInit {
  @Input() productInfoForm!: FormGroup;
  specifications: Specification[] = [];
  @Input() showSpecifications!: boolean;
  @Input() product!: ProductsDto;
  arrayCategories: Category[] = [];
  options: ProductMiscTypes[] = [];
  filteredOptions: Observable<ProductMiscTypes[]> = new Observable<ProductMiscTypes[]>();
  productSpecifications: ItemSpecificationsDto[] = [];
  @Input() productProcessType!: ProductProcessType;
  colors: ProductMiscTypes[] = [];
  regions: ProductMiscTypes[] = [];
  specs: ProductMiscTypes[] = [];
  warrantyPeriods: ProductMiscTypes[] = [];
  subcategoryTwos: ProductMiscTypes[] = [];
  protected _onDestroy = new Subject<void>();

  constructor(
    private productService: ProductService,
    private shareDataService: ShareDataService,
    private productMiscTypes: ProductMiscTypeService,
    private productInfoFormService: ProductInfoFormService
  ) {
  }

  get specificationsField() {
    return this.productInfoForm.get('specifications') as FormArray;
  }

  ngOnInit() {
    this.productMiscTypes.getMiscTypesSpecifications('items_specifications', 'subcategorytwo')
    .subscribe(result => (this.subcategoryTwos = result));
    this.productMiscTypes.getMiscTypesSpecifications('items_specifications', 'regionrestrictions')
    .subscribe(result => (this.regions = result));
    this.productMiscTypes.getMiscTypesSpecifications('items_specifications', 'specs')
    .subscribe(result => (this.specs = result));
    this.productMiscTypes.getMiscTypesSpecifications('items_specifications', 'warrantyperiod')
    .subscribe(result => (this.warrantyPeriods = result));

    this.productSpecifications = this.product.itemSpecifications;
  }

  ngAfterViewInit(): void {
    this.shareDataService.currentMessage.pipe(
      mergeMap((message: any) => {
        if (message.recallSpecifications != null && message.recallSpecifications) {
          this.setAllSpecsEmpty();
          this.getArrayCategories();
        }
        return of(this.arrayCategories);
      })).pipe(mergeMap((response: Category[]) => {
      return this.productService.getSpecifications(response);
    })).subscribe({
      next: (responseSpecifications: any) => {
        if (responseSpecifications.body && responseSpecifications.body.length > 0) {
          this.specifications = this.sortList(responseSpecifications.body);
          this.specifications.forEach((spec) => {
            if (spec.spec_code == 'color' && this.product && this.product.color) {
              this.buildSpecifications(spec.spec_code, this.product.color);
            } else {
              this.buildSpecifications(spec.spec_code);
            }
          });

          if (this.productProcessType == ProductProcessType.PRODUCT_UPDATE) {
            this.setValueToSpecs();
          }

          this.showSpecifications = true;

        } else {
          this.setAllSpecsEmpty();
        }
      }
    });
  }

  setValueToSpecs() {
    this.specificationsField.controls.forEach(control => {
      let code = control.get('code');
      let findSpec = this.productSpecifications.find(productSpec => productSpec.specifications.toString() == code?.value);
      if (findSpec) {
        control.get('value')?.setValue(findSpec.descriptionItemSpecific);
      }
    });
  }

  _filter(value: string, specification: Specification) {
    this.filteredOptions = of([]);
    if (value?.length < 3 && specification.spec_code === 'specs' || specification.spec_code === 'warrantyperiod') {
      this.callFilterOptions(value, specification);
    }
    if (value?.length >= 3) {
      this.callFilterOptions(value, specification);
    }
  }

  getSpecificationFieldAt(position: number) {
    return this.specificationsField.at(position);
  }

  setAllSpecsEmpty() {
    this.removeAllSpecifications();
    this.specifications = [];
    this.arrayCategories = [];
  }

  buildSpecifications(code: string, value?: string, isRequired: boolean = false) {
    this.specificationsField.push(this.productInfoFormService.createSpecificationsField(code, value, isRequired));
  }

  removeSpecifications(position: number) {
    this.specificationsField.removeAt(position);
  }

  removeAllSpecifications() {
    this.specificationsField.clear();
    this.specificationsField.controls = [];
    this.product.itemSpecifications = [];
  }

  removeFilteredOptions() {
    this.filteredOptions = of([]);
  }

  getArrayCategories() {
    this.arrayCategories = [];
    let category = this.productInfoForm.get('category')?.value;
    this.arrayCategories.push(category);

    this.productInfoForm.get('sub_categories')?.value.forEach((item: any) => {
      this.arrayCategories.push(item.sub_category);
    });
  }

  private sortList(specifications: Specification[]): Specification[] {
    return specifications.sort(
      (a, b) =>
        a.item_required > b.item_required ? -1
          : Number(a.item_required < b.item_required)
    );
  }

  private callFilterOptions(value: string, specification: Specification) {
    this.productMiscTypes.getMiscTypesSpecifications('items_specifications', specification.spec_code)
    .pipe(mergeMap((result: ProductMiscTypes[]) => {
      return of(result);
    })).subscribe({
      next: (finalResult: ProductMiscTypes[]) => {
        const filterValue = value.toLowerCase();
        let filter = finalResult.filter((option: ProductMiscTypes) => option.ditmmsty_value.toLowerCase().includes(filterValue));
        this.filteredOptions = of(filter);
      }
    });
  }
}
