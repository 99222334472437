import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule} from "@angular/material/dialog";
import {AmplifyService} from "@shared/services/amplify/amplify.service";
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {LoaderComponent} from './components/loader/loader.component';
import {RouterModule} from "@angular/router";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from "ngx-permissions";
import {SortByOrderAscPipe} from "@shared/pipes/sort-by-order-asc.pipe";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {ItemCategoriesComponent} from './components/item-categories/item-categories.component';
import {DaAdminMenuComponent} from './components/header/da-admin-menu/da-admin-menu.component';
import {DaAnalyticsMenuComponent} from './components/header/da-admin-menu/da-analytics-menu/da-analytics-menu.component';
import {DaProductsAndOffersMenuComponent} from './components/header/da-admin-menu/da-products-and-offers-menu/da-products-and-offers-menu.component';
import {DaRequestsMenuComponent} from './components/header/da-admin-menu/da-requests-menu/da-requests-menu.component';
import {DaMarketplaceMenuComponent} from './components/header/da-admin-menu/da-marketplace-menu/da-marketplace-menu.component';
import {DaOrdersMenuComponent} from './components/header/da-admin-menu/da-orders-menu/da-orders-menu.component';
import {DaCustomersMenuComponent} from './components/header/da-admin-menu/da-customers-menu/da-customers-menu.component';
import {DaMyAccountMenuComponent} from './components/header/da-admin-menu/da-my-account-menu/da-my-account-menu.component';
import {DaServiceDeskMenuComponent} from './components/header/da-admin-menu/da-service-desk-menu/da-service-desk-menu.component';
import {DaEcommerceMenuComponent} from './components/header/da-admin-menu/da-ecommerce-menu/da-ecommerce-menu.component';
import {HeaderMenuComponent} from './components/header/header-menu/header-menu.component';
import {FilterBusinessPartnerComponent} from './components/filter-business-partner/filter-business-partner.component';
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NgxLazyMatSelectModule} from "ngx-lazy-mat-select";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {PdfReaderComponent} from './components/pdf-reader/pdf-reader.component';
import {ChangeLogComponent} from './components/change-log/change-log.component';
import {MatTableModule} from "@angular/material/table";
import {ChangeLogDetailComponent} from './components/change-log/change-log-detail/change-log-detail.component';
import {FilterCustomersCardCodeComponent} from './components/filter-customers-card-code/filter-customers-card-code.component';
import {FilterCustomersNameComponent} from './components/filter-customers-name/filter-customers-name.component';
import {MonthYearPickerComponent} from "@shared/components/month-year-picker/month-year-picker.component";


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    SortByOrderAscPipe,
    ItemCategoriesComponent,
    DaAdminMenuComponent,
    DaAnalyticsMenuComponent,
    DaProductsAndOffersMenuComponent,
    DaRequestsMenuComponent,
    DaMarketplaceMenuComponent,
    DaOrdersMenuComponent,
    DaCustomersMenuComponent,
    DaMyAccountMenuComponent,
    DaServiceDeskMenuComponent,
    DaEcommerceMenuComponent,
    HeaderMenuComponent,
    FilterBusinessPartnerComponent,
    PdfReaderComponent,
    ChangeLogComponent,
    ChangeLogDetailComponent,
    FilterCustomersCardCodeComponent,
    FilterCustomersNameComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    NgxPermissionsModule,
    NgxPermissionsRestrictStubModule,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    NgOptimizedImage,
    MatCardModule,
    MatDatepickerModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxLazyMatSelectModule,
    PdfViewerModule,
    MatDialogModule,
    MatTableModule,
    MonthYearPickerComponent,
  ],
  providers: [AmplifyService, {provide: MAT_DIALOG_DATA, useValue: {}},],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SortByOrderAscPipe,
    ItemCategoriesComponent,
    NgxPermissionsModule,
    HeaderMenuComponent,
    FilterBusinessPartnerComponent,
    PdfReaderComponent,
    FilterCustomersCardCodeComponent,
    FilterCustomersNameComponent,
    MonthYearPickerComponent,
  ]
})
export class SharedModule {
}
