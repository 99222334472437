<form [formGroup]="productInfoForm">
  <mat-label>
    <h3><strong>Specifications</strong></h3>
  </mat-label>

  <div class="row" style="width: 90%; margin: 0 auto" formArrayName="specifications">
    <ng-container *ngFor="let spec of specifications; let i=index" style="padding: 1.0rem">
      <ng-container [formGroupName]="i">
        <div class="col-8">
          <h3>
            <strong style="font-size: 1rem">
              {{spec.spec_description}}
              <span *ngIf="spec.item_required === 1" style="color: #ff5454; ">*</span>
              :
            </strong>
          </h3>
        </div>
        <div class="col-4">
          <mat-form-field appearance="fill">
            <mat-label>{{spec.spec_description}} Value</mat-label>

            <ng-container *ngIf="['subcategorytwo','regionrestrictions','specs','warrantyperiod']
                                                .includes(spec.spec_code)">
              <mat-select *ngIf="['subcategorytwo']
                                                .includes(spec.spec_code)" formControlName="value"
                          required>
                <mat-option *ngFor="let subcategoryTwo of subcategoryTwos"
                            [value]="subcategoryTwo.ditmmsty_value">
                  {{subcategoryTwo.ditmmsty_value}}
                </mat-option>
              </mat-select>

              <mat-select *ngIf="['regionrestrictions']
                                                .includes(spec.spec_code)" formControlName="value"
                          required>
                <mat-option *ngFor="let region of regions" [value]="region.ditmmsty_value" required>
                  {{region.ditmmsty_value}}
                </mat-option>
              </mat-select>
              <mat-select *ngIf="['specs']
                                                .includes(spec.spec_code)" formControlName="value"
                          required>
                <mat-option *ngFor="let spec of specs" [value]="spec.ditmmsty_value">
                  {{spec.ditmmsty_value}}
                </mat-option>
              </mat-select>
              <mat-select *ngIf="['warrantyperiod']
                                                .includes(spec.spec_code)" formControlName="value"
                          required>
                <mat-option *ngFor="let period of warrantyPeriods" [value]="period.ditmmsty_value">
                  {{period.ditmmsty_value}}
                </mat-option>
              </mat-select>
            </ng-container>

            <ng-container *ngIf="!([ 'subcategorytwo','regionrestrictions','specs','warrantyperiod']
                                                .includes(spec.spec_code))">
              <input (focus)="removeFilteredOptions()"
                     (ngModelChange)="_filter($event,spec)"
                     [matAutocomplete]="auto"
                     [required]="spec.item_required === 1"
                     formControlName="value"
                     matInput
                     type="text"
              >
              <mat-error *ngIf="specificationsField.at(i).get('value')?.hasError('invalid')">
                {{specificationsField.at(i).get('value')?.getError('invalid')}}
              </mat-error>
            </ng-container>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptions | async"
                          [value]="option.ditmmsty_value">
                {{option.ditmmsty_value}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>
