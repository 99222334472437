import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "@features/home/home.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {
  StatsRequestBuyerComponent
} from "@features/stats/presentation/stats-request-buyer/stats-request-buyer.component";
import {
  StatsRequestSalesComponent
} from "@features/stats/presentation/stats-request-sales/stats-request-sales.component";
import {ngxPermissionsGuard} from "ngx-permissions";
import { ADMIN_USER, REQUESTS} from "@shared/config/constants/menu-roles.const";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'buyers',
    canActivate: [ngxPermissionsGuard],
    component: StatsRequestBuyerComponent,
    data: {
      permissions: {
        only: [REQUESTS.BUYERS_STATS, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'sales',
    canActivate: [ngxPermissionsGuard],
    component: StatsRequestSalesComponent,
    data: {
      permissions: {
        only: [REQUESTS.SALES_STATS, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class StatsRoutingModule {
}
