import {Component, Input} from '@angular/core';
import {ChangelogDetailDto} from "@shared/model/dto/change-log/ChangelogDetail.dto";

@Component({
  selector: 'app-change-log-detail',
  templateUrl: './change-log-detail.component.html',
  styleUrls: ['./change-log-detail.component.scss']
})
export class ChangeLogDetailComponent {

  @Input()
  changeLogDetail: ChangelogDetailDto[] = [];
  displayedColumns: string[] = ['date','changeDetail','previousValue','newValue'];
}
