import { Injectable } from '@angular/core';
import {environment} from "@env";
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {catchError} from "rxjs/operators";
import {UserSupplierDto} from "@shared/model/dto/user/UserSupplier.dto";

@Injectable({
  providedIn: 'root'
})
export class DaUserService {



  private apiUrl = environment.oauthUrl;
  private apiAutomatePurchaseUrl = environment.automatePurchasesUrl;
  private findAllBuyersUrl = `${this.apiUrl}user/buyers`;
  private findAllSalesPersonForDashboardUrl = `${this.apiUrl}user/salesperson/for/dashboard`;
  private findUserUrl = `${this.apiUrl}user/get`;
  private getAllUserSuppliersUrl = `${this.apiAutomatePurchaseUrl}user/supplier/by/owner/`;
  private findAllSalesPersonUrl = `${this.apiUrl}user/salesperson`;

  constructor(private http: HttpClient) {
  }

  findUser(): Observable<DAUserDto>{
    return this.http.get<any>(this.findUserUrl);
  }

  findAllBuyers(): Observable<DAUserDto[]> {
    return this.http.get<any>(this.findAllBuyersUrl).pipe(
      map((result: any) => {
        let items: DAUserDto[] = [];
        if (result) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  findAllSalesPersonForDashboard(): Observable<DAUserDto[]> {
    return this.http.get<any>(this.findAllSalesPersonForDashboardUrl).pipe(
      map((result: any) => {
        let items: DAUserDto[] = [];
        if (result) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }
  findAllSalesPerson(): Observable<DAUserDto[]> {
    return this.http.get<any>(this.findAllSalesPersonUrl).pipe(
      map((result: any) => {
        let items: DAUserDto[] = [];
        if (result) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }


  getAllUserSuppliers(owner: string | null): Observable<UserSupplierDto[]>{
    return this.http.get<UserSupplierDto[]>(this.getAllUserSuppliersUrl + owner);
  }

}
