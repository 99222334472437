<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <form [formGroup]="form">
        <div class="row">
          <mat-label>
            <h3><strong>Product Search</strong></h3>
          </mat-label>
          <div class="col-12">
            <mat-radio-group aria-label="Select an option"
                             class="col-12 radio_group_type_search_product"
                             formControlName="type_search_product">
              <mat-radio-button class="radio_group_type_search_product" color="primary" value="sku">
                SKU
              </mat-radio-button>
              <mat-radio-button class="radio_group_type_search_product" color="primary"
                                value="partNumber">
                Part Number
              </mat-radio-button>
              <mat-radio-button class="radio_group_type_search_product" color="primary" value="upc">
                UPC
              </mat-radio-button>
              <mat-radio-button class="radio_group_type_search_product" color="primary" value="ean">
                EAN
              </mat-radio-button>
              <mat-radio-button class="radio_group_type_search_product" color="primary"
                                value="item_description">
                Item Description
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-12">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Search</mat-label>
              <input autocomplete="on"
                     formControlName="product_search_value"
                     matInput
                     required
                     type="text"
              >
            </mat-form-field>
            <mat-error *ngIf="productSearchValue?.hasError('validate_length')">
              Check the number of characters for UPC 12 for EAN 13
            </mat-error>
          </div>
          <div class="col-12">
            <button
              (click)="searchData()"
              [disabled]="typeSearchProduct?.invalid || this.productSearchValue?.invalid"
              color="primary"
              mat-raised-button
            > Search
            </button>
            <button (click)="notProduct()"
                    mat-raised-button
                    matStepperNext>
              It's not listed, I want to add a new Item
            </button>
          </div>

          <div *ngIf="products.length >0" class="row">
            <div *ngFor="let product of products">
              <mat-card class="product_custom">
                <mat-card-header>
                  <mat-card-title>
                    {{product.itemDescription}}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="row">
                    <div class="col-4">
                      <img [src]="product.image" alt="item image" height="200px" width="200px">
                    </div>
                    <div class="col-8">
                      <p *ngIf="product.sku"><strong>SKU:</strong> {{product.sku}} </p>
                      <p><strong>Manufacturer:</strong> {{product.manufacturers
                        ? product.manufacturers.description : ''}}</p>
                      <p><strong>Model:</strong>{{product.model ?? ''}}</p>
                      <p><strong>Part Number:</strong>{{product.partNumber ?? product.partNumber }}
                      </p>
                      <p><strong>Condition:</strong>{{product.condition ?? ''}}</p>
                      <p><strong>Category: </strong>{{product.itemCategories.description ?? ''}}</p>
                      <p *ngIf="eanField(product)"><strong>EAN:</strong>{{eanField(product)}}</p>
                      <p *ngIf="upcField(product)"><strong>UPC:</strong>{{upcField(product)}}</p>
                      <ul>
                        <li>
                          <strong>Tech specs:</strong>
                          <div *ngFor="let spec of product.itemSpecifications">
                            <ul>
                              <div>
                                <li>
                                  <strong>{{spec.specifications}}</strong>:{{spec.descriptionItemSpecific}}
                                </li>
                              </div>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </mat-card-content>
                <div class="text-center">
                  <button (click)="selectProduct(product)" color="primary" mat-raised-button>
                    Select
                  </button>
                </div>
              </mat-card>

            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
