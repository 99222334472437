import { Component } from '@angular/core';
import {SapUserType} from "@shared/model/enum/sap-user-type";

@Component({
  selector: 'app-test-filters',
  templateUrl: './test-filters.component.html',
  styleUrls: ['./test-filters.component.scss']
})
export class TestFiltersComponent {

    protected readonly SapUserType = SapUserType;

  filterDashBoard($event: any) {
    console.log($event)
  }
}
