import { Component } from '@angular/core';
import {AuthService} from "@core/services";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {
  userName: string = '';
  isAuthenticated: boolean | undefined = false;


  constructor(private authService:AuthService,private router: Router) {
  }


  ngOnInit(): void {
    this.authService.isAuthenticated().then(result => {
      this.isAuthenticated = result;
      if (result){
        this.authService._getFromPayload("username")
          .then(username => {
            this.userName = username;
          });
      }else {
        this.router.navigate(['login'])
      }
    })
  }

  logout() {
    this.authService.logout();
  }

}
