<div class="row">
  <table [dataSource]="data" cdk-table>
    <ng-container cdkColumnDef="docNum">
      <th *cdkHeaderCellDef cdk-header-cell>DocNum</th>
      <td *cdkCellDef="let row" cdk-cell> {{row.docNum}} </td>
    </ng-container>

    <ng-container cdkColumnDef="sku">
      <th *cdkHeaderCellDef cdk-header-cell>SKU</th>
      <td *cdkCellDef="let row" cdk-cell> {{row.sku}} </td>
    </ng-container>

    <ng-container cdkColumnDef="item_description">
      <th *cdkHeaderCellDef cdk-header-cell>Item Description</th>
      <td *cdkCellDef="let row" cdk-cell> {{row.itemDescription}} </td>
    </ng-container>

    <ng-container cdkColumnDef="quantity">
      <th *cdkHeaderCellDef cdk-header-cell> Quantity</th>
      <td *cdkCellDef="let row" cdk-cell> {{row.quantity}} </td>
    </ng-container>

    <ng-container cdkColumnDef="cardCode">
      <th *cdkHeaderCellDef cdk-header-cell>CardCode</th>
      <td *cdkCellDef="let row" cdk-cell> {{row.cardCode}} </td>
    </ng-container>

    <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
    <tr *cdkRowDef="let row; columns: displayedColumns" cdk-row></tr>
  </table>
</div>
