// src/app/features/product/services/product-info-form.service.ts
import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProductInfoFormService {
  constructor(private formBuilder: FormBuilder) {
  }

  buildProductInfoForm(): FormGroup {
    return this.formBuilder.group({
      sku: [''],
      item_description: [''],
      upc: ['', [
        Validators.minLength(12),
        Validators.maxLength(12),
        Validators.pattern(/^[0-9]+$/)
      ]],
      ean: ['', [
        Validators.minLength(13),
        Validators.maxLength(13),
        Validators.pattern(/^[0-9]+$/)
      ]],
      condition: ['', [Validators.required, this.invalidConditionValidator]],
      category: ['', [Validators.required]],
      sub_categories: this.formBuilder.array([]),
      manufacturer: ['', [Validators.required]],
      specifications: this.formBuilder.array([], this.invalidSpecificationsValidator),
      part_number: ['', [Validators.required]],
      model: ['', [Validators.required]],
      product_description: ['', [Validators.required]],
    }, {
      validators: [this.validatorUpcOrEan]
    });
  }

  createSubCategoryField(parentKey?: string) {
    return this.formBuilder.group({
      sub_category: [parentKey ? parentKey : '', [Validators.required]],
    });
  }

  createSpecificationsField(code: string, value?: string, isRequired: boolean = false) {
    return this.formBuilder.group({
      code: [code],
      value: [value || '', isRequired ? Validators.required : []],
      isRequired: [isRequired]
    });
  }
  private invalidSpecificationsValidator(control: AbstractControl) {
    const specificationsArray = control as FormArray;
    let hasErrors = false;

    specificationsArray.controls.forEach((specControl) => {
      const isRequired = specControl.get('isRequired')?.value;
      if (isRequired && specControl.get('value')?.hasError('required')) {
        hasErrors = true;
      }
    });

    return hasErrors ? { invalidSpecifications: true } : null;
  }

  private invalidConditionValidator(control: AbstractControl) {
    const condition = control.value;
    if (condition && condition.status === false) {
      return {invalidCondition: condition.message};
    }
    return null;
  }

  private validatorUpcOrEan(control: AbstractControl) {
    let upc = control.get('upc')?.value;
    let ean = control.get('ean')?.value;
    if (!upc && !ean) {
      return {upc_or_ean_required: true};
    }
    return null;
  }
}
