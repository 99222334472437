<div class="">
  <div class="row">
    <div class="col-12 close-button">
      <button [mat-dialog-close]="true" mat-icon-button>
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
    </div>
  </div>

  <mat-stepper #stepper labelPosition="bottom" linear="true">
    <ng-container>
      <mat-step [editable]="true" [stepControl]="specialDealPeriodSetupForm">
        <ng-template matStepLabel>Special deal period setup</ng-template>
        <app-special-deal-period-setup
          [product]="product"
          [specialDealPeriodSetupForm]="specialDealPeriodSetupForm"
        >
        </app-special-deal-period-setup>
        <button color="primary" mat-raised-button matStepperNext>Next Page</button>
      </mat-step>
      <mat-step *ngIf="hasLimitValidPeriodField?.value" [editable]="true"
                [stepControl]="dealPeriodSetupForm">
        <ng-template matStepLabel>Deal period setup</ng-template>
        <app-deal-period-setup
          [dealPeriodSetupForm]="dealPeriodSetupForm"
          [product]="product"
        >
        </app-deal-period-setup>
        <div class="stepper_button_container">
          <button mat-raised-button matStepperPrevious>Previous</button>
          <button color="primary" mat-raised-button matStepperNext>Next Page</button>
        </div>
      </mat-step>
      <mat-step [editable]="true" [stepControl]="moqSetupForm">
        <ng-template matStepLabel>MOQ setup</ng-template>
        <app-moq-setup
          [moqSetupForm]="moqSetupForm"
          [product]="product"
        >
        </app-moq-setup>
        <div class="stepper_button_container">
          <button color="primary" mat-raised-button matStepperPrevious>Previous</button>
          <button (click)="saveOfferValidation()" mat-raised-button>Submit form</button>
        </div>
      </mat-step>
    </ng-container>
  </mat-stepper>
</div>
<app-loader></app-loader>
