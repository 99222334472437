import Compressor from "compressorjs";
import {saveAs} from 'file-saver';

export class FileUtil {
  static urlToFile(url: any, filename: any, mimeType: any) {
    return (fetch(url)
      .then(res => {
        return res.arrayBuffer();
      })
      .then(buf => {
        return new File([buf], filename, {type: mimeType});
      })
    );
  }

  static downloadFile(result: any, fileName: string) {
    saveAs(result, fileName);
  }

  static convertUrlToBase64(imageUrl: string) {
    const toDataUrl = (url: any) => fetch(url)
    .then(responseFetched => responseFetched.blob())
    .then(blob => new Promise(((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))).then(dataUrl => {
      return dataUrl;
    });
    return toDataUrl(imageUrl);
  }

  static convertFileToBase64(fileIn: any) {
    const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).then(data => {
      return data;
    })
    return toBase64(fileIn);
  }


  static async compressFile(target: any) {
    return new Promise((resolve, reject) => {
      let compressor = new Compressor(target, {
        quality: 0.4,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });

    });
  }

  static imgSize(imageUrl: string) {
    const img = new Image();
    img.src = imageUrl;
    return {width: img.naturalWidth, height: img.naturalHeight};
  }

  static mimeToExtension(mimeType: string): string {
    const mimeToExtMap: { [key: string]: string } = {
      'image/png': '.png',
      'application/pdf': '.pdf',
      'image/jpeg': '.jpeg',
      'image/jpg': '.jpg'
    };

    return mimeToExtMap[mimeType] || '';
  }
  static getFileExtension(fileName: string): string {
    const extensionMatch = fileName.match(/\.[0-9a-z]+$/i);
    return extensionMatch ? extensionMatch[0] : '';
  }
}
