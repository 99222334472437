export const DASHBOARD_ORDERS = {
  FILTERS:{
    DATE:'NATIVE_FILTER-j5RPBNMOd',
    GRANULARITY:'NATIVE_FILTER-0i_P9ONk6',
    CARD_CODE:'NATIVE_FILTER-847TgRm62',
    SALES_PERSON:'NATIVE_FILTER-T9_9ItukJ'
  }
}

export const DASHBOARD_CUSTOMERS = {
  FILTERS:{
    DATE:'NATIVE_FILTER-UdfFMvPZy',
    GRANULARITY:'NATIVE_FILTER-CM-UTl55V',
    CARD_CODE:'NATIVE_FILTER-FW1GRrK0V',
    SALES_PERSON:'NATIVE_FILTER-tug08KvFo'
  }
}

export const DASHBOARD_MARKETPLACE = {
  FILTERS:{
    DATE:'NATIVE_FILTER-BdAWqbE3J',
    GRANULARITY:'NATIVE_FILTER-kpFhrg-wW',
    OWNER:'NATIVE_FILTER-kmSuO2K0B',
    VENDORS:'NATIVE_FILTER-sIJUBQcCA'
  }
}

export const TEST_DASHBOARD = {
  FILTERS:{
    DATE:'NATIVE_FILTER-AApNbYTzE',
    GRANULARITY:'',
    CARD_CODE:'',
    SALES_PERSON:'NATIVE_FILTER-uFfoLfRAI'
  }
}
export const ITEM_PURCHASES = {
  FILTERS:{
    DATE:'NATIVE_FILTER-jPHACJ9NM',
    ITEM_CODE:'NATIVE_FILTER--GZYWOSMn',
    SALES_PERSON:'NATIVE_FILTER-JevwoL0E9',
    CARD_CODE: 'NATIVE_FILTER-CQkmknPg2'
  }
}

export const DASHBOARD_INVENTORY = {
  FILTERS:{
    DATE:"'NATIVE_FILTER-sxeQqOCf-'" ,
    GRANULARITY:'NATIVE_FILTER-O_YbOQzpM',
    BUYER:'NATIVE_FILTER-MqzlEBhnz'
  }
}
export const DASHBOARD_VENDORS_INVENTORY = {
  FILTERS:{
    DATE:"'NATIVE_FILTER-sxeQqOCf-'" ,
    GRANULARITY:'NATIVE_FILTER-O_YbOQzpM',
    BUYER:'NATIVE_FILTER-MqzlEBhnz'
  }
}

export const DASHBOARD_KEY_METRICS = {
  FILTERS:{
    DATE:"'NATIVE_FILTER-sxeQqOCf-'" ,
    GRANULARITY:'NATIVE_FILTER-O_YbOQzpM'
  }
}

