import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PurchaseOrderService} from "@features/product-offer/services/purchase-order.service";
import {WarehouseDto} from "@features/product-offer/model/offer-purchase-order/warehouse.dto";

@Component({
  selector: 'app-select-the-store',
  templateUrl: './select-the-store.component.html',
  styleUrls: ['./select-the-store.component.scss']
})
export class SelectTheStoreComponent implements OnInit {

  @Input()
  form!: FormGroup;

  warehouses: WarehouseDto[] = [];

  constructor(private formBuilder: FormBuilder, private purchaseOrderService: PurchaseOrderService) {

  }

  ngOnInit(): void {
    this.purchaseOrderService.getAllWarehouse()
    .subscribe(result => (this.warehouses = result));

  }

}
