import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";

@Component({
  selector: 'app-deal-period-setup',
  templateUrl: './deal-period-setup.component.html',
  styleUrls: ['./deal-period-setup.component.scss']
})
export class DealPeriodSetupComponent {
  @Input() dealPeriodSetupForm!: UntypedFormGroup;
  @Input() product!: ProductsDto


  get dealPeriodDateStartField() {
    return this.dealPeriodSetupForm.get('deal_period_date_start');
  }

  get dealPeriodDateEndField() {
    return this.dealPeriodSetupForm.get('deal_period_date_end');
  }

  get subjectToUnsoldField() {
    return this.dealPeriodSetupForm.get('subject_to_unsold');
  }

  get dealPeriodHourStartField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_start');
  }

  get dealPeriodHourEndField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_end');
  }

  get dealPeriodTierOneField() {
    return this.dealPeriodSetupForm.get('tierOne');
  }

  get dealPeriodTierTwoField() {
    return this.dealPeriodSetupForm.get('tierTwo');
  }

  get dealPeriodTierThreeField() {
    return this.dealPeriodSetupForm.get('tierThree');
  }

  get dealPeriodTierFourField() {
    return this.dealPeriodSetupForm.get('tierFour');
  }

  getTimePeriod() {
    return moment().add(1, 'hour').format('HH:mm a');
  }

}
