import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonModule} from "@angular/material/button";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {SharedModule} from "@shared/shared.module";
import {MatSortModule} from "@angular/material/sort";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {
  StatsRequestBuyerComponent
} from "@features/stats/presentation/stats-request-buyer/stats-request-buyer.component";
import {StatsRoutingModule} from "@features/stats/stats-routing.module";
import {
  StatusForRequestComponent
} from './presentation/status-for-request/status-for-request.component';
import {MatDialogModule} from "@angular/material/dialog";
import {
  StatsRequestSalesComponent
} from './presentation/stats-request-sales/stats-request-sales.component';


@NgModule({
  declarations: [
    StatsRequestBuyerComponent,
    StatusForRequestComponent,
    StatsRequestSalesComponent,
  ],
  imports: [
    StatsRoutingModule,
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatStepperModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatPaginatorModule,
    SharedModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTableModule,
    MatDialogModule,
  ]
})
export class StatsModule {
}
