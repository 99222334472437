import {ImageType} from "@features/product/model/enum/image-type";


export class ItemPicturesDto {
  id!: any;
  url!: string;
  itemId!: any;
  imageType!: ImageType;
  status!: boolean;
  checked!: boolean;
  order!: number;
}
