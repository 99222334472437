import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {TITLE_INFO} from "@shared";

import {Router} from "@angular/router";
import {NEW_DEAL_OFFER} from "@features/product/model/utility/config/constants/popup-message.const";
import {showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-choose-process',
  templateUrl: './choose-process.component.html',
  styleUrls: ['./choose-process.component.scss']
})
export class ChooseProcessComponent {

  @Input()
  form!: FormGroup

  constructor(private router: Router) {
  }

  optionSelected($event: any) {
    console.log($event);
    if ($event === '1') {
      showSuccessPopup(TITLE_INFO, NEW_DEAL_OFFER.DEFAULT_MESSAGE_RESPONSE.CREATE_NEW_ITEM)
      .then(() => this.router.navigate(['/product/create']))
    }
  }
}
