import {Category} from "@core/model/category";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ItemCodesDto} from "@features/product/model/dto/item/itemCodes.dto";


export class ItemUtility {

  static findSubCategory(allCategories: Array<Array<Category>>, toFind: any): Category {
    console.log(toFind);
    let categoryFound = new Category();
    allCategories.forEach((categories: Category[]) => {
      categories.forEach((category: Category) => {
        console.log(category);
        if (category.key === toFind) {
          console.log(category);
          categoryFound = category;
        }
      });
    });
    return categoryFound;
  }


  static getProductMasterManufacturer(manufacturerCode: any, manufacturers: ProductMasterManufacturer[]) {
    return manufacturers.find((manufacturer: ProductMasterManufacturer) => {
      if (manufacturer.ditmmnf_code === manufacturerCode) {
        return manufacturer;
      } else {
        return null;
      }
    });
  }

  static setCategory(value: any, categories: Category[]) {
    value.forEach((cat: any) => {
      let category = new Category();
      category.key = cat.itmctg_code;
      category.values = cat.itemcategory;
      categories.push(category);
    });
  }

  static pushItemCodes(itemCodes: ItemCodesDto[], itemCodeTye: string, itemCodeValue: string) {
    let itemCode = new ItemCodesDto();
    itemCode.itemCodeType = itemCodeTye;
    itemCode.itemCode = itemCodeValue;
    itemCodes.push(itemCode);
  }

}
