<div class="list-product-container">
  <mat-card>
    <mat-card-title class="text-center">
      <h2><strong>Product Master Information</strong></h2>
    </mat-card-title>
    <mat-card-content>
      <div class="row">
        <mat-form-field appearance="fill" class=" col-6 filter_field">
          <mat-label>Filter</mat-label>
          <input [formControl]="filter" matInput>
        </mat-form-field>
        <div class="col-4 search_by_row">
          <mat-radio-group [formControl]="search_by" aria-label="Select an option">
            <mat-radio-button [checked]="true" color="primary" value="SKU">SKU</mat-radio-button>
            <mat-radio-button class="search_by" color="primary" value="UPC">UPC</mat-radio-button>
            <mat-radio-button class="search_by" color="primary" value="EAN">EAN</mat-radio-button>
            <mat-radio-button class="search_by" color="primary" value="PART_NUMBER">PART NUMBER
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </div>
      <div class="row">
        <div class="col-4">
          <button (click)="search()" class="search_button" color="primary" mat-raised-button>
            <mat-icon><span class="material-symbols-outlined">search</span></mat-icon>
            Search
          </button>
        </div>
      </div>
      <div class="row">
        <table [dataSource]="data" mat-table>
          <caption>Item List</caption>

          <!-- Id item Column -->
          <ng-container matColumnDef="id_item">
            <th *matHeaderCellDef mat-header-cell>#</th>
            <td *matCellDef="let row" mat-cell>{{ row.id }}</td>
          </ng-container>
          <!-- sku item Column -->
          <ng-container matColumnDef="sku">
            <th *matHeaderCellDef mat-header-cell>SKU</th>
            <td *matCellDef="let row" mat-cell>{{ row.sku }}</td>
          </ng-container>
          <!-- description item Column -->
          <ng-container matColumnDef="item_description">
            <th *matHeaderCellDef mat-header-cell>Description</th>
            <td *matCellDef="let row" mat-cell>{{ row.itemDescription }}</td>
          </ng-container>
          <!-- UPC item Column -->
          <ng-container matColumnDef="upc">
            <th *matHeaderCellDef mat-header-cell>UPC</th>
            <td *matCellDef="let row" mat-cell>{{ getUpc(row) }}</td>
          </ng-container>
          <!-- EAN item Column -->
          <ng-container matColumnDef="ean">
            <th *matHeaderCellDef mat-header-cell>EAN</th>
            <td *matCellDef="let row" mat-cell>{{ getEan(row) }}</td>
          </ng-container>

          <!-- See preview Column -->
          <ng-container matColumnDef="item_details">
            <th *matHeaderCellDef mat-header-cell>Item Details</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="openPreview(row)" mat-icon-button>
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="item_edit">
            <th *matHeaderCellDef mat-header-cell>Item Edit</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="editItem(row)" *ngxPermissionsOnly="[ROLES.BUYER,
              ROLES.BUYERS_LEADER,
              ROLES.ADMIN,
              ROLES.ECOMMERCE_SPECIALIST,
              ROLES.MARKETPLACE_SPEC
              ]"
                      mat-icon-button>
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="upload_image">
            <th *matHeaderCellDef mat-header-cell>Upload Image</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="uploadImage(row)" *ngxPermissionsOnly="[ROLES.BUYER,
              ROLES.BUYERS_LEADER,
              ROLES.ADMIN,
              ROLES.ECOMMERCE_SPECIALIST,
              ROLES.MARKETPLACE_SPEC
              ]"
                      mat-icon-button>
                <mat-icon
                  [ngStyle]="row?.itemPictures.length > 0 ? {'color': '#3f51b5'} : {'color': '#f44336'}">
                 <span>
                    upload
                 </span>
                </mat-icon>
              </button>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>

      </div>
      <div class="row">
        <mat-paginator (page)="pagePagination($event)"
                       [hidePageSize]="this.paginator.pageIndex != 0"
                       [length]="resultsLength"
                       [pageSizeOptions]="[5, 10, 25, 50]"
                       [pageSize]="25"
                       aria-label="Select page of Deals Information search results">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
