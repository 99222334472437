import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {InventoryByEtaDto} from "@features/product-offer/model/offer-validation/inventoryByEta.dto";
import {
  SearchItemWareHouseDto
} from "@features/product-offer/model/offer-validation/searchItemWareHouse.dto";
import {showErrorPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-show-eta',
  templateUrl: './show-eta.component.html',
  styleUrls: ['./show-eta.component.scss']
})
export class ShowEtaComponent implements OnInit {
  @Input()
  sku!: string;
  @Input()
  isDialog: boolean = false;
  @Input()
  showEtaForm!: FormGroup;
  inventoryEta: InventoryByEtaDto[] = []
  displayedColumns: string[] = ['eta', 'inStock'];
  totalInStock: number = 0;
  constructor(private productOfferService: ProductOfferService,
              private formBuilder: FormBuilder,) {
    this.showEtaForm = this.formBuilder.group({}, {});
  }

  ngOnInit(): void {
    this.getInventory();
  }

  getInventory() {
    if (this.sku) {
      let searchItemWareHouseDto: SearchItemWareHouseDto = {
        sku: this.sku
      };
      try {
        this.productOfferService.getInventoryEta(searchItemWareHouseDto).subscribe({
          next: (value: any) => {
            this.inventoryEta = value;
            this.calculateTotalInStock();
          }, error: () => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
          }
        })
      } catch (error) {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
      }
    }
  }

  calculateTotalInStock(): void {
    this.totalInStock = this.inventoryEta.reduce((total, item) => total + (item.inStock || 0), 0);
  }


}
