import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Category} from "@core/model/category";
import {ProductService} from "@features/product/services/product.service";
import {TITLE_ERROR} from "@shared";
import {showErrorPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-item-categories',
  templateUrl: './item-categories.component.html',
  styleUrls: ['./item-categories.component.scss']
})
export class ItemCategoriesComponent implements OnInit {

  @Input()
  form!: FormGroup;
  @Input()
  sub_categories: Category[] = [];
  @Input()
  categories: Category[] = [];


  allCategories: Array<Array<Category>> = [];

  constructor(private productService: ProductService,
              private formBuilder: FormBuilder) {
  }

  get subCategoriesField() {
    return this.form.get('sub_categories') as FormArray;
  }

  ngOnInit(): void {
  }

  parentGetSubcategory(parentCode: string) {
    console.log(parentCode)
    this.allCategories = [];
    this.removeAllCategory();
    this.productService.getSubCategories(parentCode).subscribe({
      next: response => {
        if (response.body.length > 0) {
          this.setCategory(parentCode, response.body);
          this.buildSubCategory();
        }
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    });

  }

  buildSubCategory(parentKey?: string) {
    this.subCategoriesField.push(this.createSubCategoryField(parentKey));
  }

  removeAllCategory() {
    this.subCategoriesField.clear();
  }

  setCategory(parent: string, value: any) {
    let itemsMapped: Category[] = [];
    value.map((item: any) => {
      let category = new Category();
      category.key = item.itmctg_code;
      category.values = item.categorychild;
      category.parent = parent;
      itemsMapped.push(category);
    });
    this.allCategories.push(itemsMapped);
  }

  getSubcategory(parentCode: string) {
    this.productService.getSubCategories(parentCode).subscribe({
      next: response => {
        if (response.body.length > 0) {
          this.setCategory(parentCode, response.body);
          this.buildSubCategory(parentCode);
        }

      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    });
  }

  removeCategory($event: any, position: number) {
    console.log('enter to remove')
    let indexToDelete: number[] = [];
    this.subCategoriesField.controls.forEach(((value, index) => {
      if (index > position) {
        indexToDelete.push(index);
      }
    }));

    for (let i = indexToDelete.length - 1; i >= 0; i--) {
      this.subCategoriesField.controls.splice(indexToDelete[i], 1);
      this.allCategories.splice(indexToDelete[i], 1);
    }

  }

  getCategoriesAt(position: number) {
    return this.allCategories.at(position);
  }

  private createSubCategoryField(parentKey?: string) {
    return this.formBuilder.group({
      sub_category: [parentKey ? parentKey : '', Validators.required],
    });
  }

}
