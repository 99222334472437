<div class="menu">
  <div class="menu__item" routerLink="home">
    <mat-icon class="menu__item--icon1" svgIcon="home-icon"></mat-icon>
    <span class="menu__item--text">Home</span>
  </div>
</div>
<app-da-analytics-menu></app-da-analytics-menu>
<app-da-products-and-offers-menu></app-da-products-and-offers-menu>
<app-da-requests-menu ></app-da-requests-menu>
<app-da-orders-menu></app-da-orders-menu>


