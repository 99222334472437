import {Component, OnInit} from '@angular/core';
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {RequestDto} from "@features/request/model/RequestDto";
import {ProductService} from "@features/product/services/product.service";
import {ProductOfferUtility} from "@shared/utility/productOfferUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss']
})
export class RequestDetailComponent  implements OnInit {

  product!: ItemDto;
  request!: RequestDto;
  form!:FormGroup ;

  constructor(private productService: ProductService, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    if (this.request?.itemId){
      this.productService.getItemById(this.request.itemId).subscribe({
        next: (response) => {
          this.product = response;
        }
      })
    }

    this.form = this.formBuilder.group({
      description: [{value: this.request ? this.request.description : '', disabled: true}],
      partNumber: [{value: this.request ? this.request.partNumber : '', disabled: true}],
      upc: [{value: this.request ? this.request.upc : '', disabled: true}],
      techSpec: [{value: this.request ? this.request.techSpec : '', disabled: true}],
      priceMin: [{value: this.request ? this.request.priceMin : '', disabled: true}],
      priceMax: [{value: this.request ? this.request.priceMax : '', disabled: true}],
      quantityMin: [{value: this.request ? this.request.quantityMin : '', disabled: true}],
      quantityMax: [{value: this.request ? this.request.quantityMax : '', disabled: true}],
      eta: [{value: this.request ? this.request.eta : '', disabled: true}],
      paymentTerms: [{value: this.request ? this.request.paymentTerms : '', disabled: true}],
    })
  }



  eanField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product?.itemCodes, ItemCode.EAN)
  }

  upcField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product?.itemCodes, ItemCode.UPC)
  }
}
