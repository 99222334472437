import {RequestByStateDto} from "@features/stats/model/request-by-state.dto";

export class RequestStatsDto {
  userName!: string;
  products_created!: number;
  offersEnter!: number;
  // productsCancelled!: string;
  requestResolved!: number;
  averageTimeToTakeRequest!: number;
  averageTimeToSolveRequest!: number;
  status!: RequestByStateDto [];
}
