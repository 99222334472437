<div class="container">
  <mat-card class="container__card">
    <mat-card-title class="text-center"><h3><strong>Product Creation</strong></h3></mat-card-title>
    <mat-card-content>
      <div [formGroup]="productInfoForm">
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>UPC</mat-label>
            <input [(ngModel)]="product.upc" autocomplete="on"
                   formControlName="upc"
                   id="upc"
                   matInput
                   trim
                   type="text"
            >
            <mat-error *ngIf="upcField?.hasError('codeAlreadyExists')">
              upc code already <strong>exists</strong>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="productInfoForm?.hasError('upc_or_ean_required')">
            UPC or EAN must be completed
          </mat-error>

        </div>
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>EAN</mat-label>
            <input [(ngModel)]="product.ean" autocomplete="on"
                   formControlName="ean"
                   id="ean"
                   matInput
                   trim
                   type="text"
            >
            <mat-error *ngIf="eanField?.hasError('codeAlreadyExists')">
              ean code already <strong>exists</strong>
            </mat-error>
          </mat-form-field>
          <mat-error *ngIf="productInfoForm?.hasError('upc_or_ean_required')">
            UPC or EAN must be completed
          </mat-error>
        </div>
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>Condition</mat-label>
            <mat-select [(ngModel)]="product.condition" formControlName="condition" id="condition">
              <mat-option *ngFor="let condition of conditions" [value]="condition.condition">
                {{ condition.condition }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="conditionField?.hasError('invalidCondition')">
              {{ conditionField?.getError('invalidCondition') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="product && isProductInfoForm">
          <app-product-categories (emitSpecifications)="showSpecificationsEvent($event)"
                                  (emitSubCategories)="getSubCategories($event)"
                                  [categories]="categories"
                                  [productInfoForm]="productInfoForm"
                                  [product]="product"
                                  [sub_categories]="sub_categories"
          >
          </app-product-categories>
        </div>

        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>Manufacturer</mat-label>
            <mat-select *ngIf="manufacture && manufacture.ditmmnf_code"
                        [(ngModel)]="manufacture.ditmmnf_code"
                        formControlName="manufacturer" id="manufacturer">
              <mat-option *ngFor="let manufacturer of manufacturers "
                          [value]="manufacturer.ditmmnf_code">
                {{ manufacturer.ditmmnf_front_dsc }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="showSpecifications" class="row">
          <app-product-specifications [productInfoForm]="productInfoForm"
                                      [productProcessType]="productProcessType"
                                      [product]="product"
          ></app-product-specifications>
        </div>
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>Part Number</mat-label>
            <input [(ngModel)]="product.partNumber" autocomplete="on"
                   formControlName="part_number"
                   id="part_number"
                   matInput
                   maxlength="16"
                   type="text"
            >
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>Model</mat-label>
            <input
              [(ngModel)]="product.model"
              autocomplete="on"
              formControlName="model"
              id="model"
              matInput
              maxlength="99"
              type="text"
            >
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field *ngIf="product && isProductInfoForm" appearance="fill" class="col-12">
            <mat-label>Product Description</mat-label>
            <textarea [(ngModel)]="product.description"
                      [cdkTextareaAutosize]="true"
                      autocomplete="on"
                      formControlName="product_description"
                      id="product-description"
                      matInput
                      type="text"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
