import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DateUtil} from "@shared/utility/dateUtil";
import {DABusinessPartnerService} from "@features/product/services/da-business-partner.service";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {SapUserType} from "@shared/model/enum/sap-user-type";
import {DaUserService} from "@shared/services/user/da-user.service";
import {DASearchBusinessPartnerDto} from "@shared/model/dto/da-search-business-partner.dto";
import {
  DABusinessPartnerDto
} from "@features/product/model/dto/da-business-partner/DABusinessPartner.dto";
import {BehaviorSubject, Observable, scan} from "rxjs";
import {NameProcessLogEnum} from "@shared/model/enum/transactional-log/NameProcessLogEnum";
import {
  TransactionalLogService
} from "@shared/services/transactional-log/transactional-log.service";
import {Moment} from "moment";

@Component({
  selector: 'app-filter-business-partner',
  templateUrl: './filter-business-partner.component.html',
  styleUrls: ['./filter-business-partner.component.scss']
})
export class FilterBusinessPartnerComponent implements OnInit {
  filterOpen: boolean = false;
  @Input()
  sapUsertype!: SapUserType;
  @Input()
  title: string = '';
  @Input()
  process!: NameProcessLogEnum;
  @Input()
  showGranularity: boolean = true;

  users: DAUserDto[] = [];

  @Input()
  form!: FormGroup;
  maxDate = DateUtil.getCurrentDate().endOf('month').toDate();
  minDate = new Date('2010/01/01');
  startOfMonth = DateUtil.getCurrentDate().startOf('month').toDate();
  data: Map<string, object> = new Map<string, object>();
  customersFilter: DABusinessPartnerDto[] = [];


  options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
  options$: Observable<DABusinessPartnerDto[]> | undefined;
  offset = 0;
  total = 10000000;
  limit = 20;


  @Output() emitFormValue = new EventEmitter();
  @Output() emitUsers = new EventEmitter();

  protected readonly SapUserType = SapUserType;


  constructor(private formBuilder: FormBuilder,
              private dABusinessPartnerService: DABusinessPartnerService,
              private daUserService: DaUserService,
              private transactionalLogService: TransactionalLogService
  ) {
    if (!this.form) {
      this.form = this.formBuilder.group({
        ownerCode: [''],
        cardCode: [''],
        startDate: [this.startOfMonth, [Validators.required]],
        endDate: [this.maxDate, [Validators.required]],
        granularity: ['P1D', [Validators.required]],
        autoCardCode: [''],
      })
    }

    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );

  }

  get ownerCodeField() {
    return this.form.get('ownerCode');
  }

  get cardCodeField() {
    return this.form.get('cardCode');
  }


  get autoCardCodeField() {
    return this.form.get('autoCardCode');
  }

  get startDateField() {
    return this.form.get('startDate');
  }

  get endDateField() {
    return this.form.get('endDate');
  }

  get granularityField() {
    return this.form.get('granularity');
  }

  get monthYearField() {
    return this.form.get('monthYear');
  }

  ngOnInit(): void {
    this.sapUsertype == SapUserType.SAP_BUYER ?
      this.daUserService.findAllBuyers()
      .subscribe(result => {
        this.emitUsers.emit(result);
        this.initCallBP(result);
      })
      :
      this.daUserService.findAllSalesPersonForDashboard()
      .subscribe(result => {
        this.emitUsers.emit(result);
        this.initCallBP(result);
      });

    this.form.updateValueAndValidity();
  }

  changeSet(user: DAUserDto) {
    this.cardCodeField?.reset();
    this.autoCardCodeField?.reset();
    this.ownerCodeField?.setValue(user);
    this.options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );
    this.offset = 0;
    this.getNextBatch();
  }

  getNextBatch() {
    this.offset += this.limit;
    let searchBusinessPartner = new DASearchBusinessPartnerDto();
    searchBusinessPartner.totalItems = this.offset;
    this.setOwnerCode(searchBusinessPartner);
    this.callDataBP(searchBusinessPartner);
  }

  toggle(filters: HTMLElement) {
    this.filterOpen = !this.filterOpen;
    if (!this.filterOpen) {
      filters.style.display = 'none';
    } else {
      filters.style.display = 'flex';
    }
  }

  userName(user: DAUserDto) {
    return user.firstName + ' ' + user.lastName + ' - ' + this.getUserName(user.userCustomized);
  }

  getUserName(userCustomized: UserCustomizedDto[]) {
    let userCustomizeCode = this.sapUsertype == SapUserType.SAP_BUYER
      ? UserCustomizedUtil.findBuyerSapCode(userCustomized)?.userCustomizedValue
      : UserCustomizedUtil.findSalesSapCode(userCustomized)?.userCustomizedValue;
    return userCustomized && userCustomized.length > 0 ? userCustomizeCode : '';
  }

  getSapCode(userCustomized: UserCustomizedDto[]) {
    return UserCustomizedUtil.findBuyerOwnerCode(userCustomized)?.userCustomizedValue;
  }

  reset() {
    this.cardCodeField?.reset();
    this.autoCardCodeField?.reset();
    if (this.users.length > 1) {
      this.ownerCodeField?.reset();
    }
    this.startDateField?.reset();
    this.endDateField?.reset();
    this.granularityField?.reset();
    this.monthYearField?.reset();
    this.options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );
    this.offset = 0;
    this.initCallBP(this.users);
  }

  filterDashboard() {
    this.emitFormValue.emit(this.form.value);
    this.transactionalLogService
    .saveLog(this.form.value,
      null,
      "FILTER " + this.process + " DASHBOARD",
      this.process,
      true);
  }

  filter() {
    if (this.autoCardCodeField?.value && this.autoCardCodeField?.value.toString().length > 3) {
      console.log(this.autoCardCodeField?.value);
      this.searchBusinessPartnerByOwnerAndCardCode(this.autoCardCodeField.value.toString().toUpperCase());
    }
  }

  private initCallBP(result: DAUserDto[]) {
    if (result && result.length > 0) {
      this.users = result;
      let searchBusinessPartner = new DASearchBusinessPartnerDto();
      this.setOwnerCode(searchBusinessPartner);
      this.callDataBP(searchBusinessPartner);
    }
  }

  private callDataBP(searchBusinessPartner: DASearchBusinessPartnerDto) {
    this.dABusinessPartnerService.getBusinessPartnerByOwner(searchBusinessPartner)
    .subscribe((result: any) => {
      if (result && result.items.length > 0) {
        this.options.next(result.items as DABusinessPartnerDto[]);
      }
    });
  }

  private searchBusinessPartnerByOwnerAndCardCode(cardCode: string) {
    if (this.users && this.users.length > 0) {
      let searchBusinessPartner = new DASearchBusinessPartnerDto();
      this.setOwnerCode(searchBusinessPartner);
      searchBusinessPartner.cardCode = cardCode;
      this.dABusinessPartnerService.getBusinessPartnerByOwner(searchBusinessPartner)
      .subscribe((result: any) => {
        this.customersFilter = result.items as DABusinessPartnerDto[];
      });
    }
  }

  private setOwnerCode(searchBusinessPartner: DASearchBusinessPartnerDto) {
    if (this.users.length == 1) {
      this.ownerCodeField?.setValue(this.users.at(0));
      let userCustomized = this.users.at(0)?.userCustomized;
      let userCode = userCustomized ? this.getSapCode(userCustomized) : null;
      if (userCode) {
        searchBusinessPartner.ownerCode = Number(userCode);
      }
    } else if (this.ownerCodeField?.value) {
      let userCustomized = this.ownerCodeField?.value?.userCustomized;
      let userCode = userCustomized ? this.getSapCode(userCustomized) : null;
      if (userCode) {
        searchBusinessPartner.ownerCode = Number(userCode);
      }
    }
  }

  onMonthYearChange(date: Moment) {
    this.monthYearField?.setValue(date.format('YYYY-MM'));
  }
}
