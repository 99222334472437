<div *ngIf="!isAuthenticated" class="container-login">
  <div class="row container-login__form">
    <form [formGroup]="loginForm" class="col-12 col-lg-4 col-xl-6">
      <app-account-information-login [form]="accountForm"
                                     class="mb-2"
                                     formControlName="account_information"
                                     (emitLogin)="submit()"
      >
      </app-account-information-login>
    </form>
  </div>
</div>
<app-loader></app-loader>
