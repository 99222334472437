import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {Observable} from "rxjs";
import {UserDto} from "@shared/model/dto/user.dto";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = environment.automatePurchasesUrl;
  private findAllByRolesUrl = `${this.apiUrl}user/find/all/by/roles`;
  private getByIdUrl = `${this.apiUrl}user/get/by/id/`;

  constructor(private http: HttpClient) {
  }

  findAllByRoles(roles: string[]): Observable<any[]> {
    return this.http.post<UserDto[]>(this.findAllByRolesUrl, roles);
  }

  getById(userId: number): Observable<any> {
    return this.http.get<UserDto>(this.getByIdUrl + userId);
  }
}
