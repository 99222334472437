import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
  ListProductComponent
} from "@features/product/features/presentation/list-product/list-product.component";
import {
  ProductPreviewComponent
} from "@features/product/features/presentation/product-preview/product-preview.component";
import {MatCardModule} from "@angular/material/card";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatPaginatorModule} from "@angular/material/paginator";
import {SharedModule} from "@shared/shared.module";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ProductRoutingModule} from "@features/product/product-routing.module";
import {
  ProductUpdateComponent
} from "@features/product/features/presentation/product-update/product-update.component";
import {
  ProductMainComponent
} from "@features/product/features/smart/product-main/product-main.component";
import {MatStepperModule} from "@angular/material/stepper";
import {
  ProductProcessTypeComponent
} from "@features/product/features/presentation/product-process-type/product-process-type.component";
import {
  ProductSelectComponent
} from "@features/product/features/presentation/product-select/product-select.component";
import {
  ManufactureCreateComponent
} from "@features/product/features/presentation/manufacture-create/manufacture-create.component";
import {
  ProductInfoComponent
} from "@features/product/features/presentation/product-info/product-info.component";
import {MatSelectModule} from "@angular/material/select";
import {
  ProductCategoriesComponent
} from "@features/product/features/presentation/product-info/product-categories/product-categories.component";
import {
  ProductSpecificationsComponent
} from "@features/product/features/presentation/product-info/product-specifications/product-specifications.component";
import {
  ProductSelectImageComponent
} from "@features/product/features/presentation/product-select-image/product-select-image.component";
import {
  ProductUploadTemplateComponent
} from "@features/product/features/presentation/product-upload-template/product-upload-template.component";
import {
  UploadImagesComponent
} from "@features/product/features/presentation/upload-images/upload-images.component";
import {MatListModule} from "@angular/material/list";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NgxPermissionsModule, NgxPermissionsRestrictStubDirective} from "ngx-permissions";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {
  AddMultipleImagesComponent
} from "@features/product/features/presentation/add-multiple-images/add-multiple-images.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {
  PoAssociatedTrackingComponent
} from './features/presentation/po-associated-tracking/po-associated-tracking.component';
import {CdkTableModule} from "@angular/cdk/table";
import {
  PoAssociatedTrackingTabletComponent
} from './features/presentation/po-associated-tracking-tablet/po-associated-tracking-tablet.component';
import {MatLineModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgbCarousel, NgbSlide} from "@ng-bootstrap/ng-bootstrap";
import { DashboardInventoryComponent } from './features/presentation/dashboard-inventory/dashboard-inventory.component';
import { DashboardVendorInventoryComponent } from './features/presentation/dashboard-vendor-inventory/dashboard-vendor-inventory.component';


@NgModule({
  declarations: [
    ListProductComponent,
    ProductPreviewComponent,
    ProductUpdateComponent,
    ProductMainComponent,
    ProductProcessTypeComponent,
    ProductSelectComponent,
    ManufactureCreateComponent,
    ProductInfoComponent,
    ProductCategoriesComponent,
    ProductSpecificationsComponent,
    ProductSelectImageComponent,
    ProductUploadTemplateComponent,
    UploadImagesComponent,
    AddMultipleImagesComponent,
    PoAssociatedTrackingComponent,
    PoAssociatedTrackingTabletComponent,
    DashboardInventoryComponent,
    DashboardVendorInventoryComponent,
  ],
    exports: [
        ProductMainComponent,
        ProductPreviewComponent,
        PoAssociatedTrackingComponent,
        ProductCategoriesComponent,
        AddMultipleImagesComponent
    ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    SharedModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    DragDropModule,
    MatCheckboxModule,
    FormsModule,
    CdkTableModule,
    MatLineModule,
    NgOptimizedImage,
    MatDatepickerModule,
    NgxPermissionsRestrictStubDirective,
    NgxPermissionsModule.forChild(),
    NgbCarousel,
    NgbSlide
  ],

})
export class ProductModule {
}
