import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {NdoTransactionalLogDto} from "@features/request/model/ndo-transactional-log.dto";


@Injectable({
  providedIn: 'root'
})
export class RequestedService {

  private apiUrl = environment.automatePurchasesUrl;
  private saveTransactionalLogUrl = `${this.apiUrl}request/save/transactional_log`;

  constructor(private http: HttpClient) {
  }


  saveTransactionalLog(param: NdoTransactionalLogDto) {
    return this.http.post<any>(this.saveTransactionalLogUrl, param);
  }
}
