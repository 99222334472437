<div class="menu">
  <div class="menu__item"
       *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.PRODUCT_AND_OFFERS, ADMIN_USER]"
       (click)="showHide(subMenu)">
    <mat-icon class="menu__item--icon1" svgIcon="product-and-offers-icon"></mat-icon>
    <span  class="menu__item--text" >Products & Offers</span>
  </div>
  <ul class="menu__submenu" #subMenu>
    <li class="menu__submenu__item" (click)="showHide(menuItemMaster)"
        *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ITEM_MASTER.ITEM_MASTER, ADMIN_USER]">
      <ng-container >
        <mat-icon class="menu__submenu__item--icon" svgIcon="item-master-icon"></mat-icon>
        <span class="menu__submenu__item--text">Item Master Data</span>
      </ng-container>
    </li>
    <li class="menu__submenu__second-menu">
      <ul  #menuItemMaster>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ITEM_MASTER.ITEM_CREATION.ITEM_CREATION, ADMIN_USER]"
            routerLink="/product/create">
          Item Creation
        </li>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ITEM_MASTER.ITEM_LIST, ADMIN_USER]"
            routerLink="/product/list-product">
          Item List
        </li>
      </ul>
    </li>

    <li class="menu__submenu__item" (click)="showHide(menuOfferCreation)"
        *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.OFFER_CREATION.OFFER_CREATION,  ADMIN_USER]">
      <ng-container  >
        <mat-icon class="menu__submenu__item--icon" svgIcon="offer-creation-icon"></mat-icon>
        <span class="menu__submenu__item--text">Offer Creation</span>
      </ng-container>
    </li>
    <li class="menu__submenu__second-menu">
      <ul  #menuOfferCreation>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.OFFER_CREATION.OFFER_WIZARD,  ADMIN_USER]"
            routerLink="/product-offer/new/offer">
          Offer Wizard
        </li>
      </ul>
    </li>
    <li class="menu__submenu__item" (click)="showHide(menuActiveOffer)"
        *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS,  ADMIN_USER]">
      <ng-container  >
        <mat-icon class="menu__submenu__item--icon" svgIcon="active-offers-icon"></mat-icon>
        <span class="menu__submenu__item--text">Active Offers</span>
      </ng-container>
    </li>
    <li class="menu__submenu__second-menu">
      <ul  #menuActiveOffer>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS,  ADMIN_USER]"
            routerLink="/product-offer/list/offers">
          Offer List
        </li>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ACTIVE_OFFERS.ACTIVE_OFFERS, ADMIN_USER]"
            routerLink="/product-offer/list/deals">
          Deals Offers
        </li>
        <li *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.ACTIVE_OFFERS.DOWNLOAD_INVENTORY, ADMIN_USER]"
            routerLink="/product-offer/inventory/download">
          Download Inventory
        </li>
      </ul>
    </li>
    <li class="menu__submenu__item" (click)="showHide(menuCreatePo)"
        *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.PURCHASE_ORDER.PURCHASE_ORDER,  ADMIN_USER]">
      <ng-container  >
        <mat-icon class="menu__submenu__item--icon" svgIcon="purchase-order-icon"></mat-icon>
        <span class="menu__submenu__item--text">Purchase Orders</span>
      </ng-container>
    </li>
    <li class="menu__submenu__second-menu">
      <ul  #menuCreatePo>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.PURCHASE_ORDER.CREATE_PO, ADMIN_USER]"
            routerLink="/product-offer/create-po/undefined/undefined">
          <span>Create PO</span>
        </li>
        <li class="menu__submenu__second--item"
            *ngxPermissionsOnly="[PRODUCT_AND_OFFERS.PURCHASE_ORDER.PO_RECEIVING, ADMIN_USER]"
            routerLink="/product/po_associated_with_tracking">
          <span>Po Associated With Tracking</span>
        </li>
      </ul>
    </li>
  </ul>

</div>


