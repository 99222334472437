import {Component, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomValidator} from "@shared/utility/validators/validators";
import {Router} from "@angular/router";

@Component({
  selector: 'app-verify-confirmation-code',
  templateUrl: './verify-confirmation-code.component.html',
  styleUrls: ['./verify-confirmation-code.component.scss']
})
export class VerifyConfirmationCodeComponent {
  form: UntypedFormGroup;
  @Output() verifyCode = new EventEmitter();
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router) {
    this.form = this.buildForm();
  }

  get passwordField() {
    return this.form.get('password');
  }

  get confirmPasswordField() {
    return this.form.get('confirm_password');
  }

  buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        CustomValidator.hasNumber,
        CustomValidator.hasUpperCase,
        CustomValidator.hasLowerCase,
        CustomValidator.hasSpecialCharacter
      ],],
      code: ['', [Validators.required, Validators.minLength(4)]],
      confirm_password: ['', [Validators.required, Validators.minLength(6)]],
    }, {
      validators: CustomValidator.matchPasswords
    });
  }

  submit() {
    if (this.form.valid) {
      this.verifyCode.emit(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
