import {Component, OnInit} from '@angular/core';
import {RequestByStateDto} from "@features/stats/model/request-by-state.dto";
import {MatDialog} from "@angular/material/dialog";
import {StatsService} from "@features/stats/services/statsService";
import {RequestStateSalesDto} from "@features/stats/model/request-state-sales.dto";
import {DealsWonLostDto} from "@features/stats/model/dealsWonLost.dto";
import {FormControl} from "@angular/forms";
import {SearchStatisticsDto} from "@features/stats/model/search-statistics.dto";
import {DateUtil} from "@shared/utility/dateUtil";

@Component({
  selector: 'app-stats-request-sales',
  templateUrl: './stats-request-sales.component.html',
  styleUrls: ['./stats-request-sales.component.scss']
})
export class StatsRequestSalesComponent implements OnInit {


  startDate = new FormControl("");
  endDate = new FormControl("");
  detailUser!: string;
  detailStatus!: RequestByStateDto[];
  stats!: RequestStateSalesDto[];
  displayedColumns: string[] = [
    'userName', 'requestCreated', 'canvasDownloaded', 'linksCopied', 'feedbackProvided', 'dealsWon', 'dealsLost', 'averageTimeWonLostRequest'
  ];


  constructor(public dialog: MatDialog,
              private statsService: StatsService
  ) {
  }


  ngOnInit(): void {

    this.getStats(new SearchStatisticsDto())
  }

  getStats(searchStatisticsDto: SearchStatisticsDto) {
    this.statsService.getStatsForSales(searchStatisticsDto).subscribe(value => {
      this.stats = value;
      console.log(value)
    })

  }

  time_convert(num: number | undefined) {
    if (num) {
      const hours: number = Math.floor(num);
      const minutes: number = Math.floor(num % 60);
      return `${hours}hr : ${minutes}min`;
    }
    return ''

  }

  filterWonLost(data: DealsWonLostDto[], type: string) {
    const dataFilter: DealsWonLostDto[] = data?.filter((item) => item.type == type)
    return dataFilter[0].value ?? 0;
  }

  showStatus(status: RequestByStateDto[], name: string) {
    this.detailUser = name;
    this.detailStatus = status;
  }

  getFilteredData() {
    if (this.startDate && this.endDate) {
      let searchStatisticsDto = new SearchStatisticsDto();
      searchStatisticsDto.startDate = DateUtil.formatDate(this.startDate.value, "YYYY-MM-DDTHH:mm:ss");
      searchStatisticsDto.endDate = DateUtil.formatDate(this.endDate.value, "YYYY-MM-DDTHH:mm:ss");
      this.getStats(searchStatisticsDto);
    }
  }


}
