import {AfterViewInit, Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {ItemPricesDto} from "@features/product/model/dto/product/itemPrices.dto";
import {ProductService} from "@features/product/services/product.service";


@Component({
  selector: 'app-base-price-list',
  templateUrl: './base-price-list.component.html',
  styleUrls: ['./base-price-list.component.scss']
})
export class BasePriceListComponent implements AfterViewInit {
  @Input()
  basePriceListForm!: UntypedFormGroup;

  @Input()
  itemId!: number;

  @Input()
  sapCode!: string;

  constructor(private productService: ProductService) {
  }

  get tierOneField() {
    return this.basePriceListForm.get('tierOne');
  }

  get tierTwoField() {
    return this.basePriceListForm.get('tierTwo');
  }

  get tierThreeField() {
    return this.basePriceListForm.get('tierThree');
  }

  get tierFourField() {
    return this.basePriceListForm.get('tierFour');
  }

  ngAfterViewInit() {
    if (this.sapCode) {
      this.productService.findItemPrices(decodeURIComponent(this.sapCode)).subscribe({
        next: (value: ItemPricesDto[]) => {
          value.forEach(item => {
            if (item.priceList &&  item.priceList === 6) {
              this.tierFourField?.setValue(item.price)
            }
          })
        }
      })
    }

  }

}
