import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgxPermissionsService} from "ngx-permissions";
import jwt_decode from "jwt-decode";
import {RolesService} from "@shared/services/roles/roles.service";
import {mergeMap, Observable, of} from "rxjs";
import {DAUserRoleDto} from "@shared/model/dto/roles/DAUserRole.dto";
import {catchError} from "rxjs/operators";
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {Router} from "@angular/router";
import {showErrorPopup} from "@shared/utility/popup-message";
import {fetchAuthSession, getCurrentUser, signOut} from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private ngxPermissionService: NgxPermissionsService,
    private rolesService: RolesService,
    private router: Router
  ) {
  }


  private _token: string | undefined;

  public get token(): Promise<any> {
    return fetchAuthSession()
    .then(session => {
       // Extract the idToken
      return session?.tokens?.idToken?.toString();
    })
    .catch(error => {
      console.error('Error fetching auth session', error);
      return Promise.reject('Error fetching auth session');
    });
  }

  async isAuthenticated(): Promise<boolean> {
    let _isAuthenticated: boolean = false;
    await getCurrentUser()
    .then(() => {
      _isAuthenticated = true;
    })
    .catch(() => {
      _isAuthenticated = false;
    });
    return _isAuthenticated;
  }

  async getAuthenticatedUser(): Promise<any> {
    const {username, signInDetails} = await getCurrentUser();
    const {tokens: session} = await fetchAuthSession();

    return {
      username,
      session,
      authenticationFlowType: signInDetails ? signInDetails.authFlowType : undefined
    };
  }

  loadRolesAndPermissions(): Observable<DAUserRoleDto | null> {
    return this.rolesService.getByUserId()
    .pipe(mergeMap((result: DAUserRoleDto) => {
        return of(result);
      }), catchError(() => {
        return of(null)
      })
    )
  }

  loadPermissions(result: DAUserRoleDto) {
    let roles: string[] = [];
    roles.push(result.rol.rolName);
    if (result?.activities) {
      result.activities.forEach(activity => {
        let activitiesSplit = activity.activity.activityDescription.split('/');
        console.log(activitiesSplit)
        if (activitiesSplit) {
          roles.push(activitiesSplit[0]);
        }
        if (activitiesSplit.length == 2) {
          roles.push(activitiesSplit[0] + "/" + activitiesSplit[1]);
        }
        if (activitiesSplit.length == 3) {
          roles.push(activitiesSplit[0] + "/" + activitiesSplit[1]);
          roles.push(activitiesSplit[0] + "/" + activitiesSplit[1] + "/" + activitiesSplit[2]);
        }
        if (activitiesSplit.length == 4) {
          roles.push(activitiesSplit[0] + "/" + activitiesSplit[1] + "/" + activitiesSplit[2]);
          roles.push(activitiesSplit[0] + "/" + activitiesSplit[1] + "/" + activitiesSplit[2] + "/" + activitiesSplit[3]);
        }
      })

    }
    this.ngxPermissionService.loadPermissions(roles);
  }


  logout() {
    this.logoutWithCognito().then(() => {
      localStorage.clear();
      this.router.navigate(['login']).then(() => window.location.reload());
    }).catch(() => {
      showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
    })
  }

  async hasPermission(permissions: string[]) {
    return this.ngxPermissionService.hasPermission(permissions);
  }

  async hasRole(role: string): Promise<boolean> {
    return this.token.then(value => {
      try {
        const payload = jwt_decode(value);
        // @ts-ignore
        return payload["ROLE"].value.includes(role);
      } catch (error) {
        console.error('Invalid token specified', error);
        return false;
      }
    });
  }

  async getRole(): Promise<any> {
    return this.token.then(value => {
      try {
        const payload = jwt_decode(value);
        // @ts-ignore
        return payload["ROLE"];
      } catch (error) {
        console.error('Invalid token specified', error);
        return null;
      }
    });
  }

  async _getFromPayload(key: string): Promise<any> {
    return this.token.then(value => {
      const payload = jwt_decode(value);
      // @ts-ignore
      return payload[key];
    })
  }

  private async logoutWithCognito(): Promise<any> {
    return signOut();
  }

}
