import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormArray, FormControl, UntypedFormArray, UntypedFormGroup, Validators} from "@angular/forms";
import {ItemCode} from "@features/product/model/enum/item-code";
import {Category} from "@core/model/category";
import {CONTACT_SUPPORT, custom_popup, INVALID_OR_MISSING_FIELDS, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ProductProcessType} from "@features/product/model/enum/product-process-type";
import {FindItemDescriptionDto} from "@features/product/model/dto/item/findItemDescription.dto";
import {ImageType} from "@features/product/model/enum/image-type";
import {GetItemImagesDto} from "@features/product/model/dto/product/get-item-images.dto";
import {EMPTY, firstValueFrom, mergeMap, Observable, of} from "rxjs";
import {MatStepper} from "@angular/material/stepper";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ItemSpecificationsDto} from "@features/product/model/dto/item/itemSpecifications.dto";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {ProductService} from "@features/product/services/product.service";
import {ItemCodesDto} from "@features/product/model/dto/item/itemCodes.dto";
import {Router} from "@angular/router";
import {ItemUtility} from "@features/product/model/utility/itemUtility";
import {StringUtility} from "@shared/utility/stringUtility";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";
import {ProductInfoFormService} from "@features/product/features/presentation/product-info/product-info-form.service";
import {AddMultipleImagesFormService} from "@features/product/features/presentation/add-multiple-images/add-multiple-images-form.service";
import {ProductSelectFormService} from "@features/product/features/presentation/product-select/product-select-form.service";
import {ManufactureCreateFormService} from "@features/product/features/presentation/manufacture-create/manufacture-create-form.service";
import {ProductPreviewFormService} from "@features/product/features/presentation/product-preview/product-preview-form.service";


@Component({
  selector: 'app-product-main',
  templateUrl: './product-main.component.html',
  styleUrls: ['./product-main.component.scss']
})
export class ProductMainComponent implements OnInit, AfterViewInit {
  @Input()
  newOfferForm!: UntypedFormGroup;
  productSelectForm!: UntypedFormGroup;
  productPreviewForm!: UntypedFormGroup;
  manufactureCreateForm!: UntypedFormGroup;
  productInfoForm!: UntypedFormGroup;
  addMultipleImagesForm!: UntypedFormGroup;
  processType = new FormControl('', Validators.required);
  conditions: any[] = [];
  allCategories: Array<Array<Category>> = [];
  categories: Category[] = [];
  manufacturers: ProductMasterManufacturer[] = [];
  variant_specifications: any[] = [];

  @Input()
  product!: ProductsDto;
  categoryTree: any = '';
  descriptionCategories!: string;
  _isProductFound!: boolean;
  products: ProductsDto[] = [];
  @Input()
  manufacture!: ProductMasterManufacturer;
  @Input()
  isManufacture!: boolean;

  @Input()
  isProductInfoForm!: boolean;
  /* MANUFACTURE CREATE STEP*/
  itemId!: number;
  @ViewChild('stepperProductMain') myStepper!: MatStepper;

  @Input()
  productProcessType: ProductProcessType = ProductProcessType.PRODUCT_SAVE;

  workflowProcessType = ProductProcessType;

  @Output() callProductStateValidation = new EventEmitter<any>();
  @Output() callNewOfferOption = new EventEmitter<any>();

  @Output() emitUpdateProduct = new EventEmitter<any>();

  productBarcodes: string[] = [];

  constructor(
    private productService: ProductService,
    private router: Router,
    private authService: AuthService,
    private productInfoFormService: ProductInfoFormService,
    private addMultipleImagesFormService: AddMultipleImagesFormService,
    private productSelectFormService: ProductSelectFormService,
    private manufactureCreateFormService: ManufactureCreateFormService,
    private productPreviewFormService: ProductPreviewFormService,
  ) {
  }

  get subCategoriesField() {
    return this.productInfoForm.get('sub_categories') as UntypedFormArray;
  }


  get categoryField() {
    return this.productInfoForm.get('category');
  }


  get modelField() {
    return this.productInfoForm.get('model');
  }

  get manufacturerField() {
    return this.productInfoForm.get('manufacturer');
  }

  get partNumberField() {
    return this.productInfoForm.get('part_number');
  }

  get selectedProductImage() {
    return this.addMultipleImagesForm.get('selected_product_image');
  }

  get manufactureCreateManufactureField() {
    return this.manufactureCreateForm.get('manufacture_create_manufacture');
  }

  get manufactureCreateOptionManufactureField() {
    return this.manufactureCreateForm.get('manufacture_create_option_manufacture');
  }

  get specificationsField() {
    return this.productInfoForm.get('specifications') as FormArray;
  }

  ngOnInit(): void {
    if (this.productProcessType == ProductProcessType.PRODUCT_NEW_OFFER
      || this.productProcessType == ProductProcessType.PRODUCT_UPDATE) {
      this.processType.setValue('0');
    }

    this.manufactureCreateForm = this.manufactureCreateFormService.buildManufactureCreateForm();
    this.productSelectForm = this.productSelectFormService.buildProductSelectForm();
    this.productInfoForm = this.productInfoFormService.buildProductInfoForm();
    this.addMultipleImagesForm = this.addMultipleImagesFormService.buildAddMultipleImagesForm();
    this.productPreviewForm = this.productPreviewFormService.buildProductPreviewForm();

    this.getCategories();
    this.conditions = this.getConditions();
    this.getManufacturers();

    if (this.product?.upc && this.product.upc.length > 0) {
      this.productBarcodes.push(this.product.upc);
    }
    if (this.product?.ean && this.product.ean.length > 0) {
      this.productBarcodes.push(this.product.ean);
    }
  }

  ngAfterViewInit(): void {
    if (this.productProcessType == ProductProcessType.PRODUCT_NEW_OFFER
      || this.productProcessType == ProductProcessType.PRODUCT_UPDATE) {
      console.log('entra al after view init')
      this._isProductFound = true;
      this.isProductInfoForm = this.isManufacture;
      this.moveStep(1);
      this.myStepper.next();
    }
  }

  isProductFound($event: boolean) {
    this._isProductFound = $event;
    if (this._isProductFound) {
      this.moveStep(2);
    } else {
      let product = new ProductsDto();
      this.productSelectForm.get('product_select_value')?.setValue(product);
      this.product = product;
      this.isManufacture = false;
      this.moveStep(2);
    }
  }

// SELECT PRODUCT BY UPC OR EAN OR PART NUMBER OR NAME
  isProductSelect() {
    this.productInfoForm.reset();
    this.manufactureCreateForm.reset();
    this.addMultipleImagesForm.reset();
    this.productPreviewForm.reset();
    if (this.productSelectForm.valid) {
      this.product = this.productSelectForm.get('product_select_value')?.value;
      if (this.productSelectForm.get('type_search_product')?.value == 'upc') {
        this.product.upc = this.productSelectForm.get('product_search_value')?.value;
      } else if (this.productSelectForm.get('type_search_product')?.value == 'ean') {
        this.product.ean = this.productSelectForm.get('product_search_value')?.value;
      }
      let manufacturer = this.manufacturers.find(value => value.ditmmnf_front_dsc == this.product.manufacturer);
      if (manufacturer) {
        this.manufacture = manufacturer;
        this.isManufacture = true;
        this.isProductInfoForm = true;
      } else {
        this.manufacture = new ProductMasterManufacturer();
        this.isManufacture = false;
        this.isProductInfoForm = false;
      }
      this.myStepper.next();
    } else {
      showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS).then(() => {
        this.productSelectForm.markAllAsTouched();
      })
    }

  }

  previousProductInfoForm() {
    if (this.isManufacture) {
      if (this.productProcessType === ProductProcessType.PRODUCT_NEW_OFFER) {
        this.callNewOfferOption.emit(true);
      } else {
        this.resetForm();
      }

    } else {
      this.resetForm();
      if (this.productProcessType === ProductProcessType.PRODUCT_NEW_OFFER) {
        this.callNewOfferOption.emit(true);
      } else {
        this.moveStep(2);
      }

    }

  }


  /*GET PRODUCT SELECT FROM*/

  getSubCategories($event: any) {
    this.allCategories = $event;
  }

  getConditions() {
    return [
      {id: 1, condition: 'New'},
      {id: 2, condition: 'Used'},
      {id: 3, condition: 'Open Box'},
      {id: 4, condition: 'Brown Box'},
      {id: 5, condition: 'Factory Refurbished'},
      {id: 6, condition: '3rd Party Refurbished'},
      {id: 7, condition: 'Damaged Box'}
    ];
  }

  getCategories() {
    this.productService.getCategories().subscribe({
      next: value => {
        ItemUtility.setCategory(value.body, this.categories);
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    })
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err);
      },
    })
  }


  /*STEP TWO*/

  /*LOGIC PRODUCT INFO FROM*/

  validateProductInfoForm() {
    let itemSpecifications: ItemSpecificationsDto[] = [];
    this.specificationsField.controls.forEach((control) => {
      let specification = new ItemSpecificationsDto();
      if (control.get('value')?.value && control.get('value')?.value != '') {
        specification.specifications = control.get('code')?.value;
        specification.descriptionItemSpecific = control.get('value')?.value;
        if (specification.specifications === 'color') {
          this.product.color = specification.descriptionItemSpecific;
        }
        itemSpecifications.push(specification);
      }
    });
    this.productService.validateSpecification(itemSpecifications).subscribe({
      next: (specificationList) => {
        this.product.itemSpecifications = specificationList; // Update itemSpecifications
        let hasErrors = false;
        specificationList.forEach((specification) => {
          let control = this.specificationsField.controls.find((control) => control.get('code')?.value == specification.specifications);
          if (control) {
            if (specification.status === false) {
              control.get('value')?.setErrors({invalid: specification.message});
              hasErrors = true;
            } else {
              control.get('value')?.setErrors(null);
            }
          }
        });

        // Set or clear form-level error based on control errors
        if (hasErrors) {
          this.productInfoForm.setErrors({invalidSpecifications: true});
        } else {
          const currentErrors = this.productInfoForm.errors;
          if (currentErrors) {
            delete currentErrors['invalidSpecifications'];
            if (Object.keys(currentErrors).length === 0) {
              this.productInfoForm.setErrors(null);
            } else {
              this.productInfoForm.setErrors(currentErrors);
            }
          }
        }

        if (this.productInfoForm.valid) {
          this.product.selectCategories = this.categoryField?.value;
          this.product.selectSudCategories = this.subCategoriesField?.value;

          if (this.productProcessType != ProductProcessType.PRODUCT_UPDATE) {
            this.product.images.forEach(value => value.checked = true);
          }

          this.product.condition = this.productInfoForm.get('condition')?.value;

          if (this.productProcessType == ProductProcessType.PRODUCT_UPDATE) {
            this.validateBarcodeWhenUpdate();
          } else if (this.productProcessType == ProductProcessType.PRODUCT_NEW_OFFER) {
            this.executeItemDescriptionAndSku();
            this.findItemImagesAlreadyExists();
          } else {
            this.productExistsValidation()
            .subscribe((result: any) => {
              if (result && result.status) {
                showSuccessPopup(TITLE_INFO, result.message)
                .then(() => {
                  this.moveToWhenItemExists();
                })
              } else {
                this.executeItemDescriptionAndSku();
                this.findItemImagesAlreadyExists();
              }
            });
          }

        } else {
          showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS).then(() => {
            this.productInfoForm.markAllAsTouched();
          })
          if (hasErrors) {
            this.myStepper.previous(); // Move back to the previous step
          }
        }
      },
      error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    });
  }

  async getItemDescription() {
    let obj = Object.create(null);
    let findItemDescription = new FindItemDescriptionDto();

    const lastSubCategory = this.subCategoriesField.controls.slice(-1)[0].get('sub_category')?.value;
    let subCategoryFound = ItemUtility.findSubCategory(this.allCategories, lastSubCategory);
    findItemDescription.subcategory = StringUtility.capitalize(subCategoryFound.values);
    findItemDescription.brand = StringUtility.capitalize(this.getManufacturer(this.manufacturerField?.value).ditmmnf_front_dsc);
    findItemDescription.model = this.modelField?.value;

    this.specificationsField.controls.forEach((control) => {
      if (control.get('value')?.value && control.get('value')?.value != '') {
        obj[control.get('code')?.value] = control.get('value')?.value;
      }
    });
    findItemDescription.specificationsList = obj;
    findItemDescription.condition = this.productInfoForm.get('condition')?.value;

    const getItems = this.productService.getItemDescription(findItemDescription);
    return firstValueFrom(getItems);
  }

  async getSku() {
    let color = this.specificationsField.controls
    .find((control) => control.get('code')?.value == 'color')?.get('value')?.value
    let params = {
      partNumber: this.partNumberField?.value.toUpperCase(),
      brand: this.getManufacturer(this.manufacturerField?.value).ditmmnf_front_dsc,
      condition: this.productInfoForm.get('condition')?.value,
      color: color
    }

    const getSku = this.productService.getSKU(params);
    return firstValueFrom(getSku);
  }

  validateProductSelectImageForm() {
    debugger
    if (this.addMultipleImagesForm.valid) {
      this.product.images.forEach(images =>
        images.status = images.checked
      );
      this.product.itemPictures = this.product.images;
      this.descriptionCategories = this.getDescriptionCategories();
    }else{
      showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS).then(() => {
        this.addMultipleImagesForm.markAllAsTouched();
      })
    }
  }

  getDescriptionCategories(): string {
    let category: string = this.categoryField?.value;
    let subCategory: string = '';
    this.subCategoriesField.controls?.forEach((value: AbstractControl) => {
      subCategory = subCategory.concat(' -> '.concat(value.get('sub_category')?.value));
    });
    return category.concat(subCategory);
  }

  /*LOGIC PRODUCT PREVIEW FROM*/
  saveItem() {
    this.authService._getFromPayload("id_user").then(userId => {
      if (this.productProcessType == ProductProcessType.PRODUCT_UPDATE) {
        this.emitUpdateProduct.emit(this.convertFormToItemDto(userId));
      } else if (this.productInfoForm.valid && this.productSelectForm.valid && this.addMultipleImagesForm.valid) {
        this.productService.createItem(this.convertFormToItemDto(userId)).subscribe({
          next: (response: any) => {
            const createItemResponse = JSON.parse(response);
            if (createItemResponse.status) {
              showSuccessPopup(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.CREATION).then(() => {
                this.itemId = Number(createItemResponse.singleData);
                if (this.productProcessType === ProductProcessType.PRODUCT_NEW_OFFER) {
                  this.validateProductState(this.itemId);
                } else {
                  this.router.navigate([`product-offer/offer-validation/create/${this.itemId}/${encodeURIComponent(this.product.skuSap)}`]);
                }
                this.resetForm();
              });
            } else {
              showErrorPopup(TITLE_ERROR, createItemResponse.message);
            }
          },
          error: (err: any) => {
            console.log(err);
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        });
      } else {
        showSomethingWrongPopup1(TITLE_INFO, INVALID_OR_MISSING_FIELDS);
      }
    })

  }

  getNameCategories(): string {
    let category: string = this.categoryField?.value;
    let subCategory: string = '';
    this.subCategoriesField.controls?.forEach((value: AbstractControl) => {
      subCategory = subCategory.concat('/'.concat(value.get('sub_category')?.value));
    });
    return category.concat(subCategory);
  }

  validateManufactureCreateForm() {
    if (this.manufactureCreateForm.valid) {
      this.isProductInfoForm = true;
      this.product.manufacturer = this.manufactureCreateManufactureField?.value;
      this.product.manufacturer = this.product.manufacturer.trim().toUpperCase();
      if (this.manufactureCreateOptionManufactureField?.value == 'create') {
        let manufacturer = this.manufacturers.find(value => value.ditmmnf_front_dsc == this.product.manufacturer);
        if (manufacturer) {
          this.manufacture = manufacturer;
        } else {
          // @ts-ignore
          this.manufacture = this.manufacturers.find(value => value.ditmmnf_front_dsc == 'APPLE');
          this.showManufacturerCreation();
        }

      } else {
        let manufacturer = this.manufacturers.find(value => value.ditmmnf_front_dsc == this.product.manufacturer);
        if (manufacturer) {
          this.manufacture = manufacturer;
          this.isProductInfoForm = true;
        } else {
          this.isProductInfoForm = false;
        }
      }


    } else {
      this.moveStep(2);
    }

  }

  validatePreviousManufactureCreateForm() {
    this.resetForm();
    if (this.productProcessType === ProductProcessType.PRODUCT_NEW_OFFER) {
      this.callNewOfferOption.emit(true);
    } else {
      this.myStepper.previous();
    }
  }

  /* GENERAL LOGIC*/
  moveStep(index: number) {
    this.myStepper.selectedIndex = index;
  }

  resetForm() {
    this.product = new ProductsDto();
    this.products = [];
    this.manufacturers = [];
    this.isManufacture = false;
    this.isProductInfoForm = false;
    this.productSelectForm.reset();
    this.productInfoForm.reset();
    this.addMultipleImagesForm.reset();
    this.manufactureCreateForm.reset();
    this.getCategories();
    this.conditions = this.getConditions();
    this.getManufacturers();
  }

  private productExistsValidation(): Observable<any> {
    return this.validateBarcode()
    .pipe((mergeMap((response: any) => {
      return this.productService
      .validateItemExistence({
        itemCode: this.product.ean?.length >= 13 ? this.product.ean : this.product.upc,
        patNumber: this.product.partNumber,
        condition: this.product.condition,
      })
    })))
  }

  private validateBarcode() {
    return this.productService.validateItemExistenceInSap(
      {
        type: 'upc',
        value: this.product.ean?.length >= 13
          ? this.product.ean
          : this.product.upc
      })
    .pipe((mergeMap((response: boolean) => {
      if (response) {
        showSomethingWrongPopup1(TITLE_INFO, "Product already exists in sap by barcode, please check!")
        .then(() => this.moveToWhenItemExists())
        return EMPTY;
      }
      return of(response);
    })))
  }

  private moveToWhenItemExists() {
    if (this.productProcessType === ProductProcessType.PRODUCT_NEW_OFFER) {
      this.moveStep(1);
    } else if (this.productProcessType === ProductProcessType.PRODUCT_UPDATE) {
      this.moveStep(0);
    } else {
      if (this.isManufacture) {
        this.moveStep(2);
      } else {
        this.moveStep(3);
      }
    }
  }

  private executeItemDescriptionAndSku() {
    this.getItemDescription().then((itemDescription) => {
      this.getSku().then((sku) => {
        return {itemDescription: itemDescription, sku: sku};
      }).then((result => {
        this.product.itemDescription = result.itemDescription;
        this.product.sku = result.sku;
      })).catch(() => {
        console.log("entra aqui 1")
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      })
    }).catch(() => {
      console.log("entra aqui 2")
      showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
    })
  }

  private findItemImagesAlreadyExists() {
    let params = new GetItemImagesDto();
    params.type = "partNumber";
    params.value = this.product.partNumber;
    this.productService.findProductsImages(params).subscribe({
      next: (value) => {
        if (value && value.length > 0) {
          this.product.images = [];
          value.map(item => item.images)
          .forEach(images => {
            images.forEach(image => {
              let productImage = new ItemPicturesDto();
              productImage.imageType = ImageType.URL;
              productImage.url = image;
              this.product.images.push(productImage);
            })
          })
        }
      }
    })
    this.selectedProductImage?.setValue(this.product.images);
  }

  private validateProductState(itemId: number) {
    this.callProductStateValidation.emit({itemId: itemId, sku: this.product.skuSap});
  }

  private showManufacturerCreation() {
    custom_popup({
      title: 'Create Manufacture',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Create',
      denyButtonText: 'Cancel',
      text: 'Want to create manufacturer ' + this.product.manufacturer,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.saveManufacturer();
      } else if (result.isDenied) {
        this.moveStep(2);
      }
    })
  }

  private saveManufacturer() {
    const params = {
      "manufacturer": {
        "description": this.product.manufacturer
      },
    };
    this.productService.saveManufacture(params).subscribe({
      next: response => {
        this.manufacture.ditmmnf_front_dsc = this.product.manufacturer;
        if (response && response.manufacturer) {
          this.manufacture.ditmmnf_code = response.manufacturer.id;
          this.manufacture.ditmmnf_front_dsc = response.manufacturer.description;
          this.product.idManufacturers = response.manufacturer.id;
          this.manufacturers.push(this.manufacture);
        } else {
          showSomethingWrongPopup1(TITLE_ERROR, CONTACT_SUPPORT);
        }
      },
      error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }


  private selectProductValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<any> => {
      if (!control.value) {
        return of(null);
      }
      let value = this.productSelectForm.get('type_search_product')?.value;
      if (value == 'upc') {
        return control.value.length != 12 ? of({validate_length: true}) : of(null);
      } else if (value == 'ean') {
        return control.value.length != 13 ? of({validate_length: true}) : of(null);
      }
      return of(null);
    };
  }


  private getManufacturer(manufacturerCode: any): any {
    return ItemUtility.getProductMasterManufacturer(manufacturerCode, this.manufacturers);
  }


  private convertFormToItemDto(userId: any) {
    let itemCodes: ItemCodesDto[] = [];
    if (this.productInfoForm.get('upc')?.value && this.productInfoForm.get('upc')?.value != "") {
      ItemUtility.pushItemCodes(itemCodes, ItemCode.UPC, this.productInfoForm.get('upc')?.value);
    }
    if (this.productInfoForm.get('ean')?.value && this.productInfoForm.get('ean')?.value != "") {
      ItemUtility.pushItemCodes(itemCodes, ItemCode.EAN, this.productInfoForm.get('ean')?.value);
    }
    if (itemCodes.length > 0) {
      this.product.itemCodes = itemCodes;
    }

    this.product.idCategories = this.subCategoriesField.controls.slice(-1)[0].get('sub_category')?.value;
    this.product.idManufacturers = this.manufacturerField?.value;
    this.product.creationUser = userId;
    this.product.model = this.modelField?.value;
    return {
      itemDto: this.product,
      nameCategories: this.getNameCategories(),
      source: 'PRODUCT_MASTER',
    }
  }


  private invalidConditionValidator(control: AbstractControl) {
    const condition = control.value;
    if (condition && condition.status === false) {
      return {invalidCondition: condition.message};
    }
    return null;
  }

  private validateBarcodeWhenUpdate() {
    let findBarcode = this.productBarcodes.find(barcode => barcode == this.productInfoForm.get('upc')?.value || barcode == this.productInfoForm.get('ean')?.value);
    if (!findBarcode) {
      this.validateBarcode().subscribe((result: any) => {
        if (!result) {
          this.executeItemDescriptionAndSku();
        }
      })
    }
  }


}

