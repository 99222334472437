import {Injectable} from '@angular/core';
import {environment} from "@env";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {ProductMiscTypes} from "@shared/model/product-misc-types";
import {MiscTypes} from "@shared/model/misc-types";


@Injectable({
  providedIn: 'root'
})
export class ProductMiscTypeService {

  private apiUrl = environment.resourcesUrl;
  private miscTypesWitchDescriptionUrl = `${this.apiUrl}product-master/misc-type-specifications`;
  private miscTypesUrl = `${this.apiUrl}misc-types`;

  constructor(private http: HttpClient) {
  }

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.resourcesApiKey,
    });
  }

  getMiscTypesSpecifications(code: string, description: string): Observable<ProductMiscTypes[]> {
    return this.http.post(this.miscTypesWitchDescriptionUrl, {'code': code, 'description': description},
      {'headers': this.getHttpHeaders()})
    .pipe(
      map((result: any) => {
        let miscTypes: ProductMiscTypes[] = [];
        if (result && result.body.length > 0) {
          miscTypes = result.body;
        }
        return miscTypes;
      }), catchError(() => {
        return [];
      })
    )
  }

  getMiscTypes(code: string): Observable<MiscTypes[]> {
    return this.http.post(this.miscTypesUrl, {'listCode': code},
      {'headers': this.getHttpHeaders()})
    .pipe(
      map((result: any) => {
        let miscTypes: MiscTypes[] = [];
        if (result && result.body.length > 0) {
          miscTypes = result.body;
        }
        return miscTypes;
      }), catchError(() => {
        return [];
      })
    )
  }
}
