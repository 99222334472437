export class DashboardUtil {

  static dateFilter(nativeFilter: string,initialDate: any, finishDate: any): string {
    return `${nativeFilter}:(_cache:(label:'${initialDate} : ${finishDate}',validateStatus:!f,value:!('${initialDate} : ${finishDate}')),extraFormData:(time_range:'${initialDate} : ${finishDate}'),filterState:(label:'${initialDate} : ${finishDate}',validateStatus:!f,value:'${initialDate} : ${finishDate}'),id:${nativeFilter},ownState:())`;
  }

  static granularityFilter(nativeFilter: string, granularity: string): string {
    return nativeFilter + ':(__cache:(label:\'' + granularity + '\',validateStatus:!f,value:!(' + granularity + ')),extraFormData:(time_grain_sqla:' + granularity + '),filterState:(label:\'' + granularity + '\',validateStatus:!f,value:!(' + granularity + ')),id:'+nativeFilter+',ownState:())';
  }

  static cardCodeFilter(nativeFilter: string,vendors: any): string {
    return nativeFilter +':(__cache:(label:\'' + vendors + '\',validateStatus:!f,value:!(' + vendors + ')),extraFormData:(filters:!((col:CardCode,op:IN,val:!(' + vendors + ')))),filterState:(label:\'' + vendors + '\',validateStatus:!f,value:!(' + vendors + ')),id:'+nativeFilter+',ownState:())';
  }

  static salePersonFilter(nativeFilter: string,vendors: any, colName: string): string {
    return nativeFilter +':(__cache:(label:\'' + vendors + '\',validateStatus:!f,value:!(' + vendors + ')),extraFormData:(filters:!((col:'+colName+',op:IN,val:!(' + vendors + ')))),filterState:(label:\'' + vendors + '\',validateStatus:!f,value:!(' + vendors + ')),id:'+nativeFilter+',ownState:())';
  }

  static ownerFilter(nativeFilter: string,owner: any):string{
    return nativeFilter + ':(__cache:(label:\'' + owner + '\',validateStatus:!f,value:!(' + owner + ')),extraFormData:(filters:!((col:mkpspl_sap_owner,op:IN,val:!(' + owner + ')))),filterState:(label:\'' + owner + '\',validateStatus:!f,value:!(' + owner + ')),id:'+nativeFilter+',ownState:())';
  }

  static itemCodeFilter(nativeFilter: string,itemCode: string):string{
    return nativeFilter + ':(__cache:(label:\'' + itemCode + '\',validateStatus:!f,value:!(\'' + itemCode + '\')),extraFormData:(filters:!((col:SKU,op:IN,val:!(\'' + itemCode + '\')))),filterState:(label:\'' + itemCode + '\',validateStatus:!f,value:!(\'' + itemCode + '\')),id:'+nativeFilter+',ownState:())';
  }

  static get openFilter() {
    return '?native_filters=(';
  }

  static get closeFilter() {
    return ')&standalone=2&show_filters=0expand_filters=0';
  }
}
