<section class="internal-header" [ngStyle]="{'visibility': isAuthenticated ? 'visible' : 'hidden'}">
  <ng-container *ngIf="isAuthenticated">
    <img [ngSrc]="'assets/logo.svg'" alt="Logo" class="header-logo" width="100" height="100">
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="check-icon"></mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="chat-globe-icon"></mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="mail-icon"></mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="calendar-icon"></mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="favorite-icon"></mat-icon>
    </button>
    <span class="example-spacer"></span>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="search-icon"></mat-icon>
    </button>
    <button mat-icon-button>
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="notification-icon"></mat-icon>
    </button>
    <span class="internal-header__username">{{userName}}</span><br>
    <button mat-icon-button  [matMenuTriggerFor]="afterMenu">
      <mat-icon [ngStyle]="{'height': '30px'}" svgIcon="user-icon"></mat-icon>
    </button>
  </ng-container>
  <mat-menu #afterMenu="matMenu" xPosition="after">
    <button (click)="logout()" mat-menu-item type="submit">Sign Out</button>
  </mat-menu>

</section>
