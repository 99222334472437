<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="purchaseOrderForm">
        <div class="row">
          <div class="enter-cost-and-quantity-title">
            <h1><strong>Enter costs and quantity for each one of the Items </strong></h1>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h4>Landed Cost</h4>
            <mat-checkbox color="primary" formControlName="landed_cost">Landed Cost</mat-checkbox>
          </div>
        </div>
        <div class="row mt-3" formArrayName="purchase_order">
          <ng-container *ngFor="let order of purchaseOrderField.controls; let i=index">
            <div [formGroupName]="i" class="row">
              <div class="col-3">
                <h4>Item</h4>
                <p>{{'SKU ' + order.get('item_sku')?.value + '  -  ' + 'Item description '
                + order.get('item_description')?.value}}</p>
              </div>
              <div class="col-3">
                <h4>Quantity</h4>
                <mat-form-field appearance="fill">
                  <input (ngModelChange)="calculateProfit(i)"
                         formControlName="item_quantity"
                         matInput
                         min="0"
                         placeholder="0"
                         type="number"
                  >
                </mat-form-field>
              </div>
              <div class="col-3">
                <h4>Unit cost</h4>
                <mat-form-field appearance="fill">
                  <input (ngModelChange)="calculateProfit(i)"
                         formControlName="item_unit_cost"
                         matInput
                         min="0"
                         placeholder="0.00"
                         type="text"
                  >
                  <span matPrefix>USD&nbsp;</span>
                </mat-form-field>
              </div>
              <div class="col-3">
                <h4>Total</h4>
                <mat-form-field appearance="fill">
                  <input formControlName="item_total"
                         matInput
                         min="0"
                         placeholder="0.00"
                         readonly
                         type="text"
                  >
                  <span matPrefix>USD&nbsp;</span>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="marketplace-cost-exist">
                <h1> {{order.get('get_marketplace_cost')?.value}}</h1>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
