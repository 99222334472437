<div class="overlay"></div>


<mat-toolbar class="menu-toggle">
  <mat-toolbar-row>
    <div class="col" style="display: inline">
      <button (click)="sidenav.toggle()" mat-icon-button>
        <mat-icon class="material-icons">menu</mat-icon>
      </button>
    </div>

    <div class="col-4 header-user">
            <ng-container *ngIf="_isAuthenticated && userName">
              <span *ngIf="_isAuthenticated">{{userName}}</span><br>
              <button mat-icon-button *ngIf="_isAuthenticated" [matMenuTriggerFor]="afterMenu">
                <mat-icon [ngStyle]="{'height': '30px', 'color':'white'}" svgIcon="user-icon"></mat-icon>
              </button>
              <mat-menu #afterMenu="matMenu" xPosition="after">
                <button (click)="logout()" mat-menu-item type="submit">Sign Out</button>
              </mat-menu>
            </ng-container>
    </div>
  </mat-toolbar-row>
</mat-toolbar>


<mat-sidenav-container #sidenavContainer  id="sidenavContainer">
    <mat-sidenav #sidenav id="sidenav"  mode="side"   opened="true">
      <mat-nav-list >
        <div>
          <app-header-menu></app-header-menu>
        </div>
        <div class="menu-header">
          <app-da-admin-menu *ngIf="_isAuthenticated" [sidenav]="sidenav"></app-da-admin-menu>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="router">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>

</mat-sidenav-container>

