<div class="filter-business-partner-container">
  <div class="filter-business-partner-container--title" (click)="toggle(filters)">
    <span>FILTER BY
      <mat-icon>{{filterOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon>
    </span>
    <h2>{{title}}</h2>
  </div>
  <mat-card class="filter-business-partner-container--card">
    <mat-card-content #filters id="filters">
      <form  class="row filter-business-partner-container--card__form" [formGroup]="form" >
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="ownerCodeField">
          <mat-label>{{sapUsertype == SapUserType.SAP_BUYER ? 'Buyer' : 'Sales person'}}</mat-label>
          <mat-form-field appearance="outline" class="small" >
            <mat-select   formControlName="ownerCode"
                          (valueChange)="changeSet($event)"
                          panelClass="auto-height"
                          placeholder="Select">
              <mat-option *ngFor="let user of users" [value]="user"
                          class="filter-business-partner-container--card__form--field--option">
                {{userName(user)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="cardCodeField">
          <mat-label>{{sapUsertype == SapUserType.SAP_BUYER ? 'Vendor' : 'Customer'}}</mat-label>
          <mat-form-field appearance="outline">
            <mat-select ngxLazyMatSelect
                        multiple
                        placeholder="Select"
                        formControlName="cardCode"
                        (infiniteScroll)="getNextBatch()"
                        [complete]="offset === total">
              <mat-option *ngFor="let option of options$ | async"
                          [value]="option.cardCode"
                          class="filter-business-partner-container--card__form--field--option">
                <span> {{ option.cardCode + ' - ' + option.cardName}}</span>
                <br>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="autoCardCodeField" >
          <mat-label>Search by Card Code</mat-label>
          <mat-form-field appearance="outline" class="small">
            <input type="text"
                   placeholder="Type here"
                   matInput
                   formControlName="autoCardCode"
                   (input)="filter()"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of customersFilter" [value]="option.cardCode">
                <span> {{ option.cardCode + ' - ' + option.cardName}}</span>
                <br>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="startDateField">
          <mat-label>Start Date</mat-label>
          <mat-form-field appearance="outline" >
            <input [matDatepicker]="picker1"
                   formControlName="startDate"
                   matInput
                   [min]="minDate"
                   [max]="maxDate"
                   placeholder="MM/DD/YYYY">
            <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="endDateField">
          <mat-label>End Date</mat-label>
          <mat-form-field appearance="outline" >
            <input [matDatepicker]="picker2"
                   formControlName="endDate"
                   matInput
                   [min]="minDate"
                   [max]="maxDate"
                   placeholder="MM/DD/YYYY"
            >
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="monthYearField">
          <mat-label>Month and Year</mat-label>
          <app-month-year-picker formControlName="monthYear" (dateChange)="onMonthYearChange($event)"></app-month-year-picker>
        </div>
        <div class="col-4 filter-business-partner-container--card__form--field" *ngIf="granularityField">
          <mat-label>Granularity</mat-label>
          <mat-form-field appearance="outline" [ngStyle]="{'border-color': '#29aae3'}">
            <mat-select  formControlName="granularity" placeholder="Select">
              <mat-option value="P1D"
                          class="filter-business-partner-container--card__form--field--option">
                Day
              </mat-option>
              <mat-option value="P1W"
                          class="filter-business-partner-container--card__form--field--option">
                Week
              </mat-option>
              <mat-option value="P1M"
                          class="filter-business-partner-container--card__form--field--option">
                Month
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="filter-business-partner-container--card__form--actions" >
          <button  (click)="reset()"
                   mat-raised-button
                   [ngStyle]="{'background':'#efefef'}">
            Reset filter
            <mat-icon>restart_alt</mat-icon>
          </button>
          <button  mat-raised-button
                   (click)="filterDashboard()"
                   [disabled]="form.invalid"
                   [ngStyle]="{'background':'#005eac'}">
            Apply filters
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>
