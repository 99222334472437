import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedCode} from "@shared/model/enum/user-customized-code";

export class UserCustomizedUtil {
  static findBuyerSapCode(userCustomized: UserCustomizedDto[]): UserCustomizedDto | undefined {
    return userCustomized && userCustomized.length > 0 ? userCustomized.find(param => param.userCustomizedCode === UserCustomizedCode.SAP_BUYER_CODE)
      : new UserCustomizedDto();
  }

  static findSalesSapCode(userCustomized: UserCustomizedDto[]): UserCustomizedDto | undefined {
    return userCustomized && userCustomized.length > 0 ? userCustomized.find(param => param.userCustomizedCode === UserCustomizedCode.SAP_SALES_CODE)
      : new UserCustomizedDto();
  }

  static findSalesOwnerCode(userCustomized: UserCustomizedDto[]): UserCustomizedDto | undefined {
    return userCustomized && userCustomized.length > 0 ? userCustomized.find(param => param.userCustomizedCode === UserCustomizedCode.SAP_SALESPERSON_CODE_DISTRITECH)
      : new UserCustomizedDto();
  }

  static findBuyerOwnerCode(userCustomized: UserCustomizedDto[]): UserCustomizedDto | undefined {
    return userCustomized && userCustomized.length > 0 ? userCustomized.find(param => param.userCustomizedCode === UserCustomizedCode.SAP_OWNER_CODE_DISTRITECH)
      : new UserCustomizedDto();
  }
}

