import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {custom_popup} from "@shared";

@Component({
  selector: 'app-po-creation',
  templateUrl: './po-creation.component.html',
  styleUrls: ['./po-creation.component.scss']
})
export class PoCreationComponent {

  @Input()
  pocreationForm!: UntypedFormGroup;

  constructor(private router: Router) {
  }


  selectOption() {
    custom_popup({
      title: 'Are you sure?\n',
      html: "<p>You won't be able to revert this!</p>",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: ' Yes!'
    }).then((result) =>
      result.isConfirmed ? this.router.navigate(['/product-offer/new/offer']) : null)
  }


}


