import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {environment} from "@env";
import {SearchRequestDto} from "@features/product/model/dto/request/SearchRequestDto";
import {
  SearchSolicitudeRequestDto
} from "@features/product/model/dto/request/search-solicitude-request.dto";
import {SimilarSkusDto} from "@features/product/model/dto/request/similarSkusDto";
import {NdoTransactionalLogDto} from "@features/request/model/ndo-transactional-log.dto";
import {StatusStatsDto} from "@features/request/model/status-stats-dto";
import {GetStatsDto} from "@features/request/model/GetStats.dto";


@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private savePurchaseOrderUrl = `${this.automatePurchasesUrl}request/save`;
  private findBySpecificationUrl = `${this.automatePurchasesUrl}request/search/all/request/by/user`;
  private findAllUrl = `${this.automatePurchasesUrl}request/search/all/request`;
  private updateStatusUrl = `${this.automatePurchasesUrl}request/update`;
  private saveTypificationCommercialUrl = `${this.automatePurchasesUrl}request/save/typification-commercial`;
  private findSimilarsUrl = `${this.automatePurchasesUrl}request/similar/skus/`;
  private saveTransactionalLogUrl = `${this.automatePurchasesUrl}request/save/transactional_log`;
  private findAllActiveRequestUrl = `${this.automatePurchasesUrl}request/search/all/active/request`;
  private findAllMyRequestUrl = `${this.automatePurchasesUrl}request/search/all/my-request`;
  private statusStatsUrl = `${this.automatePurchasesUrl}request/stats`;

  constructor(private httpClient: HttpClient) {
  }


  savePurchaseOrder(request: WRequestDto): Observable<string> {
    return this.httpClient.post<string>(this.savePurchaseOrderUrl, request);
  }

  findBySpecification(value: SearchSolicitudeRequestDto): Observable<SearchRequestDto> {
    return this.httpClient.post<SearchRequestDto>(this.findBySpecificationUrl, value);
  }

  findAll(value: SearchSolicitudeRequestDto): Observable<SearchRequestDto> {
    return this.httpClient.post<SearchRequestDto>(this.findAllUrl, value);
  }

  findAllActiveRequest(value: SearchSolicitudeRequestDto):Observable<any>{
    return this.httpClient.post<any>(this.findAllActiveRequestUrl, value);
  }
  findAllMyRequest(value: SearchSolicitudeRequestDto):Observable<any>{
    return this.httpClient.post<any>(this.findAllMyRequestUrl, value);
  }

  updateStatus(request: WRequestDto) {
    return this.httpClient.post<any>(this.updateStatusUrl, request);
  }

  saveTypificationCommercial(request: WRequestDto) {
    return this.httpClient.post<any>(this.saveTypificationCommercialUrl, request);
  }

  findSimilars(requestId: number): Observable<SimilarSkusDto[]> {
    return this.httpClient.get<SimilarSkusDto[]>(this.findSimilarsUrl + requestId);
  }

  saveTransactionalLog(param: NdoTransactionalLogDto) {
    return this.httpClient.post<any>(this.saveTransactionalLogUrl, param);
  }
  statusStats(getStatsDto: GetStatsDto) {
    return this.httpClient.post<StatusStatsDto>(this.statusStatsUrl,getStatsDto);
  }

}
