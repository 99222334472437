<form [formGroup]="form">
  <mat-label>{{label}}</mat-label>
  <mat-form-field appearance="outline" class="small">
    <input type="text"
           placeholder="Type here"
           matInput
           formControlName="cardName"
           (input)="callBusinessPartner()"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="cardCodeField?.setValue($event.option.value)">
      <mat-option class="card-name-option"
                  *ngFor="let option of businessPartnerList"
                  [value]="option.carCode">
        <span> {{ option.carCode + ' - ' + option.cardName}}</span>
        <br>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
