<mat-card class="login-card">
  <mat-card-header>
    <mat-card-title class="login-card--title"><h3>Login</h3></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div [formGroup]="form" (keydown.enter)="onEnter($event)">
      <div class="row">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Email</mat-label>
          <input formControlName="email"
                 id="account_email"
                 matInput
                 type="email"
          >
        </mat-form-field>
        <div *ngIf="emailField!.touched && emailField!.invalid">
          <mat-error *ngIf="emailField!.hasError('pattern')">
            Please enter a valid email address
          </mat-error>
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>Password</mat-label>
          <input autocomplete="on"
                 formControlName="password"
                 id="account_password"
                 matInput
                 type="password"
          >
        </mat-form-field>
        <div *ngIf="passwordField!.touched && passwordField!.invalid">
          <mat-error *ngIf="passwordField!.hasError('minlength')">
            Must be at least 6 characters!
          </mat-error>
        </div>
      </div>
      <div class="login-card--submit">
        <button  (click)="submit()" [ngStyle]="{'background':'#005eac'}"
                 id="login"
                 mat-raised-button>
          Login
        </button>

      </div>
      <div class="login-card--recover-password">
        <a class="forgot-password" routerLink="reset/password">Forgot your Password?</a>
      </div>
    </div>
  </mat-card-content>
</mat-card>
