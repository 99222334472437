import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {DAUserRoleDto} from "@shared/model/dto/roles/DAUserRole.dto";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private apiUrl = environment.oauthUrl;
  private getByUserIdUrl = `${this.apiUrl}roles/get`;
  constructor(private http: HttpClient) { }

  getByUserId(): Observable<any>{
    return this.http.get<DAUserRoleDto>(this.getByUserIdUrl);
  }
}
