import { Component } from '@angular/core';
import {MARKETPLACE, REQUESTS} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-marketplace-menu',
  templateUrl: './da-marketplace-menu.component.html',
  styleUrls: ['./da-marketplace-menu.component.scss']
})
export class DaMarketplaceMenuComponent {
  showSubMenu = false;

  protected readonly MARKETPLACE = MARKETPLACE;

  showHide(ItemSubMenu: HTMLUListElement) {
    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      ItemSubMenu.style.display = 'block'
    }else {
      ItemSubMenu.style.display = 'none'
    }

  }

  protected readonly REQUESTS = REQUESTS;
}
