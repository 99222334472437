export const CUSTOMER_SERVICE_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    SUCCESS: 'Thank you, we have registered your request, you will receive a response from us shortly.',
    ERROR: 'We have encountered an issue and our technical staff have been advised. Please try again later',
    COMMITMENT_EXCEPTION: 'You can report this request in the commitment list.'
  }
}

export const LOGIN_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    ERROR: 'Please check your email and password, the information provided is not valid.'
  }
}
export const RESET_PASSWORD_CONST = {
  DEFAULT_MESSAGE_RESPONSE: {
    SUCCESS: 'We have successfully changed your password, you will be redirected now to the login form!'
  }
}

export const UPLOAD_ATTACHMENTS = {
  SALES_ORDER: {
    WARNING_FORMAT: 'File type invalid, please check!',
    WARNING_SIZE: 'File type invalid, file size cannot be larger than 2 MB, please check!',
  },
}
