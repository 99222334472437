import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ItemDto} from "@features/product/model/dto/item/item.dto";


@Component({
  selector: 'app-enter-cost-and-quantity',
  templateUrl: './enter-cost-and-quantity.component.html',
  styleUrls: ['./enter-cost-and-quantity.component.scss']
})
export class EnterCostAndQuantityComponent {


  @Input()
  purchaseOrderForm!: FormGroup;


  @Input()
  productsToOrder: ItemDto[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  get purchaseOrderField() {
    return this.purchaseOrderForm.get('purchase_order') as FormArray;
  }

  buildForm(sku: string) {
    return this.formBuilder.group({
      item_sku: [sku],
      quantity: ['', [Validators.required, Validators.min(1)]],
      unit_cost: [0, [Validators.required, Validators.min(1)]],
      total: [0, [Validators.min(1)]]
    })
  }

  getQuantity(index: number) {
    return this.purchaseOrderField.at(index).get('item_quantity');
  }

  getUnitCost(index: number) {
    return this.purchaseOrderField.at(index).get('item_unit_cost');
  }


  calculateProfit(index: number) {
    let total = (this.getQuantity(index)?.value * this.getUnitCost(index)?.value).toFixed(2);
    this.purchaseOrderField.at(index).get('item_total')?.setValue(total);
  }


}
