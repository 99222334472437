export const ADMIN_USER = 'Admin User';

export const DASHBOARD_AND_ANALYTICS = {
  DASHBOARD_AND_ANALYTICS: 'Dashboard & Analytics',
  COMPANY_ANALYTICS: 'Dashboard & Analytics/Rolling Metrics',
  ANALYTICS: {
    ANALYTICS: 'Dashboard & Analytics/Analytics',
    FINANCIALS: 'Dashboard & Analytics/Analytics/Financials',
    INVENTORY: 'Dashboard & Analytics/Analytics/Inventory',
    INVENTORY_VENDOR: 'Dashboard & Analytics/Analytics/Vendors Inventory',
    OFFERS: 'Dashboard & Analytics/Analytics/Offers',
    REQUESTS: 'Dashboard & Analytics/Analytics/Requests',
    ORDERS: 'Dashboard & Analytics/Analytics/Orders',
    MARKETPLACE: 'Dashboard & Analytics/Analytics/Marketplace',
    CUSTOMERS: 'Dashboard & Analytics/Analytics/Customers',
    VENDORS: 'Dashboard & Analytics/Analytics/Vendors',
    KEY_METRICS: 'Dashboard & Analytics/Analytics/Key Metrics'
  },
  FORECASTS: {
    FORECASTS: 'Dashboard & Analytics/Forecast',
    FORECAST_SALES_ANALYSIS: 'TODO',
    FORECAST_PRODUCT_DEMANDS: 'TODO',
  },
  MY_GOALS: {
    MY_GOALS: 'Dashboard & Analytics/My Goals',
    GOALS_SETTINGS: 'TODO'
  }
}

export const PRODUCT_AND_OFFERS = {
  PRODUCT_AND_OFFERS: 'Products & Offers',
  ITEM_MASTER: {
    ITEM_MASTER: 'Products & Offers/Item Master',
    MARGIN_CONFIGURATION: 'Products & Offers/Item Master/Margin Configurator',
    ITEM_CREATION: {
      ITEM_CREATION: 'Products & Offers/Item Master/Item Creation',
      INDIVIDUAL_CREATION: 'TODO',
      MASSIVE_CREATION: 'TODO',
    },
    ITEM_LIST: 'Products & Offers/Item Master/Item List',
    PRICE_EDITOR: 'Products & Offers/Item Master/Price Editor',
  },
  OFFER_CREATION: {
    OFFER_CREATION: 'Products & Offers/Offers',
    OFFER_WIZARD: 'Products & Offers/Offers/Offer Creation',
  },
  ACTIVE_OFFERS: {
    ACTIVE_OFFERS: 'Products & Offers/Offers/Active Offer List',
    DOWNLOAD_INVENTORY: 'Products & Offers/Offers/Download Inventory',
  },
  PURCHASE_ORDER: {
    PURCHASE_ORDER: 'Products & Offers/Purchase Orders',
    CREATE_PO: 'Products & Offers/Purchase Orders/PO Creation',
    OPEN_PO: 'Products & Offers/Purchase Orders/Open POs & Edition',
    REPURCHASE_RECOMMENDATION: 'Products & Offers/Purchase Orders/Repurchase Recomendations',
    PO_RECEIVING: 'Products & Offers/Purchase Orders/PO Receiving'
  },

}

export const REQUESTS = {
  REQUESTS: 'Requests',
  ACTIVE_REQUESTS: 'Requests/Active Requests',
  MY_REQUESTS: 'Requests/My Request',
  CREATE_REQUEST: 'Requests/Create Requests',
  BUYERS_STATS: 'Requests/Buyer Stats',
  SALES_STATS: 'Requests/Sales Stats'

}

export const MARKETPLACE = {
  MARKETPLACE: 'Marketplace',
  PRODUCT_MANAGER: {
    PRODUCT_MANAGER: 'TODO',
    ACTIVE_LISTINGS: 'TODO',
    OFFER_EXCEPTIONS: 'TODO',
    MARGIN_CONFIGURATION: 'TODO',
  },
  PO_FINANCE: 'Marketplace/PO Finance',
  STATS: {
    STATS: 'TODO',
    ACCOUNT_BALANCE: 'TODO'
  }
}

export const ORDERS = {
  ORDERS: 'Orders',
  ORDERS_GENERATION: {
    ORDERS_GENERATION: 'Orders/Order Generation',
    ORDERS_AUDIT: 'Orders/Order Generation/Auditoria de ordenes'
  },
  AUTOMATED_ORDERS_FLOW: {
    AUTOMATED_ORDERS_FLOW: 'Orders/Flujo automatizado de Ordenes',
    DISCOUNTS_AND_NEGOTIATIONS: 'Orders/Flujo automatizado de Ordenes/Descuentos y Negociaciones',
    APPROVAL_EXCEPTIONS: 'Orders/Flujo automatizado de Ordenes/Aprobación de excepciones',
    ALERTS_VALIDATIONS: 'Orders/Flujo automatizado de Ordenes/Validación de Alertas'
  },
  AUTOMATIC_ORDERS_APPROVAL: 'Orders/Priorizacion de Ordenes',
  ORDERS_TO_DELIVER: 'Orders/Ordenes por Despachar',
  ACTIVE_ORDERS: {
    ACTIVE_ORDERS: 'Orders/Active Orders',
    ORDERS_EDIT: 'Orders/Active Orders/Edicion de Ordenes',
    CHANGE_ORDER_STATUS: 'Orders/Active Orders/Change Order Status',
    EDIT_ITEMS: 'Orders/Active Orders/Edit items'
  },
  ORDERS_WARNINGS: 'Orders/Sales Orders Warning'

}
export const CUSTOMERS = {
  CUSTOMERS: 'Customers',
  MY_CUSTOMERS: {
    MY_CUSTOMERS: 'Customers/My Customers',
    PROFILE: 'Customers/My Customers/Profile',
    CONTACTS: 'Customers/My Customers/Contactos',
    ADDRESS: 'Customers/My Customers/Direcciones',
    COMMERCIAL_OPPORTUNITIES: 'Customers/My Customers/Oportunidades Comerciales',
    BILLING: {
      BILLING: 'Customers/My Customers/Cuentas por Cobrar',
      AUTOMATED_CHARGE: 'Customers/My Customers/Cuentas por Cobrar/Cobro Automatizado'
    },
    REMINDERS_BACKLOG: 'Customers/My Customers/Bitacora de Atención',
    FINANCING: 'Customers/My Customers/Financiación',
    SERVICE_CASES: 'Customers/My Customers/Casos de Servicio',
    DATA_QUALITY: 'Customers/My Customers/Contactos/Calidad de Datos'

  },

}
export const ECOMMERCE = 'eCommerce';
export const MY_ACCOUNT = {
  MY_ACCOUNT: 'MyAccount'
}
export const SERVICE_DESK = {
  SERVICE_DESK: 'Service Desk'
}
export const ADMIN = {
  ADMIN: 'Admin'
}

export const ROLES = {
  BUYERS_LEADER: 'Lider de Compras',
  BUYER: 'Comprador de Categ.',
  ECOMMERCE_SPECIALIST: 'eCom Specialist',
  ADMIN: 'Admin User',
  SALES: 'Account Manager',
  ACCOUNT_MANAGER: 'Key Account Manager',
  MARKETPLACE_SPEC: 'Marketplace Spec.',

}
