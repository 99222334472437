<div class="request-information">
  <div class="request-information--title">
    <h1>Please give us as much information as possible to find the
      right product
    </h1>
  </div>


  <div *ngIf="product" class="row">
    <div>
      <mat-card class="product_custom">
        <mat-card-header>
          <mat-card-title>
            <p>{{product.itemDescription}}</p>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row product-container">
            <div class="col-5 mx-auto">
              <img [src]="product.image" alt="item image" height="200px" width="200px">
            </div>
            <div class="col-7">
              <p *ngIf="product.sku"><strong>SKU:</strong> {{product.sku}} </p>
              <p><strong>Manufacturer:</strong> {{product.manufacturers
                ? product.manufacturers.description : ''}}</p>
              <p><strong>Model:</strong>{{product.model ?? ''}}</p>
              <p><strong>Part Number:</strong>{{product.partNumber ?? ''}}</p>
              <p><strong>Condition:</strong>{{product.condition ?? ''}}</p>
              <p><strong>Category: </strong>{{product.itemCategories.description ?? ''}}</p>
              <p *ngIf="eanField(product)"><strong>EAN:</strong>{{eanField(product)}}</p>
              <p *ngIf="upcField(product)"><strong>UPC:</strong>{{upcField(product)}}</p>
              <ul>
                <li>
                  <strong>Tech specs:</strong>
                  <div *ngFor="let spec of product.itemSpecifications">
                    <ul>
                      <div>
                        <li>
                          <strong>{{spec.specifications}}</strong>:{{spec.descriptionItemSpecific}}
                        </li>
                      </div>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <form [formGroup]="formInformation" class="request-information--form">
    <ng-container *ngIf="!product">
      <div class="row">
        <mat-form-field appearance="fill" class="col-12">
          <input formControlName="description" matInput placeholder="Product Name/Description"
                 type="text">
        </mat-form-field>
        <mat-error *ngIf="formInformation.controls['description'].hasError('maxlength')"
                   class="errorsMessages">
          <p>The maximum characters is 200</p>
        </mat-error>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="col-12">
          <input formControlName="partNumber" matInput placeholder="Manufacturer PartNumber"
                 type="text">
        </mat-form-field>
        <mat-error *ngIf="formInformation.controls['partNumber'].hasError('maxlength')"
                   class="errorsMessages">
          <p>The maximum characters for partNumber is 16</p>
        </mat-error>
      </div>
      <div class="row">
        <mat-form-field appearance="fill" class="col-12">
          <input formControlName="upc" matInput placeholder="UPC/EAN" type="text">
        </mat-form-field>
        <mat-error *ngIf="formInformation.controls['upc'].hasError('minlength')"
                   class="errorsMessages">
          <p>Check the number of characters for UPC 12 for EAN 13</p>
        </mat-error>
      </div>
      <div class="row">
        <div class="col-12">
          <app-item-categories [categories]="categories" [form]="formInformation">
          </app-item-categories>
        </div>
      </div>
    </ng-container>

    <div class="row">
      <mat-form-field appearance="fill" class="col-6">
        <input formControlName="priceMin" matInput placeholder="Target Unit Price Min" step="0.2"
               type='number'>
      </mat-form-field>
      <mat-error *ngIf="formInformation?.hasError('max_price_validator')" class="errorsMessages">
        <p>Check the price Min</p>
      </mat-error>

      <mat-form-field appearance="fill" class="col-6">
        <input formControlName="priceMax" matInput placeholder="Target Unit Price max" step="0.2"
               type='number'>
      </mat-form-field>
      <mat-error *ngIf="formInformation?.hasError('max_price_validator')" class="errorsMessages">
        <p>Check the price Max</p>
      </mat-error>


    </div>
    <div class="row">
      <mat-form-field appearance="fill" class="col-6">
        <input formControlName="quantityMin" matInput placeholder="Estimated Quantity Min"
               type='number'
        >
        <mat-error *ngIf="formInformation?.hasError('max_quantity_validator')"
                   class="errorsMessages">
          <p>Check the quantity Min</p>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-6">
        <input formControlName="quantityMax" matInput placeholder="Quantity Max"
               type="number">
        <mat-error *ngIf="formInformation?.hasError('max_quantity_validator')"
                   class="errorsMessages">
          <p>Check the quantity Max</p>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="fill" class="col-6">
        <input formControlName="eta" matInput placeholder="Expected ETA" type='number'>
      </mat-form-field>

      <mat-form-field appearance="fill" class="col-6">
        <mat-label>Select</mat-label>
        <mat-select formControlName="paymentTerms">
          <mat-option value="Prepaid/Cash">Prepaid/Cash</mat-option>
          <mat-option value="Credit/Line">Credit/Line</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="fill" class="col12">
        <mat-label>Additional Product/Tech specs</mat-label>
        <textarea  formControlName="techSpec" matInput></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<app-loader></app-loader>


