import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {Observable} from "rxjs";
import {FindAllOfferParamsDto} from "@features/product-offer/model/offer-validation/findAllOfferParams.dto";
import {FindAllOfferParamsResponseDto} from "@features/product-offer/model/offer-validation/findAllOfferParamsResponse.dto";
import {FeedbackRequestDto} from "@features/product/model/dto/request/feedback-request.dto";
import {SearchItemWareHouseDto} from "@features/product-offer/model/offer-validation/searchItemWareHouse.dto";
import {ValidateCostDto} from "@features/product-offer/model/offer-validation/validateCost.dto";

@Injectable({
  providedIn: 'root'
})
export class ProductOfferService {

  private apiUrl = environment.automatePurchasesUrl;
  private findAllOffersUrl = `${this.apiUrl}offer-validation/find/all`;
  private findOneByItemIdUrl = `${this.apiUrl}offer-validation/find/by/itemId/`;
  private findAllByItemIdUrl = `${this.apiUrl}offer-validation/find/all/by/itemId/`;
  private findLastByUserIdUrl = `${this.apiUrl}offer-validation/find/last/by/userId/`;
  private getBasePricesByItemIdUrl = `${this.apiUrl}offer-validation/find/starting/prices/by/itemId/`;
  private findAllOffersForRequestUrl = `${this.apiUrl}offer-validation/find/all/offer/for/request`;
  private downloadImageUrl = `${this.apiUrl}offer-validation/download/image`;
  private saveOfferFeedbackUrl = `${this.apiUrl}offer-validation/save/offer-feedback`;
  private getInventoryEtaUrl = `${this.apiUrl}offer-validation/get/warehouse-eta`;
  private getFeedbackUrl = `${this.apiUrl}offer-validation/offer-feedback/`;
  private validateCostMkpUrl = `${this.apiUrl}offer-validation/validate/cost-mkp`;


  constructor(private http: HttpClient) {
  }

  findAllOffers(params: FindAllOfferParamsDto) {
    return this.http.post<any>(this.findAllOffersUrl, params);
  }

  findOneByItemId(itemId: number) {
    return this.http.get<any>(this.findOneByItemIdUrl + itemId);
  }

  findAllByItemId(itemId: number) {
    return this.http.get<any>(this.findAllByItemIdUrl + itemId);
  }

  findLastByUser(userId: number) {
    return this.http.get<any>(this.findLastByUserIdUrl + userId);
  }

  getBasePricesByItemId(itemId: number): Observable<any> {
    return this.http.get<any>(this.getBasePricesByItemIdUrl + itemId);
  }

  findAllOffersForRequest(params: FindAllOfferParamsDto): Observable<any> {
    return this.http.post<FindAllOfferParamsResponseDto>(this.findAllOffersForRequestUrl, params);
  }

  downloadImage(url: string): Observable<any> {
    return this.http.post(this.downloadImageUrl, url, {responseType: 'text'});
  }

  saveFeedback(offerFeedback: FeedbackRequestDto): Observable<any> {
    return this.http.post(this.saveOfferFeedbackUrl, offerFeedback);
  }

  getInventoryEta(searchItemWareHouseDto: SearchItemWareHouseDto): Observable<any> {
    return this.http.post(this.getInventoryEtaUrl, searchItemWareHouseDto);
  }

  getFeedback(offerId: number): Observable<FeedbackRequestDto[]> {
    return this.http.get<any>(this.getFeedbackUrl + offerId);
  }

  validateCostMkp(params: ValidateCostDto): Observable<any> {
    return this.http.post<any>(this.validateCostMkpUrl, params);
  }


  inventoryDistritech(itemCode: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + 'product-info/inventory/distritech/' + itemCode);
  }

  inventoryAvailable(itemCode: string, docEntry: string): Observable<any> {
    const body = { itemCode, docEntry };
    return this.http.post<any>(this.apiUrl + 'product-info/open-orders/by-item-code', body);
  }
}
