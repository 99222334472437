import {RequestComponent} from "@features/request/presentation/request/request.component";
import {RequestMainComponent} from "@features/request/smart/request-main/request-main.component";
import {NgModule} from "@angular/core";
import {RequestRoutingModule} from "@features/request/request-routing.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatCardModule} from "@angular/material/card";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonModule} from "@angular/material/button";
import {MatStepperModule} from "@angular/material/stepper";
import {
  RequestInformationComponent
} from './presentation/request-information/request-information.component';
import {MatSelectModule} from "@angular/material/select";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatIconModule} from "@angular/material/icon";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {SharedModule} from "@shared/shared.module";
import {MatSortModule} from "@angular/material/sort";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {
  RequestUpdateStatusComponent
} from './presentation/request-update-status/request-update-status.component';
import {
  RequestDetailPreviewComponent
} from './presentation/request-deatil-preview/request-detail-preview.component';
import {FindCustomerComponent} from './presentation/find-customer/find-customer.component';
import {
  TypificationEditComponent
} from './presentation/typification-edit/typification-edit.component';
import {MatListModule} from "@angular/material/list";
import {ProductOfferModule} from "@features/product-offer/product-offer.module";
import {
  SelectMultipleSkusComponent
} from './presentation/select-multiple-skus/select-multiple-skus.component';
import {ShowSkusComponent} from './presentation/show-skus/show-skus.component';
import { MyRequestComponent } from './presentation/my-request/my-request.component';
import { ActiveRequestComponent } from './presentation/active-request/active-request.component';
import { RequestDetailComponent } from './presentation/request-detail/request-detail.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NgxLazyMatSelectModule} from "ngx-lazy-mat-select";

@NgModule({
  declarations: [
    RequestComponent,
    RequestMainComponent,
    RequestInformationComponent,
    RequestUpdateStatusComponent,
    RequestDetailPreviewComponent,
    RequestDetailPreviewComponent,
    FindCustomerComponent,
    TypificationEditComponent,
    SelectMultipleSkusComponent,
    ShowSkusComponent,
    MyRequestComponent,
    ActiveRequestComponent,
    RequestDetailComponent

  ],
  imports: [
    RequestRoutingModule,
    CommonModule,
    MatCardModule,
    ReactiveFormsModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatStepperModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    SharedModule,
    MatSortModule,
    MatPaginatorModule,
    NgxMaterialTimepickerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    ProductOfferModule,
    NgOptimizedImage,
    MatTooltipModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgxLazyMatSelectModule,


  ],

})
export class RequestModule {
}
