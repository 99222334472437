<div class="product-main-container">
  <mat-stepper #stepperProductMain labelPosition="bottom" linear="true">
    <mat-step *ngIf="productProcessType == workflowProcessType.PRODUCT_SAVE" [editable]="true"
              [stepControl]="processType">
      <app-product-process-type [processType]="processType"></app-product-process-type>
      <button color="primary" mat-raised-button matStepperNext>Next Page</button>
    </mat-step>
    <ng-container [ngSwitch]="processType?.value">
      <ng-template ngSwitchCase="0">
        <mat-step *ngIf="productProcessType == workflowProcessType.PRODUCT_SAVE" [editable]="true"
                  [stepControl]="productSelectForm">
          <ng-template matStepLabel>Product search</ng-template>
          <app-product-select
            (isProductFound)="isProductFound($event)"
            (isProductSelect)="isProductSelect()"
            [productSelectForm]="productSelectForm"
            [products]="products"
          >
          </app-product-select>
          <button mat-raised-button matStepperPrevious>Previous</button>
        </mat-step>
        <mat-step *ngIf="!isManufacture" [editable]="true" [stepControl]="manufactureCreateForm">
          <ng-template matStepLabel>Manufacture create</ng-template>
          <app-manufacture-create
            [manufactureCreateForm]="manufactureCreateForm"
            [manufacturers]="manufacturers"
            [product]="product"
          >
          </app-manufacture-create>
          <button (click)="validatePreviousManufactureCreateForm()" mat-raised-button>Previous
          </button>
          <button (click)="validateManufactureCreateForm()" color="primary" mat-raised-button
                  matStepperNext>Next Page
          </button>
        </mat-step>
        <mat-step [editable]="true" [stepControl]="productInfoForm">
          <ng-template matStepLabel>Product information</ng-template>
          <app-product-info
            (emitSubCategories)="getSubCategories($event)"
            [categories]="categories"
            [conditions]="conditions"
            [isProductInfoForm]="isProductInfoForm"
            [manufacture]="manufacture"
            [manufacturers]="manufacturers"
            [productInfoForm]="productInfoForm"
            [productProcessType]="productProcessType"
            [product]="product"
            [variant_specifications]="variant_specifications"
          >
          </app-product-info>
          <div class="stepper_button_container">
            <button (click)="previousProductInfoForm()"
                    *ngIf="productProcessType != workflowProcessType.PRODUCT_UPDATE"
                    mat-raised-button
                    matStepperPrevious>Previous
            </button>
            <button (click)="validateProductInfoForm()" color="primary" mat-raised-button
                    matStepperNext>Next Page
            </button>
          </div>
        </mat-step>
        <mat-step [editable]="true" [stepControl]="addMultipleImagesForm">
          <ng-template matStepLabel>Product select image</ng-template>
          <app-add-multiple-images
            [addMultipleImagesForm]="addMultipleImagesForm"
            [product]="product"
          >
          </app-add-multiple-images>
          <div class="stepper_button_container">
            <button mat-raised-button matStepperPrevious>Previous</button>
            <button (click)="validateProductSelectImageForm()" color="primary" mat-raised-button
                    matStepperNext>Next Page
            </button>
          </div>
        </mat-step>
        <mat-step [editable]="true">
          <ng-template matStepLabel>Product preview</ng-template>
          <app-product-preview
            [descriptionCategories]="descriptionCategories"
            [productPreviewForm]="productPreviewForm"
            [isDialog]="false"
            [product]="product"
          >
          </app-product-preview>
          <div class="stepper_button_container">
            <button mat-raised-button matStepperPrevious>Previous</button>
            <button (click)="saveItem()" color="primary" mat-raised-button>Submit form</button>
          </div>
        </mat-step>
      </ng-template>
      <ng-template ngSwitchCase="1">
        <mat-step [editable]="true">
          <app-product-upload-template></app-product-upload-template>
          <button mat-raised-button matStepperPrevious>Previous</button>
        </mat-step>
      </ng-template>
      <ng-template ngSwitchDefault>
        <h1>Not selected yet</h1>
      </ng-template>
    </ng-container>
  </mat-stepper>
</div>
<app-loader></app-loader>
