import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {LoadingService} from "@shared/services/loading.service";
import {AmplifyService} from "@shared/services/amplify/amplify.service";
import {ERROR_MESSAGE, TITLE_ERROR, TITLE_SUCCESS} from "@shared";
import {RESET_PASSWORD_CONST} from "@shared/config/constants/popup-message.const";
import {showErrorPopup, showSuccessPopup} from "@shared/utility/popup-message";


@Component({
  selector: 'app-reset-password-main',
  templateUrl: './reset-password-main.component.html',
  styleUrls: ['./reset-password-main.component.scss']
})
export class ResetPasswordMainComponent {

  showVerifyPasswordView: boolean;
  userName!: string;

  constructor(private amplifyService: AmplifyService,
              private router: Router,
              private loader: LoadingService,) {
    this.showVerifyPasswordView = false;
  }


  sendConfirmationCode($event: any) {
    this.loader.show();
    this.userName = $event.email;
    this.amplifyService.sendConfirmationCodeToResetPassword(this.userName).then(result => {
      this.loader.hide();
      if (result) {
        this.showVerifyPasswordView = true;
      }
    }).catch(error => {
      this.loader.hide();
      showErrorPopup(TITLE_ERROR, ERROR_MESSAGE);
    });
  }

  verifyConfirmationCode($event: any) {
    this.loader.show();
    console.log($event);
    this.amplifyService.passwordRecovery(this.userName, $event.code, $event.password).then(() => {
      this.loader.hide();
      showSuccessPopup(TITLE_SUCCESS, RESET_PASSWORD_CONST.DEFAULT_MESSAGE_RESPONSE.SUCCESS).then(() => {
        this.router.navigate(['login']);
      });
    }).catch(error => {
      this.loader.hide();
      showErrorPopup(TITLE_ERROR, ERROR_MESSAGE);
    });
  }
}
