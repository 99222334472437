import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";


@Component({
  selector: 'app-moq-setup',
  templateUrl: './moq-setup.component.html',
  styleUrls: ['./moq-setup.component.scss']
})
export class MoqSetupComponent {
  @Input()
  moqSetupForm!: UntypedFormGroup;
  @Input()
  product!: ProductsDto;

  get minOrderQuantityField() {
    return this.moqSetupForm.get('min_order_quantity');
  }

  get selectMinQuantityField() {
    return this.moqSetupForm.get('select_min_quantity');
  }


}
