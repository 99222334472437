import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {
  DashboardAnalyticsComponent
} from "@features/marketplace/features/presentation/dashboard-analytics/dashboard-analytics.component";
import {ADMIN_USER, DASHBOARD_AND_ANALYTICS} from "@shared/config/constants/menu-roles.const";
import {ngxPermissionsGuard} from "ngx-permissions";

const routes: Routes = [
  {
    path: 'analytics-dashboard',
    component: DashboardAnalyticsComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [DASHBOARD_AND_ANALYTICS.ANALYTICS.MARKETPLACE,ADMIN_USER],
        redirectTo: '/home'
      }
    }
  },
  {path: '**', redirectTo: '', pathMatch: 'full'},
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    RouterModule
  ]
})
export class MarketplaceRoutingModule { }
