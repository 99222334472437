import {Component, OnInit} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";
import {AuthService} from "@core/services";
import {Router} from "@angular/router";


@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss'],
})
export class HomeMainComponent implements OnInit{

  userName: string = '';
  isAuthenticated: boolean | undefined = false;

  constructor(private authService:AuthService, private router: Router) {
  }


  ngOnInit(): void {
    this.authService.isAuthenticated().then(result => {
      this.isAuthenticated = result;
      if (result){
        this.authService._getFromPayload("username")
          .then(username => {
            this.userName = username;
          });
      }else {
        this.router.navigate(['login'])
      }
    })

  }

  logout() {
    this.authService.logout();
  }
}
