<div class="menu">
  <div class="menu__item"
       *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.DASHBOARD_AND_ANALYTICS, ADMIN_USER]"
       (click)="showHide([subMenu,subMenu2,subMenu3,subMenu4,subMenu5,subMenu6])">
    <mat-icon class="menu__item--icon1" svgIcon="dashboard-and-analytics-icon"></mat-icon>
    <span class="menu__item--text">Dashboard & Analytics</span>
  </div>

  <ul class="menu__submenu" #subMenu6>
    <li class="menu__submenu__item" routerLink="/orders/key-metrics-dashboard"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.KEY_METRICS, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item&#45;&#45;icon" svgIcon="dashboard-key"></mat-icon>
        <span class="menu__submenu__item&#45;&#45;text">Key metrics</span>
      </ng-container>
    </li>
  </ul>


  <ul class="menu__submenu" #subMenu4>
    <li class="menu__submenu__item" routerLink="/product/dashboard-inventory"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.INVENTORY, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="inventory-dashboard-icon"></mat-icon>
        <span class="menu__submenu__item--text">Inventory</span>
      </ng-container>
    </li>
  </ul>


  <ul class="menu__submenu" #subMenu2>
    <li class="menu__submenu__item" routerLink="/orders/orders-dashboard"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.ORDERS, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="orders-icon"></mat-icon>
        <span class="menu__submenu__item--text">Orders</span>
      </ng-container>
    </li>
  </ul>


  <ul class="menu__submenu" #subMenu>
    <li class="menu__submenu__item" routerLink="/marketplace/analytics-dashboard"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.MARKETPLACE, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="marketplace-icon"></mat-icon>
        <span class="menu__submenu__item--text">Marketplace</span>
      </ng-container>
    </li>
  </ul>

  <ul class="menu__submenu" #subMenu3>
    <li class="menu__submenu__item" routerLink="/customers/customers-dashboard"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.CUSTOMERS, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="customers-dashboard-icon"></mat-icon>
        <span class="menu__submenu__item--text">Customers</span>
      </ng-container>
    </li>
  </ul>

  <ul class="menu__submenu" #subMenu5>
    <li class="menu__submenu__item" routerLink="/product/dashboard-inventory-vendors"
        *ngxPermissionsOnly="[DASHBOARD_AND_ANALYTICS.ANALYTICS.VENDORS, ADMIN_USER]">
      <ng-container>
        <mat-icon class="menu__submenu__item--icon" svgIcon="icon-dashboard-vendors"></mat-icon>
        <span class="menu__submenu__item--text">Vendors</span>
      </ng-container>
    </li>
  </ul>
</div>
