import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO, TITLE_SUCCESS} from "@shared";
import {
  OfferStartingPriceDto
} from "@features/product-offer/model/offer-validation/offerStartingPrice.dto";
import {OfferValidationService} from "@features/product-offer/services/offer-validation.service";
import {GlobalConstants} from "@shared/utility/global-constants";
import {ProductOfferDto} from "@features/product-offer/model/offer-validation/ProductOffer.dto";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {MatStepper} from "@angular/material/stepper";
import {
  LimitedTimeOfferDto
} from "@features/product-offer/model/offer-validation/limitedTimeOffer.dto";
import {CustomValidator} from "@shared/utility/validators/validators";
import {
  ProductOfferValidator
} from "@features/product-offer/utility/validator/productOfferValidator";
import {AuthService} from "@core/services";
import {showErrorPopup, showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-offer-validation-main',
  templateUrl: './offer-validation-main.component.html',
  styleUrls: ['./offer-validation-main.component.css']
})
export class OfferValidationMainComponent implements OnInit {
  itemId!: number;
  sapCode!: string;
  basePriceListForm: UntypedFormGroup;
  specialDealPeriodSetupForm: UntypedFormGroup;
  dealPeriodSetupForm: UntypedFormGroup;
  moqSetupForm: UntypedFormGroup;
  product!: ProductsDto;

  productOfferDto!: ProductOfferDto;
  basePrices!: OfferStartingPriceDto;
  updateBasePrice: boolean = true;
  updateOffer: boolean = true;
  isNewOffer: boolean = true;

  /*SFSDF*/
  @ViewChild('stepper')
  private readonly myStepper!: MatStepper;

  constructor(private readonly formBuilder: UntypedFormBuilder,
              private readonly offerValidationService: OfferValidationService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly authService: AuthService,
              public dialog: MatDialog
  ) {
    this.basePriceListForm = this.buildBasePriceListForm();
    this.specialDealPeriodSetupForm = this.buildSpecialDealPeriodSetupForm();
    this.dealPeriodSetupForm = this.buildDealPeriodSetupForm();
    this.moqSetupForm = this.buildMoqSetupFormForm();
  }


  get tierFourField() {
    return this.basePriceListForm.get('tierFour');
  }

  get hasLimitValidPeriodField() {
    return this.specialDealPeriodSetupForm.get('has_limit_valid_period');
  }

  get subjectToUnsoldField() {
    return this.dealPeriodSetupForm.get('subject_to_unsold');
  }

  get dealPeriodDateStartField() {
    return this.dealPeriodSetupForm.get('deal_period_date_start');
  }

  get dealPeriodDateEndField() {
    return this.dealPeriodSetupForm.get('deal_period_date_end');
  }

  get dealPeriodHourStartField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_start');
  }

  /*BASE PRICE LIST*/

  get dealPeriodHourEndField() {
    return this.dealPeriodSetupForm.get('deal_period_hour_end');
  }

  get dealPeriodTierFourField() {
    return this.dealPeriodSetupForm.get('tierFour');
  }

  get minOrderQuantityField() {
    return this.moqSetupForm.get('min_order_quantity');
  }

  get selectMinQuantityField() {
    return this.moqSetupForm.get('select_min_quantity');
  }

  get isHotOfferField() {
    return this.moqSetupForm.get('isHotOffer');
  }
  get isClearanceOfferField() {
    return this.moqSetupForm.get('isClearanceOffer');
  }


  ngOnInit(): void {
    window.scroll({
      top: 0
    })
    this.activatedRoute.params.subscribe(argsToParams => {
      console.log(argsToParams)
      this.itemId = argsToParams['id'];
      this.sapCode = argsToParams['skuSap'];
    })
  }

  save() {
    this.authService._getFromPayload("id_user").then(userId => {
      this.offerValidationService.saveOfferValidation(this.createOfferValidation(userId)).subscribe({
        next: (createOfferValidationResponse: any) => {
          if (createOfferValidationResponse && !createOfferValidationResponse.status) {
            showSuccessPopup(TITLE_INFO, createOfferValidationResponse.message);
          } else {
            showSuccessPopup(TITLE_SUCCESS, createOfferValidationResponse.message)
            .then(() => {
              this.resetForm();
              if (!this.isNewOffer) {
                this.dialog.closeAll();
              } else {
                this.router.navigate([`product-offer/create-po/${this.itemId}/${this.sapCode}`]);
              }
            })
          }
        },
        error: (err: any) => {
          console.log(err);
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    });

  }

  createOfferValidation(userId: any) {
    let limitedTimeOfferDto: any = null;

    if (this.updateOffer) {
      limitedTimeOfferDto = new LimitedTimeOfferDto();
      if (this.productOfferDto && this.productOfferDto.id) {
        limitedTimeOfferDto.id = this.productOfferDto.id;
      }
      limitedTimeOfferDto.itemId = this.itemId;
      limitedTimeOfferDto.limitPeriod = this.hasLimitValidPeriodField?.value
      limitedTimeOfferDto.creationUser = userId
      limitedTimeOfferDto.isHotOffer = this.isHotOfferField?.value;
      limitedTimeOfferDto.isClearance = this.isClearanceOfferField?.value;
      if (this.hasLimitValidPeriodField?.value) {
        limitedTimeOfferDto.tierOne = Number(this.dealPeriodTierFourField?.value);
        limitedTimeOfferDto.tierTwo = Number(this.dealPeriodTierFourField?.value);
        limitedTimeOfferDto.tierThree = Number(this.dealPeriodTierFourField?.value);
        limitedTimeOfferDto.tierFour = Number(this.dealPeriodTierFourField?.value);
        limitedTimeOfferDto.hasUnsoldLimit = this.subjectToUnsoldField?.value;
        let hourInitial = this.dealPeriodHourStartField?.value;
        let hourEnd = this.dealPeriodHourEndField?.value;
        limitedTimeOfferDto.initialDate = this.dealPeriodDateStartField?.value;
        limitedTimeOfferDto.endDate = this.dealPeriodDateEndField?.value;
        limitedTimeOfferDto.initialHour = Number(hourInitial.substring(0, 2));
        limitedTimeOfferDto.endHour = Number(hourEnd.substring(0, 2));
      }
      if (this.minOrderQuantityField?.value) {
        limitedTimeOfferDto.orderMin = this.selectMinQuantityField?.value;
      }
    }
    let offerStartingPrice!: OfferStartingPriceDto;
    if (this.updateBasePrice) {
      offerStartingPrice = new OfferStartingPriceDto();
      if (this.basePrices && this.basePrices.id) {
        offerStartingPrice.id = this.basePrices.id;
      }
      offerStartingPrice.itemId = this.itemId;
      offerStartingPrice.creationUser = userId;
      offerStartingPrice.tierOne = Number(this.tierFourField?.value);
      offerStartingPrice.tierTwo = Number(this.tierFourField?.value);
      offerStartingPrice.tierThree = Number(this.tierFourField?.value);
      offerStartingPrice.tierFour = Number(this.tierFourField?.value);
    }

    return {
      offerStartingPrice: offerStartingPrice,
      limitedTimeOffer: limitedTimeOfferDto,
    }
  }

  requiredMinQuantity(control: AbstractControl) {
    let selectMinQuantity = control.get('select_min_quantity')?.value;
    let minOrderQuantity = control.get('min_order_quantity')?.value;
    if (minOrderQuantity == "true" && !selectMinQuantity) {
      return {required_min_order_quantity: true};
    }
    if (minOrderQuantity == "true" && selectMinQuantity) {
      let hasDecimal = GlobalConstants.PATTER_ONLY_DECIMAL.test(selectMinQuantity);
      if (!hasDecimal) {
        return {not_matched_decimal_pattern: true};
      }
    }

    return null;
  }

  private buildBasePriceListForm() {
    return this.formBuilder.group({
      tierFour: ['', [Validators.required, CustomValidator.validateDecimal, Validators.min(1)]],
    });
  }

  private buildSpecialDealPeriodSetupForm() {
    return this.formBuilder.group({
      has_limit_valid_period: ['', [Validators.required]]
    });
  }

  private buildDealPeriodSetupForm() {
    return this.formBuilder.group({
      deal_period_date_start: ['', [Validators.required]],
      deal_period_date_end: ['', [Validators.required]],
      subject_to_unsold: [],
      deal_period_hour_start: ['', [Validators.required]],
      deal_period_hour_end: ['', [Validators.required]],
      tierFour: ['', [Validators.required, CustomValidator.validateDecimal, Validators.min(1)]],
    }, {
      validators: [
        ProductOfferValidator.offerValidationDateComparison
        , ProductOfferValidator.timeValidator]
    });
  }

  private buildMoqSetupFormForm() {
    return this.formBuilder.group({
      min_order_quantity: ['', [Validators.required]],
      select_min_quantity: [''],
      isHotOffer: [false],
      isClearanceOffer: [false],
    }, {
      validators: this.requiredMinQuantity
    });
  }

  private resetForm() {
    this.basePriceListForm.reset();
    this.specialDealPeriodSetupForm.reset();
    this.dealPeriodSetupForm.reset();
    this.moqSetupForm.reset();
  }

}
