import {Component, ElementRef, Input, OnInit, ViewChild,} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {TITLE_INFO} from "@shared";
import {FileUtil} from "@shared/utility/fileUtil";
import {CustomValidator} from "@shared/utility/validators/validators";
import {ImageType} from "@features/product/model/enum/image-type";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ImageService} from "@features/product/services/image.service";
import {AddMultipleImagesFormService} from "@features/product/features/presentation/add-multiple-images/add-multiple-images-form.service";

const ONE_MB = 1000000;

@Component({
  selector: 'app-add-multiple-images',
  templateUrl: './add-multiple-images.component.html',
  styleUrls: ['./add-multiple-images.component.scss'],
})
export class AddMultipleImagesComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() addMultipleImagesForm!: UntypedFormGroup;
  @Input() product!: ProductsDto;

  constructor(
    private addMultipleImagesFormService: AddMultipleImagesFormService,
    private imageService: ImageService
  ) {}

  get imagesControl() {
    return this.addMultipleImagesForm?.get('images');
  }

  ngOnInit(): void {
    if (this.product) {
      this.product.images = this.product.images.filter(img => {
        if (!img.order) {
          this.product.images.forEach((item, index) => item.order = index + 1);
        }
        return img;
      }).sort((a, b) => a.order - b.order);
      this.imagesControl?.setValue(this.product.images);
    }
  }

  drop(event: CdkDragDrop<ItemPicturesDto[]>) {
    moveItemInArray(this.product.images, event.previousIndex, event.currentIndex);
    this.product.images.forEach((item, index) => item.order = index + 1)
    console.log(this.product.images)
  }

  uploadFile(imgFile: any) {
    console.log(imgFile)
    const [file] = imgFile.target.files
    if (!file) return
    const {type} = file
    if (type == "image/avif") {
      showSomethingWrongPopup1(TITLE_INFO, 'Avif is not an allowed format')
    } else {
      FileUtil.compressFile(imgFile.target.files[0]).then((resultCompressed: any) => {
        if (resultCompressed && CustomValidator.validateSize(resultCompressed.size, ONE_MB)) {
          FileUtil.convertFileToBase64(resultCompressed).then((convertedToBase64: any) => {

            const itemPictures = new ItemPicturesDto();
            itemPictures.url = convertedToBase64;
            itemPictures.imageType = ImageType.BYTE;
            itemPictures.checked = true;
            itemPictures.status = true;
            itemPictures.order = this.product.images.length + 1;

            this.imageService.uploadImage(itemPictures).subscribe({
              next: (resultCompressed: any) => {
                if (resultCompressed) {
                  if (resultCompressed.status) {
                    this.product.images.push(resultCompressed);
                    this.imagesControl?.setValue(this.product.images);
                  } else {
                    showSomethingWrongPopup1(TITLE_INFO, resultCompressed.message);
                  }
                } else {
                  showSomethingWrongPopup1(TITLE_INFO, "Something went wrong, please try again!");
                }
              },
              error: (error: any) => {
                showSomethingWrongPopup1(TITLE_INFO, "Something went wrong, please try again!");
              }
            });
          })
          console.log(this.product)
        } else {
          showSomethingWrongPopup1(TITLE_INFO, "File size Bigger than 1mb, please check !")
        }

      })

    }

  }

  productSelectImage($event: MatCheckboxChange, image: ItemPicturesDto) {
    console.log(image.url)
    console.log($event)
    this.product.images.forEach((value, index) => {
      if (value.url == image.url) {
        value.checked = $event.checked;
        value.status = $event.checked;
      }

    })
    this.imagesControl?.setValue(this.product.images);
  }

}


