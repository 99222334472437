<div class="main">
  <mat-card class="card">
    <mat-card-title class="title-text">
      <h2><strong>Stats</strong></h2>
    </mat-card-title>
    <div class="row" style="width: 80%; margin: 0 auto">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input [formControl]="startDate" matStartDate placeholder="Start date" readonly>
          <input [formControl]="endDate" matEndDate placeholder="End date" readonly>
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <div class="col-6 generalStats">
        <p class="generalStatsTitle">Average Time To Take Request</p>
        <p class="text-center">{{time_convert(
          stats && stats.length > 0 ? stats[0].averageTimeToTakeRequest : 0)}}</p>
      </div>
      <div class="col-12 m-3">
        <button (click)="getFilteredData()"
                color="primary"
                mat-raised-button>
          Search

        </button>
      </div>
    </div>
    <mat-card-content >
      <div class="row">
        <div class="col-7">
          <table mat-table [dataSource]="stats" class="col-12 list-offer-table  " matSort>
            <ng-container matColumnDef="userName">
              <th *matHeaderCellDef mat-header-cell mat-sort-header="userName">User Name</th>
              <i class="bi bi-person"></i>
              <td *matCellDef="let row" mat-cell><i class="bi bi-person"></i> {{row.userName
              ?? 'Not assigned'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="products_created">
              <th *matHeaderCellDef mat-header-cell mat-sort-header="products_created">
                Products Created
              </th>
              <td *matCellDef="let row" class="text-center" mat-cell>{{row.products_created ?? 0}}</td>
            </ng-container>

            <ng-container matColumnDef="offersEnter">
              <th *matHeaderCellDef mat-header-cell mat-sort-header="offersEnter">Offers Enter</th>
              <td *matCellDef="let row" class="text-center"
                  mat-cell>{{row.offersEnter ?? 0}}</td>
            </ng-container>

            <ng-container matColumnDef="requestResolved">
              <th *matHeaderCellDef mat-header-cell mat-sort-header="requestResolved">Request Resolved
              </th>
              <td *matCellDef="let row" class="text-center" mat-cell>{{row.requestResolved ?? 0}}</td>
            </ng-container>

            <ng-container matColumnDef="averageTimeToSolveRequest">
              <th *matHeaderCellDef mat-header-cell mat-sort-header="averageTimeToSolveRequest">
                Response Time
              </th>
              <td *matCellDef="let row" class="text-center" mat-cell>{{time_convert(
                row.averageTimeToSolveRequest ?? 0) ?? 0}}</td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
            <tr (click)="showStatus(row.status, row.userName)"
                *matRowDef="let row; columns: displayedColumns;"
                [class.demo-row-is-clicked]="detailUser== (row.userName ?? 'Not assigned')"
                mat-row>
          </table>
        </div>
        <div class="col-5">
          <div class="sideBar">
            <h3 class="sideBar--title">{{detailUser}}</h3>
            <ul class="sideBar--content">
              <li *ngFor="let status of detailStatus" class="col-6">
                <h4 class="sideBarContentCardTitle"> {{status.quantity}}</h4>
                <p class="sideBarContentCardBody">{{status.state}}</p>
                <div class="sideBarContentCardBar"></div>
                <div class="progress" style="height: 7px">
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </mat-card-content>
  </mat-card>


</div>

<app-loader></app-loader>



