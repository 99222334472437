import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, Sort} from "@angular/material/sort";
import {RequestUtil} from "@features/request/utility/requestUtil";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {RequestService} from "@features/request/services/request.service";
import {SearchSolicitudeRequestDto} from "@features/product/model/dto/request/search-solicitude-request.dto";
import {RequestDto} from "@features/request/model/RequestDto";
import {MatDialog} from "@angular/material/dialog";
import {RequestDetailComponent} from "@features/request/presentation/request-detail/request-detail.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Columns} from "@shared/model/interfaces/Columns";
import {RequestStatus} from "@features/request/model/request-status";
import {Category} from "@core/model/category";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ItemUtility} from "@features/product/model/utility/itemUtility";
import {ProductService} from "@features/product/services/product.service";
import {StatusStatsDto} from "@features/request/model/status-stats-dto";
import {GetStatsDto} from "@features/request/model/GetStats.dto";
import {
  RequestUpdateStatusComponent
} from "@features/request/presentation/request-update-status/request-update-status.component";
import {ShowSkusComponent} from "@features/request/presentation/show-skus/show-skus.component";
import {ScrollUtil} from "@shared/utility/ScrollUtil";
import {DaUserService} from "@shared/services/user/da-user.service";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-active-request',
  templateUrl: './active-request.component.html',
  styleUrls: ['./active-request.component.scss']
})
export class ActiveRequestComponent implements OnInit{
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  columnsConfig: Columns = {};
  form!: FormGroup;


  requestUtil = RequestUtil;
  status = Object.values(RequestStatus).filter(state => state != RequestStatus.PENDING);

  buyers: DAUserDto[] = [];
  categories: Category[] = [];
  subcategories: Category[] = [];
  manufacturers: ProductMasterManufacturer[] = [];
  statusStats!: StatusStatsDto;



  filterOpen = true;


  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  resultsLength = 0;

  constructor(private requestService: RequestService,
              public dialog: MatDialog,
              private formBuilder: FormBuilder,
              private productService: ProductService,
              private userService: DaUserService,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.buildForm();
    this.initCall();
    this.getCategories();
    this.getSubCategories();
    this.getManufacturers();
    this.getBuyers();
    this.getStatusStats();

  }

  private initCall(){
    let params = new SearchSolicitudeRequestDto();
    this.route.params.subscribe(urlParams => {
      let id = urlParams['id'];
      if (id) {
       params.id = id;
      }

    })
    this.form.get('status')?.setValue(RequestStatus.SOURCING);
    params.status = RequestStatus.SOURCING;
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_ASC`,
        key: 'id',
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: 50
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: 0
      }
    ]
    this.getData(params);
  }

  private buildForm(){
    this.form = this.formBuilder.group({
      requestInitDate: [null],
      category: [null],
      subcategory: [null],
      manufacturer: [null],
      buyerId: [null],
      partNumber: [null],
      requestEndDate: [null],
      status: [null],
      upc: [null],
      sku: [null],
      itemDescription: [null]
    })
  }

  getData(searchSolicitudeRequestDto: SearchSolicitudeRequestDto){
    this.requestService.findAllActiveRequest(searchSolicitudeRequestDto).subscribe(response => {
      this.resultsLength = response.totalElements;
      this.dataSource.data = response.content;
      this.paginator.pageIndex = response.number;
      this.paginator.pageSize = response.size;
      this.columnsConfig = this.columnsConfig = response.columns.reduce((acc: Columns, column:any) => {
        acc[column.name] = {
          visible: column.isVisible,
          editable: column.isEditable,
          dtoAttribute: column.dtoAttribute
        };
        return acc;
      }, {});
      this.displayedColumns = Object.keys(this.columnsConfig).filter(key => this.columnsConfig[key].visible);
    })

  }

  isEditable(column: string): boolean {
    return this.columnsConfig[column]?.editable ?? false;
  }

  sortChange($event: Sort) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${$event?.direction ? $event.direction.toUpperCase() : 'DESC'}`,
        key: $event?.active ? $event.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: this.paginator?.pageSize ? this.paginator.pageSize : 50
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: this.paginator?.pageIndex ? this.paginator.pageIndex : 0
      }
    ];
    this.getData(params)
  }

  editItemDialog(row: any) {
    const dialogRef = this.dialog.open(RequestUpdateStatusComponent, {
      width: '50%',
      height: '90%',
    })
    dialogRef.componentInstance.request = row;

    dialogRef.afterClosed().subscribe(() => this.reset());
  }

  showSimilarSkus(id: any) {
    const dialogRef = this.dialog.open(ShowSkusComponent, {
      width: '50%',
      height: '90%',
    })
    dialogRef.componentInstance.requestId = id;
    dialogRef.afterClosed().subscribe(() => this.reset());
  }

  pagination($event: PageEvent) {
    let params = this.buildParams();
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_${this.sort?.direction ? this.sort.direction.toUpperCase() : 'DESC'}`,
        key: this.sort?.active ? this.sort.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: $event.pageSize
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: $event.pageIndex
      }
    ];
    this.getData(params)
    ScrollUtil.scrollToTop();
  }

  requestDetailPreview(row: RequestDto) {
    let dialogRef = this.dialog.open(RequestDetailComponent, {
      width: '50%',
      height: '90%',
    })
    dialogRef.componentInstance.request = row;

  }



  toggle(filters: any) {
    this.filterOpen = !this.filterOpen;
    if (!this.filterOpen) {
      filters.style.display = 'none'
    } else {
      filters.style.display = 'flex'

    }
  }


  protected readonly String = String;

  reset() {
    this.form.reset();
    let params = new SearchSolicitudeRequestDto();
    this.form.get('status')?.setValue(RequestStatus.SOURCING);
    params.status = RequestStatus.SOURCING;
    params.searchCriteria = [
      {
        operation: `PAGE_ORDER_ASC`,
        key: 'id',
        value: '',
      },
      {
        operation: "PAGE_SIZE",
        key: "",
        value: 50
      },
      {
        operation: "PAGE_INDEX",
        key: "",
        value: 0
      }
    ]
    this.getData(params);
    this.getStatusStats();
  }

  filter() {
    let searchParamsFilter = this.buildParams();
    searchParamsFilter.searchCriteria = this.buildSearchCriteria();
    this.getData(searchParamsFilter);
    this.getStatusStats();
  }

  private buildSearchCriteria() {
    return [
      {
        operation: `PAGE_ORDER_${this.sort?.direction ? this.sort.direction.toUpperCase() : 'DESC'}`,
        key: this.sort?.active ? this.sort.active : 'id',
        value: ''
      },
      {
        operation: "PAGE_SIZE",
        key: '',
        value: this.paginator? this.paginator.pageSize : 50
      },
      {
        operation: "PAGE_INDEX",
        key: '',
        value: this.paginator? this.paginator.pageIndex : 0
      }
    ];
  }


  private buildParams() {
    let params = new SearchSolicitudeRequestDto();
    if (this.form.get('status')?.value) {
      params.status = this.form.get('status')?.value;
    }

    if (this.form.get('requestInitDate')?.value) {
      params.requestInitDate = moment(this.form.get('requestInitDate')?.value)
        .format('YYYY-MM-DD').concat('T').concat('00:00:00');
    }
    if (this.form.get('requestEndDate')?.value) {
      params.requestEndDate = moment(this.form.get('requestEndDate')?.value)
        .format('YYYY-MM-DD').concat('T').concat('23:59:59');
    }
    if (this.form.get('buyerId')?.value) {
      params.buyerId = this.form.get('buyerId')?.value;
    }
    if (this.form.get('category')?.value) {
      params.category = this.form.get('category')?.value;
    }
    if (this.form.get('subcategory')?.value) {
      params.subcategory = this.form.get('subcategory')?.value;
    }
    if (this.form.get('manufacturer')?.value) {
      params.manufacturer = this.form.get('manufacturer')?.value;
    }
    if (this.form.get('partNumber')?.value) {
      params.partNumber = this.form.get('partNumber')?.value;
    }
    if (this.form.get('sku')?.value) {
      params.sku = this.form.get('sku')?.value;
    }
    if (this.form.get('itemDescription')?.value) {
      params.itemDescription = this.form.get('itemDescription')?.value;
    }
    if (this.form.get('upc')?.value) {
      params.itemCode = this.form.get('upc')?.value;
    }
    return params;
  }


  getCategories() {
    this.productService.getCategories().subscribe({
      next: value => {
        ItemUtility.setCategory(value.body, this.categories);
      },
      error: () => {
        this.categories = [];
      },
    })
  }

  getSubCategories() {
    this.productService.getAllSubCategories().subscribe({
      next: value => {
        value.body.forEach((cat: any) => {
          let category = new Category();
          category.key = cat.itmctg_code;
          category.values = cat.categorychild;
          this.subcategories.push(category);
        });
      },
      error: () => {
        this.subcategories = [];
      },
    })
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: () => {
        this.manufacturers = [];
      },
    })
  }

  getBuyers() {
    this.userService.findAllBuyers()
      .subscribe({
        next: value => {
          this.buyers = value;
        },
        error: () => {
          this.buyers = [];
        },
      })
  }


  getStatusStats() {

    let getStats = new GetStatsDto();
    if (this.form.get('buyerId')?.value){
      getStats.buyerId = this.form.get('buyerId')?.value;
    }

    if (this.form.get('requestInitDate')?.value){
      getStats.requestInitDate = this.form.get('requestInitDate')?.value;
    }
    if (this.form.get('requestEndDate')?.value){
      getStats.requestEndDate = this.form.get('requestEndDate')?.value;
    }

    this.requestService.statusStats(getStats).subscribe({
      next: value => {
        this.statusStats = value
      }
    });
  }
  time_convert(num: number) {
    const hours: number = Math.floor(num);
    const minutes: number = Math.floor(num % 60);
    return `${hours}hr : ${minutes}min`;
  }


  protected readonly RequestStatus = RequestStatus;
}
