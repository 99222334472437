<div class="list-offer-for-request-container">
  <div class="list-offer-for-request-container--title" (click)="toggle(filters)">
    <span>FILTER BY
      <mat-icon>{{ filterOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </span>
    <h2>ACTIVE OFFERS</h2>
  </div>
  <mat-card class="list-offer-for-request-container--card" style="overflow: scroll">
    <mat-card-content>

      <form [formGroup]="form" class="row list-offer-for-request-container--card__form" #filters id="filters">
        <div class="col-3 list-offer-for-request-container--card__form__hot-offer">
          <div class="list-offer-for-request-container--card__form__hot-offer--title">
            <mat-icon  svgIcon="hot-offer-icon"></mat-icon>
            <span>Hot Offer</span>
          </div>
          <div class="list-offer-for-request-container--card__form__hot-offer--checkbox">
            <mat-checkbox formControlName="hotOffer" color="warn"></mat-checkbox>
          </div>

        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Item Creation Date</mat-label>
          <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker1">
                <input matStartDate placeholder="Start date"  formControlName="itemCreationDateStart">
                <input matEndDate placeholder="End date" formControlName="itemCreationDateEnd">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-date-range-picker #picker1></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Published Date</mat-label>
          <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker2">
                <input matStartDate placeholder="Start date" formControlName="publishedDateStart">
                <input matEndDate placeholder="End date" formControlName="publishedDateEnd">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-date-range-picker #picker2></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Valid Offer Period</mat-label>
          <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker3">
                <input matStartDate placeholder="Start date" formControlName="validaOfferPeriodStart">
                <input matEndDate placeholder="End date" formControlName="validaOfferPeriodEnd">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-date-range-picker #picker3></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" style="margin-top: 0">
          <mat-label>Category</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="category" id="category"  placeholder="Select" >
              <mat-option *ngFor="let category of categories" [value]="category.key">
                {{category.values}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" style="margin-top: 0">
          <mat-label>Sub Category</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="subcategory" id="sub_category"  placeholder="Select">
              <mat-option *ngFor="let category of subcategories" [value]="category.key">
                {{category.values}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" style="margin-top: 0">
          <mat-label>Manufacturer</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="manufacturer" id="manufacturer"  placeholder="Select">
              <mat-option *ngFor="let manufacturer of manufacturers "
                          [value]="manufacturer.ditmmnf_code">
                {{manufacturer.ditmmnf_front_dsc}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" style="margin-top: 0">
          <mat-label>Condition</mat-label>
          <mat-form-field  appearance="outline" >
            <mat-select formControlName="condition" placeholder="Select" >
              <mat-option *ngFor="let condition of conditions "
                          [value]="condition"
              >
                {{condition}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Buyer</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="buyer" id="buyer"  placeholder="Select">
              <mat-option *ngFor="let buyer of buyers " [value]="buyer.id">
                {{buyer.firstName + ' ' + buyer.lastName + ' - ' + getBuyerName(
                buyer.userCustomized)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Source</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="source" id="source"  placeholder="Select">
              <mat-option value="MKP">MKP</mat-option>
              <mat-option value="In Stock">In Stock</mat-option>
              <mat-option value="In transit">In transit</mat-option>
              <mat-option value="Virt. transit">Virtual transit</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>SKU</mat-label>
          <mat-form-field appearance="outline">
            <input
              formControlName="sku"
              matInput
              placeholder="SKU"
            >
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>UPC/EAN</mat-label>
          <mat-form-field appearance="outline">
            <input
              formControlName="upc"
              matInput
              placeholder="UPC/EAN"
            >
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Part Number</mat-label>
          <mat-form-field appearance="outline">
            <input
              formControlName="partNumber"
              matInput
              placeholder="Part Number"
            >
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field">
          <mat-label>Item description</mat-label>
          <mat-form-field appearance="outline">
            <input
              formControlName="itemDescription"
              matInput
              placeholder="Item description"
            >
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" formArrayName="aging">
            <ng-container *ngFor="let control of agingArray.controls; let i=index">
              <div [formGroupName]="i">
                <mat-label>Aging</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="comparator" placeholder="Select">
                    <mat-option value=">">Greater (>)</mat-option>
                    <mat-option value=">=">Greater and Equal (>=)</mat-option>
                    <mat-option value="<">Less (<)</mat-option>
                    <mat-option value="<=">Less and Equal (<=)</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" *ngIf="agingArray.at(i).get('comparator')?.value" style="margin-top: 1rem;">
                  <input
                    formControlName="value"
                    matInput
                    placeholder="Value"
                    type="number"
                  >
                </mat-form-field>
              </div>
            </ng-container>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" formArrayName="basePrice">
          <ng-container *ngFor="let control of basePriceArray.controls; let i=index">
            <div [formGroupName]="i">
              <mat-label>Base Price</mat-label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="comparator" placeholder="Select">
                  <mat-option value=">">Greater (>)</mat-option>
                  <mat-option value=">=">Greater and Equal (>=)</mat-option>
                  <mat-option value="<">Less (<)</mat-option>
                  <mat-option value="<=">Less and Equal (<=)</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="basePriceArray.at(i).get('comparator')?.value" style="margin-top: 1rem;">
                <input
                  formControlName="value"
                  matInput
                  placeholder="Value"
                  type="number"
                >
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" formArrayName="margin">
          <ng-container *ngFor="let control of marginArray.controls; let i=index">
            <div [formGroupName]="i">
              <mat-label>Margin</mat-label>
              <mat-form-field appearance="outline">
                <mat-select formControlName="comparator"  placeholder="Select">
                  <mat-option value=">">Greater (>)</mat-option>
                  <mat-option value=">=">Greater and Equal (>=)</mat-option>
                  <mat-option value="<">Less (<)</mat-option>
                  <mat-option value="<=">Less and Equal (<=)</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="marginArray.at(i).get('comparator')?.value" style="margin-top: 1rem;">
                <input
                  formControlName="value"
                  matInput
                  placeholder="Value"
                  type="number"
                >
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" formArrayName="moq">
          <ng-container *ngFor="let control of moqArray.controls; let i=index">
            <div [formGroupName]="i">
              <mat-label>MOQ</mat-label>
              <mat-form-field appearance="outline" >
                <mat-select formControlName="comparator" placeholder="Select">
                  <mat-option value=">">Greater (>)</mat-option>
                  <mat-option value=">=">Greater and Equal (>=)</mat-option>
                  <mat-option value="<">Less (<)</mat-option>
                  <mat-option value="<=">Less and Equal (<=)</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="moqArray.at(i).get('comparator')?.value" style="margin-top: 1rem;">
                <input
                  formControlName="value"
                  matInput
                  placeholder="Value"
                  type="number"
                >
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" >
          <mat-label>Region Restrictions</mat-label>
          <mat-form-field  appearance="outline" >
            <mat-select formControlName="regionRestrictions" placeholder="Select" >
              <mat-option *ngFor="let region of regionRestrictions "
                          [value]="region.value"
              >
                {{region.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3 list-offer-for-request-container--card__form--field" >
          <mat-label>Tech Specs</mat-label>
          <mat-form-field appearance="outline">
              <input
                formControlName="specs"
                matInput
                placeholder="Tech Specs"
              >
          </mat-form-field>
        </div>


        <div class="list-offer-for-request-container--card__form--actions">
          <button (click)="reset()"
                  mat-raised-button
                  [ngStyle]="{'background':'#efefef'}">
            Reset filter
            <mat-icon>restart_alt</mat-icon>
          </button>
          <button mat-raised-button
                  (click)="filter()"
                  [ngStyle]="{'background':'#005eac', 'color':'white'}">
            Apply filters
          </button>
        </div>
      </form>

      <div class="row paginator-container">
        <mat-paginator (page)="pagination($event)"
                       [hidePageSize]="this.paginator.pageIndex != 0"
                       [length]="resultsLength"
                       [pageSizeOptions]="[5, 10, 25, 50]"
                       [pageSize]="50"
                       showFirstLastButtons
                       aria-label="Select page of Deals Information search results">
        </mat-paginator>
      </div>


      <div class="table-container">
        <table mat-table  (matSortChange)="sortChange($event)" [dataSource]="data"
               matSort>
          <caption>Active Deals</caption>

          <ng-container matColumnDef="isHotOffer">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="isHotOffer">Hot <br> Offer</th>
            <td *matCellDef="let row" mat-cell>
              <mat-icon *ngIf="row.isHotOffer" svgIcon="hot-offer-icon"></mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="initial_date">
            <th *matHeaderCellDef mat-header-cell  mat-sort-header="creationDate">Published</th>
            <td *matCellDef="let row" mat-cell>{{row.initialDate
            ?? row.creationDate | date: 'MM-dd'}}</td>
          </ng-container>

          <ng-container matColumnDef="end_date">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="endDate">Valid Until</th>
            <td *matCellDef="let row" mat-cell>{{row.endDate
            ?? row.endDate | date: 'MM-dd HH:mm:ss'}}</td>
          </ng-container>


          <ng-container matColumnDef="sub_category">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="item.itemCategories.code">SubCategory
            </th>
            <td *matCellDef="let row" mat-cell>
              {{row?.item?.itemCategories?.description}}
            </td>
          </ng-container>

          <ng-container matColumnDef="manufacturer">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="item.manufacturers.description">
              Manufacturer
            </th>
            <td *matCellDef="let row" mat-cell>
              {{row?.item?.manufacturers?.description}}
            </td>
          </ng-container>

          <ng-container matColumnDef="sku">
            <th *matHeaderCellDef class="text-center" mat-header-cell mat-sort-header="item.sku">SKU</th>
            <td *matCellDef="let row" mat-cell>
              <p style="text-align: start; margin:  0 auto">{{skuSap(row.item.itemCustomized)}}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="item_description">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="item.itemDescription">Item
              Desc.
            </th>
            <td *matCellDef="let row" mat-cell [matTooltip]="row?.item?.itemDescription">
              <p style="padding: .2rem; text-align: start; margin:  0 auto" >{{row?.item?.itemDescription.length > 20 ? String(row?.item?.itemDescription).substring(0,20)
                  .concat("...")
                :row?.item?.itemDescription }}</p>
            </td>
          </ng-container>

          <ng-container matColumnDef="buyer">
            <th *matHeaderCellDef mat-header-cell>Buy.</th>
            <td *matCellDef="let row" mat-cell>
              {{row.creationUser ? getBuyerName(row.creationUser.userCustomized) : ''}}
            </td>
          </ng-container>

          <ng-container matColumnDef="available_qty">
            <th *matHeaderCellDef mat-header-cell> QTY <br> ETA</th>
            <td (click)="showEta($event, row.item.itemCustomized)" *matCellDef="let row" mat-cell>
              <span style="color: blue;border-bottom: solid 1px blue;">{{row.item.itemSapData
              && row.item.itemSapData.stock ? row.item.itemSapData.stock : ""}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="source">
            <th *matHeaderCellDef mat-header-cell> Source </th>
            <td *matCellDef="let row" mat-cell>
              <p [innerHTML]="getSource(row)" style="margin:  0 auto; text-align: center"></p>
            </td>
          </ng-container>

          <ng-container matColumnDef="aging">
            <th *matHeaderCellDef mat-header-cell> Aging </th>
            <td *matCellDef="let row" mat-cell> {{row.item?.itemSapData?.aging}}</td>
          </ng-container>

          <ng-container matColumnDef="itemPurchases">
            <th *matHeaderCellDef mat-header-cell>Item <br> Purchases</th>
            <td *matCellDef="let row" mat-cell>
              <button mat-icon-button (click)="itemPurchases(row)" [disabled]="canShowItemPurchases(row)">
                <mat-icon svgIcon="item-purchases-icon"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="moq">
            <th *matHeaderCellDef mat-header-cell>MOQ</th>
            <td *matCellDef="let row" mat-cell>
              {{row.orderMin ?? "N/A"}}
            </td>
          </ng-container>

          <ng-container matColumnDef="price_and_canva">
            <th *matHeaderCellDef mat-header-cell>Price & <br> Canva</th>
            <td *matCellDef="let row" mat-cell>
             <button mat-icon-button (click)="downloadTemplate(row)">
               <mat-icon svgIcon="price-canva-icon"></mat-icon>
             </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="exp_margin">
            <th *matHeaderCellDef mat-header-cell>Exp. Margin</th>
            <td *matCellDef="let row" mat-cell [ngStyle]="{'color': calculateMargin(row) > 0 ? 'green' : 'red' }">
              {{calculateMargin(row).toFixed(2)}} &percnt;
            </td>
          </ng-container>

          <ng-container matColumnDef="view">
            <th *matHeaderCellDef mat-header-cell>View</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="openPreview(row)" mat-icon-button>
                <mat-icon svgIcon="view-icon"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="link">
            <th *matHeaderCellDef mat-header-cell>Link</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="copyLink(row)" mat-icon-button>
                <mat-icon svgIcon="link-icon"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="feedback">
            <th *matHeaderCellDef mat-header-cell>Feedback</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="feedbackDealOffers(row)" mat-icon-button>
                <mat-icon [svgIcon]="row?.offerFeedback.length > 0 ? 'feedback-icon-green' : 'feedback-icon' "></mat-icon>
              </button>
            </td>
          </ng-container>


          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
      </div>
      <div class="row paginator-container">
        <mat-paginator (page)="pagination($event)"
                       [hidePageSize]="this.paginator.pageIndex != 0"
                       [length]="resultsLength"
                       [pageSizeOptions]="[5, 10, 25, 50]"
                       [pageSize]="50"
                       showFirstLastButtons
                       aria-label="Select page of Deals Information search results">
        </mat-paginator>
      </div>


    </mat-card-content>
  </mat-card>


</div>
<app-loader></app-loader>
