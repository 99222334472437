import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {
  ProductOfferForRequestDto
} from "@features/product-offer/model/offer-validation/productOfferForRequest.dto";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {FeedbackRequestDto} from "@features/product/model/dto/request/feedback-request.dto";
import {ROLES} from "@shared/config/constants/menu-roles.const";
import {showErrorPopup, showSuccessPopup} from "@shared/utility/popup-message";

@Component({
  selector: 'app-feedback-deal-offers',
  templateUrl: './feedback-deal-offers.component.html',
  styleUrls: ['./feedback-deal-offers.component.scss']
})
export class FeedbackDealOffersComponent implements OnInit {

  @Input()
  request!: ProductOfferForRequestDto;
  @Input()
  isDialog: boolean = false;
  @Input()
  feedbackForm!: FormGroup;
  newFeedbackStatus: boolean = false;

  feedbackOptions: any[] = [
    {value: 1, description: 'Interested, Order coming soon.'},
    {value: 2, description: 'Interested, But cannot suply MOQ.'},
    {value: 3, description: 'Captured. Order entered.'},
    {value: 4, description: 'Not interested. Price too high.'},
    {value: 5, description: 'Not Interested. Avaliable Qty below expectations.'},
    {value: 6, description: 'Not Interested. ETA too long.'},
    {value: 7, description: 'Not Interested. Not searching for this type of product.'},
    {
      value: 8,
      description: 'Not Interested. Other.  - Deberá habilitar un campo adicional para que ingrese la razón.'
    }
  ];

  displayedColumns: string[] = ['feedback', 'comments'];

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private productOfferService: ProductOfferService
  ) {
    this.feedbackForm = this.formBuilder.group({
      feedback_request_comments: ['', [Validators.maxLength(500)]],
      feedbackBy: ['', Validators.required]
    }, {});
  }

  ngOnInit(): void {
    this.productOfferService.getFeedback(this.request.id).subscribe(value => this.request.offerFeedback = value)
  }

  newFeedback() {
    this.newFeedbackStatus = !this.newFeedbackStatus;
  }

  save() {
    if (!this.request.offerFeedback) {
      this.request.offerFeedback = [];
    }
    let offerFeedbackAux = new  FeedbackRequestDto();
    offerFeedbackAux.offerId = this.request.id;
    offerFeedbackAux.feedbackComments = this.request.feedbackComments;
    offerFeedbackAux.feedbackReason = this.request.feedbackReason;

    if (this.feedbackForm.valid) {
      try {
        this.productOfferService.saveFeedback(offerFeedbackAux).subscribe({
          next: (value: any) => {
            if (value.status) {
              showSuccessPopup(TITLE_INFO, value.message)
              .then(() => this.router.navigate(['/product-offer/list/deals']))
              .then(() => window.location.reload())
            } else {
              showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
            }
          }, error: () => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
          }
        })
      } catch (error) {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
      }
    }
  }

  protected readonly ROLES = ROLES;
}
