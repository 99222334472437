<div *ngIf="isDialog" class="col-12 close_button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div class="">
  <h2>Status</h2>

  <div class="row">
    <div *ngFor="let status of requestByStatus" class="col-xl-3 col-sm-6 col-6">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media d-flex">
              <div class="media-body text-left">
                <h3 class="primary"> {{status?.quantity}}</h3>
                <span> {{status?.state}}</span>
              </div>
              <div class="align-self-center">
                <i class="icon-book-open primary font-large-2 float-right"></i>
              </div>
            </div>
            <div class="progress mt-1 mb-0" style="height: 7px;">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="80"
                   class="progress-bar bg-primary" role="progressbar" style="width: 80%"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
