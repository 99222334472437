import {Injectable} from '@angular/core';
import {environment} from "@env";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, Observable} from "rxjs";

import {catchError} from "rxjs/operators";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {FindItemDescriptionDto} from "@features/product/model/dto/item/findItemDescription.dto";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {GetItemImagesDto} from "@features/product/model/dto/product/get-item-images.dto";
import {ItemImageDto} from "@features/product/model/dto/product/item-image.dto";
import {ItemMiscTypesDto} from "@features/product/model/dto/item/ItemMiscTypes.dto";
import {ItemSpecificationsDto} from "@features/product/model/dto/item/itemSpecifications.dto";
import {ProductConditionDto} from "@features/product/model/dto/item/productCondition.dto";


@Injectable({
  providedIn: 'root'
})
export class ProductService {


  private resourcesUrl = environment.resourcesUrl;
  private getCategoriesUrl = `${this.resourcesUrl}categories/product-master-get-categories`;
  private getManufacturerUrl = `${this.resourcesUrl}manufacturer/product-master-get-manufacturer`;
  private getCategoryTreeUrl = `${this.resourcesUrl}categories/subcategories/product-master-fullpath`;
  private getSubCategoriesUrl = `${this.resourcesUrl}categories/subcategories/product-master-get-subcategories`;
  private getSpecificationsUrl = `${this.resourcesUrl}categories/specifications/product-master-get-specifications`;
  private getAllSubCategoriesUrl = `${this.resourcesUrl}categories/subcategories/find-all`;

  private productMasterUrl = environment.productMasterUrl;
  private getProductUrl = `${this.productMasterUrl}product/get/images`;
  private geMasterProductUrl = `${this.productMasterUrl}product/get/item`;
  private getAllProductsLikeUrl = `${this.productMasterUrl}product/get/items/like`;
  private getItemByIdUrl = `${this.productMasterUrl}product/find/by/id/`;

  private geSapProductUrl = `${this.productMasterUrl}product/get/sap/items/filter`;
  private validateItemExistenceUrl = `${this.productMasterUrl}product/validate/existence`;
  private validateSpecificationUrl = `${this.productMasterUrl}product/validate/specifications`;
  private validateProductConditionUrl = `${this.productMasterUrl}product/validate/condition`;
  private validateItemExistenceInSapUrl = `${this.productMasterUrl}product/found/any/in/sap`;
  private getItemDescriptionUrl = `${this.productMasterUrl}product/get/item-description`;
  private getSkuUrl = `${this.productMasterUrl}product/get/item-sku`;
  private saveItemUrl = `${this.productMasterUrl}product/save`;
  private updateItemUrl = `${this.productMasterUrl}product/update`;
  private saveManufactureUrl = `${this.productMasterUrl}manufacturer/save`;

  private saveMassiveProductUrl = `${this.productMasterUrl}product/massive/save`;
  private downloadTemplateUrl = `${this.productMasterUrl}product/download/template`;
  private getAllProductUrl = `${this.productMasterUrl}product/get/all/items`;
  private getFindItemStateInSapUrl = `${this.productMasterUrl}product/exists/in/sap`;
  private updateItemSapStateUrl = `${this.productMasterUrl}product/save/item/sap/state`;
  private uploadImagesUrl = `${this.productMasterUrl}product/images/upload`;


  private findProductsImagesUrl = `${this.productMasterUrl}product/find/images`;

  private getItemPricesUrl = `${this.productMasterUrl}product/item/prices`;
  private getMarketplaceCostUrl = `${environment.automatePurchasesUrl}offer-validation/get-marketplace-cost`;
  private getTrackingInfoUrl = `${this.productMasterUrl}product/get-tracking-info`;

  private getMiscTypesUrl = `${this.productMasterUrl}misc-types/find/by/items_specifications/`;

  constructor(private http: HttpClient) {
  }

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.resourcesApiKey,
    });
  }

  getHeaderImage() {
    return new HttpHeaders({
      'Access-Control-Allow-Origin': 'null'
    });
  }

  getMiscTypes(param: string): Observable<ItemMiscTypesDto[]> {
    return this.http.get<ItemMiscTypesDto[]>(this.getMiscTypesUrl + param);
  }

  getMasterProduct(params: any): Observable<ItemDto []> {
    return this.http.post<any>(this.geMasterProductUrl, params).pipe(
      map((result: ItemDto) => {
        let items: ItemDto[] = [];
        if (result) {
          items.push(result)
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  getAllProductsLike(params: any): Observable<ItemDto[]> {
    return this.http.post(this.getAllProductsLikeUrl, params)
    .pipe(
      map((result: any) => {
        let items: ItemDto[] = [];
        if (result && result.length > 0) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  geSapProduct(params: GetItemParamsDto): Observable<ItemDto[]> {
    return this.http.post<any>(this.geSapProductUrl, params).pipe(
      map((result: any) => {
        let items: ItemDto[] = [];
        if (result && result.length > 0) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  getProduct(params: any): Observable<any> {
    return this.http.post<any>(this.getProductUrl, params);
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(this.getCategoriesUrl, {headers: this.getHttpHeaders()});
  }

  getAllSubCategories(): Observable<any> {
    return this.http.get<any>(this.getAllSubCategoriesUrl, {headers: this.getHttpHeaders()});
  }

  getManufacturer(): Observable<any> {
    return this.http.get<any>(this.getManufacturerUrl, {headers: this.getHttpHeaders()});
  }

  validateItemExistence(params: any) {
    return this.http.post<any>(this.validateItemExistenceUrl, params);
  }

  validateSpecification(itemSpecificationsList: ItemSpecificationsDto[]): Observable<ItemSpecificationsDto[]> {
    return this.http.post<any>(this.validateSpecificationUrl, itemSpecificationsList);
  }

  validateCondition(itemSpecificationsList: ProductConditionDto): Observable<ProductConditionDto> {
    return this.http.post<any>(this.validateProductConditionUrl, itemSpecificationsList);
  }


  validateItemExistenceInSap(params: any) {
    return this.http.post<any>(this.validateItemExistenceInSapUrl, params);
  }

  getCategoryTree(param: any) {
    return this.http.post<any>(this.getCategoryTreeUrl, param, {headers: this.getHttpHeaders()});
  }

  getSubCategories(code: string): Observable<any> {
    return this.http.post<any>(this.getSubCategoriesUrl, {"itemCategory": code}, {headers: this.getHttpHeaders()});
  }

  getSpecifications(codes: any[]): Observable<any> {
    return this.http.post<any>(this.getSpecificationsUrl, {"listCode": codes}, {headers: this.getHttpHeaders()});
  }

  getItemDescription(object: FindItemDescriptionDto): Observable<any> {
    return this.http.post(this.getItemDescriptionUrl, object, {responseType: 'text'});
  }

  getSKU(object: any): Observable<any> {
    return this.http.post(this.getSkuUrl, object, {responseType: 'text'});
  }

  createItem(saveItemDto: any): Observable<any> {
    return this.http.post(this.saveItemUrl, saveItemDto, {responseType: 'text'});
  }

  updateItem(params: any): Observable<any> {
    return this.http.post<any>(this.updateItemUrl, params);
  }

  saveManufacture(params: any): Observable<any> {
    return this.http.post<any>(this.saveManufactureUrl, params);
  }

  saveMassiveProduct(params: any): Observable<any> {
    return this.http.post<any>(this.saveMassiveProductUrl, params);
  }

  downloadTemplate() {
    return this.http.get(this.downloadTemplateUrl, {responseType: "blob"});
  }

  getAllItems(params: any): Observable<any> {
    return this.http.post<any>(this.getAllProductUrl, params);
  }

  getAllItemsV2(params: any): Observable<any> {
    return this.http.post<any>(this.getAllProductUrl + "/v2", params);
  }

  findItemStateInSap(sku: string) {
    return this.http.post<any>(this.getFindItemStateInSapUrl, sku);
  }

  updateItemSapState(sku: string) {
    return this.http.put<any>(this.updateItemSapStateUrl, sku);
  }

  findItemPrices(sku: string) {
    return this.http.post<any>(this.getItemPricesUrl, sku);
  }

  getItemById(itemId: number): Observable<any> {
    return this.http.get(this.getItemByIdUrl + itemId);
  }

  saveImages(params: ItemPicturesDto[]): Observable<boolean> {
    return this.http.post<boolean>(this.uploadImagesUrl, params);
  }

  findProductsImages(params: GetItemImagesDto): Observable<ItemImageDto[]> {
    return this.http.post<ItemImageDto[]>(this.findProductsImagesUrl, params);
  }

  getMarketplaceCost(sku: string): Observable<any> {
    return this.http.post<any>(this.getMarketplaceCostUrl, sku);
  }

  getTrackingInfo(tracking: string): Observable<any> {
    return this.http.post(this.getTrackingInfoUrl, tracking);
  }
}
