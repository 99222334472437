import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {CustomValidator} from "@shared/utility/validators/validators";
import {TITLE_INFO} from "@shared";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {FileUtil} from "@shared/utility/fileUtil";
import {ImageType} from "@features/product/model/enum/image-type";
import {showSomethingWrongPopup1} from "@shared/utility/popup-message";


const ONE_MB = 1000000;

@Component({
  selector: 'app-product-select-image',
  templateUrl: './product-select-image.component.html',
  styleUrls: ['./product-select-image.component.css']
})
export class ProductSelectImageComponent implements OnInit {
  @Input()
  productSelectImageForm!: UntypedFormGroup;
  @Input()
  product!: ProductsDto;
  fileAttr = 'Choose files or drag here';
  @ViewChild('fileInput') fileInput!: ElementRef;

  get selectedProductImage() {
    return this.productSelectImageForm.get('selected_product_image');
  }


  /*GET PRODUCT SELECT IMAGE FROM*/

  ngOnInit(): void {
    console.log(this.product)
    if (this.product) {
      this.selectedProductImage?.setValue(this.product.images);
    }
  }

  uploadFile(imgFile: any) {

    const [file] = imgFile.target.files
    if (!file) return
    const {type} = file
    if (type == "image/avif") {
      showSomethingWrongPopup1(TITLE_INFO, 'Avif is not an allowed format')
    } else {
      FileUtil.compressFile(imgFile.target.files[0]).then((resultCompressed: any) => {
        if (resultCompressed && CustomValidator.validateSize(resultCompressed.size, ONE_MB)) {
          FileUtil.convertFileToBase64(resultCompressed).then((convertedToBase64: any) => {
            this.product.images.push({
              url: convertedToBase64,
              imageType: ImageType.BYTE,
              checked: true,
              id: null,
              itemId: null,
              status: false,
              //TODO: Set right order
              order: 0
            });
            this.selectedProductImage?.setValue(this.product.images.filter(value => value.checked));
          })
        } else {
          showSomethingWrongPopup1(TITLE_INFO, "File size Bigger than 1mb, please check !")
        }

      })

    }

  }

  productSelectImage(image: any) {
    this.product.images.forEach(value => {
      if (value.url == image.url) {
        value.checked = !value.checked
      }
    })
  }
}
