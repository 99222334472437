import {Injectable} from '@angular/core';
import {confirmResetPassword, confirmSignIn, ConfirmSignInOutput, resetPassword, ResetPasswordOutput, signIn, SignInOutput, updateMFAPreference, updatePassword, verifyTOTPSetup} from 'aws-amplify/auth';
import {ValidationData} from "@core/model/validation-data";

@Injectable()
export class AmplifyService {

  async loginWithCognito(username: string, password: string, validationData: ValidationData): Promise<SignInOutput> {
    return await signIn({
      username,
      password,
      options: {
        clientMetadata: {
          ip: validationData.ip,
          recaptchaToken: validationData.recaptchaToken
        }
      }
    });
  }


  async handleSignInConfirmation(totpCode: string): Promise<ConfirmSignInOutput> {
    return await confirmSignIn({challengeResponse: totpCode});
  }

  async handleUpdateMFAPreference(): Promise<void> {
    return await updateMFAPreference({totp: 'PREFERRED'});
  }

  async sendConfirmationCodeToResetPassword(username: string, clientMetadata ?: any): Promise<ResetPasswordOutput> {
    return await resetPassword({username});
  }

  async passwordRecovery(username: string, confirmationCode: string, newPassword: string): Promise<void> {
    return await confirmResetPassword({
      username,
      newPassword,
      confirmationCode
    });
  }

  async handleTOTPVerification(totpCode: string): Promise<void> {
    return await verifyTOTPSetup({code: totpCode});
  }

  async resetPassword(oldPassword: string, newPassword: string): Promise<void> {
    return await updatePassword({
      oldPassword,
      newPassword
    });
  }

}
