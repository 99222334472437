<div class="deal-period-setup-container">
  <mat-card class="col-12">
    <mat-card-title class="text-center"><h3><strong>Deal Period Setup</strong></h3></mat-card-title>
    <mat-card-content>
      <div [formGroup]="dealPeriodSetupForm">
        <div class="row mt-4">
          <mat-label>
            <h3><strong>Does this offer is limited to unsold?</strong></h3>
          </mat-label>
          <p>
            <mat-checkbox color="primary" formControlName="subject_to_unsold">Subject to unsold
            </mat-checkbox>
          </p>
        </div>
        <div class="col-12 mt-5">
          <div class="row">
            <h3><strong>Define period when applicable</strong></h3>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <mat-form-field appearance="fill">
                  <mat-label>From</mat-label>
                  <input [matDatepicker]="picker" formControlName="deal_period_date_start" matInput>
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error
                  *ngIf="dealPeriodDateStartField!.touched && dealPeriodDateStartField!.invalid">
                  date doesn't matched the format, please check!
                </mat-error>
              </div>
              <div>
                <div class="row mt-4">
                  <div class="12hr-example">
                    <mat-form-field appearance="outline">
                      <mat-label>Initial time</mat-label>
                      <input [format]="24"
                             [ngxTimepicker]="deal_period_hour_start" aria-label="12hr format"
                             formControlName="deal_period_hour_start"
                             matInput
                             placeholder="HH"
                             readonly
                             required>
                      <ngx-material-timepicker #deal_period_hour_start
                                               [hoursOnly]="true"></ngx-material-timepicker>
                    </mat-form-field>
                    <mat-error *ngIf="dealPeriodDateStartField!.touched
                    && dealPeriodDateStartField!.valid
                         && dealPeriodHourStartField!.touched
                         && dealPeriodHourStartField!.valid
                         && dealPeriodSetupForm.hasError('offer_validation_first_date_time_invalid')">
                      first start time must be greater or equal than {{getTimePeriod()}}, please
                      check!
                    </mat-error>
                  </div>

                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="row">
                  <mat-form-field appearance="fill">
                    <mat-label>To</mat-label>
                    <input [matDatepicker]="picker2" formControlName="deal_period_date_end"
                           matInput>
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="dealPeriodDateStartField!.touched
                    && dealPeriodDateStartField!.valid
                    && dealPeriodDateEndField!.touched
                    && dealPeriodDateEndField!.valid
                         && dealPeriodHourStartField!.touched
                         && dealPeriodHourStartField!.valid
                         && dealPeriodHourEndField!.touched
                         && dealPeriodHourEndField!.valid
                         && dealPeriodSetupForm.hasError('offer_validation_date_invalid')">
                    deal period start date can't be greater than deal period end date, please check!
                  </mat-error>
                  <mat-error
                    *ngIf="dealPeriodDateEndField!.touched && dealPeriodDateEndField!.invalid">
                    date doesn't match the format, please check!
                  </mat-error>
                  <div>
                  </div>
                  <div class="row mt-4">
                    <div class="12hr-example">
                      <mat-form-field appearance="outline">
                        <mat-label>End Time</mat-label>
                        <input [format]="24"
                               [ngxTimepicker]="deal_period_hour_end" aria-label="12hr format"
                               formControlName="deal_period_hour_end"
                               matInput
                               placeholder="HH"
                               readonly
                               required
                        >
                        <ngx-material-timepicker #deal_period_hour_end
                                                 [hoursOnly]="true"></ngx-material-timepicker>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Tier 1</mat-label>
              <input autocomplete="off"
                     formControlName="tierOne"
                     id="tierOne"
                     matInput
                     placeholder="0.00$"
                     type="text"
              >
              <span matPrefix>USD&nbsp;</span>
            </mat-form-field>
            <mat-error *ngIf="dealPeriodTierOneField?.hasError('validation_value_one')">
              Check the number tier 1
            </mat-error>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Tier 2</mat-label>
              <input autocomplete="off"
                     formControlName="tierTwo"
                     id="tierTwo"
                     matInput
                     placeholder="0.00$"
                     type="text"
              >
              <span matPrefix>USD&nbsp;</span>
            </mat-form-field>
            <mat-error *ngIf="dealPeriodTierTwoField?.hasError('validation_value_two')">
              Check the number tier 2
            </mat-error>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Tier 3</mat-label>
              <input autocomplete="off"
                     formControlName="tierThree"
                     id="tierThree"
                     matInput
                     placeholder="0.00$"
                     type="text"
              >
              <span matPrefix>USD&nbsp;</span>
            </mat-form-field>
            <mat-error *ngIf="dealPeriodTierThreeField?.hasError('validation_value_three')">
              Check the number tier 3
            </mat-error>
          </div>
          <div class="row">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Tier 4</mat-label>
              <input
                (ngModelChange)="dealPeriodTierOneField || dealPeriodTierTwoField || dealPeriodTierFourField"
                autocomplete="off"
                formControlName="tierFour"
                id="tierFour"
                matInput
                placeholder="0.00$"
                type="text"
              >
              <span matPrefix>USD&nbsp;</span>
            </mat-form-field>
            <mat-error *ngIf="dealPeriodTierFourField?.hasError('validation_value_four')">
              Check the number tier 4
            </mat-error>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
