import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {RequestStatsDto} from "@features/stats/model/request-stats.dto";
import {RequestByStateDto} from "@features/stats/model/request-by-state.dto";
import {StatsService} from "@features/stats/services/statsService";
import {UserDto} from "@shared/model/dto/user.dto";
import {FormControl} from "@angular/forms";
import {SearchStatisticsDto} from "@features/stats/model/search-statistics.dto";
import {DateUtil} from "@shared/utility/dateUtil";

@Component({
  selector: 'app-stats-request-buyer',
  templateUrl: './stats-request-buyer.component.html',
  styleUrls: ['./stats-request-buyer.component.scss'],
})


export class StatsRequestBuyerComponent implements OnInit {


  startDate = new FormControl("");
  endDate = new FormControl("");

  detailUser!: string;
  detailStatus!: RequestByStateDto[];
  stats!: RequestStatsDto[];
  value: UserDto[] = [];
  displayedColumns: string[] = [
    'userName', 'products_created', 'offersEnter', 'requestResolved', 'averageTimeToSolveRequest'
  ];


  constructor(public dialog: MatDialog,
              private statsService: StatsService,
  ) {
  }

  ngOnInit(): void {
    this.getStats(new SearchStatisticsDto());
  }

  getStats(searchStatisticsDto: SearchStatisticsDto) {
    this.statsService.getStatsForBuyers(searchStatisticsDto).subscribe(value => {
      this.stats = value;
      if (this.stats && this.stats.length > 0) {
        this.showStatus(this.stats[0].status, this.stats[0].userName)
      }

    })
  }

  time_convert(num: number | undefined) {
    if (num) {
      const hours: number = Math.floor(num);
      const minutes: number = Math.floor(num % 60);
      return `${hours}hr : ${minutes}min`;
    }
    return '';

  }

  // status(requestByStatus: RequestByStateDto[]) {
  //   const dialogRef = this.dialog.open(StatusForRequestComponent, {
  //     width: '50%',
  //     height: '60%',
  //   })
  //   const instance =  dialogRef.componentInstance;
  //   instance.requestByStatus = requestByStatus;
  //
  //   instance.isDialog = true;


  showStatus(status: RequestByStateDto[], name: string) {
    this.detailUser = name ?? 'Not assigned';
    this.detailStatus = status;
  }

  getFilteredData() {
    if (this.startDate && this.endDate) {
      let searchStatisticsDto = new SearchStatisticsDto();
      searchStatisticsDto.startDate = DateUtil.formatDate(this.startDate.value, "YYYY-MM-DDTHH:mm:ss");
      searchStatisticsDto.endDate = DateUtil.formatDate(this.endDate.value, "YYYY-MM-DDTHH:mm:ss");
      this.getStats(searchStatisticsDto);
    }
  }
}
