import { Component } from '@angular/core';
import {ADMIN_USER, DASHBOARD_AND_ANALYTICS, ORDERS, REQUESTS} from "@shared/config/constants/menu-roles.const";

@Component({
  selector: 'app-da-orders-menu',
  templateUrl: './da-orders-menu.component.html',
  styleUrls: ['./da-orders-menu.component.scss']
})
export class DaOrdersMenuComponent {

  protected readonly DASHBOARD_AND_ANALYTICS = DASHBOARD_AND_ANALYTICS;
  protected readonly ORDERS = ORDERS;


  showSubMenu = false;

  showHide(ItemSubMenu: HTMLUListElement) {
    this.showSubMenu = !this.showSubMenu;
    if (this.showSubMenu){
      ItemSubMenu.style.display = 'block'
    }else {
      ItemSubMenu.style.display = 'none'
    }

  }

  protected readonly REQUESTS = REQUESTS;
  protected readonly ADMIN_USER = ADMIN_USER;
}
