<div class="test-dashboard-container">
  <app-filter-business-partner [title]="'Test dashboard'"
                               [sapUsertype]="SapUserType.SAP_BUYER"
                               (emitFormValue)="filterDashBoard($event)"
  >
  </app-filter-business-partner>
  <div class="superset-dashboard" style="background: white" >
    <div id="dashboard-orders" style="background: white"></div>
  </div>
</div>
