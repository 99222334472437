<div  class="col-12 close_button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon svgIcon="close-popup-icon">close</mat-icon>
  </button>
</div>

<div class="icon-container">
  <img src="assets/icon/offers/FeedbackPopupIcon.svg" alt="Feedback List Icon">
</div>


<div *ngIf="request && isDialog" class="feedback-container">
  <mat-card class="feedback-container__card">
    <mat-card-title>
      <h2>FEEDBACK</h2>
      <h3>
      {{
          (request.item.itemDescription.length >= 50
            ? request.item.itemDescription.toString().substring(0, 50).concat('...')
            : request.item.itemDescription) ?? ""
        }}
    </h3>
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="feedbackForm">
        <div *ngxPermissionsOnly="[ROLES.ADMIN,ROLES.SALES,ROLES.ACCOUNT_MANAGER]"
             class="feedback-container__card--add-new-feedback">
          <button (click)="newFeedback()" class="btn">ADD NEW FEEDBACK</button>
        </div>
        <div *ngIf="newFeedbackStatus" class="feedback-container__card--form-field">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Please select a valid reason</mat-label>
            <mat-select [(ngModel)]="request.feedbackReason" formControlName="feedbackBy">
              <mat-option *ngFor="let option of feedbackOptions" [value]="option.description">
                {{ option.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="row">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>Additional comments</mat-label>
              <textarea [(ngModel)]="request.feedbackComments"
                        autocomplete="on"
                        formControlName="feedback_request_comments"
                        id="feedback_request_comments"
                        matInput
                        type="text"
                        cdkTextareaAutosize
                        maxlength="500"
              ></textarea>
            </mat-form-field>
            <mat-error
              *ngIf="this.feedbackForm.controls['feedback_request_comments'].hasError('maxlength')">
              Maximum length is 500
            </mat-error>
          </div>
          <div class="feedback-container__card--save-feedback">
            <button (click)="save()" class="btn" [disabled]="feedbackForm.invalid">Save</button>
          </div>
        </div>

        <div class="feedback-container__card--table">
          <table >
            <caption>Request Feedback</caption>
            <tr>
              <th>Feedback</th>
              <th>Comments</th>
              <th>Date</th>
            </tr>
            <tr *ngFor="let items of request.offerFeedback">
              <td> {{items?.feedbackReason}}</td>
              <td> {{items?.feedbackComments}}</td>
              <td> {{items?.creationDate | date:'MM-dd-yyyy HH:mm:ss'}}</td>
            </tr>
          </table>
        </div>
      </form>
    </mat-card-content>

  </mat-card>
</div>

