import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "@env";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class OfferValidationService {

  private automatePurchasesUrl = environment.automatePurchasesUrl;

  private saveOfferValidationUrl = `${this.automatePurchasesUrl}offer-validation/save`;
  private updateOfferValidationUrl = `${this.automatePurchasesUrl}offer-validation/update`;


  constructor(private http: HttpClient) {
  }

  getHttpHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': environment.resourcesApiKey,
    });
  }

  saveOfferValidation(offerValidationDto: any): Observable<any> {
    return this.http.post(this.saveOfferValidationUrl, offerValidationDto);
  }

  updateOfferValidation(offerValidationDto: any) {
    return this.http.post(this.updateOfferValidationUrl, offerValidationDto);
  }


}
