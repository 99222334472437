import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";
import {AuthService} from "@core/services";
import {SidenavService} from "@shared/services/sidenav.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @ViewChild('sidenav', {}) sidenav!: MatSidenav;
  @ViewChild("sidenavContainer", {}) sidenavContainer: any;

  title: string = "";
  _isAuthenticated: boolean | undefined;

  userName: string = 'User Name';

  constructor(public authService: AuthService,
              public sidenavService: SidenavService) {
  }

  ngOnInit(): void {
    this.setIsAuthenticated();
  }


  setIsAuthenticated() {
    this.authService.isAuthenticated().then((value: boolean) => {
      this._isAuthenticated = value;

      if (value){
        this.setTitle();
        this.authService.loadRolesAndPermissions()
          .subscribe(result => {
            if (result) {
              this.authService.loadPermissions(result)
            }
          })
      }

    })
  }

  setTitle() {
    this.authService._getFromPayload("username")
      .then(username => {
        this.userName = username;
      });
  }

  logout() {
    this.authService.logout();
  }


}


