import {Component, Input} from '@angular/core';
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {FormGroup} from "@angular/forms";
import {WRequestDto} from "@features/product/model/dto/request/WRequestDto";
import {ProductOfferUtility} from "@shared/utility/productOfferUtility";
import {ItemCode} from "@features/product/model/enum/item-code";
import {Category} from "@core/model/category";

@Component({
  selector: 'app-request-information',
  templateUrl: './request-information.component.html',
  styleUrls: ['./request-information.component.scss']
})
export class RequestInformationComponent {
  itemId!: number;
  skuSap!: string;

  @Input()
  product!: ItemDto | null;
  info!: string;

  @Input()
  formInformation!: FormGroup;

  @Input()
  requestInformation!: WRequestDto;

  @Input()
  categories: Category[] = [];

  get priceMin() {
    return this.formInformation.get('priceMin');
  }

  get priceMax() {
    return this.formInformation.get('priceTwo');
  }

  get quantityMin() {
    return this.formInformation.get('quantityMin');
  }

  get quantityMax() {
    return this.formInformation.get('quantityMax');
  }

  eanField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.EAN)
  }

  upcField(product: ItemDto) {
    return ProductOfferUtility.getItemCode(product!.itemCodes, ItemCode.UPC)
  }
}
