import {
  DealsNotificationSegmentDto
} from "@features/product-offer/model/notifications/deals-notifications-segments.dto";


export class DealsNotificationsDto {
  id!: string;
  sku!: string;
  itemDescription!: string;
  initialDate!: any;
  endDate!: any;
  cost!: number;
  orderMinQty!: number;
  statusSales!: number;
  statusCustomer!: number;
  statusWhatsapp!: number;
  idEvent!: number;
  url!: string;
  orderMin!: number;
  segments: DealsNotificationSegmentDto [] = [];


}
