export class OfferStartingPriceDto {
  id!: number;
  itemId!: number;
  tierOne!: number;
  tierTwo!: number;
  tierThree!: number;
  tierFour!: number;
  creationUser!: string;

}
