<section>

  <section class="row">
  <div class="main-home col-12 col-lg-6 col-xxl-8" >
    <p class="main-home__welcome-back">Welcome Back! <b>{{userName}}</b></p>
    <p class="col-12 main-home__text">Your centralized hub to access everything you need! Use this platform to stay up-to-date
      with the latest products, share information, and collaborate with your colleagues.
      We're hereto make your workday easier!</p>

    <p class="main-home__text2">Alright, where would you like to start?</p>
    <div class="row main-home__actions ">
      <button mat-raised-button class="col-3 col-xxl-4">
        <mat-icon svgIcon="dashboard-and-analytics-icon-blue" class="col-2"></mat-icon>
        <span class="col-10">See my entire summary</span>
      </button>
      <button mat-raised-button class="col-3 col-xxl-4">
        <mat-icon svgIcon="marketplace-icon-blue" class="col-2"></mat-icon>
        <span class="col-10">Organize my marketplace</span>
      </button>
      <button mat-raised-button class="col-3 col-xxl-4">
          <mat-icon svgIcon="product-and-offers-icon-blue" class="col-2"></mat-icon>
          <span class="col-10">Add a new product</span>
      </button>
      <button mat-raised-button class="col-3 col-xxl-4">
          <mat-icon svgIcon="requests-icon-blue" class="col-2"></mat-icon>
          <span class="col-10">Make a request</span>
      </button>
    </div>
    <p class="main-home__see-profile">See My Profile
        <mat-icon>
          arrow_circle_right
        </mat-icon>
     </p>
  </div>

  <div class="main-banner col-12 col-lg-2">
    <img [ngSrc]="'assets/home/home-banner.png'" alt="Banner" height="500" width="800">
  </div>
  </section>

</section>
