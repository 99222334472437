import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {Category} from "@core/model/category";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductService} from "@features/product/services/product.service";
import {TITLE_ERROR} from "@shared";
import {ShareDataService} from "@shared/services/share-data.service";
import {showErrorPopup} from "@shared/utility/popup-message";
import {ProductInfoFormService} from "@features/product/features/presentation/product-info/product-info-form.service";

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.css']
})
export class ProductCategoriesComponent implements OnInit {

  @Input()
  productInfoForm!: FormGroup;
  @Input()
  sub_categories: Category[] = [];
  @Input()
  categories: Category[] = [];
  @Output() emitSpecifications = new EventEmitter();
  @Output() emitSubCategories = new EventEmitter();

  @Input()
  product!: ProductsDto;

  allCategories: Array<Array<Category>> = [];

  constructor(
    private productService: ProductService,
    private shareDataService: ShareDataService,
    private productInfoFormService: ProductInfoFormService
  ) {
  }

  get categoryField() {
    return this.productInfoForm.get('category');
  }

  get subCategoriesField() {
    return this.productInfoForm.get('sub_categories') as FormArray;
  }

  get specificationsField() {
    return this.productInfoForm.get('specifications') as FormArray;
  }

  ngOnInit(): void {

    if (this.product && this.product.selectCategories && this.product.selectCategories.key) {
      this.categoryField?.setValue(this.product.selectCategories.key);
      this.categoryField?.markAsTouched();
      this.parentGetSubcategory(this.product.selectCategories.key, true);
    }
  }

  getCategoriesAt(position: number) {
    return this.allCategories.at(position);
  }

  buildSubCategory(parentKey?: string) {
    this.subCategoriesField.push(this.productInfoFormService.createSubCategoryField(parentKey));
  }

  removeAllCategory() {
    this.subCategoriesField.clear();
  }

  removeAllSpecifications() {
    this.specificationsField.clear();
  }

  parentGetSubcategory(parentCode: string, callSpecs: boolean) {
    console.log(parentCode)
    this.allCategories = [];
    this.removeAllCategory();
    this.removeAllSpecifications();
    this.productService.getSubCategories(parentCode).subscribe({
      next: response => {
        if (response.body.length > 0) {
          this.setCategory(parentCode, response.body);
          if (callSpecs) {
            this.buildSubCategory(this.product.selectSudCategories);
            this.shareDataService.changeMessage({recallSpecifications: true});
            this.emitSpecifications.emit(true);
            this.emitSubCategories.emit(this.allCategories);
          } else {
            this.buildSubCategory();
            this.emitSpecifications.emit(false);
          }
        }
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    });
  }

  getSubcategory(parentCode: string) {
    this.productService.getSubCategories(parentCode).subscribe({
      next: response => {
        if (response.body.length > 0) {
          this.setCategory(parentCode, response.body);
          this.buildSubCategory(parentCode);
        }
        this.shareDataService.changeMessage({recallSpecifications: true});
        this.emitSpecifications.emit(this.categoriesValid());
        this.emitSubCategories.emit(this.allCategories);
      },
      error: (err: any) => {
        showErrorPopup(TITLE_ERROR, err.message);
      },
    });
  }

  setCategory(parent: string, value: any) {
    let itemsMapped: Category[] = [];

    value.map((item: any) => {
      let category = new Category();
      category.key = item.itmctg_code;
      category.values = item.categorychild;
      category.parent = parent;
      itemsMapped.push(category);
    });
    this.allCategories.push(itemsMapped);
  }

  removeCategory($event: any, position: number) {
    console.log('enter to remove')
    let indexToDelete: number[] = [];
    this.subCategoriesField.controls.forEach(((value, index) => {
      if (index > position) {
        indexToDelete.push(index);
      }
    }));

    for (let i = indexToDelete.length - 1; i >= 0; i--) {
      this.subCategoriesField.controls.splice(indexToDelete[i], 1);
      this.allCategories.splice(indexToDelete[i], 1);
    }
  }

  categoriesValid() {
    let foundAnyControl = this.subCategoriesField.controls.filter(control => control.invalid || control.untouched);
    return this.categoryField?.touched
      && this.categoryField?.valid
      && this.subCategoriesField.touched
      && this.subCategoriesField.valid
      && foundAnyControl.length === 0;
  }
}
