import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "@features/home/home.component";

import {
  ListProductComponent
} from "@features/product/features/presentation/list-product/list-product.component";
import {
  ProductMainComponent
} from "@features/product/features/smart/product-main/product-main.component";
import {
  PoAssociatedTrackingComponent
} from "@features/product/features/presentation/po-associated-tracking/po-associated-tracking.component";
import {ngxPermissionsGuard} from "ngx-permissions";
import {PRODUCT_AND_OFFERS, ADMIN_USER, DASHBOARD_AND_ANALYTICS} from "@shared/config/constants/menu-roles.const";
import {
  DashboardInventoryComponent
} from "@features/product/features/presentation/dashboard-inventory/dashboard-inventory.component";
import {
  DashboardVendorInventoryComponent
} from "@features/product/features/presentation/dashboard-vendor-inventory/dashboard-vendor-inventory.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'list-product',
    canActivate: [ngxPermissionsGuard],
    component: ListProductComponent,
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ITEM_MASTER.ITEM_LIST, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'create',
    component: ProductMainComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.ITEM_MASTER.ITEM_CREATION.ITEM_CREATION, ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'po_associated_with_tracking',
    component: PoAssociatedTrackingComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [PRODUCT_AND_OFFERS.PURCHASE_ORDER.PO_RECEIVING, ADMIN_USER],
        redirectTo: '/home'
      }
    },

  },
  {
    path: 'dashboard-inventory',
    component: DashboardInventoryComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [DASHBOARD_AND_ANALYTICS.ANALYTICS.INVENTORY, ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {
    path: 'dashboard-inventory-vendors',
    component: DashboardVendorInventoryComponent,
    canActivate: [ngxPermissionsGuard],
    data: {
      permissions: {
        only: [DASHBOARD_AND_ANALYTICS.ANALYTICS.VENDORS, ADMIN_USER],
        redirectTo: '/home'
      }
    },
  },
  {path: '**', redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductRoutingModule {
}
