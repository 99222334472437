import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MatCardModule} from "@angular/material/card";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatRadioModule} from "@angular/material/radio";
import {ReactiveFormsModule} from "@angular/forms";
import {MatPaginatorModule} from "@angular/material/paginator";
import {SharedModule} from "@shared/shared.module";
import {MatTableModule} from "@angular/material/table";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSelectModule} from "@angular/material/select";
import {MatListModule} from "@angular/material/list";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {
  ChooseProcessComponent
} from "@features/product-offer/presentation/choose-process/choose-process.component";
import {
  ListOfferComponent
} from "@features/product-offer/presentation/list-offer/list-offer.component";
import {
  NewOfferComponent
} from "@features/product-offer/presentation/new-offer/new-offer.component";
import {
  CustomerNotificationsComponent
} from "@features/product-offer/presentation/notifications-offer/customer-notifications/customer-notifications.component";
import {
  SalesNotificationsComponent
} from "@features/product-offer/presentation/notifications-offer/sales-notifications/sales-notifications.component";
import {
  AddItemsToOrderComponent
} from "@features/product-offer/presentation/offer-purchase-order/add-items-to-order/add-items-to-order.component";
import {
  EnterCostAndQuantityComponent
} from "@features/product-offer/presentation/offer-purchase-order/enter-cost-and-quantity/enter-cost-and-quantity.component";
import {
  PoCreationComponent
} from "@features/product-offer/presentation/offer-purchase-order/po-creation/po-creation.component";
import {
  PoForProductSelectedComponent
} from "@features/product-offer/presentation/offer-purchase-order/po-for-product-selected/po-for-product-selected.component";
import {
  SearchForVendorComponent
} from "@features/product-offer/presentation/offer-purchase-order/search-for-vendor/search-for-vendor.component";
import {
  SelectTheStoreComponent
} from "@features/product-offer/presentation/offer-purchase-order/select-the-store/select-the-store.component";
import {
  BasePriceListComponent
} from "@features/product-offer/presentation/offer-validation/base-price-list/base-price-list.component";
import {
  DealPeriodSetupComponent
} from "@features/product-offer/presentation/offer-validation/deal-period-setup/deal-period-setup.component";
import {
  MoqSetupComponent
} from "@features/product-offer/presentation/offer-validation/moq-setup/moq-setup.component";
import {
  SpecialDealPeriodSetupComponent
} from "@features/product-offer/presentation/offer-validation/special-deal-period-setup/special-deal-period-setup.component";
import {
  OfferPurchaseOrderMainComponent
} from "@features/product-offer/smart/offer-purchase-order-main/offer-purchase-order-main.component";
import {
  OfferValidationMainComponent
} from "@features/product-offer/smart/offer-validation-main/offer-validation-main.component";
import {
  UpdateBasePricesMainComponent
} from "@features/product-offer/smart/offer-validation-main/update-base-prices-main/update-base-prices-main.component";
import {
  UpdateDealOfferMainComponent
} from "@features/product-offer/smart/offer-validation-main/update-deal-offer-main/update-deal-offer-main.component";
import {
  ProductOfferMainComponent
} from "@features/product-offer/smart/product-offer-main/product-offer-main.component";
import {
  NotificationsComponent
} from "@features/product-offer/smart/notifications-main/notifications.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {ProductModule} from "@features/product/product.module";
import {ProductOfferRoutingModule} from "@features/product-offer/product-offer-routing.module";
import {MatNativeDateModule} from "@angular/material/core";
import {
  ListOfferForRequestComponent
} from './presentation/list-offer-for-request/list-offer-for-request.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {
  ProductOfferCanvaComponent
} from './presentation/product-offer-canva/product-offer-canva.component';
import {MatSortModule} from "@angular/material/sort";
import {
  FeedbackDealOffersComponent
} from './presentation/feedback-deal-offers/feedback-deal-offers.component';
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from "ngx-permissions";
import {ShowEtaComponent} from './presentation/show-eta/show-eta.component';
import {
  NotificationWhatsappComponent
} from "@features/product-offer/presentation/notification-whatsapp/notification-whatsapp.component";
import { CanvaAndPriceComponent } from './presentation/canva-and-price/canva-and-price.component';
import { PricesAndCostComponent } from './presentation/canva-and-price/prices-and-cost/prices-and-cost.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { DownloadInventoryComponent } from './presentation/download-inventory/download-inventory.component';
import { MyCustomersPriceComponent } from './presentation/canva-and-price/my-customers-price/my-customers-price.component';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollableElement,
  CdkVirtualScrollViewport, ScrollingModule
} from "@angular/cdk/scrolling";
import {NgxLazyMatSelectModule} from "ngx-lazy-mat-select";
import { DashboardItemPurchasesComponent } from './presentation/dashboard-item-purchases/dashboard-item-purchases.component';


@NgModule({
    declarations: [
        ChooseProcessComponent,
        ListOfferComponent,
        NewOfferComponent,
        CustomerNotificationsComponent,
        SalesNotificationsComponent,
        AddItemsToOrderComponent,
        EnterCostAndQuantityComponent,
        PoCreationComponent,
        PoForProductSelectedComponent,
        SearchForVendorComponent,
        SelectTheStoreComponent,
        BasePriceListComponent,
        DealPeriodSetupComponent,
        MoqSetupComponent,
        SpecialDealPeriodSetupComponent,
        OfferPurchaseOrderMainComponent,
        OfferValidationMainComponent,
        UpdateBasePricesMainComponent,
        UpdateDealOfferMainComponent,
        ProductOfferMainComponent,
        NotificationsComponent,
        ListOfferForRequestComponent,
        ProductOfferCanvaComponent,
        FeedbackDealOffersComponent,
        ShowEtaComponent,
        NotificationWhatsappComponent,
        CanvaAndPriceComponent,
        PricesAndCostComponent,
        DownloadInventoryComponent,
        MyCustomersPriceComponent,
        DashboardItemPurchasesComponent,
    ],
  imports: [
    CommonModule,
    ProductOfferRoutingModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    SharedModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    ProductModule,
    MatSnackBarModule,
    MatSortModule,
    NgxPermissionsModule,
    NgxPermissionsRestrictStubModule,
    NgOptimizedImage,
    MatTooltipModule,
    CdkVirtualScrollableElement,
    CdkVirtualScrollViewport,
    CdkVirtualForOf,
    CdkFixedSizeVirtualScroll,
    ScrollingModule,
    NgxLazyMatSelectModule
  ],

    exports: [
        CustomerNotificationsComponent
    ]
})
export class ProductOfferModule {
}
