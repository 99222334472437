<div class="container">
  <mat-card class="col-12">
    <mat-card-content>
      <div [formGroup]="salesForm">
        <div class="row">
          <div class="sales-notification-title">
            <h1><strong>Would you like to notify the Sales team about this deal?</strong></h1>
          </div>
          <mat-radio-group
            class="col-12 group-sales-notification"
            formControlName="selection">
            <mat-radio-button [value]="1" class="group-sales-notification__radio-button"
                              color="primary">Yes
            </mat-radio-button>
            <mat-radio-button [value]="0" class="group-sales-notification__radio-button"
                              color="primary">No
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
