import {Component, OnInit, ViewChild} from '@angular/core';
import {CONTACT_SUPPORT, TITLE_ERROR} from "@shared";
import {MatPaginator, PageEvent} from "@angular/material/paginator";

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ProductOfferState} from "@features/product/model/enum/product-offer-state";
import {
  UpdateDealOfferMainComponent
} from "@features/product-offer/smart/offer-validation-main/update-deal-offer-main/update-deal-offer-main.component";
import {
  OfferStartingPriceDto
} from "@features/product-offer/model/offer-validation/offerStartingPrice.dto";
import {
  UpdateBasePricesMainComponent
} from "@features/product-offer/smart/offer-validation-main/update-base-prices-main/update-base-prices-main.component";
import {ProductOfferDto} from "@features/product-offer/model/offer-validation/ProductOffer.dto";
import {
  FindAllOfferParamsDto
} from "@features/product-offer/model/offer-validation/findAllOfferParams.dto";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {ItemCodesDto} from "@features/product/model/dto/item/itemCodes.dto";
import {ItemCode} from "@features/product/model/enum/item-code";
import {DateUtil} from "@shared/utility/dateUtil";
import {showErrorPopup} from "@shared/utility/popup-message";


@Component({
  selector: 'app-list-offer',
  templateUrl: './list-offer.component.html',
  styleUrls: ['./list-offer.component.scss']
})
export class ListOfferComponent implements OnInit {

  form: FormGroup;
  displayedColumns: string[] = [
    'sku', 'item_description', 'initial_date', 'end_date', 'barcode', 'order_min',
    'tier_four', 'status', 'edit_offer', 'edit_base_prices'
  ];
  data: ProductOfferDto[] = [];
  state = ProductOfferState;

  resultsLength = 0;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  statesToEditOffer: any[] = ['NO_LIMITED_TIME_OFFER', 'OPEN_LIMITED_TIME_OFFER']


  constructor(private productOfferService: ProductOfferService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) {
    this.form = this.formBuilder.group({
      type_search_product: ['', Validators.required],
      product_search_value: ['', Validators.required]
    })
  }

  get searchType() {
    return this.form.get('type_search_product');
  }

  get searchValue() {
    return this.form.get('product_search_value');
  }

  ngOnInit(): void {
    let params = new FindAllOfferParamsDto();
    params.searchType = 'OTHER';
    this.callData(params);
  }

  openUpdateOffer(productOfferDto: ProductOfferDto) {
    const dialogRef = this.dialog.open(UpdateDealOfferMainComponent, {
      width: '80%',
      height: '90%',
    })
    let instance = dialogRef.componentInstance;
    instance.productOfferDto = productOfferDto;
    dialogRef.afterClosed().subscribe({
      next: () => {
        this.recallDefault();
      }
    })
  }

  openEditBasePrice(productOfferDto: ProductOfferDto) {
    this.productOfferService.getBasePricesByItemId(productOfferDto.item.id).subscribe({
      next: value => {
        if (value) {
          let startingPrice: OfferStartingPriceDto = value;
          const dialogRef = this.dialog.open(UpdateBasePricesMainComponent, {
            width: '80%',
            height: '90%',
          })
          let instance = dialogRef.componentInstance;
          instance.basePrices = startingPrice;
          dialogRef.afterClosed().subscribe({
            next: () => {
              this.recallDefault();
            }
          })
        }
      }
    })

  }

  recallDefault() {
    let params = new FindAllOfferParamsDto();
    params.page = this.paginator.pageIndex;
    params.size = this.paginator.pageSize;
    params.searchType = this.searchType?.value;
    params.searchValue = this.searchValue?.value;
    this.callData(params)
  }

  pagination($event: PageEvent) {
    let params = new FindAllOfferParamsDto();
    params.page = $event.pageIndex;
    params.size = $event.pageSize;
    params.searchType = this.searchType?.value;
    params.searchValue = this.searchValue?.value;
    this.callData(params);
  }

  getProduct() {
    let params = new FindAllOfferParamsDto();
    params.searchType = this.searchType?.value;
    params.searchValue = this.searchValue?.value;
    this.callData(params);
  }

  getBarcode(itemCodes: ItemCodesDto[]) {
    if (itemCodes) {
      let upc = this.upcField(itemCodes);
      let ean = this.eanField(itemCodes);
      return upc ? upc : (ean ? ean : '');
    }
    return '';

  }

  upcField(itemCodes: ItemCodesDto[]) {
    return this.getItemCode(itemCodes, ItemCode.UPC);
  }

  eanField(itemCodes: ItemCodesDto[]) {
    return this.getItemCode(itemCodes, ItemCode.EAN);
  }

  getItemCode(itemCodes: ItemCodesDto[], codeType: string) {
    if (itemCodes && itemCodes.length > 0) {
      return itemCodes.find(code => code.itemCodeType === codeType)?.itemCode;
    }
    return '';
  }

  formatDate(date: any) {
    return date ? DateUtil.formatDate(date, 'DD-MM-YYYY HH:mm:ss') : '';
  }

  getState(state: any) {
    switch (state) {
      case ProductOfferState.NO_LIMITED_TIME_OFFER:
        return 'NO DATE/TIME RESTRICTIONS'
      case ProductOfferState.OPEN_LIMITED_TIME_OFFER:
        return 'LIMITED OFFER'
      case ProductOfferState.LIMITED_TIME_OFFER_ENDS_BY_INVENTORY:
        return 'OFFER ENDS BY INVENTORY'
      case ProductOfferState.LIMITED_TIME_OFFER_ENDS_BY_DATE:
        return 'OFFER ENDS BY DATE'
      default:
        return ''
    }
  }

  private callData(params: any) {
    this.productOfferService.findAllOffers(params).subscribe({
      next: value => {
        if (value) {
          this.data = value.items;
          this.resultsLength = value.totalItems;
        }
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }
}
