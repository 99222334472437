// src/app/features/product/services/add-multiple-images-form.service.ts
import {Injectable} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AddMultipleImagesFormService {
  constructor(private formBuilder: FormBuilder) {
  }

  buildAddMultipleImagesForm(): FormGroup {
    return this.formBuilder.group({
      images: ['', [atLeastOneCheckboxCheckedValidator]],
    });
  }
}

export function atLeastOneCheckboxCheckedValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (Array.isArray(value)) {
    const isChecked = value.some((item: any) => item.checked);
    return isChecked ? null : {atLeastOneCheckboxChecked: true};
  }
  return {atLeastOneCheckboxChecked: true};
}
