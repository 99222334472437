import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {ItemCodesDto} from "@features/product/model/dto/item/itemCodes.dto";
import {ItemCustomizedCode} from "@features/product/model/enum/item-customized-code";


export class ProductOfferUtility {
  static findSapCode(itemCustomized: ItemCustomizedDto[]): ItemCustomizedDto | undefined {
    return itemCustomized.find(param => param.itemCustomizedCode === ItemCustomizedCode.SAP_CODE)
  }

  static getItemCode(itemCodes: ItemCodesDto[], codeType: string) {
    if (itemCodes && itemCodes.length > 0) {
      return itemCodes.find(code => code.itemCodeType === codeType)?.itemCode;
    }
    return '';
  }
}
