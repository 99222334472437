import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BehaviorSubject, Observable, scan} from "rxjs";
import {DABusinessPartnerDto} from "@features/product/model/dto/da-business-partner/DABusinessPartner.dto";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {DaUserService} from "@shared/services/user/da-user.service";
import {DABusinessPartnerService} from "@features/product/services/da-business-partner.service";
import {DASearchBusinessPartnerDto} from "@shared/model/dto/da-search-business-partner.dto";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";

@Component({
  selector: 'app-filter-customers-card-code',
  templateUrl: './filter-customers-card-code.component.html',
  styleUrls: ['./filter-customers-card-code.component.scss']
})
export class FilterCustomersCardCodeComponent {


  form!: FormGroup;
  options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
  options$: Observable<DABusinessPartnerDto[]> | undefined;
  offset = 0;
  total = 10000000;
  limit = 20;

  user!: DAUserDto;

  @Input()
  className: string ="col-12";
  @Input()
  multiple: boolean = false;


  @Output() emitCardCode = new EventEmitter<any>;
  @Input()
  label: string = '';


  constructor(private userService: DaUserService,
              private dABusinessPartnerService: DABusinessPartnerService,
              private formBuilder: FormBuilder) {

      this.form = formBuilder.group({
        cardCode:['']
      })

    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );

  }

  get cardCodeField() {
    return this.form.get('cardCode');
  }

  ngOnInit( ): void {
    this.initCallBP();
    this.cardCodeField?.valueChanges.subscribe(value => {
      this.emitCardCode.emit(value);
    });
  }

  private initCallBP() {
    this.userService.findUser().subscribe(result => {
      if (result?.userCustomized?.length > 0 && !result?.isLeader){
        this.user = result;
        let searchBusinessPartner = new DASearchBusinessPartnerDto();
        let userCode = this.getSapCode(result.userCustomized);
        if (userCode) {
          searchBusinessPartner.ownerCode = Number(userCode);
        }
        this.callDataBP(searchBusinessPartner);
      }else {
        let searchBusinessPartner = new DASearchBusinessPartnerDto();
        this.callDataBP(searchBusinessPartner);
      }
    })

  }

  private callDataBP(searchBusinessPartner: DASearchBusinessPartnerDto) {
    this.dABusinessPartnerService.getBusinessPartnerByOwner(searchBusinessPartner)
      .subscribe((result: any) => {
        if (result && result.items.length > 0) {
          this.options.next(result.items as DABusinessPartnerDto[])
        }
      })
  }

  getNextBatch() {
    this.offset += this.limit;
    let searchBusinessPartner = new DASearchBusinessPartnerDto();
    searchBusinessPartner.totalItems = this.offset;

    if (this.user?.userCustomized?.length > 0 && !this.user?.isLeader) {
      let userCode = this.getSapCode(this.user.userCustomized);
      if (userCode) {
        searchBusinessPartner.ownerCode = Number(userCode);
      }
    }
    this.callDataBP(searchBusinessPartner);
  }

  resetForm() {
    this.form.reset();
  }

  getSapCode(userCustomized: UserCustomizedDto[]) {
    return UserCustomizedUtil.findBuyerOwnerCode(userCustomized)?.userCustomizedValue;
  }

}
