export class StringUtility {
  static capitalize(word: string) {
    console.log(word);
    return word
    .split('')
    .map((letter, index) =>
      index ? letter.toLowerCase() : letter.toUpperCase(),
    )
    .join('');
  }

  static validateStringEmpty(value: string) {
    if (!value) {
      return false;
    }
    return String(value).trim().length > 0
  }
}
