import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { LoginModule } from '@features/login/login.module';
import { Amplify } from 'aws-amplify';
import { environment } from '@env';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NetworkInterceptor } from '@core/interceptors/network.interceptor';
import { ProductOfferModule } from '@features/product-offer/product-offer.module';
import { RequestModule } from '@features/request/request.module';
import { RoleGuard } from '@core/guards';
import { StatsModule } from '@features/stats/stats.module';
import { EcommerceModule } from '@features/eCommerce/ecommerce.module';
import { MarketplaceModule } from '@features/marketplace/marketplace.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: environment.userPoolWebClientId,
      userPoolId: environment.userPoolId
    }
  }
});

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    ProductOfferModule,
    LoginModule,
    NgxPermissionsModule.forRoot(),
    SweetAlert2Module.forRoot(),
    MatIconModule,
    RequestModule,
    StatsModule,
    EcommerceModule,
    MarketplaceModule,
    NgbModule
  ],
  providers: [
    RoleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ],
  bootstrap: [AppComponent],
  exports: [NgxPermissionsModule]
})
export class AppModule { }
