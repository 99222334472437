import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {CONTACT_SUPPORT, custom_popup, TITLE_ERROR} from "@shared";
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ProductService} from "@features/product/services/product.service";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {showErrorPopup} from "@shared/utility/popup-message";
import {ProductSelectFormService} from "@features/product/features/presentation/product-select/product-select-form.service";

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.css']
})
export class ProductSelectComponent implements OnInit {
  @Input()
  productSelectForm!: UntypedFormGroup;
  selectRadio!: boolean;
  @Input()
  products: ProductsDto[] = [];
  @Output()
  isProductSelect = new EventEmitter<any>();
  @Output()
  isProductFound = new EventEmitter<boolean>();

  constructor(
    private productService: ProductService,
    private productSelectFormService: ProductSelectFormService
  ) {}

  get typeSearchProduct() {
    return this.productSelectForm.get('type_search_product');
  }

  get productSearchValue() {
    return this.productSelectForm.get('product_search_value');
  }

  get productSelectValue() {
    return this.productSelectForm.get('product_select_value');
  }

  ngOnInit(): void {
    this.selectRadio = false;
    if (!this.productSelectForm) {
      this.productSelectForm = this.productSelectFormService.buildProductSelectForm();
    }
  }

  getProductValue() {
    const params = {
      searchCode: this.typeSearchProduct?.value,
      searchValue: this.productSearchValue?.value,
    };
    console.log(params);
    this.productService.getProduct(params).subscribe({
      next: response => {
        if (response.products && response.products.length > 0) {
          this.products = response.products;
        } else {
          custom_popup({
            title: 'Product not found',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'New search',
            denyButtonText: 'Continue',
            text: ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_FOUND,
          }).then((result) => {
            if (result.isConfirmed) {
              this.isProductFound.emit(true);
            } else if (result.isDenied) {
              this.isProductFound.emit(false);
            }
          });
        }
      },
      error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    });
  }

  getProductSelect(product: ProductsDto) {
    product.images.forEach((item, index) => item.order = index + 1);
    this.productSelectForm.get('product_select_value')?.setValue(product);
    this.isProductSelect.emit();
  }

  chanceRadio() {
    this.selectRadio = true;
    this.productSearchValue?.setValue('');
    this.products = [];
  }
}
