// src/app/features/product/services/manufacture-create-form.service.ts
import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ManufactureCreateFormService {
  constructor(private formBuilder: FormBuilder) {
  }

  buildManufactureCreateForm(): FormGroup {
    return this.formBuilder.group({
      manufacture_create_manufacture: ['', [Validators.required]],
      manufacture_create_option_manufacture: ['', [Validators.required]],
    });
  }
}
