<div class="col-12 close-button-template">
  <button [mat-dialog-close]="true" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div class="download">
  <button (click)="download()" color="primary" mat-raised-button>
    <mat-icon>download</mat-icon>
    Download
  </button>
</div>
<div #template style="width: 700px;">
  <div>
    <img alt="Header Distritech.com" src="assets/icon/header-email.png"/>
  </div>

  <div class="template-content__body">
    <div class="template-content__body--content">
      <div class="template-content__container-image">
        <a
          [href]="'https://www.distritech.com/products/' +  (skuSap(item.itemCustomized) ?? item.sku) "
          class="template-content__image-link" target="_blank">
          <img *ngIf="base64" [src]="base64" alt="image product"/>
        </a>
      </div>
      <h3 class="template-content__item-description">
        {{item.itemDescription}}
      </h3>
      <table aria-label="Item data" class="template-content__table">
        <th></th>
        <tr>
          <td>
            <h3 style="text-align: center; vertical-align: middle;">{{skuSap(item.itemCustomized)
            ?? item.sku }}</h3>
          </td>
        </tr>
        <tr *ngIf="price">
          <td>
            <h1
              style="text-align: center; vertical-align: middle;">{{price | currency: "USD":"symbol":'.2-2'}}</h1>
          </td>
        </tr>
        <tr>
          <td style="text-align: center;">
            <a
              [href]="'https://www.distritech.com/products/' +  (skuSap(item.itemCustomized) ?? item.sku) "
              class="template-content__table--shop-now"
              target="_blank">
              <img alt="shop now" src="assets/icon/buttonshopnow.png">
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="container-canva-footer">
    <img alt="Footer Distritech.com" src="assets/icon/footer-email.png"/>
    <p>© Distritech</p>
    <p> 12600 Northwest 25th Street, Suite 107, Miami, United States, 33182</p>
  </div>
</div>

