import {Component, Input, OnInit} from '@angular/core';
import {ProductProcessType} from '@features/product/model/enum/product-process-type';
import {ProductsDto} from "@features/product/model/dto/product/Products.dto";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductService} from "@features/product/services/product.service";
import {MatDialog} from "@angular/material/dialog";
import {CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ItemCode} from "@features/product/model/enum/item-code";
import {Category} from "@core/model/category";
import {ItemPicturesDto} from "@features/product/model/dto/item/itemPictures.dto";
import {ImageType} from "@features/product/model/enum/image-type";
import {ProductMasterManufacturer} from "@features/product/model/productMasterManufacturer";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {showErrorPopup, showSuccessPopup} from "@shared/utility/popup-message";


@Component({
  selector: 'app-product-update',
  templateUrl: './product-update.component.html',
  styleUrls: ['./product-update.component.scss']
})
export class ProductUpdateComponent implements OnInit {
  productProcessType = ProductProcessType.PRODUCT_UPDATE;

  product!: ProductsDto;

  itemId!: number;
  item!: ItemDto;

  @Input()
  itemImages!: ItemPicturesDto[];
  manufacture!: ProductMasterManufacturer;
  manufacturers: ProductMasterManufacturer[] = [];
  isManufacture!: boolean;

  constructor(private productService: ProductService, private matDialog: MatDialog) {

  }

  ngOnInit(): void {
    this.productService.getItemById(this.itemId).subscribe({
      next: value => {
        this.product = this.convertTo(value);
        this.item = value;
      }
    })
  }

  getManufacturers() {
    this.productService.getManufacturer().subscribe({
      next: value => {
        this.manufacturers = value.body;
      },
      error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      },
    })
  }


  upcField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.UPC);
  }

  eanField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.EAN);
  }

  saveProduct($event: any) {
    this.productService.updateItem(this.convertFormToItemDto($event)).subscribe({
      next: value => {
        showSuccessPopup(TITLE_INFO, value.message).then(() => {
          if (value.status) {
            this.matDialog.closeAll();
          }
        })
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }

  private convertFormToItemDto($event: any) {
    let item: ItemDto = $event.itemDto;
    item.itemSpecifications.forEach(spec => {
      let findSpec = this.item.itemSpecifications.find(find => find.specifications == spec.specifications);
      if (findSpec) {
        spec.id = findSpec.id;
        spec.itemId = findSpec.itemId;
      }
    })

    item.itemCodes.forEach(code => {
      let findCode = this.item.itemCodes.find(find => find.itemCodeType == code.itemCodeType);
      if (findCode) {
        code.id = findCode.id;
        code.idItem = findCode.idItem;
      }
    })

    item.itemPictures.forEach(image => {
      let findImage = this.item.itemPictures.find(find => find.url == image.url);
      if (findImage) {
        image.itemId = findImage.itemId;
        image.id = findImage.id;
      }
    })

    return {
      itemDto: item,
      nameCategories: $event.nameCategories,
    };
  }


  private convertTo(itemDto: ItemDto) {
    let product = new ProductsDto();
    let pictures: ItemPicturesDto[] = [];

    itemDto.itemPictures.forEach(picture => {
      let image = new ItemPicturesDto()
      image.imageType = ImageType.URL;
      image.url = picture.url;
      image.id = picture.id;
      image.itemId = picture.itemId;
      image.status = picture.status;
      image.checked = picture.status;
      image.order = picture.order;
      pictures.push(image);
    })
    let skuSap = ProductUtility.findSapCode(product.itemCustomized);
    if (skuSap) {
      product.skuSap = skuSap.itemCustomizedValue;
    }

    product.id = itemDto.id;
    product.sku = itemDto.sku;
    product.itemDescription = itemDto.itemDescription;
    product.description = itemDto.itemDescription;
    product.partNumber = itemDto.partNumber;
    product.condition = itemDto.condition;
    product.model = itemDto.model;
    product.itemSpecifications = itemDto.itemSpecifications;
    let upc = this.upcField(itemDto);
    let ean = this.eanField(itemDto);
    if (upc) {
      product.upc = upc;
    }
    if (ean) {
      product.ean = ean;
    }

    if (itemDto.manufacturers) {
      product.idManufacturers = itemDto.manufacturers.id;
      product.manufacturer = itemDto.manufacturers.description;

      this.manufacture = new ProductMasterManufacturer();
      this.manufacture.id = itemDto.manufacturers.id;
      this.manufacture.ditmmnf_code = itemDto.manufacturers.id;
      this.manufacture.ditmmnf_front_dsc = itemDto.manufacturers.description;
      this.isManufacture = true;
    } else {
      this.manufacture = new ProductMasterManufacturer();
      this.isManufacture = false;
    }
    let category = new Category()
    category.key = itemDto.itemCategories.parentCode;
    product.selectCategories = category;

    product.selectSudCategories = itemDto.itemCategories.code ?? "";


    product.itemSpecifications = itemDto.itemSpecifications;
    product.images = pictures;

    return product;
  }
}
