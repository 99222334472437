import {Component, Input} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {confirm_popup_html, CONTACT_SUPPORT, TITLE_ERROR, TITLE_INFO} from "@shared";
import {ItemCode} from "@features/product/model/enum/item-code";
import {ProductService} from "@features/product/services/product.service";
import {GetItemParamsDto} from "@features/product/model/dto/product/getItemParams.dto";
import {ITEM_CONST} from "@features/product/model/utility/config/constants/popup-message.const";
import {ProductUtility} from "@features/product/model/utility/productUtility";
import {showErrorPopup, showSomethingWrongPopup1, showSuccessPopup} from "@shared/utility/popup-message";


@Component({
  selector: 'app-add-items-to-order',
  templateUrl: './add-items-to-order.component.html',
  styleUrls: ['./add-items-to-order.component.scss']
})
export class AddItemsToOrderComponent {

  @Input()
  form!: FormGroup;

  @Input()
  purchaseOrderForm!: FormGroup;

  products: ItemDto[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService
  ) {
  }

  get searchValueField() {
    return this.form.get('product_search_value');
  }

  get typeSearchProduct() {
    return this.form.get('type_search_product');
  }

  get productSearchValue() {
    return this.form.get('product_search_value');
  }

  get productSelectValue() {
    return this.form.get('product_select_value') as FormArray;
  }

  get purchaseOrderField() {
    return this.purchaseOrderForm.get('purchase_order') as FormArray;
  }

  get getMarketplaceCostField() {
    return this.purchaseOrderForm.get('get_marketplace_cost') as FormArray;
  }

  getProduct() {
    this.products = [];
    let getItemsParams = new GetItemParamsDto();
    getItemsParams.type = this.typeSearchProduct?.value;
    getItemsParams.value = this.searchValueField?.value;
    return this.productService.geSapProduct(getItemsParams)
    .subscribe({
      next: (finalResult: ItemDto[]) => {
        console.log(finalResult)
        if (finalResult.length > 0) {
          this.products = finalResult;
        } else {
          this.products = [];
          showSomethingWrongPopup1(TITLE_INFO, ITEM_CONST.DEFAULT_MESSAGE_RESPONSE.NOT_MATCH_CRITERIA);
        }

      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })

  }

  upcField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.UPC);
  }

  eanField(product: ItemDto) {
    return ProductUtility.getItemCode(product.itemCodes, ItemCode.EAN);
  }

  getProductSelect(product: ItemDto) {
    let alreadyExists = this.purchaseOrderField.controls.filter(item => item.get('item_sku')?.value === product.sku);
    console.log(alreadyExists)
    if (alreadyExists.length <= 0) {
      this.productService.findItemStateInSap(product.sku).subscribe({
        next: value => {
          console.log(value);
          if (value && value.state === 'tNO') {
            this.activateState(value, product);
          } else {
            this.productService.getMarketplaceCost(product.sku).subscribe({
              next: value => {
                product.costInMarketplace = value.message;
                this.purchaseOrderField.push(this.buildOrderForm(product));
                this.products = [];
              }, error: () => {
                showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
              }, complete: () => {
              }
            });
          }
        }
      });
    } else {
      showSomethingWrongPopup1(TITLE_INFO, 'Item already added, please check!');
      this.products = [];
    }


  }

  private activateState(value: any, product: ItemDto) {
    console.log(value);
    confirm_popup_html("This product is currently marked as \"Inactive\"",
      `
                          <p>You cannot create a purchase order unless the product is back to "Active" status</p>
                          <div style="text-align: start">
                          <h2 >Inactive period</h2>
                          <p>From ${value.validFrom}&nbsp;&nbsp;&nbsp;&nbsp; To  ${value.validTo}</p>
                          <p>Remarks</p>
                          </div>
                          <p style="text-align: center">Would you like us to update this product back to active?</p> `
    ).then(option => {
      if (option.isConfirmed) {
        this.productService.updateItemSapState(value.sku).subscribe({
          next: responseUpdate => {
            if (responseUpdate && responseUpdate.state === 'tYES') {
              showSuccessPopup(TITLE_INFO, 'Updated successfully!').then(() => {
                this.purchaseOrderField.push(this.buildOrderForm(product));
                this.products = [];
              })
            } else {
              showSomethingWrongPopup1(TITLE_ERROR, CONTACT_SUPPORT);
            }

          }, error: () => {
            showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
          }
        })
      }
    })
  }

  private buildOrderForm(item: ItemDto) {
    return this.formBuilder.group({
      item_sku: [item.sku],
      item_description: [item.itemDescription],
      item_quantity: ['', Validators.required],
      item_unit_cost: ['', Validators.required],
      item_total: ['', Validators.required],
      item_vendor: ['', Validators.required],
      item_warehouse: ['', Validators.required],
      get_marketplace_cost: [item.costInMarketplace]
    })
  }
}
