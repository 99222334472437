import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {DASearchBusinessPartnerDto} from "@shared/model/dto/da-search-business-partner.dto";
import {
  DABusinessPartnerPriceListDto
} from "@features/product/model/dto/da-business-partner/DABusinessPartnerPriceList.dto";
import {
  SearchBPPriceListDto
} from "@features/product-offer/model/offer-purchase-order/SearchBPPriceList.dto";
import {
  SearchBusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {
  DABusinessPartnerDto
} from "@features/product/model/dto/da-business-partner/DABusinessPartner.dto";

@Injectable({
  providedIn: 'root'
})
export class DABusinessPartnerService {
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private getBusinessPartnerByOwnerUrl = `${this.automatePurchasesUrl}da-business-partner/get/by/owner`;
  private getBusinessPartnerPriceUrl = `${this.automatePurchasesUrl}da-business-partner/get/price`;
  private getUrl = `${this.automatePurchasesUrl}da-business-partner/get`;
  private getBusinessPartnerPriceByOwnerUrl = `${this.automatePurchasesUrl}da-business-partner/get/all-price/by/owner`;
  private getBusinessPartnerByFatherCardUrl = `${this.automatePurchasesUrl}da-business-partner/get-all-by-fatherCard/`;
  private getBusinessPartnerUrl = `${this.automatePurchasesUrl}da-business-partner/get-business-partner/`;

  constructor(private httpClient: HttpClient) {
  }

  getBusinessPartnerByOwner(searchBusinessPartner: DASearchBusinessPartnerDto): Observable<any> {
    return this.httpClient.post<any>(this.getBusinessPartnerByOwnerUrl, searchBusinessPartner).pipe(
      map((result: any) => {
        let items: Map<string, object> = new Map<string, object>();
        if (result) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return new Map<string, object>();
      })
    )

  }

  getBusinessPartnerPrice(searchBusinessPartner: SearchBPPriceListDto): Observable<DABusinessPartnerPriceListDto> {
    return this.httpClient.post<any>(this.getBusinessPartnerPriceUrl, searchBusinessPartner);
  }

  getBusinessPartnerPriceByOwner(searchBPPriceListDto: SearchBPPriceListDto): Observable<any> {
    return this.httpClient.post<any>(this.getBusinessPartnerPriceByOwnerUrl, searchBPPriceListDto);

  }


  get(search: SearchBusinessPartnerDto): Observable<DABusinessPartnerDto[]> {
    return this.httpClient.post<any>(this.getUrl, search).pipe(
      map((result: any) => {
        let items: DABusinessPartnerDto[] = [];
        if (result) {
          items = result;
        }
        return items;
      }), catchError(() => {
        return [];
      })
    )
  }

  getBusinessPartnerByFatherCard(fatherCard: string): Observable<any> {
    return this.httpClient.get<any>(`${this.getBusinessPartnerByFatherCardUrl}${fatherCard}`);
  }

  getBusinessPartner(cardCode: string): Observable<DABusinessPartnerDto> {
    return this.httpClient.get<DABusinessPartnerDto>(`${this.getBusinessPartnerUrl}${cardCode}`);
  }
}
