import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchBusinessPartnerDto} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {BusinessPartnerType} from "@shared/model/enum/business-partner-type";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {BusinessPartnerService} from "@features/product-offer/services/business-partner.service";
import {DaUserService} from "@shared/services/user/da-user.service";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {FormBuilder, FormGroup} from "@angular/forms";
import {BusinessPartnerDto} from "@features/product-offer/model/offer-purchase-order/business-partner.dto";


@Component({
  selector: 'app-filter-customers-name',
  templateUrl: './filter-customers-name.component.html',
  styleUrls: ['./filter-customers-name.component.scss']
})
export class FilterCustomersNameComponent implements OnInit{

  businessPartnerList: BusinessPartnerDto[] = [];
  userOwnerCode!: string;
  form!: FormGroup;
  @Input()
  label: string = '';

  @Output() emitCardCode = new EventEmitter<any>;

  constructor(private businessPartner: BusinessPartnerService,
              private userService: DaUserService,
              private formBuilder: FormBuilder) {
    this.form = formBuilder.group({
      cardName: [''],
      cardCode: ['']
    })
  }



  ngOnInit(): void {
    this.getOwnerCode();
    this.cardCodeField?.valueChanges.subscribe(value => {
      this.emitCardCode.emit(value);
    });
  }

  get searchValue() {
    return this.form.get('cardName');
  }
  get cardCodeField() {
    return this.form.get('cardCode');
  }


  callBusinessPartner() {
    if (this.userOwnerCode && this.searchValue?.value?.length > 3) {
      let searchBusinessPartnerDto = new SearchBusinessPartnerDto();
      searchBusinessPartnerDto.searchType = 'cardName';
      searchBusinessPartnerDto.value = this.searchValue?.value;
      searchBusinessPartnerDto.userType = BusinessPartnerType.CUSTOMER;
      searchBusinessPartnerDto.ownerCode = Number.parseInt(this.userOwnerCode);
      this.businessPartner.getBusinessPartner(searchBusinessPartnerDto)
        .subscribe({
          next: result => {
            if (result && result.length > 0) {
              this.businessPartnerList = result;
            }
          }, error: () => {
            this.businessPartnerList = [];
          }
        })
    }

  }

  private getOwnerCode() {
    if (!this.userOwnerCode){
      this.userService.findUser().subscribe(result => {
        if (result?.userCustomized?.length > 0) {
          let userCode = this.getSapCode(result.userCustomized);
          if (userCode) {
            this.userOwnerCode = userCode;
          }
        }
      })
    }
  }


  getSapCode(userCustomized: UserCustomizedDto[]) {
    return UserCustomizedUtil.findBuyerOwnerCode(userCustomized)?.userCustomizedValue;
  }

  resetForm() {
    this.form.reset();
  }


}
