import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as htmlToImage from "html-to-image";
import {FileUtil} from "@shared/utility/fileUtil";
import {ItemDto} from "@features/product/model/dto/item/item.dto";
import {ProductOfferService} from "@features/product-offer/services/product-offer.service";
import {CONTACT_SUPPORT, TITLE_INFO} from "@shared";
import {RequestedService} from "@features/request/services/requestedService";
import {NdoTransactionalLogDto} from "@features/request/model/ndo-transactional-log.dto";
import {ItemCustomizedDto} from "@features/product/model/dto/item/itemCustomized.dto";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";


@Component({
  selector: 'app-product-offer-canva',
  templateUrl: './product-offer-canva.component.html',
  styleUrls: ['./product-offer-canva.component.scss']
})
export class ProductOfferCanvaComponent implements OnInit {

  @ViewChild('template', {}) template!: ElementRef;

  @Input()
  item!: ItemDto;
  @Input()
  price!: number | null;

  base64!: string;

  @Input()
  offerId!: number;

  constructor(private productOfferService: ProductOfferService,
              private requestService: RequestedService,
              private authService: AuthService,) {
  }

  ngOnInit() {
    if (this.item) {
      this.downloadImage();
    }
  }

  download() {
    let htmlObject = this.template.nativeElement;
    if (htmlObject) {
      htmlToImage.toJpeg(htmlObject, {skipFonts: true}).then((dataUrl) => {
        FileUtil.downloadFile(dataUrl, this.item.sku + '.jpeg');
      })
      let transactionalLog = new NdoTransactionalLogDto();
      transactionalLog.transactionDetail = 'Offer Canva Dowloaded by Sales';
      transactionalLog.transactionType = 'Update';
      transactionalLog.transactionEntity = 'ndo_offer';
      transactionalLog.idPk = this.offerId ? this.offerId : null;
      console.log(transactionalLog)
      this.requestService.saveTransactionalLog(transactionalLog).subscribe();
    }
  }

  downloadImage() {
    let findAnyImage = this.item.itemPictures && this.item.itemPictures.length > 0 ? this.item.itemPictures.at(0)?.url : undefined;
    let url = this.item.image ? this.item.image : findAnyImage;
    if (url) {
      this.productOfferService.downloadImage(url).subscribe({
        next: value => {
          this.base64 = "data:image/jpeg;base64,".concat(value);
        }, error: () => {
          showErrorPopup(TITLE_INFO, CONTACT_SUPPORT);
        }
      })
    } else {
      showSomethingWrongPopup1(TITLE_INFO, CONTACT_SUPPORT);
    }

  }

  skuSap(itemCustomized: ItemCustomizedDto[]): any {
    const filteredData = itemCustomized.filter((item) => item.itemCustomizedCode == 'sap_itemcode')
    return filteredData.length == 1 ? filteredData[0].itemCustomizedValue : null;
  }
}
