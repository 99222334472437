<div class="list-my-request">
  <div class="list-my-request--title" (click)="toggle(filters)">
    <span>FILTER BY
      <mat-icon>{{ filterOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</mat-icon>
    </span>
    <h2>MY REQUEST</h2>
  </div>
  <mat-card class="list-my-request--card">
      <mat-card-content>
        <form [formGroup]="form" class="row list-my-request--card__form" #filters id="filters">
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Status</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="status" id="status" placeholder="Select">
                <mat-option *ngFor="let state of status " [value]="state">
                  {{requestUtil.getDescriptionStatus(state)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Enter a date range</mat-label>
            <mat-form-field appearance="outline">
              <mat-date-range-input [rangePicker]="picker">
                <input formControlName="initDate" matStartDate placeholder="Start date">
                <input formControlName="endDate" matEndDate placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Buyer</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="buyer" id="buyer" placeholder="Select">
                <mat-option *ngFor="let buyer of buyers " [value]="buyer.id">
                  {{buyer.firstName + ' ' + buyer.lastName + ' - ' + requestUtil.getBuyerName(
                  buyer.userCustomized)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Category</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="category" id="category" placeholder="Select">
                <mat-option *ngFor="let category of categories" [value]="category.key">
                  {{category.values}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Sub Category</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="subcategory" id="sub_category" placeholder="Select">
                <mat-option *ngFor="let category of subcategories" [value]="category.key">
                  {{category.values}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3 list-my-request--card__form--field">
            <mat-label>Manufacturer</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="manufacturer" id="manufacturer" placeholder="Select">
                <mat-option *ngFor="let manufacturer of manufacturers "
                            [value]="manufacturer.ditmmnf_code">
                  {{manufacturer.ditmmnf_front_dsc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-3 list-my-request--card__form--field" >
            <app-filter-customers-card-code  className=""
                                             [multiple]="false"
                                             (emitCardCode)="cardCodeField?.setValue($event)"
                                             label="Select Customer"
            />
          </div>
          <div class="col-3 list-my-request--card__form--field" >
            <app-filter-customers-name  label="Search Customer by Name"
                                        (emitCardCode)="cardCodeField?.setValue($event)"/>
          </div>

          <div class="list-my-request--card__form--actions">
            <button (click)="reset()"
                    mat-raised-button
                    [ngStyle]="{'background':'#efefef'}">
              Reset filter
              <mat-icon>restart_alt</mat-icon>
            </button>
            <button mat-raised-button
                    (click)="filter()"
                    [ngStyle]="{'background':'#005eac', 'color':'white'}">
              Apply filters
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
    <div class="mt-4" style="margin: 0 auto">
      <div class="paginator-container">
        <mat-paginator (page)="pagination($event)"
                       [hidePageSize]="this.paginator.pageIndex != 0"
                       [length]="resultsLength"
                       [pageSizeOptions]="[5, 10, 25, 50]"
                       [pageSize]="50"
                       aria-label="Select page of request Information search results">
        </mat-paginator>
      </div>
      <div class="table-container">

        <table mat-table (matSortChange)="sortChange($event)" [dataSource]="dataSource"
               class="col-12 list-offer-table"
               matSort>
          <caption>Active Request</caption>

          <ng-container matColumnDef="viewRequest">
            <th *matHeaderCellDef mat-header-cell>View Request</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="requestDetailPreview(row)"
                      mat-icon-button
                      [disabled]="!isEditable('viewRequest')">
                <mat-icon svgIcon="view-icon"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="cancelRequest">
            <th *matHeaderCellDef mat-header-cell>Cancel Rqs</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="executeProcess(row, 'edit')"
                      [disabled]="!isEditable('cancelRequest') ||
                      !(row.status==RequestStatus.PENDING || row.status==RequestStatus.SOURCING)"
                      mat-icon-button

              >
                <mat-icon [ngStyle]="{'color':'#29abe2'}">cancel</mat-icon>
              </button>
            </td>
          </ng-container>


          <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="status">Status</th>
            <td *matCellDef="let row" mat-cell> {{requestUtil.getDescriptionStatus(row.status)}}</td>
          </ng-container>


          <ng-container matColumnDef="update">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="requestDateUpdate">Update</th>
            <td *matCellDef="let row"
                mat-cell>
              {{
                (row.requestDateUpdate ? row.requestDateUpdate : row.requestDate)
                | date: 'MM-dd-yyyy'
              }}
            </td>

          </ng-container>


          <ng-container matColumnDef="subCategory">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.itemCategories.code">
              Sub Category
            </th>
            <td *matCellDef="let row" mat-cell>{{row?.itemSubcategory}}</td>
          </ng-container>


          <ng-container matColumnDef="buyer">
            <th *matHeaderCellDef mat-header-cell>Buy.</th>
            <td *matCellDef="let row" mat-cell>{{row?.buyer}}</td>
          </ng-container>


          <ng-container matColumnDef="manufacturer">
            <th *matHeaderCellDef mat-header-cell
                mat-sort-header="offer.item.manufacturers.description">Manufacturer
            </th>
            <td *matCellDef="let row" mat-cell>{{row?.itemManufacturer}}</td>
          </ng-container>

          <ng-container matColumnDef="cardCode">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="cardCode">CC</th>
            <td *matCellDef="let row" mat-cell>{{ row.cardCode ? row.cardCode : ''}}</td>
          </ng-container>

          <ng-container matColumnDef="comments">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="comments">Buy. Comms</th>
            <td *matCellDef="let row" mat-cell>{{ row.comments ? row.comments : ''}}</td>
          </ng-container>

          <ng-container matColumnDef="sku">
            <th *matHeaderCellDef mat-header-cell mat-sort-header="offer.item.sku">SKU</th>
            <td *matCellDef="let row" mat-cell>
              <p *ngIf="row?.sku" style="color: #005eac; cursor: pointer; text-decoration: underline"
                 (click)="router.navigate(['product-offer/list/deals/' + row?.sku])">
                {{row?.sku}}
              </p>
            </td>
          </ng-container>


          <ng-container matColumnDef="viewProduct">
            <th *matHeaderCellDef mat-header-cell>View Product</th>
            <td *matCellDef="let row" mat-cell>
              <button (click)="executeProcess(row, 'view')"
                      [disabled]="!isEditable('viewProduct') || !row.itemId" mat-icon-button>
                <mat-icon svgIcon="view-icon"></mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="opportunity">
              <th *matHeaderCellDef mat-header-cell>Opportunity Result</th>
              <td *matCellDef="let row" mat-cell>
                <button (click)="executeProcess(row, 'typification')"
                        mat-icon-button
                [disabled]="!isEditable('opportunity')">
                  <mat-icon svgIcon="edit-orders-icon"></mat-icon>
                </button>
              </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row>
        </table>
      </div>
    </div>
</div>
<app-loader></app-loader>
