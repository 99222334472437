import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DAUserDto} from "@shared/model/dto/user/da-user.dto";
import {BehaviorSubject, Observable, scan} from "rxjs";
import {DABusinessPartnerDto} from "@features/product/model/dto/da-business-partner/DABusinessPartner.dto";
import {DaUserService} from "@shared/services/user/da-user.service";
import {DASearchBusinessPartnerDto} from "@shared/model/dto/da-search-business-partner.dto";
import {DABusinessPartnerService} from "@features/product/services/da-business-partner.service";
import {UserCustomizedDto} from "@shared/model/dto/userCustomized.dto";
import {UserCustomizedUtil} from "@shared/utility/userCustomizedUtil";
import {SearchBusinessPartnerDto} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {showErrorPopup, showSuccessPopup, TITLE_ERROR, TITLE_SUCCESS} from "@shared/utility/popup-message";
import {ItemInventoryService} from "@features/product-offer/services/item-inventory.service";
import {CONTACT_SUPPORT} from "@shared";

@Component({
  selector: 'app-download-inventory',
  templateUrl: './download-inventory.component.html',
  styleUrls: ['./download-inventory.component.scss']
})
export class DownloadInventoryComponent implements OnInit{
  form!: FormGroup ;
  formSearch!: FormGroup ;
  users: DAUserDto[] = [];

  options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
  options$: Observable<DABusinessPartnerDto[]> | undefined;
  offset = 0;
  total = 10000000;
  limit = 20;
  businessPartnerList: DABusinessPartnerDto[] = [];

  constructor(private daUserService: DaUserService,
              private formBuilder: FormBuilder,
              private dABusinessPartnerService: DABusinessPartnerService,
              private itemInventoryService: ItemInventoryService) {
    this.form = this.formBuilder.group({
      ownerCode: [''],
      cardCode: ['', Validators.required],
      searchType: ['cardName'],
      searchValue: [''],
    })

    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );
  }

  ngOnInit(): void {
      this.daUserService.findAllSalesPersonForDashboard()
        .subscribe(result => this.initCallBP(result));
  }

  private initCallBP(result: DAUserDto[]) {
    if (result && result.length > 0) {
      this.users = result
      let searchBusinessPartner = new DASearchBusinessPartnerDto();
      this.setOwnerCode(searchBusinessPartner);
      this.callDataBP(searchBusinessPartner);
    }
  }

  private callDataBP(searchBusinessPartner: DASearchBusinessPartnerDto) {
    this.dABusinessPartnerService.getBusinessPartnerByOwner(searchBusinessPartner)
      .subscribe((result: any) => {
        if (result && result.items.length > 0) {
          this.options.next(result.items as DABusinessPartnerDto[])
        }
      })
  }


  private setOwnerCode(searchBusinessPartner: DASearchBusinessPartnerDto) {
    if (this.users.length == 1) {
      this.ownerCodeField?.setValue(this.users.at(0))
      let userCustomized = this.users.at(0)?.userCustomized;
      let userCode = userCustomized ? this.getSapCode(userCustomized) : null;
      if (userCode) {
        searchBusinessPartner.ownerCode = Number(userCode);
      }
    } else if (this.ownerCodeField?.value) {
      let userCustomized = this.ownerCodeField?.value?.userCustomized;
      let userCode = userCustomized ? this.getSapCode(userCustomized) : null;
      if (userCode) {
        searchBusinessPartner.ownerCode = Number(userCode);
      }
    }
  }

  changeSet(user: DAUserDto) {
    this.cardCodeField?.reset();
    this.autoCardCodeField?.reset();
    this.ownerCodeField?.setValue(user);
    this.options = new BehaviorSubject<DABusinessPartnerDto[]>([]);
    this.options$ = this.options.asObservable().pipe(
      scan((acc: DABusinessPartnerDto[], curr) => {
        return [...acc, ...curr];
      }, [])
    );
    this.offset = 0;
    this.getNextBatch();
  }


  getNextBatch() {
    this.offset += this.limit;
    let searchBusinessPartner = new DASearchBusinessPartnerDto();
    searchBusinessPartner.totalItems = this.offset;
    this.setOwnerCode(searchBusinessPartner);
    this.callDataBP(searchBusinessPartner);
  }






  filter(){
    let search = new SearchBusinessPartnerDto();
    search.searchType = this.form.get('searchType')?.value;
    search.value = this.form.get('searchValue')?.value;
    this.dABusinessPartnerService.get(search)
      .subscribe(result => {
        if(result.length > 0){
          this.businessPartnerList = result
        }else {
          showErrorPopup(TITLE_ERROR,
            'We weren\'t able to find any user that match your search criteria, please check')
        }

      });
  }

  download(){
    if (this.cardCodeField?.value){
      this.itemInventoryService.downloadInventory({cardCode: this.cardCodeField?.value})
        .subscribe({
        next: () =>  {
          showSuccessPopup(TITLE_SUCCESS,
            'The Items inventory File will be send to your email registered.')
        },error: () => showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT)
      })
    }else {
      showErrorPopup(TITLE_ERROR, 'There is not user selected, please check')
    }

  }

  selectBusinessPartner(businessPartner: DABusinessPartnerDto) {
    this.cardCodeField?.setValue(businessPartner.cardCode);
    this.download();
  }

  getSapCode(userCustomized: UserCustomizedDto[]) {
    return UserCustomizedUtil.findBuyerOwnerCode(userCustomized)?.userCustomizedValue;
  }


  userName(user: DAUserDto) {
    return user.firstName + ' ' + user.lastName + ' - ' + this.getUserName(user.userCustomized);
  }

  getUserName(userCustomized: UserCustomizedDto[]) {
    let userCustomizeCode = UserCustomizedUtil.findSalesSapCode(userCustomized)?.userCustomizedValue;
    return userCustomized && userCustomized.length > 0 ? userCustomizeCode : '';
  }



  get ownerCodeField() {
    return this.form.get('ownerCode');
  }

  get cardCodeField() {
    return this.form.get('cardCode');
  }

  get autoCardCodeField() {
    return this.form.get('autoCardCode');
  }
}
