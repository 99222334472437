<div  class="col-12 close_button">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button>
    <mat-icon svgIcon="close-popup-icon">close</mat-icon>
  </button>
</div>
<div class="dashboard-item-purchases-container">
  <h2>Purchases for Item {{sku}}  -  {{itemDescription}}</h2>
  <app-filter-business-partner [title]="''"
                               [process]=NameProcessLogEnum.ITEM_PURCHASES_DASHBOARD
                               [sapUsertype]="SapUserType.SAP_SALES"
                               (emitFormValue)="filterDashBoard($event)"
                               (emitUsers)="usersResult($event)"
                               [form]="form"
  >
  </app-filter-business-partner>
  <div class="dashboard-item-purchases-container--superset-dashboard" style="background: white">
    <div id="dashboard-item-purchases" style="background: white"></div>
  </div>
</div>

