import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-product-process-type',
  templateUrl: './product-process-type.component.html',
  styleUrls: ['./product-process-type.component.scss']
})
export class ProductProcessTypeComponent {

  @Input()
  processType!: FormControl;


}
