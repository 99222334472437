<div class="col-12 close_button" id="close-button-container">
  <button [mat-dialog-close]="true" class="close-button" mat-icon-button id="close-button">
    <mat-icon svgIcon="close-popup-icon" id="close-popup-icon">close</mat-icon>
  </button>
</div>
<div class="change-log-detail" id="change-log-detail">
  <ng-container *ngIf="changeLogDetail.length > 0; else notFound" id="change-log-container">
    <div id="table-wrapper">
      <table [dataSource]="changeLogDetail" mat-table id="change-log-table">
        <caption id="table-caption"></caption>
        <ng-container matColumnDef="date" id="date-column">
          <th *matHeaderCellDef mat-header-cell id="date-header">Date Time</th>
          <td *matCellDef="let row" mat-cell id="date-cell">
            {{ row?.operationDatetime |  date:'yyyy-MM-dd HH:mm:ss' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="changeDetail" id="change-detail-column">
          <th *matHeaderCellDef mat-header-cell id="change-detail-header">Change Detail</th>
          <td *matCellDef="let row" mat-cell id="change-detail-cell">
            <p style="text-align: start; margin: 0" id="change-detail-text">{{ row?.description}}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="previousValue" id="previous-value-column">
          <th *matHeaderCellDef mat-header-cell id="previous-value-header">Previous Value</th>
          <td *matCellDef="let row" mat-cell id="previous-value-cell" [innerHTML]="row?.previousValue"></td>
        </ng-container>
        <ng-container matColumnDef="newValue" id="new-value-column">
          <th *matHeaderCellDef mat-header-cell id="new-value-header">New Value</th>
          <td *matCellDef="let row" mat-cell id="new-value-cell" [innerHTML]="row?.newValue"></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row id="header-row"></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row id="data-row-{{row?.operationDatetime}}"></tr>
      </table>
    </div>
  </ng-container>

  <ng-template #notFound>
    <p id="not-found-text">Not data Found</p>
  </ng-template>
</div>
