import {Component, ElementRef, ViewChild} from '@angular/core';
import {FileUtil} from "@shared/utility/fileUtil";
import {FormControl, Validators} from "@angular/forms";
import {
  CONTACT_SUPPORT,
  custom_popup,
  TITLE_ERROR,
  TITLE_INFO,
  TITLE_SUCCESS
} from "@shared";
import {ProductService} from "@features/product/services/product.service";
import {AuthService} from "@core/services";
import {showErrorPopup, showSomethingWrongPopup1} from "@shared/utility/popup-message";

@Component({
  selector: 'app-product-upload-template',
  templateUrl: './product-upload-template.component.html',
  styleUrls: ['./product-upload-template.component.scss']
})
export class ProductUploadTemplateComponent {

  fileControl = new FormControl({}, Validators.required);
  xlsxFileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  valid = false;

  @ViewChild('uploadInput')
  uploadInput!: ElementRef;


  constructor(private productService: ProductService,
              private authService: AuthService) {
  }


  validateFile($event: any) {
    console.log($event.target.files);
    if ($event.target.files && $event.target.files[0]) {
      if (String($event.target.files[0].type) === this.xlsxFileType) {
        this.authService._getFromPayload("id_user").then(userId => {
          FileUtil.convertFileToBase64($event.target.files[0]).then((convertedToBase64: any) => {
            this.fileControl?.setValue(
              {
                fileName: $event.target.files[0].name,
                fileContent: convertedToBase64,
                userId: userId
              }
            );
          })
        })

        this.valid = true;
      } else {
        showSomethingWrongPopup1(TITLE_INFO, 'File type invalid, please check!')
        this.valid = false;
      }

    }
  }

  uploadFile() {
    if (this.fileControl.valid) {
      console.log(this.fileControl.value);
      this.productService.saveMassiveProduct(this.fileControl.value).subscribe({
        next: value => {
          custom_popup({
            title: value ? TITLE_SUCCESS : TITLE_ERROR,
            confirmButtonText: 'OK',
            confirmButtonColor: '#4facff',
            customClass: {
              popup: 'sweet-customized-popup',
              actions: 'sweet-customized-actions',
              confirmButton: 'sweet-customized-confirm-button',
            },
            html: value?.message ? value.message : CONTACT_SUPPORT,
          }).then(() => {
            this.fileControl.reset();
            this.uploadInput.nativeElement.value = ""
          })
        },
        error: () => {
          showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
        }
      })
    }
  }

  downloadTemplate() {
    this.productService.downloadTemplate().subscribe({
      next: value => {
        FileUtil.downloadFile(value, 'MASTER_ITEM_TEMPLATE.xlsx');
      }, error: () => {
        showErrorPopup(TITLE_ERROR, CONTACT_SUPPORT);
      }
    })
  }
}
