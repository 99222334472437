import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from "@angular/forms";
import {Subscription} from "rxjs";

export interface AccountInformationFormValues {
  email: string;
  password: string;
}

@Component({
  selector: 'app-account-information-login',
  templateUrl: './account-information-login.component.html',
  styleUrls: ['./account-information-login.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccountInformationLoginComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AccountInformationLoginComponent),
      multi: true,
    }
  ]
})
export class AccountInformationLoginComponent implements OnInit, ControlValueAccessor, OnDestroy {

  @Input()
  form!: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  @Output() emitLogin = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  get value(): AccountInformationFormValues {
    return this.form.value;
  }

  set value(value: AccountInformationFormValues) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get passwordField() {
    return this.form.get('password');
  }

  get emailField() {
    return this.form.get('email');
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      console.log(value);
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {personal_information: {valid: false,},};
  }

  private buildForm() {
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  submit() {
    this.emitLogin.emit(true);
  }

  onEnter(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    keyboardEvent.preventDefault();
    this.submit();
  }
}
