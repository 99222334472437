import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "@env";
import {
  SearchBusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/search-business-partner.dto";
import {Observable} from "rxjs";
import {
  BusinessPartnerDto
} from "@features/product-offer/model/offer-purchase-order/business-partner.dto";
import {SearchBPPriceListDto} from "@features/product-offer/model/offer-purchase-order/SearchBPPriceList.dto";
import {
  BusinessPartnerPriceListDto
} from "@features/product-offer/model/offer-purchase-order/BusinessPartnerPriceList.dto";

@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {
  private automatePurchasesUrl = environment.automatePurchasesUrl;
  private getBusinessPartnerUrl = `${this.automatePurchasesUrl}purchase-order/get/business/partner`;
  private getBusinessPartnerPriceUrl = `${this.automatePurchasesUrl}da-business-partner/get/price`;
  private getBusinessPartnerPriceByOwnerUrl = `${this.automatePurchasesUrl}da-business-partner/get/all-price/by/owner`;

  constructor(private httpClient: HttpClient) {
  }

  getBusinessPartner(searchBusinessPartner: SearchBusinessPartnerDto): Observable<BusinessPartnerDto[]> {
    return this.httpClient.post<BusinessPartnerDto[]>(this.getBusinessPartnerUrl, searchBusinessPartner);
  }

  getBusinessPartnerPrice(searchBPPriceListDto: SearchBPPriceListDto){
    return this.httpClient.post<BusinessPartnerPriceListDto>(this.getBusinessPartnerPriceUrl, searchBPPriceListDto);
  }




}
