<div>
  <section>
    <form [formGroup]="form">
      <div class="row">
        <mat-form-field appearance="outline" class="col-12">
          <mat-icon matPrefix svgIcon="account-email"></mat-icon>
          <mat-label>Email</mat-label>
          <input formControlName="email"
                 id="account_email"
                 matInput
                 type="email"
          >
        </mat-form-field>
        <div *ngIf="emailField!.touched && emailField!.invalid">
          <mat-error *ngIf="emailField!.hasError('pattern')">
            Please enter a valid email address
          </mat-error>
        </div>
      </div>
      <div class="row">
        <mat-form-field appearance="outline" class="col-12">
          <mat-icon matPrefix svgIcon="account-password-pointer"></mat-icon>
          <mat-label>Current Password</mat-label>
          <input [type]="hideOldPassword ? 'password' : 'text'"
                 autocomplete="on"
                 formControlName="old_password"
                 id="old_password"
                 matInput
                 type="password"
          >
          <button (click)="hideOldPassword = !hideOldPassword" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hideOldPassword"
                  mat-icon-button
                  matSuffix type="button">
            <mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline" class="col-12 col-lg-6">
          <mat-icon matPrefix svgIcon="account-password-pointer"></mat-icon>
          <mat-label>New Password</mat-label>
          <input [type]="hidePassword ? 'password' : 'text'"
                 autocomplete="on"
                 formControlName="password"
                 id="account_password"
                 matInput
                 type="password"
          >
          <button (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hidePassword" mat-icon-button
                  matSuffix type="button">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-12 col-lg-6">
          <mat-icon matPrefix svgIcon="account-password-pointer"></mat-icon>
          <mat-label>Confirm Password</mat-label>
          <input [type]="hideConfirmPassword ? 'password' : 'text'"
                 autocomplete="on"
                 formControlName="confirm_password"
                 id="account_confirm_password"
                 matInput
                 type="password"
          >
          <button (click)="hideConfirmPassword = !hideConfirmPassword"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword"
                  mat-icon-button
                  matSuffix type="button">
            <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="confirmPasswordField!.touched && confirmPasswordField!.valid">
          <mat-error *ngIf="form.hasError('match_password')">
            Password does not match, please check!
          </mat-error>
        </div>
        <div *ngIf="newPasswordField!.touched && newPasswordField!.invalid">
          <mat-error *ngIf="newPasswordField!.hasError('minlength')">
            Must be at least 6 characters!
          </mat-error>
          <mat-error *ngIf="newPasswordField!.hasError('has_not_number')">
            Must contain at least 1 number!
          </mat-error>
          <mat-error *ngIf="newPasswordField!.hasError('has_not_upper_case')">
            Must contain at least 1 in Capital Case!
          </mat-error>
          <mat-error *ngIf="newPasswordField!.hasError('has_not_lower_case')">
            Must contain at least 1 Letter in Small Case!
          </mat-error>
          <mat-error *ngIf="newPasswordField!.hasError('has_not_special_character')">
            Must contain at least 1 Special Character!
          </mat-error>
        </div>
      </div>

    </form>
  </section>
  <mat-card-actions>
    <div class="submit">
      <button (click)="submit()" class="btn">Save</button>
    </div>
  </mat-card-actions>
</div>

