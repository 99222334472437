<div class="col-12 close-button-template">
  <button [mat-dialog-close]="true" mat-icon-button>
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
</div>
<div class="containerModal">
  <h3>Sourced - Previous Stock/Sourced - Similar Item</h3>
  <div *ngFor="let product of products" class="relatedProducts">
    <p><strong>SKU:</strong>{{product.sku}}</p>
    <p><strong>Item Description:</strong> {{product.itemDescription}}</p>
  </div>
</div>

