export enum MessageType {
  Email = 'Email',
  Whatsapp = 'WhatsApp'
}

export class DealsNotificationSegmentDto {
  id!: number;
  dealNotificationId!: number;
  name!: string;
  segment!: string;
  messageType!: MessageType;
}
